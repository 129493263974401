import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../../_helper/http.form.codec';
import { ParamsHelper } from '../../_helper/params';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function UserService(http) {
        this.http = http;
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    UserService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     */
    UserService.prototype.getAll = function () {
        return this.http.get(environment.apiv2_server + "/su/user", { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    UserService.prototype.get = function (id) {
        return this.http.get(environment.apiv2_server + "/su/user/" + id, { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    UserService.prototype.update = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.put(environment.apiv2_server + "/su/user", body, { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
