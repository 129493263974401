<div class="container">
    <div class="row">
        <!--end of col-->
        <div class="col-12 col-md-3">
            <div class="card card-sm">
                <ul class="list-group">
                    <li class="list-group-item bg-light">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_SmsSettings()"
                            translate>btn-sms-settings
                        </button>
                    </li>
                    <li class="list-group-item bg-light">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_EmailSettings()"
                            translate>btn-email-settings
                        </button>
                    </li>
                    <li class="list-group-item bg-light">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_LockApi()" translate>btn-exivo-settings
                            </button>
                    </li>
                    <li class="list-group-item bg-light">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_Settings()" translate>btn-misc-settings
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-md-9">
            <div class="card card-sm" *ngIf="is_on_settings">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <div>
                        <h6>{{title}}</h6>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-vat-appied-to-all-services-by-default</label><br>
                            <input class="form-control form-control-lg"
                                type="number" [(ngModel)]="vat"
                                placeholder="e.g. 14" />
                            <div><small class="text text-muted" translate>lbl-vat-info</small></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="enable_categories">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="enable_categories ===
                                    boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (enable_categories=
                                    boolean_status[0].value) :
                                    (enable_categories=
                                    boolean_status[1].value);"
                                    id="enable_categories">
                                <label class="custom-control-label"
                                    for="enable_categories" translate>lbl-enable-categories</label>
                                <small translate>txt-enable-categories-information</small>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="enable_internal_booking">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="enable_internal_booking ===
                                    boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (enable_internal_booking=
                                    boolean_status[0].value) :
                                    (enable_internal_booking=
                                    boolean_status[1].value);"
                                    id="enable_internal_booking">
                                <label class="custom-control-label"
                                    for="enable_internal_booking" translate>lbl-enable-internal-bookings</label>
                                <small translate>txt-internal-booking-information</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-4">
                        <div translate="" class="title-decorative mb-1">heading-landing-page-options</div>
                        <div class="col-6">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="is_show_working_hours">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="is_show_working_hours ===
                                    boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (is_show_working_hours=
                                    boolean_status[0].value) :
                                    (is_show_working_hours=
                                    boolean_status[1].value);"
                                    id="is_show_working_hours">
                                <label class="custom-control-label"
                                    for="is_show_working_hours" translate>lbl-is-show-working-hours</label>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="is_show_map_address">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="is_show_map_address ===
                                    boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (is_show_map_address=
                                    boolean_status[0].value) :
                                    (is_show_map_address=
                                    boolean_status[1].value);"
                                    id="is_show_map_address">
                                <label class="custom-control-label"
                                    for="is_show_map_address" translate>lbl-is-show-map-address</label>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="is_show_about_us">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="is_show_about_us ===
                                    boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (is_show_about_us=
                                    boolean_status[0].value) :
                                    (is_show_about_us=
                                    boolean_status[1].value);"
                                    id="is_show_about_us">
                                <label class="custom-control-label"
                                    for="is_show_about_us" translate>lbl-is-show-about-us</label>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4 mt-4">
                        <div translate="" class="title-decorative mb-1">heading-how-will-customer-view-accommodation-calendar</div>
                        <div class="form-group">
                            <div class="col-12">
                                <div class="custom-control custom-radio">
                                    <input id="customer_calendar_ui_mode_1" type="radio" class="custom-control-input"
                                        [value]="1" [(ngModel)]="customer_calendar_ui_mode">
                                    <label class="custom-control-label" for="customer_calendar_ui_mode_1" translate>lbl-customer-calendar-ui-mode-1</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="custom-control custom-radio">
                                    <input id="customer_calendar_ui_mode_2" type="radio" class="custom-control-input" [value]="2"
                                        [(ngModel)]="customer_calendar_ui_mode">
                                    <label class="custom-control-label" for="customer_calendar_ui_mode_2" translate>lbl-customer-calendar-ui-mode-2</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card-footer bg-light">
                    <button class="btn btn-lg btn-primary"
                        (click)="onClick_Save()" translate>btn-save</button>
                </div>
            </div>

            <div class="card card-sm" *ngIf="is_on_sms_settings">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <div>
                        <h6>{{title}}</h6>
                    </div>
                </div>
                <div class="card-body">
                    <div class="alert alert-primary" translate>txt-enabling-sms-information</div>
                    <div class="alert {{sms_alert_class}}">
                        <span class=""><span translate>lbl-sms-credits-left</span>&nbsp;
                            <strong>{{sms_credits}}</strong></span>
                    </div>
                    <hr>
                    <div class="title-decorative" translate>heading-enable-sms-globally</div>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="is_activated_sms_service">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="is_activated_sms_service ===
                                    boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (is_activated_sms_service=
                                    boolean_status[0].value) :
                                    (is_activated_sms_service=
                                    boolean_status[1].value);"
                                    id="is_activated_sms_service">
                                <label class="custom-control-label"
                                    for="is_activated_sms_service" translate>lbl-enable</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-12">
                            <label translate>lbl-sender-name</label>
                            <input class="form-control form-control-lg"
                                mask='AAAAAAAAAAA' maxlength="11" type="text"
                                [(ngModel)]="sms_sender_name" />
                        </div>
                    </div>
                    <hr>
                    <div class="title-decorative" translate>heading-booking-reminders</div>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="is_send_sms_confirmation_on_booking">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="is_send_sms_confirmation_on_booking
                                    === boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (is_send_sms_confirmation_on_booking=
                                    boolean_status[0].value) :
                                    (is_send_sms_confirmation_on_booking=
                                    boolean_status[1].value);"
                                    id="is_send_sms_confirmation_on_booking">
                                <label class="custom-control-label"
                                    for="is_send_sms_confirmation_on_booking"
                                    translate>lbl-send-booking-confirmation-to-customer-via-sms</label>
                                <small translate>txt-you-must-have-enough-sms-credit-and-sms-must-be-enabled</small>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col">
                            <h6 translate>heading-send-sms-reminder-to-customer</h6>
                        </div>
                    </div>
                    <div class="form-row form-group">
                        <div class="col-sm-12 col-lg-6 col-md-6">
                            <select class="form-control form-control-lg"
                                [(ngModel)]="reminder_via_sms_x_hrs_before_booking">
                                <option *ngFor="let m of sms_reminder_stack"
                                    [value]="m.in_min">
                                    {{m.value}} {{m.unit}} <span translate>txt-before-booking</span>
                                </option>
                            </select>
                            <small translate>txt-you-must-have-enough-sms-credits-and-sms-must-be-enabled</small>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-light">
                    <button class="btn btn-lg btn-primary"
                        (click)="onClick_Save()" translate>btn-save</button>
                    <button class="btn btn-lg btn-success float-right"
                        (click)="onClick_BuySMS()" translate>btn-buy-sms-credits
                        <i class="icon icon-chevron-right"></i></button>
                </div>
            </div>

            <div class="card card-sm" *ngIf="is_on_email_settings">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <div>
                        <h6>{{title}}</h6>
                    </div>
                </div>
                <div class="card-body">
                    <div class="title-decorative" translate>heading-reminders</div>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="is_send_email_confirmation_to_provider_on_booking">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="is_send_email_confirmation_to_provider_on_booking
                                    === boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (is_send_email_confirmation_to_provider_on_booking=
                                    boolean_status[0].value) :
                                    (is_send_email_confirmation_to_provider_on_booking=
                                    boolean_status[1].value);"
                                    id="is_send_email_confirmation_to_provider_on_booking">
                                <label class="custom-control-label"
                                    for="is_send_email_confirmation_to_provider_on_booking"
                                    translate>
                                    lbl-send-email-confirmation-to-us-on-new-booking</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="is_send_email_confirmation_to_provider_on_booking_cancel">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="is_send_email_confirmation_to_provider_on_booking_cancel
                                    === boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (is_send_email_confirmation_to_provider_on_booking_cancel=
                                    boolean_status[0].value) :
                                    (is_send_email_confirmation_to_provider_on_booking_cancel=
                                    boolean_status[1].value);"
                                    id="is_send_email_confirmation_to_provider_on_booking_cancel">
                                <label class="custom-control-label"
                                    for="is_send_email_confirmation_to_provider_on_booking_cancel"
                                    translate>
                                    lbl-send-email-to-us-when-booking-cancelled</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="is_send_email_confirmation_to_provider_on_booking_changed">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="is_send_email_confirmation_to_provider_on_booking_changed
                                    === boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (is_send_email_confirmation_to_provider_on_booking_changed=
                                    boolean_status[0].value) :
                                    (is_send_email_confirmation_to_provider_on_booking_changed=
                                    boolean_status[1].value);"
                                    id="is_send_email_confirmation_to_provider_on_booking_changed">
                                <label class="custom-control-label"
                                    for="is_send_email_confirmation_to_provider_on_booking_changed"
                                    translate>
                                    lbl-send-email-to-us-when-booking-modified</label>
                            </div>
                        </div>
                    </div>
                    <!--
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox custom-checkbox-switch">
                                <input type="hidden" [(ngModel)]="is_send_email_confirmation_to_provider_on_booking_finished">
                                <input type="checkbox" class="custom-control-input" [checked]="is_send_email_confirmation_to_provider_on_booking_finished === boolean_status[0].value"
                                    (change)="$event.target.checked? (is_send_email_confirmation_to_provider_on_booking_finished = boolean_status[0].value) :
                                    (is_send_email_confirmation_to_provider_on_booking_finished = boolean_status[1].value);"
                                    id="is_send_email_confirmation_to_provider_on_booking_finished">
                                <label class="custom-control-label" for="is_send_email_confirmation_to_provider_on_booking_finished"
                                    translate>
                                    lbl-send-email-to-us-when-booking-finished</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox custom-checkbox-switch">
                                <input type="hidden" [(ngModel)]="is_send_email_confirmation_to_customer_on_booking_finished">
                                <input type="checkbox" class="custom-control-input" [checked]="is_send_email_confirmation_to_customer_on_booking_finished === boolean_status[0].value"
                                    (change)="$event.target.checked? (is_send_email_confirmation_to_customer_on_booking_finished = boolean_status[0].value) :
                                    (is_send_email_confirmation_to_customer_on_booking_finished = boolean_status[1].value);"
                                    id="is_send_email_confirmation_to_customer_on_booking_finished">
                                <label class="custom-control-label" for="is_send_email_confirmation_to_customer_on_booking_finished"
                                    translate>
                                    lbl-send-email-to-customer-when-booking-finished</label>
                            </div>
                        </div>
                    </div>
                    -->
                    <div class="row form-group">
                        <div class="col-12">
                            <label translate>lbl-add-more-emails-for-notifications</label>
                            <input class="form-control form-control-lg"
                                type="text"
                                [(ngModel)]="extra_emails_for_notification"
                                placeholder="" />
                            <small class="text text-primary">comma ',' separated</small>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col">
                            <h6 translate>heading-send-email-reminder-to-customer</h6>
                        </div>
                    </div>
                    <div class="form-row form-group">
                        <div class="col-sm-12 col-lg-6 col-md-6">
                            <select class="form-control form-control-lg"
                                [(ngModel)]="reminder_via_email_x_hrs_before_booking">
                                <option *ngFor="let m of sms_reminder_stack"
                                    [value]="m.in_min">
                                    {{m.value}} {{m.unit}} before booking
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="card-footer bg-light">
                    <button class="btn btn-lg btn-primary"
                        (click)="onClick_Save()" translate>btn-save</button>
                </div>
            </div>

            <div class="card card-sm" *ngIf="is_on_buy_sms">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <div>
                        <h6>{{title}}</h6>
                    </div>
                </div>
                <div class="card-body mb-10">
                    <div class="row mb-1">
                        <div class="col">
                            <h6 translate>heading-select-sms-credits-to-buy</h6>
                        </div>
                    </div>
                    <div class="form-row form-group">
                        <div class="col-sm-12 col-lg-6 col-md-6">
                            <select class="form-control form-control-lg"
                                (change)="onChange_BuySMSCreditStack(c.price)">
                                <option *ngFor="let c of sms_credits_stack"
                                    [value]="c.price">
                                    {{c.credits}} <span translate>txt-credits-for</span>
                                    {{c.price}} NOK
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-secondary">
                    <div class="card-body shadow border pt-3 pb-3">
                        <div class="row col
                            text-danger"
                            *ngIf="business.cards.length
                        <1">
                            Cards not found.
                        </div>
                        <div class="row
                            form-group"
                            *ngIf="business.cards.length>
                            0">
                            <div
                                class="col">
                                <label
                                    translate>Select
                                    A Card
                                    For
                                    Payment</label>
                                <br>
                                <select
                                    [(ngModel)]="selected_existing_card"
                                    class="form-control">
                                    <option
                                        [value]="cc.gateway_token"
                                        *ngFor="let
                                        cc
                                        of
                                        business.cards">{{cc.card_number}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                
                    <button (click)="onClick_BuyNow()"  *ngIf="business.cards.length>0" class="mt-3 btn btn-lg btn-dark">Buy Now</button>
                </div>
            </div>

            <div class="card card-sm" *ngIf="is_on_lock_api">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <div>
                        <h6>{{title}}</h6>
                    </div>
                </div>
                <div class="card-body">
                    <div class="alert alert-danger" *ngIf="!current_plan.is_allowed_to_use_exivo_services" translate>
                        Exivo is only available Pro Plan. It cost 249 NOK/month. 
                    </div>
                    <div class="alert alert-primary" *ngIf="current_plan.is_allowed_to_use_exivo_services" translate>
                        txt-you-must-also-explicitly-enable-lock-api-on-each-service-and-calendar
                    </div>
                    <div class="row form-group" *ngIf="current_plan.is_allowed_to_use_exivo_services">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="enable_exivo_lock_api">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="enable_exivo_lock_api ===
                                    boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (enable_exivo_lock_api=
                                    boolean_status[0].value) :
                                    (enable_exivo_lock_api=
                                    boolean_status[1].value);"
                                    id="enable_exivo_lock_api">
                                <label class="custom-control-label"
                                    for="enable_exivo_lock_api" translate>
                                    <span translate="">lbl-enable-exivo-lock-api </span><span translate="" class="text text-success pl-3 ">  +249 NOK/month</span></label>
                                    
                            </div>
                        </div>
                    </div>
                    <div class="card bg-light" *ngIf="enable_exivo_lock_api ==
                        '1'">
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-12">
                                    <label translate>lbl-lock-code-pattern</label>
                                    <div class="">
                                        <select class="form-control
                                            form-control-lg"
                                            [(ngModel)]="exivo_lock_code_pattern">
                                            <option *ngFor="let ex of
                                                exivo_lock_code_pattern_stack"
                                                [value]="ex.value" translate>
                                                {{ex.label}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-12">
                                    <label translate>lbl-send-lock-code-to-customer-before-checkin</label>
                                    <div class="">
                                        <select class="form-control
                                            form-control-lg"
                                            [(ngModel)]="exivo_send_lock_code_before_x_minute">
                                            <option *ngFor="let ex of
                                                exivo_send_lock_code_before_x_minute_stack"
                                                [value]="ex.value" translate>
                                                {{ex.label}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-12">
                                    <label translate>lbl-exivo-system-id</label>
                                    <input  autocomplete="off" class="form-control form-control-lg"
                                        type="text"
                                        [(ngModel)]="exivo_system_id" />
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-12">
                                    <label translate>lbl-exivo-api-key</label>
                                    <input autocomplete="off"  class="form-control form-control-lg"
                                        type="text" [(ngModel)]="exivo_api_key"
                                        />
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-12">
                                    <label translate>lbl-exivo-api-secret</label>
                                    <input autocomplete="off"  class="form-control form-control-lg"
                                        type="text"
                                        [(ngModel)]="exivo_api_secret" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-light" *ngIf="is_show_save_btn">
                    <button class="btn btn-lg btn-primary"
                        (click)="onClick_Save()" translate>btn-save</button>
                </div>
            </div>
        </div>
    </div>
</div>