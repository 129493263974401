import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClientModule,HttpParams,HttpHeaders } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import { ParamsHelper } from '../_helper/params';
/**
 * 
 */
@Injectable()
export class AuthService {

    params: any;
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) { 

        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    }

    /**
     * 
     */
    _getBody(){
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    signin(username: string, password: string,subdomain: string,customer_only = "0", must_be_existing_customer = "0") {
        let body = this._getBody()
        .append('username', username)
        .append("customer_only",customer_only)
        .append("must_be_existing_customer",must_be_existing_customer)
        .append('password', password).append('subdomain',subdomain);
        return this.http.post(environment.server+"/acl/v1/auth/login",body, { headers: this.headers })
            .pipe(map(data => {
                localStorage.setItem('currentUser', JSON.stringify(data));
                return data;
            }));
    }

        /**
     * 
     * @param username 
     * @param password 
     */
    signin2(_p) {
        let body = this.params.make(this._getBody(), _p);

        return this.http.post(environment.server+"/acl/v1/auth/login",body, { headers: this.headers })
            .pipe(map(data => {
                localStorage.setItem('currentUser', JSON.stringify(data));
                return data;
            }));
    }

    /** 
     * 
    */
    customerSignup(_p){

        let temp = this._getBody();        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/acl/v1/auth/customer-signup",body, { headers: this.headers })
            .pipe(map(data => {
                localStorage.setItem('currentUser', JSON.stringify(data));
                return data;
            }));
    
    }

    /**
     * 
     */
    isLoggedInOnServer(){
        let user = JSON.parse(localStorage.getItem("currentUser"));
        if(user != null && user != undefined){
            let body = this._getBody().append("auth_token", user.data.auth_token);
            return this.http.post(environment.server+"/acl/v1/auth/is-logged-in",body, { headers: this.headers })
                .pipe(map(data => {
                    return data;
                }));
        }

    }

    /** 
     * 
    */
    providerSignup(_p){

        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody();        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/acl/v1/business/add",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    
    }
         
        
    /**
     * 
     * @param temp 
     * @param _p 
     */
    _append(temp,_p){
        
        Object.keys(_p).forEach(k=>{
            let t = temp.append(k,_p[k]);   
            // below line is important because temp only return
            // it does not update temp 
            temp = t;
        });
        return temp;
    }
    /**
     * 
     */
    signout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}