import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthHelper } from '../../_helper/auth';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/_service/su/booking.service';
import { DateHelper } from 'src/app/_helper/date';
import { LogService } from 'src/app/_service/su/log.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {

  logs: any;
  is_loading = false;
  settings = {
    noDataMessage : this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions:false,
    columns: {
      business_id: {
        title: "Business"
      },
      dated: {
        title: "Created",
        valuePrepareFunction: (value) => { return this.date.formatForNorway(value) }
      }
    }
  };

  constructor(    
    private translate: TranslateService,
    private router: Router,
    public date:DateHelper,
    private log: LogService,
    private auth: AuthHelper
    ) {

  }

  ngOnInit() {
    this.is_loading = true;
    this.log.getAll().subscribe(data=>{
      let temp:any;
      temp = data;
      this.is_loading = false;
      this.logs = temp.payload;
    });

  }

  userRowSelect(e){

  }
  

}
