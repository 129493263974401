import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../../_helper/http.form.codec';
import { ParamsHelper } from '../../_helper/params';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    params: any;
    /**
     * 
     */
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) {
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();

    }

    /**
     * 
     */
    _getBody() {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     */
    getAll() {
        return this.http.get(environment.apiv2_server + "/su/dashboard",  { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(data => {
                return data;
            }));
    }


}
