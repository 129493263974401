import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_service/alert.service';
import { SupportService } from '../../_service/support.service';
import { AuthHelper } from '../../_helper/auth';
import { UserService } from 'src/app/_service/user.service';
import { UrlHelper } from 'src/app/_helper/url';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  query = "";
  query_type = "";
  is_loggedin = false;
  

  constructor(
    private supportService: SupportService,
    private auth: AuthHelper,
    private url: UrlHelper,
    private alertService: AlertService,
  ) {

    if (auth.isLoggedIn()) {
      this.is_loggedin = true;
      var _u = this.auth.getUser();
    }
  }

  ngOnInit() {
  }

  onSubmit() {
    let valid = this._validate();
    if (valid == true) {
      let _p = {
        "email": this.auth.getUser().username,
        "query": this.query,
        "subdomain":this.url.getSubdomain(),
        "query_type":this.query_type
      }
      this.supportService.contact(_p).subscribe(data => {
        let temp: any;
        temp = data;
        if (temp.success == true) {
          this.alertService.success(temp.msg);
          this.query = "";
        }
        else {
          this.alertService.error(temp.msg);
        }
      });
    }
  }

  /**
   * validate the inputs
   */
  _validate() {
    let flag = true;
    if (this.query.trim() == "") {
      this.alertService.error("Query is required");
      flag = false;
    }
    if (this.query_type == "") {
      this.alertService.error("Please select a Query Type");
      flag = false;
    }
    

    return flag;
  }
}
