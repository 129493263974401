import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import { ParamsHelper } from '../_helper/params';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    params: any;
    /**
     * 
     */
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) {
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    }

    /**
     * 
     */
    _getBody() {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     */
    getAll(business = null) {
        let _p: any;
        if (business != null) {
            _p = {
                "id": business.id,
                "business_id":business.id
            }
        }
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/poll/all", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllByBusinessId(_p = null) {

        let body = this.params.make(this._getBody(), _p);

        return this.http.post(environment.server + "/provider/category/poll/all-by-business-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

        /**
     * 
     */
    getAllByBusinessDomain(_p = null) {

        let body = this.params.make(this._getBody(), _p);

        return this.http.post(environment.server + "/provider/category/poll/all-by-business-domain", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     */
    save(_p) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/do/save", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    delete(_p) {
        let body = this.params.make(this._getBody(), _p);

        return this.http.post(environment.server + "/provider/category/do/delete", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     */
    getAllWithServices(_p = null) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/poll/all-with-services", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllWithServicesByBusinessId(_p = null) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/poll/all-with-services-by-business-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }



}
