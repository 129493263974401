<div class="container">
  <div class="row">
    <!--end of col-->
    <div class="col-12 col-md-3">
      <div class="card card-sm">
        <ul class="list-group">

          <li class="list-group-item list-group-item-action " id="tpl{{tpl.id}}" *ngFor="let tpl of templates" (click)="onClick_Tpl($event,tpl.value,tpl.name,tpl.id,tpl.description,tpl.alias)"><a
              href="javascript:void(0);" id="tpl{{tpl.id}}">{{tpl.alias}}</a></li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-md-9">
      <div class="card card-sm">
        <div class="card-header bg-secondary d-flex justify-content-between align-items-center">
          <span class="float-left">
            <span *ngIf="is_loading == false">{{title}}</span>
            <span class="" *ngIf="is_loading == true" translate>
              <img src="assets/img/ajax-loader-dark-gray.gif" translate >
              txt-loading
            </span>
          </span>
        </div>
        <div class="card-body">
          <div *ngIf="is_item_selected == false" class="alert alert-danger" translate >txt-please-select-a-template-to-edit</div>
          <div *ngIf="is_item_selected == true">
            <div class="row form-group">
              <div class="col-12">
                <div class="alert alert-primary">{{item.description}}</div>
                <div class="text text-muted"><small translate >txt-for-system-security-we-do-not-allow-any-script-tag-and-styling-on-templates</small></div>
                <textarea [(ngModel)]="item.value" class="col form-control" 
                  placeholder="Above shortcodes can be included in tempaltes. You can add simple html as well."
                  rows="10"></textarea>
              </div>
            </div>
            <div class="row form-group">
              <div class="col">
                <div class="title-decorative" translate >lbl-preview</div>
                <p [innerHTML]="item.value" class="bg-secondary rounded pb-4 pt-4 pl-2 pr-2"> </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer bg-light">
          <button *ngIf="is_loading == true" class="btn btn-lg text text-muted btn-light" translate>
            <img src="assets/img/ajax-loader-dark-gray.gif">txt-loading
          </button>
          <button class=" btn btn-lg btn-primary" *ngIf="is_loading == false && is_item_selected == true" (click)="onClick_Save()" translate >btn-save</button>
        </div>
      </div>
    </div>
  </div>
</div>