import { Component, OnInit } from '@angular/core';
import { BusinessService } from '../../_service/business.service';
import { AlertService } from '../../_service/alert.service';
import { environment } from '../../../environments/environment';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
import { FileHelper } from '../../_helper/file';
import { StringHelper } from '../../_helper/string';
import { TranslateService } from '@ngx-translate/core';

//import { fail } from 'assert';

@Component({
  selector: 'app-config-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.css']
})
export class GlobalComponent implements OnInit {

  /**
   * 
   */
  status = [{ "value": "active" }, { "value": "inactive" }];
  boolean_status = [{ "value": "1" }, { "value": "0" }];
  title = "SMS Settings";
  vat = "";
  customer_calendar_ui_mode = 1;
  enable_internal_booking = "0";
  enable_categories = "0";
  is_send_sms_confirmation_on_booking = "1";
  reminder_via_sms_x_hrs_before_booking = 120;
  exivo_send_lock_code_before_x_minute = 120;

  selected_existing_card = "";
  is_show_save_btn = true;

  is_send_email_confirmation_to_provider_on_booking = "1";
  is_send_email_confirmation_to_provider_on_booking_cancel = "1";
  is_send_email_confirmation_to_provider_on_booking_changed = "1";
  is_send_email_confirmation_to_provider_on_booking_finished = "1";
  is_send_email_confirmation_to_customer_on_booking_finished = "1";
  extra_emails_for_notification = "";
  reminder_via_email_x_hrs_before_booking = 120;
  is_show_working_hours = "1";
  is_show_map_address = "1";
  is_show_about_us = "1";

  business:any;
  exivo_system_id = "";
  exivo_api_key = "";
  exivo_api_secret = "";

  payPalConfig?: PayPalConfig;
  sms_credits = 0;
  sms_sender_name = "";
  // below are the price for sms
  sms_credits_stack = [
    { "credits": 100, "price": 89 },
    { "credits": 250, "price": 220 },
    { "credits": 500, "price": 400 },
    { "credits": 1000, "price": 750 },
  ];
  exivo_lock_code_pattern_stack = [
    { "value": 0, "label": "txt-do-not-generate-lock-code" },
    { "value": 4, "label": "txt-generate-4-digit-lock-code" },
    { "value": 5, "label": "txt-generate-5-digit-lock-code" }
  ];

  exivo_send_lock_code_before_x_minute_stack = [
    { "value": 0, "label": "txt-do-not-send-lock-code" },
    { "value": 30, "label": "txt-send-30-minute-before-checkin" },
    { "value": 60, "label": "txt-send-1-hour-before-checkin" },
    { "value": 120, "label": "txt-send-2-hour-before-checkin" },
    { "value": 720, "label": "txt-send-12-hour-before-checkin" },
    { "value": 1440, "label": "txt-send-12-hour-before-checkin" }
  ];

  sms_reminder_stack = [
    { "in_min": 0, "value": "Do not send", "unit": "reminder" },
    { "in_min": 30, "value": 30, "unit": "minutes" },
    { "in_min": 60, "value": 1, "unit": "hour" },
    { "in_min": 120, "value": 2, "unit": "hours" },
    { "in_min": 720, "value": 12, "unit": "hours" },
    { "in_min": 1440, "value": 24, "unit": "hours" }
  ];

  is_activated_sms_service = "1";
  is_on_settings = false;
  is_on_lock_api = false;
  banner = "";
  is_on_buy_sms = false;
  is_on_sms_settings = true;
  is_on_email_settings = false;
  is_on_banner = false;
  enable_exivo_lock_api = "0";
  is_allow_customer_to_register = "1";
  total_price_for_sms = 0;
  sms_alert_class = "alert-success";
  exivo_lock_code_pattern = 0;
  current_plan:any;


  /**
   * 
   * @param userService 
   */
  constructor(
    private businessService: BusinessService,
    private file: FileHelper,
    public translate: TranslateService,
    private string: StringHelper,
    private alertService: AlertService) {

    this._resetForm();
    this.getSettings();
    this.is_on_sms_settings = true;
  }

  /**
   * 
   */
  ngOnInit() {
    this.total_price_for_sms = this.sms_credits_stack[0].price;
  }

  /**
   * 
   */
  initPaypalConfig() {

    this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PayPalEnvironment.Production, {
      commit: true,
      client: {
        production: environment.bookit_paypal_production_client_id
      },
      button: {
        label: 'pay',
        size: "large",
        shape: 'rect',
        color: 'black',
        tagline: false
      },
      onPaymentComplete: (data, actions) => {
        this.alertService.success(this.translate.instant("alert-success"));
        this._onSuccessUpdateCredit();
      },
      onCancel: (data, actions) => {
        this.alertService.error(this.translate.instant("alert-error"));
      },
      onError: (err) => {
        this.alertService.error(this.translate.instant("alert-error"));
      },
      transactions: [{
        amount: {
          currency: 'NOK',
          total: this.total_price_for_sms
        }
      }]
    });
  }


  /**
   * 
   */
  _resetForm() {
    this.title = "SMS";
    this.is_on_lock_api = false;
    this.is_on_buy_sms = false;
    this.is_on_settings = false;
    this.is_on_banner = false;
    this.is_on_email_settings = false;
    this.is_on_sms_settings = false;
    this.is_show_save_btn = true;

  }

  /**
   * 
   */
  onClick_Settings() {
    this._resetForm();
    this.title = "Global";
    this.is_on_settings = true;
    this.is_show_save_btn = true;
  }

  onClick_SmsSettings() {
    this._resetForm();
    this.title = "SMS";
    this.is_on_sms_settings = true;
    this.is_show_save_btn = true;

  }
  onClick_EmailSettings() {
    this._resetForm();
    this.title = "Email";
    this.is_on_email_settings = true;
    this.is_show_save_btn = true;
  }

  /**
   * 
   */
  onClick_LockApi() {
    this._resetForm();
    this.title = "Lock API";
    this.is_on_lock_api = true;
    this.is_show_save_btn = true;
  }

  /**
   * 
   */
  onClick_BuySMS() {
    this._resetForm();
    this.title = "SMS Credits";
    this.is_on_buy_sms = true;

  }

  onClick_Banner() {
    this._resetForm();
    this.title = "Public Portal Banner";
    this.is_on_banner = true;

  }

  /**
 * 
 * @param event 
 */
  selectImage(event: any) {
    let _t = this;
    this.file.onFileChanged(event, function (url, filedata) {
      _t.banner = url
    });
  }

  /**
   * 
   */
  onClick_DeleteImage() {
    this.banner = "";
    this.onClick_Save();
  }

  /**
   * 
   */
  getSettings() {
    this.businessService.getWhosLoggedIn().subscribe(data => {
      let temp: any;
      temp = data;
      this.business = temp.data;
      this.vat = temp.data.vat;
      this.is_show_working_hours = temp.data.is_show_working_hours;
      this.is_show_map_address = temp.data.is_show_map_address;
      this.is_show_about_us = temp.data.is_show_about_us;
      this.enable_internal_booking = temp.data.enable_internal_booking;
      this.enable_categories = temp.data.enable_categories;
      this.sms_sender_name = temp.data.sms_sender_name;
      this.customer_calendar_ui_mode = parseInt(temp.data.customer_calendar_ui_mode);
      this.is_allow_customer_to_register = temp.data.is_allow_customer_to_register;
      if(temp.data.sms_credits == null || temp.data.sms_credits == 'null'){
        temp.data.sms_credits = 0;
      }
      this.sms_credits = temp.data.sms_credits;
      this.is_send_sms_confirmation_on_booking = temp.data.is_send_sms_confirmation_on_booking;
      this.reminder_via_sms_x_hrs_before_booking = temp.data.reminder_via_sms_x_hrs_before_booking;
      this.is_activated_sms_service = temp.data.is_activated_sms_service;
      this.is_send_email_confirmation_to_provider_on_booking = temp.data.is_send_email_confirmation_to_provider_on_booking;
      this.is_send_email_confirmation_to_provider_on_booking_cancel = temp.data.is_send_email_confirmation_to_provider_on_booking_cancel;
      this.is_send_email_confirmation_to_provider_on_booking_changed = temp.data.is_send_email_confirmation_to_provider_on_booking_changed;
      this.is_send_email_confirmation_to_provider_on_booking_finished = temp.data.is_send_email_confirmation_to_provider_on_booking_finished;
      this.is_send_email_confirmation_to_customer_on_booking_finished = temp.data.is_send_email_confirmation_to_customer_on_booking_finished;
      this.extra_emails_for_notification = temp.data.extra_emails_for_notification;
      this.reminder_via_email_x_hrs_before_booking = temp.data.reminder_via_email_x_hrs_before_booking;
      // lock api

      this._setupExivoParams(temp);
      if(this.business.plan){
        this.current_plan = JSON.parse(JSON.parse(this.business.plan).payload);
      }
      else{
        this.current_plan = {};
      }
      //console.log(this.current_plan);
      this.sms_alert_class = "alert-success";
      if (Number(this.sms_credits) < 50) {
        this.sms_alert_class = "alert-danger";
      }
    });
  }

  /**
   * 
   * @param temp 
   */
  _setupExivoParams(temp) {
    this.enable_exivo_lock_api = temp.data.enable_exivo_lock_api;
    this.exivo_lock_code_pattern = temp.data.exivo_lock_code_pattern;
    this.exivo_send_lock_code_before_x_minute = temp.data.exivo_send_lock_code_before_x_minute;

    // more..
    if (temp.data.lock != undefined) {
      if (temp.data.lock.exivo != undefined) {
        this.exivo_system_id = temp.data.lock.exivo.exivo_system_id;
        this.exivo_api_key = temp.data.lock.exivo.exivo_api_key;
        this.exivo_api_secret = temp.data.lock.exivo.exivo_api_secret;
      }
    }
  }

  /**
   * 
   * @param v 
   */
  onChange_BuySMSCreditStack(v) {
    this.total_price_for_sms = v;

    //this.initPaypalConfig();
  }

  onClick_BuyNow(){
    if(this.total_price_for_sms < 1){
      this.alertService.error(this.translate.instant("alert-please-select-sms-plan"));
      return false;
    }
    if(this.selected_existing_card == ""){
      this.alertService.error(this.translate.instant("alert-please-select-a-card"));
      return false;
    }
    let _p = {"description":"SMS","price":this.total_price_for_sms,"selected_existing_card":this.selected_existing_card};
    this.businessService.makePayment(_p).subscribe(data=>{
      let temp :any;
      temp = data;
      if(temp.success){
        this.alertService.success(temp.msg);
        this._onSuccessUpdateCredit();
      }
      else{
        this.alertService.error(temp.msg);
      }

    });
  }


  /**
   * 
   */
  _onSuccessUpdateCredit() {
    let credits = 0;
    this.sms_credits_stack.forEach((k, i) => {
      if (k.price == this.total_price_for_sms) {
        credits = k.credits;
      }
    });
    let _p = {
      "credit": credits
    };
    this.businessService.updateSMSCredits(_p).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        
        this.alertService.success(this.translate.instant("alert-success"));
        this.getSettings();
        this._resetForm();
        this.is_on_sms_settings = true;
      }
      else {
        this.alertService.error(this.translate.instant("alert-error"));
      }
    });
  }



  /**
   * 
   * @param event 
   * @param name 
   * @param id 
   */
  onClick_Save() {

    let v = this._validate();
    if (v == true) {
      this._save();
    }
  }

  /**
   * 
   */
  _validate() {
    let flag = true;
    if (this.is_on_email_settings == true) {
      let c = 0;
      if (this.extra_emails_for_notification != null) {
        c = this.extra_emails_for_notification.split(",").length - 1; 
        if (c > 3) {
          this.alertService.error("Maximum 4 Emails are allowed for notifications");
          flag = false;
        }
        // check email format
        let all_emails = this.extra_emails_for_notification.split(",");
        all_emails.forEach(k=>{
          let v = k.trim();
          if(!this.string.isValidEmail(v)){
            if(k.length >0){
              this.alertService.error("'"+k+"' is not a valid email format.");
              flag = false;
            }
          }
        });
      }
    }

    return flag;
  }

  /**
   * 
   */
  _save() {
    let _p: any;

    if (this.is_on_settings == true) {
      _p = {
        "vat": this.vat,
        "enable_internal_booking": this.enable_internal_booking,
        "enable_categories": this.enable_categories,
        "is_show_working_hours": this.is_show_working_hours,
        "is_show_map_address": this.is_show_map_address,
        "customer_calendar_ui_mode":this.customer_calendar_ui_mode,
        "is_show_about_us": this.is_show_about_us

      }
      // update local storage as well
      let temp = JSON.parse(localStorage.getItem("currentUser"));
      temp.data.business.enable_categories = this.enable_categories;
      localStorage.setItem("currentUser",JSON.stringify(temp));

    }
    if (this.is_on_sms_settings == true) {
      _p = {
        "is_activated_sms_service": this.is_activated_sms_service,
        "sms_sender_name": this.sms_sender_name,
        "is_send_sms_confirmation_on_booking": this.is_send_sms_confirmation_on_booking,
        "reminder_via_sms_x_hrs_before_booking": this.reminder_via_sms_x_hrs_before_booking
      }
    }
    if (this.is_on_email_settings == true) {
      _p = {
        "is_send_email_confirmation_to_provider_on_booking": this.is_send_email_confirmation_to_provider_on_booking,
        "is_send_email_confirmation_to_provider_on_booking_cancel": this.is_send_email_confirmation_to_provider_on_booking_cancel,
        "is_send_email_confirmation_to_provider_on_booking_changed": this.is_send_email_confirmation_to_provider_on_booking_changed,
        "is_send_email_confirmation_to_provider_on_booking_finished": this.is_send_email_confirmation_to_provider_on_booking_finished,
        "is_send_email_confirmation_to_customer_on_booking_finished": this.is_send_email_confirmation_to_customer_on_booking_finished,
        "extra_emails_for_notification": this.extra_emails_for_notification,
        "reminder_via_email_x_hrs_before_booking": this.reminder_via_email_x_hrs_before_booking
      }

      if (_p.reminder_via_email_x_hrs_before_booking == null) {
        _p.reminder_via_email_x_hrs_before_booking = 0;
      }
  
      if (_p.extra_emails_for_notification == null) {
        _p.extra_emails_for_notification = "";
      }
  
    }
    if (this.is_on_lock_api == true) {
      _p = {
        "exivo_lock_config": true,
        "enable_exivo_lock_api": this.enable_exivo_lock_api,
        "exivo_lock_code_pattern": this.exivo_lock_code_pattern,
        "exivo_send_lock_code_before_x_minute": this.exivo_send_lock_code_before_x_minute,
        "params[exivo_system_id]": this.exivo_system_id,
        "params[exivo_api_key]": this.exivo_api_key,
        "params[exivo_api_secret]": this.exivo_api_secret,

      }
    }


    this.businessService.save(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.getSettings();
      //this._resetForm();
      this.alertService.success(temp.msg);
    });
    // for lock api hit another update as well
    if (this.is_on_lock_api == true) {
      this.businessService.update(_p).subscribe(data => {
        let temp: any;
        temp = data;
        // this.alertService.success(temp.msg);
      });
    }
  }
}
