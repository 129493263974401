<div class="card card-sm">
    <div class="card-header bg-secondary">
        <a href="javascript:void(0);" (click)="onClick_BackBtnOnPay($event)" class="float-left text text-danger" translate ><i
                style="padding-top: 4px;" class=" icon-chevron-left"></i>btn-back</a>
        <div class="text text-center title-decorative" *ngIf="is_loading == true" translate>
            <img src="assets/img/ajax-loader-light-gray.gif" translate > txt-loading
        </div>
        <div class="title-decorative text-center" *ngIf="is_loading == false">{{title}}
        </div>

    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-lg-12">
                <!-- Nav tabs -->
                <!--
                <ul class="nav nav-tabs">
                    <li class="nav-item"><a class="nav-link active" (click)="onClick_PayByPaypalTab()"
                        data-toggle="tab" href="#paywithpaypal">Pay With Paypal</a></li>
                    <li class="nav-item"><a class="nav-link " (click)="onClick_PayByCCTab()"
                        data-toggle="tab" href="#paywithcard">Pay With CC</a></li>
                    <li class="nav-item"><a class="nav-link" data-toggle="tab"
                        (click)="onClick_PayBySavedCardTab()" href="#savedcard">Saved Card</a></li>
                </ul>
                -->
                <!-- Tab panes -->
                <div class="tab-content">
                    <div class="tab-pane  active" id="paywithpaypal">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-lg-9 mb-4" *ngIf="is_show_confirm_btn == true">
                                    <h6 class="text mt-2" translate > txt-please-confirm-your-booking-this-service-require-you-to-pay-offline</h6>
                                </div>
                                <div class="col-12 col-lg-9 mb-4" *ngIf="is_show_paypal_btn == true">
                                    <h5 class="text text-muted" translate >txt-proceed-to-pay
                                        <span class="bold text text-primary"> {{total_price}} NOK </span> <span translate>txt-using-paypal</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--
                    <div class="tab-pane  " id="paywithcard">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 mb-4">
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-12 col-lg-9 mb-4">
                                    <div class="text text-center">
                                        <div>Please fillin card details and confirm payment.</div>
                                        <small class="text text-muted"> We will not save any of
                                            these information unless your ask us to. </small>
                                    </div>
                                    <div class="form-row form-group">
                                        <div class="col">
                                            <input class="form-control" type="text" [(ngModel)]="card_number"
                                                placeholder="card number" />
                                        </div>
                                    </div>
                                    <div class="form-row form-group">
                                        <div class="col">
                                            <input class="form-control" type="text" [(ngModel)]="card_full_name"
                                                placeholder="full name as on card" />
                                        </div>
                                    </div>
                                    <div class="form-row form-group">
                                        <div class="col">
                                            <input class="form-control" type="text" [(ngModel)]="card_expiry"
                                                placeholder="e.g. 0420" />
                                        </div>
                                        <div class="col">
                                            <input class="form-control" type="text" [(ngModel)]="card_csv" placeholder="csv/cvv" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane " id="savedcard">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 mb-4">
                                </div>
                            </div>

                            <ul class="list-group mb-4">
                                <li class="list-group-item" id="cat{{cat.id}}" *ngFor="let cat of all_categories"
                                    (click)="onClick_Category($event,cat.name)"><a href="javascript:void(0);" id="cat{{cat.id}}">Card
                                        ending ****2012
                                        <button class="btn btn-primary btn-lg float-right">Pay With This Card</button>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    -->
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer bg-light" *ngIf="is_loading == false">
        <textarea [(ngModel)]="notes" class="form-control" rows="2" placeholder="Comments for booking">
        </textarea>
    </div>
    <div class="card-footer bg-secondary" *ngIf="is_loading == false">
        <span class="float-right" *ngIf="is_show_paypal_btn == true">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </span>
        <span class="float-right" *ngIf="is_show_confirm_btn == true">
            <button class="btn btn-success btn-lg float-right" (click)="onClick_Confirm()" translate >btn-confirm-booking
            </button>
        </span>
    </div>
</div>