<div class="row mb-2">
  <div class="col text-left mt-3">
    <div class="">
      <a href="javascript:void(0);" (click)="onClick_ChangeLanguage('en')"
        class="text text-muted">English</a>&nbsp;&nbsp;
      <a href="javascript:void(0);" (click)="onClick_ChangeLanguage('nb')"
        class="text text-muted">Norwegian</a>
    </div>
    
  </div>
  <div class="col text-right ">
    <a href="javascript:void(0);">
      <img src="/assets/img/bookit24_black.png" class="responsive
        responsive-fluid" width="120">
    </a>
  </div>
</div>



<div class="card shadow-sm border rounded card-lg mt-2">
  <div class="card-header text-center bg-secondary" *ngIf="is_signup_pending ==
    true">
    <span class="badge badge-dark">{{plan.payload.name}}</span>
    <h2 class="title-decorative text-dark" translate>heading-signup-as-a-provider
    </h2>
  </div>

  <div class="card-header" style="padding-top:18px" *ngIf="is_signup_pending ==
    true">
    <div class="row">
      <div class="col">
        <div class="custom-control custom-radio">
          <input autocomplete="off" id="monthly" type="radio"
            class="custom-control-input" value="1"
            [(ngModel)]="payment_type">
          <label class="custom-control-label"
            for="monthly" translate>lbl-monthly-payment</label>
        </div>
      </div>
      <div class="col">
        <div class="custom-control custom-radio">
          <input autocomplete="off" id="yearly" type="radio"
            class="custom-control-input"
            value="2" [(ngModel)]="payment_type">
          <label class="custom-control-label" for="yearly"
            translate>lbl-annually-payment</label>
        </div>
      </div>
    </div>
  </div>
  <div class="card-header"*ngIf="is_signup_pending == false">
    <div class="row col-12 text text-danger" translate="">txt-14-days-is-free</div>
  </div>

  <div class="card-body" *ngIf="is_signup_pending == false">
    <h3 translate>txt-you-are-registered-successfully</h3>
    <div translate>
      txt-account-has-been-activated
    </div>
    <br><br>
    <br>
    <a href="https://{{subdomain}}.{{url.getJustDomainName()}}/#/signin" class="btn
      btn-lg btn-success" translate>
      btn-signin-now <i class="fas fa-chevron-right"></i></a>
  </div>
  <div class="card-body" *ngIf="is_signup_pending == true">
    <div class="row form-group">
      <div class="col">
        <input autocomplete="off" [(ngModel)]="name" placeholder="{{ 'lbl-company-name-required' |
          translate }}" minlength="2" maxlength="40" class="form-control
          form-control-lg" />
      </div>
      <div class="col">
        <input autocomplete="off" [(ngModel)]="mobile"  class="form-control form-control-lg"
          placeholder="{{ 'lbl-phone' | translate }}" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <label class="text text-muted">https://<span class="text text-primary">{{subdomain}}</span>.{{url.getJustDomainName()}}
        </label>
        <input autocomplete="off" [(ngModel)]="subdomain" mask='A*' [dropSpecialCharacters]="true"
          maxlength="30" minlength="4" class="form-control form-control-lg"
          placeholder="{{ 'lbl-choose-your-domain-required-min-length-2-char' |
          translate }}" />
      </div>
      <!-- 
      <div class="col">
        <label class="text text-muted" translate>txt-your-business-timezone
        </label>
        <select required class="form-control form-control-lg"
          [(ngModel)]="timezone">
          <option [value]="tz.timezone" *ngFor="let tz of zones">{{tz.timezone}}
            ( {{tz.offset}} UTC )</option>
        </select>
      </div>
    -->
    </div>
    <!-- 
    <div class="bg-secondary  card card-sm card-body p-3 pb-2">
      <div class="title-decorative mb-2" translate="">lbl-contact-person</div>
      <div class="row form-group">
        <div class="col">
          <input [(ngModel)]="contact_name" minlength="2" maxlength="60"
            class="form-control form-control-lg"
            placeholder="{{ 'lbl-name' |
            translate }}" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col">
          <input [(ngModel)]="contact_email" minlength="6" maxlength="60"
            class="form-control form-control-lg"
            placeholder="{{ 'lbl-email' |
            translate }}" />
        </div>
        <div class="col">
          <input [(ngModel)]="contact_mobile" minlength="8" maxlength="60"
            class="form-control form-control-lg"
            placeholder="{{ 'lbl-mobile' |
            translate }}" />
        </div>
      </div>
    </div>
  -->
    <div class="bg-secondary card card-sm card-body p-3 pb-2">
      <div class="title-decorative mb-2" translate="">lbl-login-details</div>
      <div class="row form-group">
        <div class="col">
          <input autocomplete="off" [(ngModel)]="username" minlength="6" maxlength="60"
            class="form-control form-control-lg"
            placeholder="{{ 'lbl-username-must-be-a-valid-email-required' |
            translate }}" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col">
          <input  autocomplete="off" [(ngModel)]="password" type="password" minlength="8"
            maxlength="16" class="form-control form-control-lg"
            placeholder="{{ 'lbl-password' | translate }}" />
        </div>
        <div class="col">
          <input autocomplete="off" [(ngModel)]="confirm_password" type="password" minlength="8"
            maxlength="16" class="form-control form-control-lg"
            placeholder="{{ 'lbl-confirm-password' | translate }}" />
        </div>
      </div>
    </div>
    <div class="bg-secondary card card-sm card-body p-3 pb-2">
      <div class="title-decorative mb-2" translate="">lbl-billing-address</div>
      <div class="row form-group">
        <div class="col">
          <input autocomplete="off" [(ngModel)]="street" minlength="2" maxlength="60"
            class="form-control form-control-lg"
            placeholder="{{ 'lbl-street-address' |
            translate }}" />
        </div>
      </div>
      <div class="row form-group">
        <!-- 
        <div class="col">
          <input [(ngModel)]="state" minlength="2" maxlength="60"
            class="form-control form-control-lg"
            placeholder="{{ 'lbl-state' |
            translate }}" />
        </div>
      -->
        <div class="col">
          <input autocomplete="off" [(ngModel)]="zipcode" minlength="4" maxlength="60"
            class="form-control form-control-lg"
            placeholder="{{ 'lbl-zipcode' |
            translate }}" />
        </div>
        <div class="col">
          <input autocomplete="off" [(ngModel)]="city" minlength="2" maxlength="60"
            class="form-control form-control-lg"
            placeholder="{{ 'lbl-city' |
            translate }}" />
        </div>
      </div>
    </div>
    <div class="bg-secondary card card-sm card-body p-3 pb-2">
      <div class="row form-group">
        <div class="col">
          <div class="title-decorative mb-2" translate="">lbl-preferred-language-for-invoices</div>
          <select [(ngModel)]="billing_language" class="form-control-lg form-control">
            <option *ngFor="let c of countries" [value]="c.locale">{{c.code}}</option>
          </select>
        </div>
        <div class="col">
          <div class="title-decorative mb-2" translate="">lbl-tax-details</div>
          <input autocomplete="off" [(ngModel)]="tax_id" minlength="4" maxlength="60"
            class="form-control form-control-lg"
            placeholder="{{ 'lbl-tax-id' |
            translate }}" />
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="custom-control custom-checkbox align-items-center">
        <input autocomplete="off" type="checkbox" class="custom-control-input" value="1"
          [(ngModel)]="tnc"  id="tnc">
        <label class="custom-control-label text-muted" for="tnc"
          translate="">I Accept <a href="/#/tnc" target="_blank" translate="">btn-terms-n-conditions</a></label>
      </div>
    </div>
    <hr>
    <div class="">
      <span *ngIf="is_loading == true"
        class="btn btn-light btn-lg btn-muted" translate><img
          src="assets/img/ajax-loader-light-gray.gif"
          translate> txt-loading</span>
      <button *ngIf="is_loading == false" (click)="onClick_Signup()" class="btn
        btn-lg btn-primary" translate>btn-submit</button>
    </div>
  </div>
  <div class="card-footer bg-secondary" *ngIf="is_signup_pending == true">
    <a href="javascript:void(0);" (click)="onClick_NeedHelp()" class="btn
      btn-light float-right" translate>btn-need-help</a>
    <a href="javascript:void(0);" [routerLink]="['/signup']" class="btn
      btn-light float-left" translate> <i class="fas fa-chevron-left"></i>
      btn-back</a>
  </div>
</div>
