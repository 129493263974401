import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertService } from '../../../../_service/alert.service';
import { AuthService } from '../../../../_service/auth.service';
import { UrlHelper } from '../../../../_helper/url';
import { StringHelper } from '../../../../_helper/string'; 
import { from } from 'rxjs';
import { BusinessService } from 'src/app/_service/business.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-booking-customer-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Input() config: any;
  @Output() emit_config = new EventEmitter();
  business:any;
  customer = { username: "", password: "", subdomain: "", customer_only: "0", must_be_existing_customer: "0" };
  email = "";
  username = "";
  password = "";
  new_password = "";
  first_name = "";
  last_name = "";
  mobile = "";

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    public translate: TranslateService,
    private businessService: BusinessService,
    private string:StringHelper,
    private urlHelper: UrlHelper
  ) { }


  ngOnInit() {
    this.customer.subdomain = this.urlHelper.getSubdomain();
    this._getBusiness();
  }

  _getBusiness() {
    this.config.is_loading = true;
    this.businessService.getBySubdomain(this.urlHelper.getSubdomain()).subscribe(data => {
      let temp: any;
      temp = data;
      this.config.is_loading = false;

      if (temp.success) {
        this.business = temp.data;
      }
      else {
        this.alertService.error(this.translate.instant("alert-invalid-business-account"));
      }
    });
  }


  onClick_Back(){
    this.config.step = "datetime";
    this.emit_config.emit(this.config);
  }
  
  onClick_Signin() {
    this.config.is_loading = true;
    // assign to config after signin
    this.authService.signin2(this.customer).subscribe(data => {
      let temp:any;
      temp = data;
      this.config.is_loading = false;
      if(temp.success){
        this.config.summary.customer = temp.data.username;
        this.config.customer =  { "is_new": 0, "username": temp.data.username, "id": temp.data.id, "first_name": temp.data.first_name, "last_name": temp.data.last_name, "mobile": temp.data.mobile };
        this.config.step = "pay";
        this.emit_config.emit(this.config);
      }
      else{
        this.alertService.error(temp.msg);
      }
    });
  }

  /**
   * 
   */
  onClick_Signup() {
    let _p = {
      "username":this.username,
      "password":this.new_password,
      "mobile":this.mobile,
      "first_name":this.first_name,
      "subdomain":this.business.subdomain,
      "last_name":this.last_name
    };
    if(this._validate(_p)){
      this.config.is_loading = true;
      this.authService.customerSignup(_p).subscribe(data => {
        let temp: any;
        temp = data;
        this.config.is_loading = false;
        // assign to config here
        if (temp.success == false) {
          this.alertService.error(temp.msg);
        } else {
          this.config.step = "pay";
          this.emit_config.emit(this.config);
        }
      });
    }
  }

  _validate(_p){

    if(!this.string.isValidEmail(_p.username)){
      this.alertService.error(this.translate.instant("alert-valid-email-is-required"));
      return false;
    }
    if(_p.first_name == "" || _p.last_name == ""){
      this.alertService.error(this.translate.instant("alert-first-last-are-required"));
      return false;
    }
    if(!this.string.isValidNorwayMobile(_p.mobile)){
      this.alertService.error(this.translate.instant("alert-valid-mobile-is-required"));
      return false;
    }
    return true;
    
  }
}
