import { Component, OnInit } from '@angular/core';
import { Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { BusinessService } from './../../../_service/business.service';
import { CustomerService } from '../../../_service/customer.service';
import { AlertService } from './../../../_service/alert.service';
import { StringHelper} from '../../../_helper/string';
import { UrlHelper } from '../../../_helper/url';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


@Component({
    selector: 'app-booking-create-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.css']
})

export class CustomerComponent implements OnInit {

    @Output() backBtn = new EventEmitter<object>();
    @Output() startOver = new EventEmitter<boolean>();
    @Output() selectCustomer = new EventEmitter<object>();
    @Output() confirmBooking = new EventEmitter<any>();
    @Input() is_loading: boolean;

    new_customer_email = "";
    new_customer_first_name = "";
    new_customer_last_name = "";
    new_customer_mobile = "";
    new_customer_street = "";
    new_customer_city = "";
    new_customer_state = "";
    new_customer_zipcode = "";
    new_customer_country = "Norway";

    need_to_customer_name = false;
    is_pay_now_ready = false;
    all_customers: any;
    err = "";
    subdomain = "";
    business: any;
    title = "Customer";
    notes = "";
    customer_info = "";
    customer = {
        "username": "",
        "first_name": "",
        "last_name": "",
        "mobile": "",
        "id": 0
    };
    search_customer = "";

    /**
     * 
     * @param alertService 
     * @param customerService 
     */
    constructor(
        private alertService: AlertService,
        private url: UrlHelper,
        private customerService: CustomerService,
        private string: StringHelper,
        private businessService: BusinessService
    ) {

        this.subdomain = url.getSubdomain();
        businessService.getBySubdomain(this.subdomain).subscribe(data => {
            let temp: any;
            temp = data;
            if (temp.success) {
                if (temp.data.id != undefined) {
                    this.business = temp.data;
                    this.getAllCustomers();
                }
            }
        });

    }

    /**
     * 
     */
    ngOnInit() {

    }

    reset() {
        this.search_customer = "";
        this.customer_info = "";
        this.is_loading = false;
        this.customer.id = 0;
        this.customer.username = "";
        this.is_pay_now_ready = false;
    }
    /**
     * 
     * @param event 
     */
    onClick_BackBtnOnCustomer(event) {
        this.customer_info = "";
        this.backBtn.emit(event);
    }

    /**
     * 
     * @param v 
     */
    onSelect_SelectCustomer(v) {
        this.customer_info = "";

        this._selectCustomer(v);
    }

    /**
     * 
     * @param v 
     */
    _selectCustomer(v) {
        if (v.item == undefined) {
            this.is_pay_now_ready = false;
            this.alertService.error("Customer not selected. Make sure you have clicked on Customer from the search List.");
        } else {
            this.is_pay_now_ready = true;
            this.customer.username = v.item.username;
            this.customer.id = v.item.id;
            this.alertService.success('"' + v.item.username + '" selected for booking.');
            this.emitCustomer();
        }
    }

    /**
     * 
     */
    emitCustomer() {
        let _p = {
            "customer": this.customer
        }
        this.is_loading = false;
        this.selectCustomer.emit(_p);

    }

    /**
     * 
     */
    onClick_ConfirmBookingBtnOnCustomer() {
        if (this.customer.id == 0 || this.customer.username == "") {
            this.alertService.error("Customer not selected");
        }
        else {
            this.is_loading = true;
            let _p = {"notes":this.notes,"status":true};
            this.confirmBooking.emit(_p);
        }
    }

    /**
     * 
     */
    onClick_CreateNewCustomer() {
        if(!this._verifyCustomerInfo()){
            return false;
        }
        this.is_loading = true;
        let _p = {
            "username": this.new_customer_email,
            "first_name": this.new_customer_first_name,
            "last_name": this.new_customer_last_name,
            "mobile": this.new_customer_mobile,
            "street": this.new_customer_street,
            "city": this.new_customer_city,
            "state": this.new_customer_state,
            "zipcode": this.new_customer_zipcode,
            "country": this.new_customer_country
        };
        this.customer_info = "";
        this.customerService.create(_p).subscribe(
            (res) => {
                this.is_loading = false;
                let response = JSON.parse(JSON.stringify(res));
                if (response.success) {

                    let temp: any;
                    temp = response;
                    this.customer.id = temp.data.id;
                    this.customer.username = temp.data.username;

                    if (temp.data.is_new == false) {
                        if (temp.data.first_name == null || temp.data.first_name == 'null' || temp.data.last_name == null || temp.data.last_name == 'null') {
                            this.customer_info = "<span class='text text-success'>" + temp.data.username + " selected for booking.</span><br><br>Looks like <strong>" + this.customer.username + "</strong> already exists.";
                        } else {
                            this.customer_info = "<span class='text text-success'>" + temp.data.first_name + " " + temp.data.last_name + " selected for booking.</span><br><br>Looks like <strong>" + this.customer.username + "</strong> already exists with name <strong>" + temp.data.first_name + " " + temp.data.last_name + "</strong>.";
                        }
                    } else {
                        this.customer_info = "<span class='text text-success'>" + temp.data.username + " selected for booking.</span>";
                    }
                    this.new_customer_email = "";
                    this.new_customer_first_name = "";
                    this.new_customer_last_name = "";
                    this.new_customer_mobile = "";
                    this.new_customer_street = "";
                    this.new_customer_city = "";
                    this.new_customer_state = "";
                    this.new_customer_zipcode = "";
                    this.search_customer = "";
                    this.getAllCustomers();
                    this.is_pay_now_ready = true;
                    this.alertService.success('"' + temp.data.first_name + " " + temp.data.last_name + '" selected for booking.');

                    this.emitCustomer();
                } else {
                    this.alertService.error(response.msg);
                }
            },
            error => {
                this.customer_info = "";

                this.is_loading = false;
                this.alertService.error("An Error occurred, please try again or relogin.");
            });

    }

    /**
     * 
     */
    _verifyCustomerInfo(){
        let flag = true;
        if(this.new_customer_mobile.length != 8){
            flag = false;
            this.alertService.error("Please provider a Norwegian 8 digit Mobile");
        }
        if(!this.string.isValidEmail(this.new_customer_email)){
            flag = false;
            this.alertService.error("Invalid Email Provided");
        }
        return flag;
    }

    /**
     * 
     */
    getAllCustomers() {
        this.is_loading = true;
        let _p = {
            "business_id": this.business.id
        };

        this.customerService.getAll(_p).subscribe(
            (res) => {
                this.is_loading = false;
                let response = JSON.parse(JSON.stringify(res));
                if (response.success) {
                    this.all_customers = response.data;
                } else {
                    this.err = response.msg;
                }
            },
            error => {
                this.is_loading = false;
                //console.log(error);
                this.alertService.error(error);
            });

    }

    /**
     *             //: this.all_customers.filter(v => new RegExp(term, 'gi').test(v)).slice(0, 10))   
     */
    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => 
                term === '' ? [] : this.all_customers.filter(v => v.username.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.mobile.toLowerCase().indexOf(term.toLowerCase()) > -1 ).slice(0, 10)
            )
        );

    /**
     * 
     */
    formatter = (x: {
        username: string
    }) => x.username;


    onClick_StartAgain() {
        this.startOver.emit(true);
    }
}