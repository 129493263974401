/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faqs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./faqs.component";
var styles_FaqsComponent = [i0.styles];
var RenderType_FaqsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqsComponent, data: {} });
export { RenderType_FaqsComponent as RenderType_FaqsComponent };
export function View_FaqsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "space-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FAQs"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, null); }
export function View_FaqsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faqs", [], null, null, null, View_FaqsComponent_0, RenderType_FaqsComponent)), i1.ɵdid(1, 114688, null, 0, i2.FaqsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqsComponentNgFactory = i1.ɵccf("app-faqs", i2.FaqsComponent, View_FaqsComponent_Host_0, {}, {}, []);
export { FaqsComponentNgFactory as FaqsComponentNgFactory };
