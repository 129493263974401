<div class="card card-sm">
    <div class="card-header bg-secondary d-flex justify-content-between
        align-items-center">
        <div>
            <h6 translate="">heading-manage-stripe-credentials</h6>
        </div>
    </div>

    <div class="card-body">
        <div class="row form-group">
            <div class="col">
                <label translate="">lbl-stripe-secret</label><br>
                <input class="form-control form-control-lg" type="text"
                    [(ngModel)]="secret" />
            </div>
        </div>
        <div class="row form-group">
            <div class="col">
                <label translate="">lbl-stripe-publishable</label><br>
                <input class="form-control form-control-lg" type="text"
                    [(ngModel)]="publishable" />
            </div>
        </div>
    </div>
    <div class="card-footer bg-light">
        <button *ngIf="is_loading == true" class="btn btn-lg text text-muted
            btn-light" translate>
            <img src="assets/img/ajax-loader-dark-gray.gif"> txt-loading
        </button>

        <button *ngIf="is_loading == false" class="btn btn-lg float-left
            btn-primary" (click)="onClick_Save()" translate>btn-save</button>
        <button *ngIf="is_loading == false" class="btn btn-lg float-right
            btn-outline-danger" (click)="onClick_Delete()" translate>btn-delete</button>
    </div>
</div>