/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/forms";
import * as i4 from "./confirm.component";
import * as i5 from "../../../_helper/auth";
var styles_ConfirmComponent = [i0.styles];
var RenderType_ConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmComponent, data: {} });
export { RenderType_ConfirmComponent as RenderType_ConfirmComponent };
export function View_ConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "card-header bg-secondary pl-2 pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "float-left btn btn-sm\n\t\tbtn-outline-secondary"], ["href", "javascript:void(0);"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick_Back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" btn-back"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "text text-center text-muted title-decorative mt-1"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" heading-confirm"])), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 10, "div", [["class", "row mb-4 mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 9, "div", [["class", "col-sm-12 col-md-12 col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "label", [["class", " mb-0"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(12, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["lbl-notes-for-booking"])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "textarea", [["class", "form-control"], ["placeholder", ""], ["rows", "4"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.notes = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(17, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(19, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 7, "div", [["class", "card-footer bg-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "button", [["class", "btn btn-lg btn-outline-secondary"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick_StartAgain() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "icon icon-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" btn-start-again "])), (_l()(), i1.ɵeld(25, 0, null, null, 2, "button", [["class", "btn btn-success btn-lg float-right"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick_Confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" btn-confirm-booking "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; _ck(_v, 6, 0, currVal_1); var currVal_2 = ""; _ck(_v, 12, 0, currVal_2); var currVal_10 = _co.notes; _ck(_v, 17, 0, currVal_10); var currVal_11 = ""; _ck(_v, 22, 0, currVal_11); var currVal_12 = ""; _ck(_v, 26, 0, currVal_12); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 19).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 19).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 19).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 19).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 19).ngClassValid; var currVal_8 = i1.ɵnov(_v, 19).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_ConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-booking-confirm", [], null, null, null, View_ConfirmComponent_0, RenderType_ConfirmComponent)), i1.ɵdid(1, 114688, null, 0, i4.ConfirmComponent, [i5.AuthHelper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmComponentNgFactory = i1.ɵccf("app-new-booking-confirm", i4.ConfirmComponent, View_ConfirmComponent_Host_0, { config: "config" }, { emit_config: "emit_config" }, []);
export { ConfirmComponentNgFactory as ConfirmComponentNgFactory };
