/// <reference types="@types/googlemaps" />

import { Component, OnInit } from '@angular/core';
import { BusinessService } from '../../_service/business.service';
import { AlertService } from '../../_service/alert.service';
//https://angular-maps.com/guides/getting-started/
//import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { FileHelper } from '../../_helper/file';
import { UrlHelper } from '../../_helper/url';
import { TranslateService } from '@ngx-translate/core';
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-config-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {
  
  /**
   * 
   */
  banners_list = [1,2,3,4,5,6,7,8,9];
  title = "Portfolio Settings";
  is_loading = false;
  mon_working_hours_alt = "";
  tue_working_hours_alt = "";
  wed_working_hours_alt = "";
  thu_working_hours_alt = "";
  fri_working_hours_alt = "";
  sat_working_hours_alt = "";
  sun_working_hours_alt = "";
  business = {
    "name":"",
    "predefined_template":false,
    "banner":"",
    "about_us_page":"",
    "facebook_page_url":"",
    "twitter_handle":"",
    "tagline1":"",
    "tagline1_color":"",
    "tagline1_size":"",
    "tagline2":"",
    "tagline2_color":"",
    "tagline2_size":"",
    "is_show_opening_hours":1,
    "mon_working_hours":"",
    "tue_working_hours":"",
    "wed_working_hours":"",
    "thu_working_hours":"",
    "fri_working_hours":"",
    "sat_working_hours":"",
    "sun_working_hours":"",
    "street":"",
    "city":"",
    "state":"",
    "country":"",
    "zipcode":"",
    "lat":"",
    "lng":""
  };
  is_opening_hours = false;
  map:any;
  openedWindow : number = 0; // alternative: array of numbers
  

  /**
   * 
   */
  constructor(
    public businessService: BusinessService,
    public mapsAPILoader: MapsAPILoader,
    public file: FileHelper,
    public url:UrlHelper,
    public translate: TranslateService,
    public alertService: AlertService  ) { 

    this._resetForm();
    this.getSettings();
  }

  /**
   * 
   */
  ngOnInit() {
  }

  /**
   * 
   */
  _resetForm(){
    this.title = "Portfolio Settings";
  }
  
  /**
   * 
   */
  openWindow() {
  }

  /**
   * 
   * @param event 
   */
  selectImage(event: any) {
    let _t = this;
    this.file.onFileChanged(event,function(url,filedata){ 
      _t.business.banner = url;
      _t.business.predefined_template = false;
    });
  }

  /**
   * 
   * @param map 
   */
  mapReady(map){
    this.map = map;
  }
  

  /**
   * 
   */
  onInput_FindLatLng(){
    let address = this.business.street+" "+this.business.city+" "+this.business.state+" "+this.business.zipcode+" "+this.business.country;
    this.getGeoLocation(address);
  }

  /**
   * 
   * @param address 
   */
  getGeoLocation(address: string): void {
    let _t = this;
    let geocoder = new google.maps.Geocoder();
    if (geocoder) {
        geocoder.geocode({
            'address': address
        }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                _t.resetLocation(results[0].geometry);
            }
        });
    }
  }

  resetLocation(g){
    this.business.lat=g.location.lat();
    this.business.lng=g.location.lng();
    this.map.setCenter ({ lat: Number(this.business.lat), lng: Number(this.business.lng) });          
    
  }

  /**
   * 
   */
  onClick_PredefinedBanner(img){
    this.business.predefined_template = true;
    this.business.banner = this.url.getDomainName()+"/assets/img/banner/"+img+".jpg";
  }

  /**
   * 
   */
  onClick_DeleteImage(){
    this.business.banner = "";
    this.onClick_Save();
  }

  /**
   * 
   */
  getSettings(){
    this.is_loading = true;
    this.businessService.getWhosLoggedIn().subscribe(data => {
      this.is_loading = false;
      let temp:any;
      temp = data;
      this.business = temp.data;
      this.business.predefined_template = false;
      this._setWorkingHrs();
      this._fixWorkingTime();
      if(this.map != undefined){
        this.map.setCenter ({ lat: Number(this.business.lat), lng: Number(this.business.lng) });          
      }
    });
  }

  /**
   * 
   */
  onInput_FixTime(){
    this._setWorkingHrs();
    this._fixWorkingTime();
  }

  /**
   * 
   */
  _setWorkingHrs(){
    this.mon_working_hours_alt = this.business.mon_working_hours;
    this.tue_working_hours_alt = this.business.tue_working_hours;
    this.wed_working_hours_alt = this.business.wed_working_hours;
    this.thu_working_hours_alt = this.business.thu_working_hours;
    this.fri_working_hours_alt = this.business.fri_working_hours;
    this.sat_working_hours_alt = this.business.sat_working_hours;
    this.sun_working_hours_alt = this.business.sun_working_hours;
    
  }

  /**
   * 
   */
  _fixWorkingTime(){
    if(this.mon_working_hours_alt == ""){
      this.mon_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if(this.tue_working_hours_alt == ""){
      this.tue_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if(this.wed_working_hours_alt == ""){
      this.wed_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if(this.thu_working_hours_alt == ""){
      this.thu_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if(this.fri_working_hours_alt == ""){
      this.fri_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if(this.sat_working_hours_alt == ""){
      this.sat_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if(this.sun_working_hours_alt == ""){
      this.sun_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if(this.mon_working_hours_alt == "00:00-00:00"){
      this.mon_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if(this.tue_working_hours_alt == "00:00-00:00"){
      this.tue_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if(this.wed_working_hours_alt == "00:00-00:00"){
      this.wed_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if(this.thu_working_hours_alt == "00:00-00:00"){
      this.thu_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if(this.fri_working_hours_alt == "00:00-00:00"){
      this.fri_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if(this.sat_working_hours_alt == "00:00-00:00"){
      this.sat_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if(this.sun_working_hours_alt == "00:00-00:00"){
      this.sun_working_hours_alt = this.translate.instant("lbl-any-time");
    }

  }

  /**
   * 
   * @param event 
   * @param name 
   * @param id 
   */
  onClick_Save(){

    let _p = {
      "predefined_template":this.business.predefined_template,
      "banner":this.business.banner,
      "about_us_page":this.business.about_us_page,
      "facebook_page_url":this.business.facebook_page_url,
      "twitter_handle":this.business.twitter_handle,
      "tagline1":this.business.tagline1,
      "tagline1_color":this.business.tagline1_color,
      "tagline1_size":this.business.tagline1_size,
      "tagline2":this.business.tagline2,
      "tagline2_color":this.business.tagline2_color,
      "tagline2_size":this.business.tagline2_size,
      "is_show_opening_hours":this.business.is_show_opening_hours,
      "mon_working_hours":this.business.mon_working_hours,
      "tue_working_hours":this.business.tue_working_hours,
      "wed_working_hours":this.business.wed_working_hours,
      "thu_working_hours":this.business.thu_working_hours,
      "fri_working_hours":this.business.fri_working_hours,
      "sat_working_hours":this.business.sat_working_hours,
      "sun_working_hours":this.business.sun_working_hours,
      "street":this.business.street,
      "city":this.business.city,
      "state":this.business.state,
      "country":this.business.country,
      "zipcode":this.business.zipcode,
      "lat":this.business.lat,
      "lng":this.business.lng
      
    };
    this.is_loading = true;
    
    this.businessService.save(_p).subscribe(data=>{
      this.is_loading = false;
      let temp:any;
      temp = data;
      this.getSettings();
      this.alertService.success(temp.msg);
        
    });
  }

}
