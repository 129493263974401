import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ParamsHelper } from '../_helper/params';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ServiceService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function ServiceService(http) {
        this.http = http;
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    ServiceService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     * @param _p
     */
    ServiceService.prototype.save = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/do/save-step-1", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    ServiceService.prototype.delete = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/do/delete", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    ServiceService.prototype.getAll = function (_p) {
        if (_p === void 0) { _p = null; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/all", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    ServiceService.prototype.validateForBooking = function (_p) {
        if (_p === void 0) { _p = null; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/do/validate-for-booking", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    ServiceService.prototype.getAllByBusinessId = function (_p) {
        if (_p === void 0) { _p = null; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/all-by-business-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    ServiceService.prototype.getById = function (id) {
        var _p = {
            "id": id
        };
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/by-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    ServiceService.prototype.getAllByCalendarId = function (id) {
        var _p = {
            "calendar_id": id
        };
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/all-by-calendar-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    ServiceService.prototype.getAllWithCalendars = function (type) {
        if (type === void 0) { type = null; }
        var _p = {
            "type": type
        };
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/all-with-calendars", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    ServiceService.prototype.getAllByCategoryId = function (_p) {
        if (_p === void 0) { _p = null; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/all-by-category-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    ServiceService.prototype.getAvailableSlots2 = function (_p) {
        if (_p === void 0) { _p = {}; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/polll/get-available-slots", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    ServiceService.prototype.getAvailableRooms = function (_p) {
        if (_p === void 0) { _p = {}; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/polll/get-available-rooms", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    ServiceService.prototype.getAvailableSlots = function (selected_services_id, date, business) {
        if (business === void 0) { business = null; }
        var _p = {
            "selected_services_id": selected_services_id,
            "date": date,
            "business_id": business.id
        };
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/get-available-slots", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    ServiceService.prototype.getAvailableDates____ = function (selected_services_id, date) {
        var _p = {
            "selected_services_id": selected_services_id,
            "date": date
        };
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/get-available-dates-for-rental-24-hr", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    ServiceService.prototype.getAvailableSlotsForReschedule = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/get-available-slots-for-reschedule", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    ServiceService.prototype.toggleCategory = function (assign, category_id, id) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("category_id", category_id)
            .append("service_id", id).append("auth_token", user.data.auth_token);
        var api = "deassign-category";
        if (assign) {
            api = "assign-category";
        }
        return this.http.post(environment.server + "/provider/service/do/" + api, body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    ServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServiceService_Factory() { return new ServiceService(i0.ɵɵinject(i1.HttpClient)); }, token: ServiceService, providedIn: "root" });
    return ServiceService;
}());
export { ServiceService };
