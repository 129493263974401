<div class="card-header bg-secondary pl-2 pr-2">
	<a (click)="onClick_Back()" class="float-left btn btn-sm
		btn-outline-secondary" href="javascript:void(0);"
		translate=""><i class="icon-chevron-left"></i> btn-back</a>
	<a (click)="onClick_Next()" *ngIf="is_next" class="float-right btn btn-sm
		btn-primary" href="javascript:void(0);"
		translate="">btn-next <i class="icon-chevron-right"></i> </a>
	<div class="text text-center text-muted title-decorative mt-1" translate>
		heading-datetime<img src="assets/img/ajax-loader-dark-gray.gif"
			class="float-right"
			*ngIf="config.is_loading">
	</div>
</div>
<div class="pt-0 pl-2 pr-2" *ngIf="auth.getUser().user_type=='customer' &&
	config.payment.required">
	<div class="card-header row bg-light">
		<div class="col-lg-12 col-md-12 col-sm-12">
			<span class="title-decorative text-muted mb-0"
				translate="">txt-this-service-requires-you-to-pay-online</span>
		</div>
	</div>
</div>

<div class="" *ngIf="customer_calendar_ui_mode=='2'">
	<hr class="mb-0 pb-0 pt-0 mt-0">
	<div class="card-header mb-0 title-decorative text-primary">
		<div class="row">
			<span class="col-4">
				{{config.service[0].name}}
			</span>
			<span class="col-3">
				<span class="text-muted" translate="">lbl-check-in</span><span>:{{config.service[0].checkin}}</span>
			</span>
			<span class="col-3">
				<span class="text-muted" translate="">lbl-check-out</span><span>:{{config.service[0].checkout}}</span>
			</span>
		</div>
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-lg-4 col-md-4 col-sm-12 text-center pt-2">
				<p-calendar (onSelect)="onClick_DatetimeChange_2($event)"
					selectionMode="range"
					[utc]=true [locale]="no" [numberOfMonths]="1" [minDate]="today"
					[inline]="true" [readonlyInput]="true">
					<ng-template pTemplate="date" let-date>
						<span>{{date.day}}</span>
					</ng-template>
				</p-calendar>
			</div>
			<hr>
			<div class="col-lg-8 col-md-8 col-sm-12" *ngIf="available_cals.length> 0">
				<div class="col-12 mb-2 pb-4 pt-2 border-bottom mb-3" *ngFor="let room of
					available_cals">
					<div class="row">
						<div class="col-md-4 col-lg-4 col-sm-12 mb-2">
							<img class="img img-fluid rounded shadow-sm" src="{{config.service[0].image}}">
						</div>
						<div class="col-md-8 col-lg-8 col-sm-12">
							<small *ngIf="config?.service[0]?.detailed_description != '' && config?.service[0]?.detailed_description != null">
								{{config?.service[0]?.detailed_description}}
							</small>
							<small *ngIf="config?.service[0]?.detailed_description == '' || config?.service[0]?.detailed_description == null">
								Click Next to proceed ...
							</small>
						</div>
					</div>
				</div>
				<a (click)="onClick_Next()" *ngIf="is_next" class="float-right btn btn-sm
				btn-primary mb-3" href="javascript:void(0);"
				translate="">btn-next <i class="icon-chevron-right"></i></a>
		
			</div>
			<div *ngIf="datetime_s!=null && datetime_e==null">
				<div class="p-2 text-muted">Please select end date to search available services
				</div>
			</div>
			<div *ngIf="datetime_s==null && datetime_e==null">
				<div class="p-2 text-muted">Please select start date to search available services
				</div>
			</div>
			<div *ngIf="available_cals.length == 0 && datetime_s!=null && datetime_e!=null">
				<div class="p-2 text-muted">No available service found on requested date.
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="customer_calendar_ui_mode=='1'">
	<div class="" *ngFor="let cal of cals">
		<hr class="mb-0 pb-0 pt-0 mt-0">
		<div class="card-header mb-0 title-decorative text-primary bg-light">
			<div class="row">
				<span class="col-2" >
					<img *ngIf="cal?.image != '' && cal?.image != 'null' && cal?.image != null"
						(click)="open(model_id,cal)" class="thumbnail
						rounded img-fluid" [src]="cal?.image">
				</span>
				<span class="col-4 text-muted">
					{{cal.name}}
				</span>
				<span class="col-3">
					<span class="text-muted" translate="">lbl-check-in</span><span>{{config.service[0].checkin}}</span>
				</span>
				<span class="col-3">
					<span class="text-muted" translate="">lbl-check-out</span><span>{{config.service[0].checkout}}</span>
				</span>
			</div>
		</div>
		<div class="card-body mb-4">
			<div class="row">
				<div class="col-12 text-center">
					<div class="pb-1"><small translate>txt-dates-with-red-color-are-only-available-for-check-out</small></div>
					<p-calendar (onSelect)="onClick_DatetimeChange($event,cal)"
						[(ngModel)]="datetime[cal.id]"

						selectionMode="range"
						[disabledDates]="getMomentTime(cal.unavailable_slots)"
						[utc]=true [locale]="no" [numberOfMonths]="3" [minDate]="today"
						[inline]="true" [readonlyInput]="true">
						<ng-template pTemplate="date" let-date>
							<span
								[ngClass]="{'checkout':isHalfCheckinDay(cal,date),'checkin':isHalfCheckoutDay(cal,date)}">{{date.day}}</span>
						</ng-template>
					</p-calendar>
				</div>
			</div>
		</div>
	</div>
</div>


<ng-template #model_id let-modal>
	<div class="modal-header">
		<div class="modal-title" id="modal-basic-title">{{modal_data.name}}</div>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<img src="{{modal_data.image}}" class="shadow rounded img-fluid">
			</div>
		</div>
		<br>
		<div>{{modal_data.description}}</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.close()"
			translate>btn-close</button>
	</div>
</ng-template>