<div class="card card-sm">
    <div class="card-header bg-secondary d-flex justify-content-between
        align-items-center">
        <span *ngIf="is_loading == false" translate>heading-manage-paypal-settings</span>
        <span *ngIf="is_loading == true" translate>
            <img src="assets/img/ajax-loader-dark-gray.gif">
            txt-loading
        </span>
    </div>
    <div class="card-body">
        
        <!-- 
        <div class="alert alert-primary"><span translate>txt-please-provide-paypal-info</span>.
            <span translate>txt-read-paypal-document</span>
            <a target="_blank"
                href="https://developer.paypal.com/developer/applications/">
                https://developer.paypal.com/developer/applications/</a></div>
        -->
        <!--
        <div class="row form-group">
            <div class="col">
                <label>Paypal Username:</label><br>
                <input class="form-control form-control-lg" type="text" [(ngModel)]="username"  />
          </div>
        </div>
        <div class="row form-group">
                <div class="col">
                <label>Paypal Password:</label><br>
                <input class="form-control form-control-lg" type="text" [(ngModel)]="password"  />
          </div>
        </div>
        <div class="row form-group">
                <div class="col">
                <label>Paypal Signature:</label><br>
                <input class="form-control form-control-lg" type="text" [(ngModel)]="signature"  />
          </div>
        </div>
        -->
        <!-- 
        <div class="row form-group">
            <div class="col">
                <label translate>lbl-paypal-live-access-token</label><br>
                <input class="form-control form-control-lg" type="text"
                    [(ngModel)]="signature" />
            </div>
        </div>
        -->

        <div class="alert alert-primary ">
            <span translate>txt-read-paypal-document-for-client-id-secret</span>
            <a target="_blank"
                href="https://developer.paypal.com/developer/applications/">
                https://developer.paypal.com/developer/applications/</a></div>
        <div class="row form-group">
            <div class="col">
                <label translate>lbl-paypal-client-id</label><br>
                <input  autocomplete="off" class="form-control form-control-lg" type="text"
                    [(ngModel)]="client_id" />
            </div>
        </div>
        <div class="row form-group mb-4">
            <div class="col">
                <label translate>lbl-paypal-client-secret</label><br>
                <input autocomplete="off"  class="form-control form-control-lg" type="text"
                    [(ngModel)]="client_secret" />
            </div>
        </div>
    </div>
    <div class="card-footer bg-light">
        <button *ngIf="is_loading == true" class="btn btn-lg text text-muted
            btn-light" translate>
            <img src="assets/img/ajax-loader-dark-gray.gif"> txt-loading
        </button>

        <button *ngIf="is_loading == false" class="btn btn-lg float-left
            btn-primary" (click)="onClick_Save()" translate>btn-save</button>
        <button *ngIf="is_loading == false" class="btn btn-lg float-right
            btn-outline-danger" (click)="onClick_Delete()" translate>btn-delete</button>
    </div>
</div>
