import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/_service/su/user.service';
import { AlertService } from 'src/app/_service/alert.service';
import { BookingService } from 'src/app/_service/su/booking.service';
import { DateHelper } from 'src/app/_helper/date';
import { TranslateService } from '@ngx-translate/core';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  is_loading = false;
  user_id = 0;
  user: any;
  new_password:any;
  bookings:any;
  user_cols = [
    {
      "name": "user_type",
      "disabled": true
    },
    {
      "name": "business_id",
      "disabled": true,
    },
    {
      "name": "stripe_cust_id",
      "disabled": true,
    },
    {
      "name": "first_name"
    },
    {
      "name": "last_name"
    },
    {
      "name": "username"
    },
    {
      "name": "mobile"
    },
    {
      "name": "country_mobile_code"
    },
    {
      "name": "telephone"
    },
    {
      "name": "image",
      "disabled": true,
    },
    {
      "name": "street"
    },
    {
      "name": "city"
    },
    {
      "name": "state"
    },
    {
      "name": "country"
    },
    {
      "name": "zipcode"
    },
    {
      "name": "lat",
      "disabled": true,
    },
    {
      "name": "lng",
      "disabled": true,
    },
    {
      "name": "utc_offset"
    },
    {
      "name": "timezone"
    },
    {
      "name": "locale"
    },
    {
      "name": "is_allowed_new_booking"
    },
    {
      "name": "is_allowed_config"
    },
    {
      "name": "is_allowed_payment"
    },
    {
      "name": "is_allowed_customer"
    },
    {
      "name": "reset_password_time",
      "disabled": true,
      "datetime": true,
    },
    {
      "name": "reset_password_token",
      "disabled": true,
    },
    {
      "name": "status",
    },
    {
      "name": "last_updated",
      "disabled": true,
      "datetime": true,
    },
    {
      "name": "last_update_json",
      "disabled": true,
    },
    {
      "name": "mark_for_delete",
    },
    {
      "name": "dated",
      "disabled": true,
      "datetime": true,
    }

  ]

  settings = {
    noDataMessage : this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions:false,
    columns: {
      name: {
        title: "Business"
      },
      service_name: {
        title: "Service"
      },
      price: {
        title: "Price"
      },
      start_timestamp_in_minute: {
        title: "Start",
        valuePrepareFunction: (value) => { return this.date.formatForNorway(value*60) },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            return moment.unix(cell*60).utc().format(this.date.norway_datetime_format).match(search);
          }
        }
      },
      end_timestamp_in_minute: {
        title: "Ends",
        valuePrepareFunction: (value) => { return this.date.formatForNorway(value*60) },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            return moment.unix(cell*60).utc().format(this.date.norway_datetime_format).match(search);
          }
        }
      },
      b_status: {
        title: "Status"
      },
      dated: {
        title: "Created",
        valuePrepareFunction: (value) => { return this.date.formatForNorway(value) },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            return moment.unix(cell).utc().format(this.date.norway_datetime_format).match(search);
          }
        }
      }
    }
  };

  /**
   * 
   * @param userService 
   * @param route 
   * @param router 
   * @param date 
   * @param translate 
   * @param bookingService 
   * @param alert 
   */
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private date: DateHelper,
    public translate: TranslateService,
    private bookingService: BookingService,
    private alert: AlertService
  ) {
    this.route.queryParams.subscribe(params => {
      this.user_id = params['id'];
    });

  }

  /**
   * 
   */
  ngOnInit() {
    this._getUser();
    this._getBookings();
  }

  /**
   * 
   */
  _getUser(){
    this.userService.get(this.user_id).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        let payload = temp.payload;
        this.user = payload;
        this.user_cols.forEach((k, i) => {
          this.user_cols[i]['value'] = payload[k.name];
        })
      }
    });
  }

  /**
   * 
   */
  _getBookings(){
    this.bookingService.getAllByCustomerId(this.user_id).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        let payload = temp.payload;
        this.bookings = payload;
      }
    });
  }
  
  /**
   * 
   */
  onSubmit_Profile() {
    let _p = {};
    this.user_cols.forEach((k, i) => {
      if (!k.disabled) {
        _p[k.name] = k["value"];
      }
    });

    _p["id"] = this.user_id;

    this.userService.update(_p).subscribe(data => {
      let temp:any;
      temp = data;
      if(temp.success){
        this.alert.success(temp.msg);
        this._getUser();
      } 
      else{
        this.alert.error(temp.msg);
      }
    })
  }

  /**
   * 
   * @param event 
   */
  userRowSelect(event){
    this.router.navigateByUrl("su/booking.details?id="+event.data.b_id);
  }

  /**
   * 
   */
  onSubmit_NewPassword(){
    let _p = {
      "secure":true,
      "id":this.user_id,
      "x":this.new_password
    }
    this.userService.update(_p).subscribe(data=>{
      let temp:any;
      temp = data;
      if(temp.success){
        this.alert.success(temp.msg);
      }
      else{
        this.alert.error(temp.msg);
      }
    })
  }

  /**
   * 
   */
  onClick_SendRandomPassword(){
    let _p = {
      "id":this.user_id,
      "send_random_pwd":true
    }
    this.userService.update(_p).subscribe(data=>{
      let temp:any;
      temp = data;
      if(temp.success){
        this.alert.success(temp.msg);
      }
      else{
        this.alert.error(temp.msg);
      }
    })

  }

  /**
   * 
   */
  onClick_SendResetLink(){
    let _p = {
      "id":this.user_id,
      "send_reset_pwd_link":true
    }
    this.userService.update(_p).subscribe(data=>{
      let temp:any;
      temp = data;
      if(temp.success){
        this.alert.success(temp.msg);
      }
      else{
        this.alert.error(temp.msg);
      }
    })

  }

}
