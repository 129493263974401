import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-new-booking-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  @Input() config: any;
  @Output() emit_config = new EventEmitter<any>();
  title = "Error";
  notes = "";
  
  constructor() { }

  ngOnInit() {
  }

  onClick_StartAgain(){
    this.config.step = "start-again";
    this.emit_config.emit(this.config);
  }
}
