import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthHelper } from '../../_helper/auth';
import { Router } from '@angular/router';
import { DateHelper } from 'src/app/_helper/date';
import { UserService } from 'src/app/_service/su/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  users: any;
  is_loading = false;
  settings = {
    noDataMessage : this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions:false,
    columns: {
      user_type: {
        title: "User Type"
      },
      first_name: {
        title: "First"
      },
      last_name: {
        title: "Last"
      },
      username: {
        title: "Username"
      },
      mobile: {
        title: "Mobile"
      },
      status: {
        title: "Status"
      },
      dated: {
        title: "Created",
        valuePrepareFunction: (value) => { return this.date.formatForNorway(value) }
      }
    }
  };

  constructor(    
    private translate: TranslateService,
    private router: Router,
    public date:DateHelper,
    public user:UserService,
    private auth: AuthHelper
    ) {

  }

  ngOnInit() {
    this.is_loading = true;
    this.user.getAll().subscribe(data=>{
      let temp:any;
      temp = data;
      this.is_loading = false;
      this.users = temp.payload;
    });

  }

  userRowSelect(event){
    this.router.navigateByUrl("su/user.details?id="+event.data.id);     

  }
  

}
