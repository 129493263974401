<nav aria-label="breadcrumb" role="navigation"
class="bg-primary text-white">
<div class="container">
  <div class="row justify-content-center">
    <div class="col">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0);" translate >txt-customers</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" translate >txt-listing-all-your-customers</li>
        <li class="breadcrumb-item active" *ngIf="is_loading === true" aria-current="page" translate>
          <img src="assets/img/ajax-loader-blue.gif"  > txt-loading 
        </li>
      </ol>
    </div>
  </div>
</div>
</nav>
<section class="space-xs">
    <div class="container">
        <div class="row card-borderless mb-1">
            <div class="col ">
                <span class="title-decorative mb-0 pb-0 mt-0 pt-0 fsloat-left" translate >txt-click-on-a-customer-to-view-details 
                  <img *ngIf="is_loading === true" src="assets/img/ajax-loader-light-gray.gif" ></span>
            </div>
            <div class="col">
              <a [routerLink]="['/customer.create']" class="btn float-right btn-md btn-success" translate >btn-create-customer</a>
            </div>
        </div>
        <ng2-smart-table (userRowSelect)="onClick_User($event)" class="table-responsive" [settings]="settings" [source]="customers" ></ng2-smart-table>
    </div>
</section>
