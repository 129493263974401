<div class="row" *ngIf="is_service_disabled">
	<div class="col-12">
		<div class="alert alert-danger" translate="">txt-services-disabled</div>
	</div>
</div>
<div *ngIf="!is_service_disabled" class="card-header bg-secondary pl-2 pr-2">
	<a (click)="onClick_Next()" *ngIf="show_next_btn" class="float-right btn btn-sm
		btn-primary ml-1" href="javascript:void(0);"
		translate="">btn-next<i class="icon-chevron-right"></i></a>
	<div class="text text-center text-muted title-decorative mt-1 "translate>
		{{title}} <img src="assets/img/ajax-loader-dark-gray.gif" class="float-right" *ngIf="config.is_loading" ></div>
</div>
<div *ngIf="!is_service_disabled" class="card-header pl-2 pr-2 text-muted d-flex justify-content-between" >
	<small *ngIf="config.service.length==0" translate="">lbl-please-select-a-service.</small>
	<!-- <small *ngIf="config.service.length>0" class="text-primary" >Service selected, please click Next button to proceed.</small> -->
	<a *ngIf="services != undefined" href="javascript:void(0);" (click)="onClick_ClearSelection()"
		class=" " translate="">
		<small translate="">btn-clear-selection</small>
	</a>
</div>
<div *ngIf="!is_service_disabled" class="card-body pl-2 pr-2">
	<div class="row">
		<div class="col-sm-12 col-md-4 col-lg-3 mb-3">
			<div class="text-muted mb-0 mt-0 " ><span class="title-decorative" translate="">heading-category</span></div>
			<div class="list-group" >
				<a href="javascript:void(0);" (click)="onClick_Category(c)"
					class="list-group-item {{c.active_class}} list-group-item-action" *ngFor="let c of
					categories">
					{{c.name}}<i style="padding-top:4px"
						class="float-right icon-chevron-right"></i>
				</a>
			</div>
		</div>
		<div class="col-sm-12 col-md-8 col-lg-9 ">
			<div class="text-muted mb-0 mt-0 " ><span class="title-decorative" translate="">btn-services</span></div>
			<div class=" mt-1" *ngIf="services.length == 0"><small class="text-danger" translate="">alert-service-not-found</small></div>
			<div class="list-group  ">
				<ng-container *ngFor="let s of
				services">
				<a *ngIf="s.service_type!='4'" href="javascript:void(0);" (click)="onClick_Service(s)"  class="list-group-item  {{s.active_class}} list-group-item-action">
					{{s.name}}
					<div *ngIf="s.service_type=='1' || s.service_type=='2' ">
						<small *ngIf="s.description!='' && s.description!= 'null' &&
						s.description!=null">{{s.description}}</small>
						<div><small class="text text-muted">{{s.duration}}
						min, {{s.price}} NOK</small></div>
					</div>
					
					<div *ngIf="s.service_type=='3' ">
						<small *ngIf="s.description!='' && s.description!= 'null' &&
						s.description!=null">{{s.description}}<br></small>
						<div><small class="text text-muted">{{s.price}} NOK / <span translate="">lbl-day</span></small></div>
					</div>
				</a>
				<a target="_blank" *ngIf="s.service_type=='4'" class="list-group-item  {{s.active_class}} list-group-item-action"
					href="{{s.description}}"
					>
					<div>{{s.name}}</div>
				</a>

				</ng-container>
			</div>
		</div>
	</div>
</div>
