<div class="card card-sm">
  <div class="card-header bg-secondary">
    <a href="javascript:void(0);" (click)="onClick_BackBtnOnLogin($event)"
      class="float-left text text-danger" translate><i
        style="padding-top: 4px;" class="icon-chevron-left"></i> btn-back</a>
    <div class="text text-center title-decorative" *ngIf="loading == true"
      translate>
      <img src="assets/img/ajax-loader-light-gray.gif" translate> txt-loading
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="card">
          <div class="card-header bg-light text-muted title-decorative" translate>
            heading-signin
          </div>
          <div class="card-body">
            <div class="row no-gutters justify-content-center">
              <div class="text-left col-lg-12">
                <div class="form-group">
                  <input autocomplete="off" [(ngModel)]="email" class="form-control
                    form-control-lg" placeholder="{{'lbl-email' | translate}}"
                    />
                </div>
                <div class="form-group">
                  <input autocomplete="off" [(ngModel)]="password" class="form-control
                    form-control-lg" type="password" placeholder="{{
                    'lbl-password' | translate}}" />
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-light">
            <button (click)="onClick_Signin()" class="btn btn-lg btn-primary"
              translate>btn-signin</button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card">
          <div class="card-header  text-muted bg-light title-decorative" translate>
            heading-register-to-bookit24
          </div>
          <div class="card-body">
            <div class="row no-gutters justify-content-center">
              <div class="text-left col-lg-12">
                <div class="form-group">
                  <input autocomplete="off" [(ngModel)]="username" class="form-control
                    form-control-lg" placeholder="{{ 'lbl-email' | translate }}"
                    />
                </div>
                <div class="form-group">
                  <input autocomplete="off" [(ngModel)]="new_password" class="form-control
                    form-control-lg" placeholder="{{ 'lbl-password' | translate
                    }}" />
                </div>
                <div class="form-group">
                  <input autocomplete="off" [(ngModel)]="mobile" class="form-control
                    form-control-lg" type="" placeholder="{{ 'lbl-mobile' |
                    translate }}" />
                </div>
                <div class="form-group">
                  <input autocomplete="off" [(ngModel)]="first_name" class="form-control
                    form-control-lg" type="" placeholder="{{ 'lbl-first-name' |
                    translate }}" />
                </div>
                <div class="form-group">
                  <input autocomplete="off" [(ngModel)]="last_name" class="form-control
                    form-control-lg" type="" placeholder="{{ 'lbl-last-name' |
                    translate }}" />
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-success">
            <button (click)="onClick_Signup()" class="btn btn-lg active
              btn-success" translate>btn-submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
