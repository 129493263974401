import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../_service/customer.service';
import { AuthHelper } from '../../_helper/auth';
import { Router } from '@angular/router';
import { StringHelper } from '../../_helper/string';
import { AlertService } from '../../_service/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  /**
   * 
   */
  is_loading = false;
  first_name = "";
  last_name = "";
  mobile = "";
  username = "";
  password = "";
  street = "";
  city = "";
  state = "";
  zipcode = "";
  country = "";
  is_new_customer_done = false;
  /**
   * 
   */
  constructor(
    public customerService: CustomerService,
    public auth: AuthHelper,
    public string:StringHelper,
    private translate: TranslateService,
    public alert:AlertService,
    public router: Router

  ) { 

    if(this.auth.getUser().is_allowed_customer!='1'){
      this.router.navigate(['/dashboard']);
    }
    this._resetForm();

  
  }

  /**
   * 
   */
  ngOnInit() {
  }

  /**
   * 
   */
  _resetForm(){

  }

  /**
   * 
   */
  onClick_Save(){
    let _p = {
      "first_name":this.first_name,
      "last_name":this.last_name,
      "mobile":this.mobile,
      "username":this.username,
      "street":this.street,
      "city":this.city,
      "state":this.state,
      "zipcode":this.zipcode,
      "country":"Norway",
      "country_mobile_code":"40"
    };
    if(this._validate(_p)){
      this.is_loading = true;
      this.customerService.create(_p).subscribe(data => {
        this.is_loading = false;
        let temp:any;
        temp = data;
        if(temp.success){
          this.alert.success(temp.msg);
        }else{
          this.alert.error(temp.msg);
        }
        this.is_new_customer_done = true;
        this.first_name = "";
        this.last_name = "";
        this.username = "";
        this.mobile = "";
        this.street = "";
        this.city = "";
        this.state = "";
        this.zipcode = "";
        this.country = "";
        
      },
      error=>{
        this.is_loading = false;
        this.alert.error(this.translate.instant("alert-error"));
      });
    }

  }

  _validate(_p){
    if(!this.string.isValidEmail(_p.username)){
      this.alert.error(this.translate.instant("alert-valid-email-is-required"));
      return false;
    }
    if(_p.first_name == "" || _p.last_name == ""){
      this.alert.error(this.translate.instant("alert-first-last-are-required"));
      return false;
    }

    if(_p.mobile.length < 8){
      this.alert.error(this.translate.instant("alert-valid-mobile-is-required"));
      return false;
    }
    return true;
  }

}