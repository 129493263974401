<section class="space-sm">
  <div class="container align-self-start">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-7">
        <div class="card card-lg" *ngIf="is_reset_done == false">
          <div class="card-header bg-secondary" translate="">
            heading-set-new-bookit24-password
          </div>
          <div class="card-body" >
            <div class="row no-gutters">
              <div class="text-left col-lg-12">
                <div class="form-group">
                  <label for="email" translate="">lbl-please-enter-a-new-password</label>
                  <div><small class="text text-muted" translate="">txt-must-be-atleast-8-char-length-with-no-spaces</small></div>
                  <input [(ngModel)]="password" autocomplete="off" class="form-control form-control-lg" maxlength="40" type="password" (keyup.enter)="onSubmit()"
                    placeholder="" />
                </div>
              </div>
              <div class="text-left col-lg-12">
                <div class="form-group">
                  <label for="email" translate="">lbl-re-type-new-password</label>
                  <input [(ngModel)]="confirm_password" autocomplete="off" class="form-control form-control-lg" type="password" maxlength="40"  (keyup.enter)="onSubmit()"
                    placeholder="" />
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <span *ngIf="is_loading == true"  
              class="btn btn-light btn-lg btn-muted" translate=""><img src="assets/img/ajax-loader-light-gray.gif" 
              translate="" >txt-loading</span>
            <button (click)="onSubmit()" class="btn btn-lg btn-primary" *ngIf="is_loading == false" translate="">btn-submit</button>
          </div>

        </div>
        <div class="card card-lg" *ngIf="is_reset_done == true">
          <div class="card-body" >
            <div class="row no-gutters">
              <div class="text-left col-lg-12">
                <h5 translate="">heading-your-password-has-been-changed-please-try-signin-now</h5>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</section>
