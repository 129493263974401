/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./good-bye.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./good-bye.component";
var styles_GoodByeComponent = [i0.styles];
var RenderType_GoodByeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoodByeComponent, data: {} });
export { RenderType_GoodByeComponent as RenderType_GoodByeComponent };
export function View_GoodByeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "section", [["class", "height-20 flush-with-above"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "row justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-12 col-md-4 mb-4 col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "Image"], ["class", "w-100"], ["src", "assets/img/graphic-imac-interface.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "col-12 col-md-8 col-lg-8 mb-4 text-center text-md-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h1", [["class", "display-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you for using Bookit24. "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you would like to come back or have any suggestions to improve our services, please drop us an email at support@bookit24.com"]))], null, null); }
export function View_GoodByeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-good-bye", [], null, null, null, View_GoodByeComponent_0, RenderType_GoodByeComponent)), i1.ɵdid(1, 114688, null, 0, i2.GoodByeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoodByeComponentNgFactory = i1.ɵccf("app-good-bye", i2.GoodByeComponent, View_GoodByeComponent_Host_0, {}, {}, []);
export { GoodByeComponentNgFactory as GoodByeComponentNgFactory };
