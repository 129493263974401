/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gdpr.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./gdpr.component";
var styles_GdprComponent = [i0.styles];
var RenderType_GdprComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GdprComponent, data: {} });
export { RenderType_GdprComponent as RenderType_GdprComponent };
export function View_GdprComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "space-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Personvern."])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ved leie av leilighet: Vennligst legg merke til at systemet logger navn p\u00E5 bruker, samt tidpunktene for n\u00E5r d\u00F8ren l\u00E5ses opp. Dette blir lagret for servicehistorikk i systemet i 90 dager og er beskyttet av brukernavn og passord og kun tilgjengelig for administratorer av systemet. "]))], null, null); }
export function View_GdprComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gdpr", [], null, null, null, View_GdprComponent_0, RenderType_GdprComponent)), i1.ɵdid(1, 114688, null, 0, i2.GdprComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GdprComponentNgFactory = i1.ɵccf("app-gdpr", i2.GdprComponent, View_GdprComponent_Host_0, {}, {}, []);
export { GdprComponentNgFactory as GdprComponentNgFactory };
