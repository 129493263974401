import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerService } from '../../../_service/customer.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AlertService } from './../../../_service/alert.service';
import { AuthHelper } from '../../../_helper/auth';
import { UrlHelper } from '../../../_helper/url';
import { BusinessService } from '../../../_service/business.service';
import { StringHelper } from '../../../_helper/string';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-new-booking-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  @Input() config: any;
  @Output() emit_config = new EventEmitter<any>();
  show_back_btn = true;
  all_customers = [];
  business: any;
  customer = { "username": "", "first_name": "", "last_name": "", "mobile": "", "street": "", "city": "", "state": "", "zipcode": "", "country": "Norway" };

  constructor(
    private customerService: CustomerService,
    private alertService: AlertService,
    private auth: AuthHelper,
    public translate: TranslateService,
    private string: StringHelper,
    private businessService: BusinessService,
    private url: UrlHelper
  ) { }

  ngOnInit() {
    this._getBusiness();
  }

  _getBusiness() {
    this.config.is_loading = true;
    this.businessService.getBySubdomain(this.url.getSubdomain()).subscribe(data => {
      let temp: any;
      temp = data;
      this.config.is_loading = false;

      if (temp.success) {
        this.business = temp.data;
        this.getAllCustomers();
      }
      else {
        this.alertService.error(this.translate.instant("alert-invalid-business-account"));
      }
    });
  }

  onClick_Back() {
    this.config.step = "datetime";
    this.emit_config.emit(this.config);
  }


  /**
     * 
     */
  getAllCustomers() {
    let _p = {
      "business_id": this.business.id
    };
    this.config.is_loading = true;

    this.customerService.getAll(_p).subscribe(
      (res) => {
        this.config.is_loading = false;
        let response = JSON.parse(JSON.stringify(res));
        if (response.success) {
          this.all_customers = response.data;
        }
      },
      error => {
        this.config.is_loading = false;
        this.alertService.error(error);
      });

  }

  /**
  */
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term === '' ? [] : this.all_customers.filter(v => v.username.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.mobile.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
      )
    );

  /**
   * 
   */
  formatter = (x: {
    username: string
  }) => x.username;


  onSelect_SelectCustomer(e) {
    this.config.summary.customer = e.item.username;
    this.config.customer = e.item;
    this._emit();
  }

  _emit() {
    this.config.step = "confirm";
    this.emit_config.emit(this.config);
  }

  /**
   * 
   */
  onClick_CreateNewCustomer() {
    if (!this._verifyCustomerInfo()) {
      return false;
    }
    this.config.is_loading = true;
    this.customerService.create(this.customer).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        this.getAllCustomers();
        this.config.customer.username = temp.data.username;
        this.config.customer.id = temp.data.id;
        this.config.customer.first_name = temp.data.first_name;
        this.config.customer.last_name = temp.data.last_name;
        this.config.customer.mobile = temp.data.mobile;
        this.config.summary.customer = temp.data.username;
        this.config.is_loading = false;

        this._emit();
      } else {
        this.alertService.error(this.translate.instant("alert-error"));
      }
    },
      error => {
        this.config.is_loading = false;
        this.alertService.error(this.translate.instant("alert-error"));
      });
  }

  /**
     * 
     */
  _verifyCustomerInfo() {
    let flag = true;
    if (!this.string.isValidNorwayMobile(this.customer.mobile)) {
      this.alertService.error(this.translate.instant("alert-valid-mobile-is-required"));
      return false;
    }
    if (this.customer.first_name == "" || this.customer.last_name == "") {
      this.alertService.error(this.translate.instant("alert-first-last-are-required"));
      return false;
    }

    if (!this.string.isValidEmail(this.customer.username)) {
      flag = false;
      this.alertService.error(this.translate.instant("alert-valid-email-is-required"));
    }
    return flag;
  }

}
