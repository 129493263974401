<div class="container">
    <div class="row">
        <!--end of col-->
        <div class="col-12 col-md-3">
            <div class="card card-sm">
                <ul class="list-group">
                    <li class="list-group-item bg-light">
                        <button class="btn btn-block btn-link text-muted"
                            (click)="onClick_ManageCategory()" translate>btn-manage-categories</button>
                    </li>

                    <li class="list-group-item bg-light">
                        <button class="btn btn-block btn-link text-muted"
                            (click)="onClick_AddService()" translate>btn-add-service</button>
                    </li>

                    <li class="list-group-item pl-1" id="cal{{ser.id}}"
                        *ngFor="let ser of all_services"
                        (click)="onClick_Service($event,ser.name,ser.id)"><a
                            href="javascript:void(0);" id="cal{{ser.id}}"><span
                                class="label label-primary"
                                [style.backgroundColor]="ser.color">&nbsp;</span>
                            {{ser.name}}<i style="padding-top: 4px;"
                                class="float-right icon-chevron-right"
                                id="cal{{ser.id}}"></i>&nbsp;
                            <small class="text text-muted">{{ser.category}}</small></a></li>
                </ul>
            </div>
        </div>

        <div class="col-12 col-md-9">
            <div *ngIf="is_on_service == false">
                <app-config-category></app-config-category>
            </div>

            <!-- 
                Service Info
                Timings
                Payment
                Cancellation
            -->

            <div >

            </div>

            <div *ngIf="is_on_service == true">
                <div class="card card-sm">
                    <div class="card-header bg-secondary d-flex
                        justify-content-between align-items-center">
                        <span *ngIf="is_loading == true" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif"
                                translate>
                            txt-loading
                        </span>
                        <span *ngIf="is_loading == false" translate="">{{title}}</span>
                    </div>
                    <!-- service radios -->
                    <div class="card-body bg-light " style="padding-top:18px"
                        *ngIf="is_add_new_service == true">
                        <div class="row col-12">
                            <div class="col">
                                <div class="custom-control custom-radio">
                                    <input id="type_appointment" type="radio"
                                        class="custom-control-input" [value]="1"
                                        [(ngModel)]="selected_service.service_type">
                                    <label class="custom-control-label  "
                                        for="type_appointment" translate>lbl-appointment</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="custom-control custom-radio">
                                    <input id="type_rental_variable_hrs"
                                        type="radio"
                                        class="custom-control-input"
                                        [value]="2"
                                        [(ngModel)]="selected_service.service_type">
                                    <label class="custom-control-label"
                                        for="type_rental_variable_hrs"
                                        translate>lbl-rental</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="custom-control custom-radio">
                                    <input id="type_rental_24hrs" type="radio"
                                        class="custom-control-input" [value]="3"
                                        [(ngModel)]="selected_service.service_type">
                                    <label class="custom-control-label"
                                        for="type_rental_24hrs" translate>lbl-accommodation</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="custom-control custom-radio">
                                    <input id="type_external" type="radio"
                                        class="custom-control-input" [value]="4"
                                        [(ngModel)]="selected_service.service_type">
                                    <label class="custom-control-label"
                                        for="type_external" translate>lbl-external-service</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- service msg -->
                    <div class="card-footer"style="padding-top:18px"
                        *ngIf="selected_service.service_type == 2 &&
                        (is_service_selected == true || is_add_new_service ==
                        true)">
                        <div class="custom-control custom-checkbox
                            custom-checkbox-switch">
                            <input type="hidden"
                                [(ngModel)]="selected_service.is_return_same_day">
                            <input type="checkbox" class="custom-control-input"
                                [checked]="selected_service.is_return_same_day
                                === boolean_status[0].value"
                                (change)="$event.target.checked?
                                (selected_service.is_return_same_day=
                                boolean_status[0].value) :
                                (selected_service.is_return_same_day=
                                boolean_status[1].value)"
                                id="is_return_same_day">
                            <label class="custom-control-label text"
                                for="is_return_same_day" translate>lbl-this-rental-service-must-be-returned-same-day</label>
                        </div>
                    </div>

                    <!-- service 4 info -->
                    <div class="" *ngIf="(is_service_selected == true ||
                    is_add_new_service == true ) && selected_service.service_type == 4">
                    </div>
                    <div *ngIf="selected_service.service_type == 4">
                        <div class="card-footer"style="padding-top:18px">
                            <div class="alert alert-primary" translate="">
                                txt-extra-service-information
                            </div>

                            <div class="form-group">
                                <div *ngIf="is_add_new_service == true">
                                    <label translate>lbl-category</label>
                                    <select required class="form-control
                                        form-control-lg"
                                        [(ngModel)]="selected_service.category_id">
                                        <option *ngFor="let cat of
                                            all_categories" [value]="cat.id">
                                            {{cat.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label translate="">lbl-name</label>
                                <input class="form-control form-control-lg"
                                    required
                                    [(ngModel)]="selected_service.name">
                            </div>
                            <div class="form-group">
                                <label translate="">lbl-external-url</label>
                                <input class="form-control form-control-lg"
                                    required
                                    [(ngModel)]="selected_service.description">
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="custom-control custom-checkbox
                                        custom-checkbox-switch">
                                        <input type="hidden"
                                            [(ngModel)]="selected_service.status">
                                        <input type="checkbox"
                                            class="custom-control-input"
                                            [checked]="selected_service.status
                                            === status[0].value"
                                            (change)="$event.target.checked?
                                            (selected_service.status=
                                            status[0].value) :
                                            (selected_service.status=
                                            status[1].value)"
                                            id="status">
                                        <label class="custom-control-label"
                                            for="status" translate>lbl-enable</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body" *ngIf="selected_service.calendars?.length == 0">
                        <div class="alert alert-danger" translate>
                            txt-no-calendar-assigned-to-this-service                            
                        </div>
                    </div>

                    <div class="card-body p-0" *ngIf="is_service_selected == true ||
                        is_add_new_service == true">

                        <div *ngIf="selected_service.service_type == 1 ||
                            selected_service.service_type == 2
                            || selected_service.service_type == 3">
                            <!-- service 1,2,3 info-->  
                            <div class=" bg-light border-top border-bottom p-2 mb-2" >
                                <div class="title-decorative text-primary " translate=""><span class="fa fa-info"></span> heading-service-information</div>
                            </div>
        
                            <div class="row form-group pl-2 pr-2">
                                <div class="col-1">
                                    <label translate>lbl-color</label>
                                    <input [cpPosition]="'bottom'"
                                        [(colorPicker)]="selected_service.color"
                                        [style.background]="selected_service.color"
                                        class="form-control form-control-lg"
                                        type="text" />
                                </div>

                                <div class="col-4" *ngIf="is_add_new_service ==
                                    true">
                                    <label translate>lbl-category</label>
                                    <select class="form-control form-control-lg"
                                        [(ngModel)]="selected_service.category_id">
                                        <option *ngFor="let cat of
                                            all_categories" [value]="cat.id">
                                            {{cat.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-7">
                                    <label translate>lbl-name</label>
                                    <input required class="form-control
                                        form-control-lg" type="text"
                                        [(ngModel)]="selected_service.name"
                                        placeholder="" />
                                </div>

                                <div class="col-12 mt-2">
                                    <label translate>lbl-description</label>
                                    <input class="form-control form-control-lg"
                                        type="text"
                                        [(ngModel)]="selected_service.description"
                                        placeholder="" />
                                </div>
                                <div class="col-12 mt-2"
                                    *ngIf="selected_service.service_type == 3">
                                        <label translate>lbl-detailed-description</label>
                                        <textarea rows='5' class="form-control
                                            form-control-lg" type="text"
                                            [(ngModel)]="selected_service.detailed_description"
                                            placeholder=""></textarea>
                                </div>                                
                            </div>

                            <div class="bg-light border-top border-bottom p-2 mb-2 mt-4" >
                                <div class="title-decorative text-primary"  translate=""><span class="fa fa-clock"></span> heading-service-timing</div>
                            </div>

                            <div class="row form-group pl-2 pr-2"
                                *ngIf="selected_service.service_type == 1 ||
                                selected_service.service_type == 2">
                                <div class="col-6">
                                    <label>
                                        <span translate
                                            *ngIf="selected_service.service_type
                                            == 2">lbl-minimum-duration</span>
                                        <span
                                            *ngIf="selected_service.service_type
                                            == 2" > (<span translate="">lbl-hrs</span>)</span>
                                        <span translate
                                            *ngIf="selected_service.service_type
                                            == 1">lbl-duration(mins)</span>
                                    </label>

                                    <input *ngIf="selected_service.service_type
                                        == 1" class="form-control
                                        form-control-lg"
                                        [(ngModel)]="selected_service.duration"
                                        mask="0{4}" placeholder="" />

                                    <input *ngIf="selected_service.service_type
                                        == 2" class="form-control
                                        form-control-lg"
                                        [(ngModel)]="selected_service.duration"
                                        step=".5" type="number" min="0"
                                        max="100" placeholder="" />
                                </div>
                                <div class="col-6">
                                    <label>
                                        <span translate>lbl-break-before-next-service</span>
                                        <span
                                            *ngIf="selected_service.service_type
                                            == 2 || selected_service.service_type
                                            == 1" > (<span translate="">lbl-mins</span>)</span>
                                    </label>
                                    <input class="form-control form-control-lg"
                                        mask=0{4}
                                        [(ngModel)]="selected_service.break_before"
                                        placeholder="" />
                                </div>
                                <div class="col-6 mt-2"
                                    *ngIf="selected_service.service_type == 2">
                                    <label>
                                        <span translate="">lbl-maximum-duration</span>
                                        <span > (<span translate="">lbl-hrs</span>)</span>
                                    </label>
                                    <input class="form-control form-control-lg"
                                        step=".5" type="number" min="0"
                                        max="100"
                                        [(ngModel)]="selected_service.max_duration"
                                        placeholder="" />
                                </div>
                                <div class="col-6" *ngIf="false">
                                    <label>
                                        <span translate>lbl-break-after</span>
                                        <span> (<span translate="">mins</span>)</span>
                                    </label>
                                    <input class="form-control form-control-lg"
                                        mask="0{4}"
                                        [(ngModel)]="selected_service.break_after"
                                        placeholder="" />
                                </div>
                            </div>

                            <div class="row form-group pl-2 pr-2"
                                *ngIf="selected_service.service_type == 3 ||
                                selected_service.service_type == 2">
                                <div class="col-6"
                                    *ngIf="selected_service.service_type == 3">
                                    <label translate>lbl-checkin</label>
                                    <input class="form-control form-control-lg"
                                        type="time"
                                        [(ngModel)]="selected_service.checkin"
                                        placeholder="" />
                                </div>
                                <div class="col-6"
                                    *ngIf="selected_service.service_type == 3">
                                    <label translate>lbl-checkout</label>
                                    <input class="form-control form-control-lg"
                                        type="time"
                                        [(ngModel)]="selected_service.checkout"
                                        placeholder="" />
                                </div>
                                <div class="col-6 mt-2"
                                    *ngIf="selected_service.service_type == 3">
                                    <label translate>lbl-max-days-allowed-to-book</label>
                                    <input class="form-control form-control-lg"
                                        mask="0{4}"
                                        [(ngModel)]="selected_service.max_duration"
                                        placeholder="" />
                                </div>
                            </div>

                            <div class="bg-light border-top border-bottom p-2 mb-2  mt-4" >
                                <div class="title-decorative text-primary" translate=""><span class="fa fa-check"></span> heading-service-availability</div>
                            </div>

                            <div class="row  form-group pl-2 pr-2">
                                <div class="col-6">
                                    <label>
                                        <span translate>lbl-can-be-booked-ahead-in</span>
                                        <span > (<span translate="">lbl-days</span>)</span>
                                    </label>
                                    <input class="form-control form-control-lg"
                                        mask="0{4}"
                                        [(ngModel)]="selected_service.how_far_ahead"
                                        placeholder="" />
                                </div>
                                <div class="col-6 ">
                                    <label>
                                        <span translate>lbl-min-time-before-cancel</span>
                                        <span > (<span translate="">lbl-hrs</span>)</span>
                                    </label>
                                    <input class="form-control form-control-lg"
                                        mask="0{4}"
                                        [(ngModel)]="selected_service.minimum_time_before_cancel"
                                        placeholder="" />
                                </div>

                                <div class="col-6 mt-2"
                                    *ngIf="selected_service.service_type == 1 ||
                                    selected_service.service_type == 2">
                                    <label translate>lbl-minimum-hours-required-before-booking</label>
                                    <input class="form-control form-control-lg
                                        " mask="0{4}"
                                        [(ngModel)]="selected_service.minimum_time_needed_before_booking"
                                        placeholder="" />
                                </div>
                                <div class="col-6 mt-2"
                                    *ngIf="selected_service.service_type == 3 ||
                                    selected_service.service_type == 2">
                                    <label>
                                        <span translate>lbl-max-people</span>
                                        <span translate="">lbl-if-applicable</span>
                                    </label>
                                    <input class="form-control form-control-lg"
                                        mask="0{4}%"
                                        [(ngModel)]="selected_service.max_number_of_people"
                                        placeholder="" />
                                </div>
                            </div>

                            <div class="bg-light border-top border-bottom p-2 mb-2 mt-4" >
                                <div class="title-decorative text-primary " translate=""><i class="fas fa-dollar-sign"></i> heading-service-cost</div>
                            </div>
        
                            <div class="row form-group pl-2 pr-2">
                                <div class="col-6">
                                    <label *ngIf="selected_service.service_type
                                        == 1" translate>lbl-price</label>
                                    <label *ngIf="selected_service.service_type
                                        == 2" translate>lbl-price-per-hour</label>
                                    <label *ngIf="selected_service.service_type
                                        == 3" translate>lbl-price-per-day</label>
                                    <input class="form-control form-control-lg"
                                        mask="0{4}"
                                        [(ngModel)]="selected_service.price"
                                        placeholder="" />
                                </div>

                                <div 
                                    *ngIf="(is_service_selected == true ||
                                    is_add_new_service == true) &&
                                    selected_service.service_type == 3" class="col-6 ">
                                    <label class=" " translate>lbl-cleaning-fee</label>
                                        <input class="form-control form-control-lg
                                            " mask="0{4}"
                                            [(ngModel)]="selected_service.cleaning_fee"
                                            placeholder="" />
        
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer bg-light" style="padding-top:18px"
                        *ngIf="(is_service_selected == true ||
                        is_add_new_service == true) &&
                        (selected_service.service_type == 1 ||
                        selected_service.service_type == 2
                        || selected_service.service_type == 3)">
                        <div class="row">
                            <div class="col-6">
                                <div class="custom-control custom-checkbox
                                    custom-checkbox-switch">
                                    <input type="hidden"
                                        [(ngModel)]="selected_service.status">
                                    <input type="checkbox"
                                        class="custom-control-input"
                                        [checked]="selected_service.status ===
                                        status[0].value"
                                        (change)="$event.target.checked?
                                        (selected_service.status=
                                        status[0].value) :
                                        (selected_service.status=
                                        status[1].value)"
                                        id="status">
                                    <label class="custom-control-label"
                                        for="status" translate>lbl-enable</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="custom-control custom-checkbox
                                    custom-checkbox-switch">
                                    <input type="hidden"
                                        [(ngModel)]="selected_service.is_require_online_payment">
                                    <input type="checkbox"
                                        class="custom-control-input"
                                        [checked]="selected_service.is_require_online_payment
                                        === boolean_status[0].value"
                                        (change)="$event.target.checked?
                                        (selected_service.is_require_online_payment=
                                        boolean_status[0].value) :
                                        (selected_service.is_require_online_payment=
                                        boolean_status[1].value)"
                                        id="is_require_online_payment">
                                    <label class="custom-control-label"
                                        for="is_require_online_payment"
                                        translate>lbl-require-online-payment</label>
                                </div>
                            </div>
                            <div class="col-6"
                                *ngIf="selected_service.service_type == '3'">
                                <div class="custom-control custom-checkbox
                                    custom-checkbox-switch">
                                    <input type="hidden"
                                        [(ngModel)]="selected_service.gap_between_next_booking">
                                    <input type="checkbox"
                                        class="custom-control-input"
                                        [checked]="selected_service.gap_between_next_booking
                                        === boolean_status[0].value"
                                        (change)="$event.target.checked?
                                        (selected_service.gap_between_next_booking=
                                        boolean_status[0].value) :
                                        (selected_service.gap_between_next_booking=
                                        boolean_status[1].value)"
                                        id="gap_between_next_booking">
                                    <label class="custom-control-label"
                                        for="gap_between_next_booking"
                                        translate>lbl-gap-between-next-booking</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="custom-control custom-checkbox
                                    custom-checkbox-switch">
                                    <input type="hidden"
                                        [(ngModel)]="selected_service.is_send_notification_for_new_booking">
                                    <input type="checkbox"
                                        class="custom-control-input"
                                        [checked]="selected_service.is_send_notification_for_new_booking
                                        === boolean_status[0].value"
                                        (change)="$event.target.checked?
                                        (selected_service.is_send_notification_for_new_booking=
                                        boolean_status[0].value) :
                                        (selected_service.is_send_notification_for_new_booking=
                                        boolean_status[1].value)"
                                        id="is_send_notification_for_new_booking">
                                    <label class="custom-control-label"
                                        for="is_send_notification_for_new_booking"
                                        translate>lbl-send-confirmation-when-this-service-booked</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer"*ngIf="(is_service_selected == true
                        || is_add_new_service == true) &&
                        (selected_service.service_type == 1 ||
                        selected_service.service_type == 2
                        || selected_service.service_type == 3)">
                        <div class="row">
                            <div class="col media flex-wrap mb-2
                                align-items-center">
                                <img *ngIf="selected_service.image != '' &&
                                    selected_service.image != 'null'"alt=""
                                    style="background-color:#eee"
                                    [src]="selected_service.image" width="160"
                                    class="rounded img-fluid">

                                <div class="media-body alert">
                                    <label class="custom-file mb-2" for="file2">
                                        <input type="file"
                                            (change)="selectImage($event)"
                                            id="service_image" class="btn btn-
                                            btn-sm">
                                        &nbsp;
                                        <button class="btn btn-outline-danger
                                            btn-sm"
                                            (click)="onClick_DeleteImage()"
                                            *ngIf="is_image_exists == true"
                                            translate>btn-delete</button>
                                    </label>
                                    <div class="text text-muted">
                                        <small translate="">txt-for-best-result-use-x-x-size
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer"*ngIf="(selected_service.service_type
                        == 2 || selected_service.service_type == 3 )
                        && is_add_new_service == true">
                        <label translate>lbl-how-many-quantity-you-have</label>&nbsp;
                        <small class="text text-muted" translate="">txt-some-information-or-example-for-rental-qty</small>
                        <input class="form-control form-control-lg" mask="0{3}"
                            (input)="onChange_ServiceQuantity($event.target.value)"
                            [(ngModel)]="selected_service.qty" placeholder="" />
                    </div>

                    <div class="card-footer bg-light"
                        *ngIf="selected_service.service_type == 1 ||
                        selected_service.service_type == 2
                        || selected_service.service_type == 3 ||
                        selected_service.service_type == 4">

                        <button *ngIf="is_loading == true" class="btn btn-lg
                            text text-muted btn-light" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif">
                            txt-loading
                        </button>

                        <button class="btn btn-lg btn-primary"
                            (click)="onClick_Save()" *ngIf="is_loading == false
                            && (is_service_selected == true ||
                            is_add_new_service == true)"
                            translate>
                            btn-save</button>

                        <button class="btn btn-lg btn-outline-danger
                            float-right" (click)="onClick_TryDelete()"
                            *ngIf="is_loading == false && is_service_selected ==
                            true"
                            translate>btn-delete</button>

                    </div>

                </div>
                <div class="card card-sm" *ngIf="is_service_selected == true">
                    <div class="card-header bg-secondary d-flex
                        justify-content-between align-items-center">
                        <span *ngIf="is_loading == true" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif"
                                translate>
                            txt-loading
                        </span>
                        <span *ngIf="is_loading == false">{{title_category}}
                        </span>
                    </div>
                    <div class="">
                        <ul class="list-group">
                            <li class="list-group-item" id="cat_li_{{cat.id}}"
                                *ngFor="let cat of all_categories;let i= index"><span
                                    id="cat_span_{{cat.id}}">{{cat.name}}

                                    <span class="float-right custom-control
                                        custom-checkbox custom-checkbox-switch">
                                        <input type="checkbox"
                                            class="custom-control-input"
                                            (change)="onClick_SelectCategory($event,cat)"
                                            [checked]="cat.title=='btn-assign'"
                                            id="cat{{cat.id}}">
                                        <label class="custom-control-label"
                                            for="cat{{cat.id}}"></label>
                                    </span>

                                </span></li>
                        </ul>
                    </div>
                </div>
            </div>
        


        </div>
    </div>
</div>

<ngx-smart-modal identifier="ser_id">
    <h6 class="h4 mb-2 text text-danger" translate>heading-are-you-sure</h6>
    <hr class="row row mt-2 mb-2">
    <div class="h6 alert"translate="">
        txt-warning-this-may-delete-or-disabled-some-data-related-to-this-item
    </div>
    <div class="fixed-bottom p-2 border-top">
        <button (click)="onClick_ModalClose();" class="btn btn-lg btn-secondary
            float-left" translate="">btn-cancel</button>
        <button (click)="onClick_Delete();" class="btn btn-lg btn-danger
            float-right" translate="">btn-submit</button>
        <!--   <button (click)="onClick_Delete();deleteModal.close()"  class="btn btn-md btn-danger float-right" >Confirm !</button>
  -->
    </div>
</ngx-smart-modal>