import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import { ParamsHelper } from '../_helper/params';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    params: any;

    /**
     * 
     */
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) {
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    }

    /**
     * 
     */
    _getBody() {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    getAll(_p = null) {
        let body = this.params.make(this._getBody(), _p);

        return this.http.post(environment.server + "/provider/customer/poll/all", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param id 
     */
    get(id) {
        return this._getById(id);
    }

    /**
     * 
     */
    getById(id) {
        return this._getById(id);
    }

    /**
     * 
     */
    _getById(id) {
        let _p = {
            "id":id
        }
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/customer/poll/by-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllCards() {
        let body = this.params.make(this._getBody());
        return this.http.post(environment.server + "/customer/account/poll/get-all-cards", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     * @param username 
     * @param password 
     */
    create(_p) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/customer/do/save", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param _p 
     */
    save(_p) {

        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/customer/account/do/save", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }
    
    /**
     * 
     * @param _p 
     */
    sendMsg(_p) {

        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/customer/message/do/save", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

}
