import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthHelper } from '../../_helper/auth';
import { UrlHelper } from '../../_helper/url';
import { BookingService } from '../../_service/booking.service';
import { BusinessService } from '../../_service/business.service';
import { AlertService } from '../../_service/alert.service';
import { ConfigService } from 'src/app/_service/config.service';
import { Location } from '@angular/common';
import { DateHelper } from 'src/app/_helper/date';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})

export class NewComponent implements OnInit {

  config: any;

  step_service = false;
  step_datetime = false;
  step_customer = false;
  step_confirm = false;
  step_customer_login = false;
  step_pay = false;
  step_finish = false;
  step_payment_finish = false;
  step_error = false;

  constructor(
    private router: Router,
    private url: UrlHelper,
    private location: Location,
    public date: DateHelper,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private businessService: BusinessService,
    private bookingService: BookingService,
    private alertService: AlertService,
    public auth: AuthHelper
  ) {

    if (this.auth.isLoggedIn()) {
      if (this.auth.getUser().user_type != 'customer' && this.auth.getUser().is_allowed_new_booking != '1') {
        this.router.navigate(['/dashboard']);
      }
    }
    
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

  }
  ngAfterContentInit() {
  }

  _resetFull() {
    this.config = {
      "is_booking_initiated_by_customer": true,
      "url_id": "0",
      "confirmed_bookings": [],
      "is_loading": false,
      "summary": { "service": "", "price": 0, "starts": "", "start_ts": 0, "ends": "", "end_ts": 0, "customer": "", "duration": 0 },
      "type": 0,
      "step": "service",
      "service": [],
      "calendar_id": 0,
      "customer": { "is_new": 0, "username": "", "id": 0, "first_name": "", "last_name": "", "mobile": "" },
      "payment": { "required": false, "success": false, "token": "", "vendor": "" },
      "notes": "",
      "business_id": 0,
      "business": {}
    };

    this._resetStep();
  }

  _resetStep() {
    this.step_service = false;
    this.step_datetime = false;
    this.step_customer = false;
    this.step_confirm = false;
    this.step_finish = false;
    this.step_error = false;
    this.step_payment_finish = false;
    this.step_pay = false;
    this.step_customer_login = false;
  }

  ngOnInit() {
    this._resetFull();
    this._setIfCutomerLogin();
    // get business from subdomain here then do below
    this.config.is_loading = true;
    this.businessService.getBySubdomain(this.url.getSubdomain()).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        this.config.business_id = temp.data.id;
        this.config.business = temp.data;
        this.config.is_loading = false;
        this.step_service = true;

        // check if internal booking is enabled
        // if yes and not logged in, redirect to home page
        if (Number(this.config.business.enable_internal_booking) == 1) {
          if (this.auth.isLoggedIn() != true) {
            this.router.navigateByUrl("/");
          }
        }
      this.route.queryParams.subscribe(params => {
          if (params['id'] != "" && params['id'] != undefined && Number(params['id']) > 0) {
            // below is sending url id but service comppnent not doing anything yet
            this.location.replaceState(this.location.path().split('?')[0], '');
            this.config.url_id = params['id'];
          }
        });

      }
      else {
        this.alertService.error(this.translate.instant("alert-invalid-business-account"));
      }
    });
  }

  /**
   * 
   */
  _setStep() {
    this._resetStep();
    if (this.config.step == 'start-again') {
      this.ngOnInit();
      this.step_service = true;
    }
    if (this.config.step == 'service') {
      this.step_service = true;
    }
    if (this.config.step == 'datetime') {
      if (this.config.type != '1') {
        this.config.summary.price = 0;
        this.config.summary.starts = "";
        this.config.summary.start_ts = 0;
        this.config.summary.ends = "";
        this.config.summary.end_ts = 0;
        this.config.summary.customer = "";
        this.config.summary.duration = 0;
      }
      this.step_datetime = true;
    }

    if (this.config.step == 'customer') {
      if (this.auth.isLoggedIn()) {
        if (this.auth.getUser().user_type != 'customer') {
          this.step_customer = true;
        } else {
          if (this.config.payment.required) {
            this.step_pay = true;
          }
          else {
            this.step_confirm = true;
          }
        }
      }
      else {
        this.step_customer_login = true;
      }
    }
    if (this.config.step == 'confirm') {
      this.step_confirm = true;
    }
    if (this.config.step == 'pay') {
      if (this.config.payment.required) {
        this.step_pay = true;
      }
      else {
        this.step_confirm = true;
      }
    }
    if (this.config.step == 'error') {
      this.step_error = true;
    }

    this._setIfCutomerLogin();

    if (this.config.step == 'finish') {
      this.step_finish = true;
      this._finish();
    }
    if (this.config.step == 'payment-finish') {
      this.step_finish = true;
      this._finish();
    }
  }

  /**
   * 
   */
  _setIfCutomerLogin() {
    if (this.auth.isLoggedIn()) {
      if (this.auth.getUser().user_type == 'customer') {
        this.config.is_booking_initiated_by_customer = true;
        this.config.summary.customer = this.auth.getUser().username;
        this.config.customer.username = this.auth.getUser().username;
        this.config.customer.id = this.auth.getUser().id;
        this.config.customer.first_nname = this.auth.getUser().first_name;
        this.config.customer.last_name = this.auth.getUser().last_name;
        this.config.customer.mobile = this.auth.getUser().mobile;
      }
      else {
        this.config.is_booking_initiated_by_customer = false;

      }
    }
  }


  /**
   * 
   */
  _finish() {
    this.config.is_loading = true;
    let _p = { "params": JSON.stringify(this.config) };
    this.bookingService.create(_p).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        this.config.is_loading = false;
        this.config.confirmed_bookings = temp.data;
      }
      else {
        this.config.step = 'error';
        this._setStep();
        this.alertService.error(temp.msg);
      }
    }, error => {
      this.config.step = "error";
      this._setStep();
      this.alertService.error(this.translate.instant("alert-error"));
    });
  }

  onEmit_StartOver(e) {
    this.config = e;
    this._setStep();

  }

  onEmit_ServiceConfig(e) {
    this.config = e;
    this._setStep();
  }

  onEmit_DatetimeConfig(e) {
    this.config = e;
    this._setStep();
  }

  onEmit_CustomerConfig(e) {
    this.config = e;
    this._setStep();
  }

  onEmit_ConfirmConfig(e) {
    //console.log(e);
    this.config = e;
    this._setStep();
  }

  onEmit_PayConfig(e) {
    this.config = e;
    this._setStep();

  }
  _runtime(e) {
    this.config = e;

  }

}
