<nav aria-label="breadcrumb" role="navigation" class="bg-primary text-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);" translate>txt-customers</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page" translate>txt-listing-all-your-customers</li>
                </ol>
            </div>
            <!--end of col-->
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</nav>

<section class="space-xs">
    <div class="container">
        <div class="row flex-md-row card card-lg border shadow-sm">
            <div class="col-12 col-md-7 card-body bg-light">
                <div class=" mb-2">
                    <h5 class="h4 mb-2" translate>lbl-create-customer</h5>
                    <span class="title-decorative" translate>txt-a-random-password-will-be-send-on-customer-email</span>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-12">
                        <div class="form-row form-group">
                            <div class="col">
                                <input class="form-control form-control-lg shadow-sm" type="text" [(ngModel)]="first_name"
                                    placeholder="{{ 'lbl-first-name' |  translate }}" />
                            </div>
                            <div class="col">
                                <input class="form-control form-control-lg shadow-sm" type="text" [(ngModel)]="last_name"
                                    placeholder="{{ 'lbl-last-name' |  translate }}" />
                            </div>
                        </div>
                        <div class="form-row form-group">
                            <div class="col">
                                <input class="form-control form-control-lg shadow-sm" type="text" [(ngModel)]="username"
                                    placeholder="{{ 'lbl-email' | translate }}" />
                            </div>
                            <div class="col">
                                <input class="form-control form-control-lg shadow-sm" type="text" [(ngModel)]="mobile"
                                    placeholder="{{ 'lbl-mobile' | translate }}" />
                            </div>
                        </div>
                        <div class="form-row form-group">
                            <div class="col">
                                <input class="form-control form-control-lg shadow-sm" type="text" [(ngModel)]="street"
                                    placeholder="{{ 'lbl-street-address' | translate }}" />
                            </div>
                            <div class="col">
                                <input class="form-control form-control-lg shadow-sm" type="text" [(ngModel)]="city"
                                    placeholder="{{ 'lbl-city' | translate }}" />
                            </div>
                        </div>
                        <div class="form-row form-group">
                            <div class="col">
                                <input class="form-control form-control-lg shadow-sm" type="text" [(ngModel)]="state"
                                    placeholder="{{ 'lbl-state' | translate }}" />
                            </div>
                            <div class="col">
                                <input class="form-control form-control-lg shadow-sm" type="text" [(ngModel)]="zipcode"
                                    placeholder="{{ 'lbl-zipcode' | translate }}" />
                            </div>
                        </div>
                        <div class="form-row form-group mt-4 border-top pt-4   ">
                            <div class="col">
                                <span *ngIf="is_loading == true"  
                                    class="btn btn-light btn-lg btn-muted" translate><img src="assets/img/ajax-loader-light-gray.gif" 
                                    translate > txt-loading</span>                                  
                                <button *ngIf="is_loading == false" class="btn btn-success btn-lg" (click)="onClick_Save()" type="submit" translate>btn-save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end of col-->
            <div class="col-12 col-md-5 card-body">
                <div>
                    <div class="mb-2 ">
                        <h5 class="h5 mb-2" translate>heading-about-bookit-customer</h5>
                        
                    </div>

                    <div class="form-row form-group">
                        <div class="col text-muted" translate>
                            txt-customer-help-information
                       </div>
                    </div>
                </div>
            </div>
            <!--end of col-->
        </div>
    </div>
</section>