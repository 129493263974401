<!doctype html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500" rel="stylesheet">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
        crossorigin="anonymous">

</head>
<body>
    <div class="main-container">
        <div class="row" *ngIf="is_loading == true">
            <div class="col-12 ">
                <div class="text text-center">
                    <div class="height-80 flush-with-above title-decorative" translate><br><br>txt-loading</div>
                </div>
            </div>
        </div>
        <div *ngIf="business.enable_internal_booking == '1' && is_loading == false">
            <section class="height-80 flush-with-above">
                <div class="container">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-12 col-md-6 mb-4">
                            <img alt="Image" src="assets/img/graphic-imac-interface.svg" class="w-100">
                        </div>
                        <div class="col-12 col-md-6 col-lg-5 mb-4 text-center text-md-left">
                            <h1 class="display-4" translate>txt-page-does-not-exists</h1>
                            <hr>
                            <h2 class="lead" translate>txt-we-will-look-into-it</h2>
                            <a href="/#/signin" translate>btn-go-to-signin-page</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="space-sm" *ngIf="business.enable_internal_booking == '0' && is_loading == false">
            <div class="container align-self-start">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 col-lg-8" *ngIf="is_customer_signup == true">
                        <app-customer-signup></app-customer-signup>
                    </div>
                    <div class="col-12 col-md-10 col-lg-10" *ngIf="is_provider_signup == true">
                        <app-provider-signup (backToPlan)="onClick_BackToPlan()" [plan]="plan_choosen"></app-provider-signup>
                    </div>
                    <div class="col-12 col-md-12 col-lg-12" *ngIf="is_provider_signup_plan == true">
                        <app-provider-signup-plan (submit)="onSubmit_Plan($event)"></app-provider-signup-plan>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <footer class="footer-short">
        <div class="container">
            <hr>
            <nav class="row justify-content-between align-items-center">
                <small>
                    <div class="col-auto">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a  class="text-muted" href="javascript:void(0);" [routerLink]="['/privacy']" translate  >btn-privacy-policy</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted"  href="javascript:void(0);" [routerLink]="['/tnc']" translate >btn-terms-n-conditions</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" href="https://bookit24.zendesk.com/hc/no/requests/new" target="_blank"  translate >btn-contact-us</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-primary" href="https://www.bookit24.no" target="_blank"  translate>https://www.bookit24.no</a>
                            </li>

                        </ul>
                    </div>
                </small>
            </nav>
            <!--end of row-->
            <div class="row">
                <div class="col">
                    <small>&copy; 2021 Bookit24. All Rights Reserved.</small>
                </div>
                <!--end of col-->
            </div>
            <!--end of row-->
        </div>
        <!--end of container-->
    </footer>



</body>

</html>