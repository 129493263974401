
import { NgModule, Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import * as moment from "moment-timezone";

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPLugin from '@fullcalendar/list';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import enLocale from '@fullcalendar/core/locales/en-gb';
import nbLocale from '@fullcalendar/core/locales/nb';

import { BusinessService } from './../../_service/business.service';
import { CategoryService } from './../../_service/category.service';
import { MessageService } from './../../_service/message.service';
import { CalendarService } from './../../_service/calendar.service';
import { ServiceService } from './../../_service/service.service';
import { BookingService } from './../../_service/booking.service';
import { CustomerService } from './../../_service/customer.service';
import { AlertService } from './../../_service/alert.service';
import { AuthHelper } from '../../_helper/auth';
import { LocaleHelper } from '../../_helper/locale';
import { UrlHelper } from '../../_helper/url';
import { Observable } from 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';
import { rendererTypeName } from '@angular/compiler';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

  resourceCalendarOptions: any;
  calendarOptions: any;
  eventsModel: any;
  @ViewChild('fullcalendar', { static: false }) fullcalendar: FullCalendarComponent;
  //@ViewChild('resourcefullcalendar', { static: false }) resourcefullcalendar: FullCalendarComponent;
  @ViewChild('external', { static: false }) external: ElementRef;

  events: any;
  event_list: any;
  on_tab = "resource";
  //  @ViewChild(CalendarComponent, { static: false }) ucCalendar: CalendarComponent;

  is_show_advanced_search = false;
  is_download_as_csv = false;
  all_calendars: any;
  calendar_array = [];
  stats_title = "Monthly Stats";
  open_calendar = { "id": 0, "name": "" };
  current_calendar: number;
  current_calendar_title = "";
  calendar_title = "";
  title = "Dashboard";
  is_agenda_month = true;
  subdomain = "";
  is_loading = false;
  loading_title = "please wait ...";
  is_slot_available = false;
  selected_booking_for_reschedule: any;
  reschedule_selected_date_string = "";
  slots: any;
  reschedule_selected_date: any;
  from_date = "";
  to_date = "";
  boolean_status = [{ "value": "1" }, { "value": "0" }];
  filters = { "confirmed": "1", "finished": "1", "cancelled": "0", "unpaid": "0", "breaks": "0" };
  is_on_email_logs = false;
  is_on_sms_logs = false;
  sms_history_list = [];
  email_history_list = [];
  smses = [];
  emails = [];
  current_year = moment().format("YYYY");
  show_deleted = "0";
  is_show_trial_ends_warning = false;
  trial_ends = 0;

  mark_customer_tab_active = "";
  mark_customer_tab_show = "";

  stats_type = [
    { "name": "bt", "label": "Booking Total", "value": "NA" },
    { "name": "bc", "label": "Booking Cancelled", "value": "NA" },
    { "name": "bns", "label": "Booking No Show", "value": "NA" },
    { "name": "sbh", "label": "Service Booked Hourly", "value": "NA" },
    { "name": "sbr", "label": "Service Booked Rental", "value": "NA" },
    { "name": "sb24r", "label": "Service Booked Accomm.", "value": "NA" },
    { "name": "srt", "label": "Service Revenue Total", "value": "NA" },
    { "name": "srh", "label": "Service Revenue Hourly", "value": "NA" },
    { "name": "srr", "label": "Servive Revenue Rental", "value": "NA" },
    { "name": "sr24r", "label": "Servive Revenue Accomm.", "value": "NA" },
    { "name": "ct", "label": "Customer Total", "value": "NA" },
    { "name": "nc", "label": "New Customers", "value": "NA" }
  ];

  sms_table_settings = {
    noDataMessage: this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions: false,
    columns: {
      dated: {
        title: this.translate.instant('lbl-dated'),
        width: "20%",
      },
      receiver_email: {
        title: this.translate.instant('lbl-receiver'),
        width: "20%",
      },
      message: {
        title: this.translate.instant('lbl-message'),
        width: "40%",
      },
    }
  };
  email_table_settings = {
    noDataMessage: this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions: false,
    columns: {
      dated: {
        title: this.translate.instant('lbl-dated'),
        width: "15%",
      },
      receiver_email: {
        title: this.translate.instant('lbl-receiver'),
        width: "15%",
      },
      message: {
        title: this.translate.instant('lbl-message'),
        type: 'html',
        width: "70%",
      },
    }
  };
  settings = {
    noDataMessage: this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions: false,
    columns: {
      email: {
        title: this.translate.instant('lbl-customer')
      },
      service: {
        title: this.translate.instant('lbl-service')
      },
      price: {
        title: this.translate.instant('lbl-price')
      },
      start: {
        title: this.translate.instant('lbl-starts')
      },
      end: {
        title: this.translate.instant('lbl-ends')
      },
      balance: {
        title: this.translate.instant('lbl-balance'),
      },
      status: {
        title: this.translate.instant('lbl-status'),
        type: "html",
        valuePrepareFunction: (cell, row) => {
          let ret = cell;
          if (row.refund_time > 0) {
            ret = cell + " (Refunded)";
          }
          return ret;
        }
      },
    },
    rowClassFunction: (row) => {
      //console.log(row.data);
    }
  };


  /**
   * 
   */
  constructor(
    public calendarService: CalendarService,
    public bookingService: BookingService,
    public businessService: BusinessService,
    public serviceService: ServiceService,
    public alertService: AlertService,
    public messageService: MessageService,
    public router: Router,
    public translate: TranslateService,
    public url: UrlHelper,
    public locale: LocaleHelper,
    public auth: AuthHelper
  ) {
    this.subdomain = url.getSubdomain();
    this.current_calendar = 0;

    if (auth.getUser().user_type == "customer") {
      this.title = "Your Bookings";
      this.mark_customer_tab_active = "active";
      this.mark_customer_tab_show = "show";
    }
    this.is_on_sms_logs = true;

  }

  /**
   * 
   */
  ngOnInit() {

    this._setCalOptions();

    if (this.auth.getUser().user_type == 'customer') {
      this.loadEvents();
      this.title = "Your Bookings";
      this.mark_customer_tab_active = "active";
      this.mark_customer_tab_show = "show";
    }
    else {
      this.getBusiness();
      this.getAllCalendar();
      this.getResourceEvents();
      //this.getAllOpenCalServices();
    }
    this._getAllSMS();
    this._getAllEmail();

    // to be used on separate stats page.
    //this._loadStats(moment());
    // auto refresh in few seconds
    //this._setupAutoload();
  }

  /**
   * 
   */
  _setupAutoload() {
    let timer = Observable.timer(60000, 30000);
    timer.subscribe(tick => {
      this.loadEvents();
    });
  }

  onChange_Filter($event) {
    $event.target.checked ?
      (this.filters.finished =
        this.boolean_status[0].value) :
      (this.filters.finished =
        this.boolean_status[1].value);
    console.log(this.filters);

  }

  /**
   * 
   */
  _setCalOptions() {
    // Don't use FullcalendarOption interface
    let locale = this.locale.getLocale();
    let lc = nbLocale;
    if (locale == 'nb') {
      lc = nbLocale;
    }
    else {
      lc = enLocale;
    }

    this.calendarOptions = {
      editable: true,
      timeZone: 'UTC',
      locales: [nbLocale, enLocale],
      locale: lc,
      defaultView: "listWeek",
      theme: 'standard', // default view, may be bootstrap
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      // add other plugins
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPLugin],
    };

    // Don't use FullcalendarOption interface
    this.resourceCalendarOptions = {
      editable: true,
      timeZone: 'UTC',
      locales: [nbLocale, enLocale],
      locale: lc,
      defaultView: "resourceTimeGrid",
      theme: 'standard', // default view, may be bootstrap
      header: {
        left: 'prev,next today',
        center: 'title',
        right: ''
      },
      resources: [],
      // add other plugins
      plugins: [resourceTimeGridPlugin],
    };

    if (this.auth.getUser().user_type == 'customer') {
      this.calendarOptions.defaultView = 'dayGridMonth';
    }

  }

  /**
   * 
   */
  getBusiness() {
    this.businessService.getWhosLoggedIn().subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success == true) {
        let ex = Number(temp.data.dated) + (Number(temp.data.trail_days) * 24 * 60 * 60);
        this.trial_ends = Math.ceil((ex - moment().unix()) / (24 * 60 * 60));
        if (this.trial_ends > 0) {
          this.is_show_trial_ends_warning = true;
        }
      }
    })
  }

  /**
   * 
   */
  getAllOpenCalServices() {
    this.calendarService.getOpen().subscribe(data => {
      let temp: any;
      temp = data;
      this.open_calendar = temp.data;
    });
  }

  /**
   * 
   */
  onClick_Tab(type) {

    this.on_tab = type;
    this.loadEvents();
  }

  /**
   * 
   */
  getAllCalendar() {
    let _p = {
      "include_deleted": this.show_deleted
    }
    this.calendarService.getAll(null, _p).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.data != null && temp.data.length > 0) {
        this.current_calendar = temp.data[0].id;
        this.all_calendars = temp.data;
        this._selectActiveCal();
        this.loadEvents();
      }
    });
  }

  /**
   * 
   */
  getResourceEvents() {
    let _t = this;
    let _p = {
      "filters": JSON.stringify(this.filters)
    }

    this.calendarService.getAllResources(null,_p).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        // assign resource data now.
        this.resourceCalendarOptions.resources = temp.data.resources;
        let e = _t._getFormatedEvents(temp.data, false);
        this.resourceCalendarOptions.events = e;
      }
      this.is_loading = false;
    });
  }

  /**
   * 
   */
  _selectActiveCal() {
    let temp = this.all_calendars;
    temp.forEach(t => {
      if (t.id == this.current_calendar) {
        this.current_calendar_title = " / " + t.name;
        t.class = "text-danger";
      }
      else {
        t.class = "text-muted";
      }
    });
    this.all_calendars = temp;

  }

  /**
   * 
   */
  clearEvents() {
    this.events = [];
  }

  /**
   * 
   */
  changeCalendar(id) {
    this.clearEvents();
    this.current_calendar = id;
    this.loadEvents();
    this._selectActiveCal();

  }

  /**
   * 
   */
  changeOpenCalendar() {
    this.clearEvents();
    this.current_calendar = this.open_calendar.id;
    this.loadEvents();
    this._selectActiveCal();
  }

  /**
   * 
   * @param e 
   */
  updateEvent(event) {
    // console.log(event.event.extendedProps);
    let e = event.event;
    if (e.extendedProps.service_type == '1') {
      this.selected_booking_for_reschedule = e;
      let ts = moment(e.start).utcOffset(0, true);
      this.reschedule_selected_date = ts.startOf('day').unix();

      this.reschedule_selected_date_string = moment.unix(ts.unix()).utc().format("LL");
      this._reschedule(e.id);
    }
    else {
      this.loadEvents();
      this.alertService.error("Rescheduled not allowed.");
      setTimeout(() => {
        this.alertService.error("Rental Services or Accommodation Services must be deleted and created again.");
      },
        1500);
    }
  }

  /**
   * 
   * @param booking_id 
   */
  _reschedule(booking_id) {
    this.is_loading = true;
    let _p = {
      "booking_id": booking_id,
      "date": this.reschedule_selected_date,
    }
    this.serviceService.getAvailableSlotsForReschedule(_p).subscribe(data => {
      this.is_loading = false;
      let temp: any;
      temp = data;
      if (temp.success) {
        this._findSlots(temp.data);
      }
      else {
        this.alertService.error(temp.msg);
        this.loadEvents();
      }
    });

  }

  /**
   * 
   */
  onClick_RescheduleSlot(cal_id, slot_minute, slot_string) {
    this.is_loading = true;
    let _p = {
      "calendar_id": cal_id,
      "selected_slot": slot_string.substring(0, 5),
      "date": this.reschedule_selected_date,
      "booking_id": this.selected_booking_for_reschedule.id
    };
    this.bookingService.reschedule(_p).subscribe(data => {
      this.is_loading = false;
      let temp: any;
      temp = data;
      if (temp.success) {
        this.alertService.success(temp.msg);
      }
      else {
        this.alertService.error(temp.msg);
      }
      this.is_slot_available = false;
      this.loadEvents();
    });
  }

  /**
 * 
 * @param data 
 */
  _findSlots(data) {

    let breaks = [];
    let br = 0;
    let is_slot = false;
    let stack: any;
    let temp: any;
    temp = [];

    if (Object.keys(data).length > 0) {
      $.each(data, function (k, cal) {
        if (cal != null) {
          if (cal.hasOwnProperty('id')) {

            $.each(cal.breaks, function (bk, bv) {
              for (br = bv.start_timestamp_in_minute; br < bv.end_timestamp_in_minute;) {
                breaks.push(Number(br));
                br = Number(br) + Number(1);
              }
            });

            let i = 0;
            stack = [];
            stack.name = "";
            stack.slots = [];
            ///*
            let m: any;
            for (i = cal.start_timestamp_in_minute; i < cal.end_timestamp_in_minute;) {
              if ($.inArray(Number(i), breaks) == -1) {
                stack.name = cal.name;
                m = moment.unix(Number(i * 60)).utc().format('LT');
                stack.slots.push({ "cal_name": cal.name, "cal_id": cal.id, "slot_minute": i, "slot_string": m });
              }
              i = Number(i) + Number(cal.slot);
            }
            temp.push(stack);
          }
        }

      });
    }

    this.slots = temp;
    if (this.slots.length < 1) {
      this.is_slot_available = false;
    }
    else {
      this.is_slot_available = true;
    }

  }

  /**
   * 
   */
  onClick_RescheduleCancel() {
    this.is_slot_available = false;
    this.loadEvents();

  }


  /** 
   * 
   * @param e 
   */
  eventClick(e) {
    this.router.navigateByUrl("booking.detail?id=" + e.event.id);

  }

  /**
   * 
   * @param e 
   */
  clickButton(e) {
    if (
      e.buttonType == "agendaDay" ||
      e.buttonType == "agendaWeek" ||
      e.buttonType == "ListMonth" ||
      e.buttonType == "today"
    ) {
      this.is_agenda_month = false;
    }

    if (e.buttonType == "month") {
      this.is_agenda_month = true;
    }

    //console.log(e);
    if (this.is_agenda_month) {
      // load event when user click perv/next  button on cal.
      // this.loadEvents(e.data);
      // this._loadStats(e.data);
    }

  }

  /**
   * 
   */
  _loadStats(e) {
    this.stats_title = "loading ...";
    this.is_loading = true;
    let ts_start = e.utc().startOf("month").utc().unix();
    let ts_end = e.utc().endOf('month').utc().unix();

    this.stats_type.forEach((k, i) => {
      let _p = {
        "type": k.name,
        "date_start": ts_start,
        "date_end": ts_end
      };
      this.bookingService.getStats(_p).subscribe(data => {
        this.is_loading = false;
        let temp: any;
        temp = data;
        this.stats_type[i].value = temp.data;
        this.stats_title = e.format("MMMM YYYY");
      });
    });

  }


  /**
   * 
   */
  loadEvents(e = null) {
    this.is_loading = true;
    this.calendar_title = "loading ...";
    let from_date = 0;
    let to_date = 0;

    // load event only for current year selected
    if (e != null) {
      from_date = e.utc().startOf("year").utc().unix();
      to_date = e.utc().endOf('year').utc().unix();
    }
    else {
      // else load from the dates selected
      if (this.from_date != '' && this.from_date != null) {
        from_date = moment(this.from_date).utcOffset(0, true).unix();
      }
      if (this.to_date != null && this.to_date != "") {
        to_date = moment(this.to_date).utcOffset(0, true).unix();
      }
    }
    let _p: any;
    if (this.on_tab == 'history') {
      _p = {
        "from_date": from_date,
        "to_date": to_date,
        "csv": this.is_download_as_csv,
        "filters": JSON.stringify(this.filters)
      }
    }
    else {
      _p = {
        "filters": JSON.stringify(this.filters)
      }
    }

    if (this.auth.getUser().user_type == 'customer') {
      this.bookingService.getAllForCustomer({ "subdomain": this.url.getSubdomain() }).subscribe(data => {
        this._formatForCal(data);
        this.is_loading = false;
        this.calendar_title = this.events.length + " ";
      });
    }
    else {
      if (this.on_tab == 'resource') {
        this.getResourceEvents();

      }
      else {
        this.bookingService.getAllByCalendarId(this.current_calendar, _p).subscribe(data => {
          if (this.is_download_as_csv) {
            this.is_download_as_csv = false;
            let temp: any;
            temp = data;
            window.location.href = temp.data;
          } else {
            this._formatForCal(data);
          }
          this.is_loading = false;
          this.calendar_title = this.events.length + " ";
        });

      }
    }
  }

  /**
   * 
   * @param data 
   */
  _formatForCal(data) {
    this.events = this.calendarOptions.events = this._getFormatedEvents(data);
  }

  /**
   * 
   * @param data 
   */
  _getFormatedEvents(data, update_event_list = true) {
    let events = [];
    if (update_event_list) {
      this.event_list = [];
    }
    let start1 = ""
    let end1 = "";
    let start2 = ""
    let end2 = "";
    let title = "";
    if (data.data != null) {
      data.data.forEach(e => {

        if (e.service_type == "3") {
          start1 = moment.unix(e.start_timestamp_in_minute * 60).utc().format();
          end1 = moment.unix(e.end_timestamp_in_minute * 60).utc().format();
          start2 = moment.unix(e.start_timestamp_in_minute * 60).utc().format("LL");
          end2 = moment.unix(e.end_timestamp_in_minute * 60).utc().format("LL");
        }
        else {
          start1 = moment.unix(e.start_timestamp_in_minute * 60).utc().format();
          end1 = moment.unix(e.end_timestamp_in_minute * 60).utc().format();
          start2 = moment.unix(e.start_timestamp_in_minute * 60).utc().format("LLL");
          end2 = moment.unix(e.end_timestamp_in_minute * 60).utc().format("LLL");
        }

        // based on customer change title
        if (this.auth.getUser().user_type == 'customer') {
          title = e.service_name;
        }
        else {
          title = e.customer_name;
        }

        if (e.is_this_a_break == "1") {
          e.color = "#aaaaaa";
          title = "Break";
        }

        if (e.status == '1' || e.status == '2') {
          title = "CANCELLED";
          e.color = "#ff7f7f";
        }

        let item = {
          "id": e.id,
          "resourceId": e.calendar_id,
          "service_type": e.service_type,
          "url": "javascript:void(0);",
          "status": e.status_string,
          "email": e.customer_email,
          "price": e.price,
          "service": e.service_name,
          "start": start1,
          "title": title,
          "end": end1,
          "balance": e.balance,
          "is_this_a_break": e.is_this_a_break,
          "color": e.color
        };

        let item2 = {
          "id": e.id,
          "service_type": e.service_type,
          "url": "#",
          "status": e.status_string,
          "email": e.customer_email,
          "price": e.price,
          "service": e.service_name,
          "title": title,
          "start": start2,
          "balance": e.balance,
          "is_this_a_break": e.is_this_a_break,
          "end": end2,
          "refund_time": e.refund_time,
          "color": e.color
        };

        events.push(item);
        if (update_event_list) {
          this.event_list.push(item2);
        }
      });
    }

    return events;

  }

  /**
   * 
   * @param event 
   */
  onChange_AllCal(event) {
    event.target.checked ? (this.show_deleted =
      this.boolean_status[0].value) : (this.show_deleted = this.boolean_status[1].value);

    this.getAllCalendar();
  }

  /**
   * 
   */
  onClick_DownloadCsv() {
    if (
      (this.from_date == '' || this.from_date == null) && (this.to_date == '' || this.to_date == null)) {
      this.alertService.error("From Date or To Date are not selected");
    }
    else {
      this.is_download_as_csv = true;
      this.loadEvents();
    }
  }

  /**
   * 
   */
  onClick_DownloadXls() {
    this.is_download_as_csv = false;
    this.loadEvents();
  }

  onClick_SMSLogs() {
    this.is_on_sms_logs = true;
    this.is_on_email_logs = false;
    this._getAllSMS();
  }
  onClick_EmailLogs() {
    this.is_on_sms_logs = false;
    this.is_on_email_logs = true;
    this._getAllEmail();
  }

  /**
 * 
 * @param data 
 */
  _formatEmail() {
    this.email_history_list = [];
    if (this.emails != null) {
      this.emails.forEach(e => {
        let item = {
          "id": e.id,
          "message": e.message,
          "sender_email": e.sender_email,
          "receiver_email": e.receiver_email,
          "dated": moment.unix(e.dated).utc().format("LLL")
        };
        this.email_history_list.push(item);
      });
    }
  }

  /**
   * 
   * @param data 
   */
  _formatSMS() {
    this.sms_history_list = [];
    if (this.smses != null) {
      this.smses.forEach(e => {
        let item = {
          "id": e.id,
          "message": e.message,
          "sender_email": e.sender_email,
          "receiver_email": e.receiver_email,
          "dated": moment.unix(e.dated).utc().format("LLL")
        };
        this.sms_history_list.push(item);
      });
    }
  }



  _getAllSMS() {
    let _p = {};
    this.messageService.getAllSMSByBusinessId(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.smses = temp.data;
      this._formatSMS();
    });
  }

  _getAllEmail() {
    let _p = {};
    this.messageService.getAllEmailByBusinessId(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.emails = temp.data;
      this._formatEmail();
    });
  }

  /**
   * 
   */
  onClick_ShowHideAdvancedSearch() {
    if (this.is_show_advanced_search == true) {
      this.is_show_advanced_search = false;
    } else {
      this.is_show_advanced_search = true;
    }
  }

  /**
   * 
   */
  onClick_RunAdvancedSearch() {
    this.loadEvents();
  }

  /**
   * 
   */
  onClick_Booking(e) {
    this.router.navigateByUrl("booking.detail?id=" + e.data.id);
  }

}
