/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tnc.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./tnc.component";
var styles_TncComponent = [i0.styles];
var RenderType_TncComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TncComponent, data: {} });
export { RenderType_TncComponent as RenderType_TncComponent };
export function View_TncComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nav", [["aria-label", "breadcrumb"], ["class", "bg-primary text-white"], ["role", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "section", [["class", "space-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms And Conditions"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "a", [["class", "btn btn-default border bg-light"], ["href", "/assets/terms.pdf"], ["routerLinkActive", "router-link-active"], ["target", "_blank"]], null, null, null, null, null)), i1.ɵdid(9, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 0, "span", [["class", "fa fa-download"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Click here to download Bookit24 Terms and Conditions"]))], function (_ck, _v) { var currVal_0 = "router-link-active"; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_TncComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tnc", [], null, null, null, View_TncComponent_0, RenderType_TncComponent)), i1.ɵdid(1, 114688, null, 0, i3.TncComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TncComponentNgFactory = i1.ɵccf("app-tnc", i3.TncComponent, View_TncComponent_Host_0, {}, {}, []);
export { TncComponentNgFactory as TncComponentNgFactory };
