import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../../_service/payment.service';
import { AlertService } from '../../../_service/alert.service';

@Component({
  selector: 'app-config-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.css']
})
export class PaypalComponent implements OnInit {

  signature = "";
  client_id = "";
  client_secret = "";
  is_loading = false;

  /**
   * 
   */
  constructor(private paymentService: PaymentService, private alertService: AlertService) { 

    this._resetForm();
    this._getPaypalInfo();
  }
  /**
   * 
   */
  ngOnInit() {
  }

  /**
   * 
   */
  _resetForm(){
    this.signature = "";
    
  }

  /**
   * 
   */
  _getPaypalInfo(){
    this.is_loading = true;
    this.paymentService.getPaypalInfo().subscribe(data=>{
        let temp:any;
        temp = data;
        if(temp.data.payload_json != null){
          this.signature = temp.data.payload_json.signature;
          this.client_secret = temp.data.payload_json.client_secret;
          this.client_id = temp.data.payload_json.client_id;
        }
        this.is_loading = false;
      });
  }
  /**
   * 
   */
  onClick_Save(){
    let _p  = {
      "signature": this.signature,
      "client_id": this.client_id,
      "client_secret": this.client_secret
    };
    this.paymentService.savePaypal(_p).subscribe(data=>{
      let temp:any;
      temp = data;
      this.alertService.success(temp.msg);
      this._getPaypalInfo();
      this.is_loading = false;
      
    });
  }

  /**
   * 
   */
  onClick_Delete(){
    this.is_loading = true;
    
    this.paymentService.deletePaypal({}).subscribe(data=>{
      let temp:any;
      temp = data;
      this.alertService.success(temp.msg);
      this._resetForm();
      this.is_loading = false;
    });
  }

}
