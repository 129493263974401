import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {  NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_service/alert.service';
import * as $ from 'jquery';
import * as moment from 'moment-timezone';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-booking-create-rental',
  templateUrl: './rental.component.html',
  styleUrls: ['./rental.component.css']
})
export class RentalComponent implements OnInit {

  @Input() current_date: any;
  @Input() price = 0;
  @Output() selectSlot = new EventEmitter<object>();

  datetime: NgbDateStruct;

  err: any;
  is_show_error = false;
  is_slots_available = false;
  slots: any;
  slots_data: any;
  is_start_slot_selected = false;

  start_slot_timestamp = "";
  start_slot_name = "";
  end_slot_timestamp = "";
  end_slot_name = "";

  calendar_id = "";
  calendar_name = "";

  services: any;

  is_customer = true;
  is_pay_now_ready = false;

  min_duration = 0;
  max_duration = 0;
  start_datetime: any;
  end_datetime: any;
  start_timestamp: any;
  end_timestamp: any;

  is_ready_to_select_customer = false;
  modal_title = "";
  modal_image = "";
  modal_description = "";
  break_point_slot = 1;
  no:any;


  /**
   * 
   * @param router 
   * @param alertService 
   */
  constructor(private router: Router, private modalService: NgbModal,
    private alertService: AlertService) {

      this.no = {
        firstDayOfWeek: 1,
        dayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
        dayNamesShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
        dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
        monthNames: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
        today: 'I dag',
        clear: 'Klar'
      };
  
  
  }

  ngOnInit() {
  }

  open(modal, item) {
    this.modal_title = item.name;
    this.modal_image = item.image;
    this.modal_description = item.description;
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }


  /**
   * 
   * @param data 
   * _x is for rental-24 when only customer is login
   */
  generateSlots(data, _p = null) {
    this.is_slots_available = false;

    this.slots_data = data;
    let temp = [];
    let breaks: any;

    if (_p != null && this.is_start_slot_selected == false) {
      // here min duration need to be in seconds, not minute
      this.datetime = this.current_date.toDate();
  
      this.is_start_slot_selected = false;
      this.min_duration = _p.min_duration;
      this.max_duration = _p.max_duration;
    }

    let temp_slot: any;
    temp_slot = [];
    data.data.forEach((cal, index) => {
      if(cal == null){
        return false;
      }
      this.services = cal.service;

      // below if to only show same calendar which is used for
      if (this.is_start_slot_selected == true) {
        if (cal.id != this.calendar_id) {
          return;
        }
      }
      breaks = this._findRentalBreaks(cal);
      if (this.is_start_slot_selected == false) {
        this.break_point_slot = 1;
        temp_slot = this._findRentalStartSlot(cal, breaks);
        if (temp_slot.slots.length > 0) {
          temp.push(temp_slot);
        }
      } else {
        temp_slot = this._findRentalEndSlot(cal, breaks);
        if (temp_slot.slots.length > 0) {
          temp.push(temp_slot);
        }
      }
    });

    this.slots = temp;
    if (this.slots.length < 1) {
      this.is_show_error = true;
      this.is_slots_available = false;
      this.err = "Bookings Not Available";
    } else {
      this.is_slots_available = true;
      this.is_show_error = false;
      this.err = "";
    }

  }

  /**
   * 
   * @param cal 
   */
  _findRentalBreaks(cal) {
    let br = 0;
    let breaks = [];
    $.each(cal.breaks, function (bk, bv) {
      for (br = bv.start_timestamp_in_minute; br < bv.end_timestamp_in_minute;) {
        breaks.push(Number(br));
        br = Number(br) + Number(1);
      }
    });
    return breaks;

  }

  /**
   * 
   */
  _findRentalEndSlot(cal, breaks) {

    let i = 0;
    let stack: any;
    stack = [];
    stack.name = "";
    stack.image = "";
    stack.description = "";
    stack.slots = [];
    let _v = false;
    let _end = 0;
    let is_next_day = false;
    // rental end date can be select from future date, so we have to check that if current date is same 
    // as start date or some future date, if future date then slots must start from 00:00

    var start_ts = moment.unix(this.start_timestamp).utc().startOf('day').unix();
    var c_ts = this.current_date.startOf('day').unix();
    var start_from = Number(this.start_slot_timestamp);
    // if c_ts > start_ts
    // means if select day is greate than start day
    // then start_from 00:00 of select day
    let add_min = this.min_duration;
    if(c_ts > start_ts){
      start_from = c_ts / 60;
      is_next_day = true;
      add_min = 0;
    }
    
    
    _end = Number(cal.end_timestamp_in_minute);
    for (i = start_from; i <= _end;) {
      _v = this._isValidEndSlot(Number(i), breaks,is_next_day,cal);
      if (_v == false) {
        this.break_point_slot = Number(i);
        break;
      }
      else {
        stack.name = cal.name;
        stack.image = cal.image;
        stack.description = cal.description;
        let s = {
          "cal_name": cal.name,
          "cal_id": cal.id,
          "slot_minute": i + Number(add_min),
          "slot_string": moment.unix(Number(i + add_min) * 60).utc().format('LT')
        };
        stack.slots.push(s);
      }
      i = Number(i) + Number(cal.slot);
    }
    //  console.log(this.min_duration);
    return stack;
  }

  /**
   * 
   * @param slot 
   * @param breaks 
   */
  _isValidEndSlot(slot, breaks, is_next_day,cal) {
    // (start - slot) > (max duration - min duration)    
    if((Number(slot) -  Number(this.start_timestamp)/60 ) > (Number(this.max_duration) - Number(this.min_duration))){
      return false;
    }
    if(this.break_point_slot > 1 && this.break_point_slot < slot){
      return false;
    }
    let flag = true;
    let i = 0;
    let add_min = this.min_duration;
    if(is_next_day){
      add_min = cal.slot;
    }
    let upto = Number(slot) + Number(add_min);
    // check 1
    // check next slot has min required duration
    for (i = slot; i < upto; i++) {
      if(is_next_day){
        //console.log("1="+upto);
        //console.log("2="+i+",="+moment.unix(Number(i) * 60).utc().format('LT'));
      }
      if ($.inArray(i, breaks) > -1) {
        this.break_point_slot = i;
        flag = false;
        break;
      }
    }
    //console.log("return="+i+",="+moment.unix(Number(i) * 60).utc().format('LT'));

    return flag;
  }


  /**
   * 
   */
  _findRentalStartSlot(cal, breaks) {
    
    let i = 0;
    let stack: any;
    stack = [];
    stack.name = "";
    stack.image = "";
    stack.description = "";
    let flag = true;
    stack.slots = [];
    
    for (i = cal.start_timestamp_in_minute; i <= cal.end_timestamp_in_minute;) {
      if (i > cal.current_time_at_provider_location && $.inArray(Number(i), breaks) == -1) {
        stack.name = cal.name;
        stack.image = cal.image;
        stack.description = cal.description;
        let s = {
          "cal_name": cal.name,
          "cal_id": cal.id,
          "slot_minute": i,
          "slot_string": moment.unix(Number(i * 60)).utc().format('LT')
        };

        // check is slot have enoigh min hour
        // so if 10am is booking and min duration - 120 min, 
        // then cannot show 9am
        flag = this._findNextBreakForStartSlot(i + this.min_duration, breaks);
        stack.slots.push(s);
        if (flag == true) {
        }
      }
      //i = Number(i) + this.min_duration;
      i = Number(i) + Number(cal.slot);
    }
    return stack;
  }

  _findNextBreakForStartSlot(s, breaks) {
    if ($.inArray(s, breaks) > -1) {
      return false;
    }
    return true;
  }

  /**
   * 
   * @param cal_id 
   * @param min_ts 
   * @param slot_string 
   * @param cal_name 
   */
  onClick_Slot(cal_id, min_ts, slot_string, cal_name) {
    if (this.is_start_slot_selected == true) {
      this._endSlot(cal_id, min_ts, slot_string, cal_name);
    } else {
      this._startSlot(cal_id, min_ts, slot_string, cal_name);
    }
  }

  /**
   * 
   * @param cal_id 
   * @param min_ts 
   * @param slot_string 
   * @param cal_name 
   */
  _endSlot(cal_id, min_ts, slot_string, cal_name) {
    this._calDurationAndPrice(cal_id, min_ts, slot_string);
  }

  /**
   * 
   */
  _calDurationAndPrice(cal_id, min_ts, end_slot_name) {

    this.end_timestamp = moment.unix(Number(min_ts * 60)).utc().unix();
    this.end_datetime = moment.unix(Number(min_ts * 60)).utc().format("LLL");

    let divisor = Number(min_ts) - Number(this.start_slot_timestamp);
    let qty = divisor / 60;
    let duration = this.end_timestamp - this.start_timestamp;

    let checkMaxDuration = this.validateMaxDuration(this.start_timestamp,this.end_timestamp,true);
    if (checkMaxDuration == true) {
      let _p = {
        "end_slot_timestamp": min_ts,
        "end_slot_name": end_slot_name,
        "start_slot_name": this.start_slot_name,
        "start_slot_timestamp": this.start_slot_timestamp,
        "calendar_name": this.calendar_name,
        "calendar_id": cal_id,
        "start_timestamp": this.start_timestamp,
        "end_timestamp": this.end_timestamp,
        "start_datetime": this.start_datetime,
        "end_datetime": this.end_datetime,
        "min_duration": this.min_duration,
        "total_duration": duration / 60,
        "qty": qty,
        "price": this.price
      }

      this.selectSlot.emit(_p);
    }
  }

  /**
   * 
   */
  validateMaxDuration(start,end,show_alert = false) {
    let max = Number(this.services.max_duration);
    let d = end - start;
    d = d / 60;
    if (max > 0) {
      if (d > max) {
        if(show_alert){
          this.alertService.error("Max allow duration to book this service is " + max / 60 + " hrs. Please select End Time again.");
        }
        return false;
      }
    }
    return true;
  }

  /**
   * 
   * @param cal_id 
   * @param min_ts 
   * @param slot_string 
   */
  _startSlot(cal_id, min_ts, slot_string, cal_name) {

    //console.log(min_ts);
    this._setSelectedSlotParams(cal_id, min_ts, slot_string, cal_name);
    this.alertService.success("Please Select End Date/Time Now");
    this.is_start_slot_selected = true;
    this.end_datetime = "?";
    this._recalculateEndtime();
  }

  /**
   * 
   */
  _recalculateEndtime() {
    this.generateSlots(this.slots_data);
    this.datetime = this.current_date.toDate();

  }

  /**
   * 
   * @param date_string 
   */
  _getTs(date_string) {
    return moment(date_string).utcOffset(0, true).startOf("day").unix();
  }

  /**
   * 
   * @param event 
   */
  onClick_CalendarStartDate(date_string) {
    this.current_date = moment(date_string).utcOffset(0, true).startOf("day");
    let ts = this._getTs(date_string);
    let _p = {
      "ts": ts,
      "current_date": this.current_date,
      "jump_to_date": true
    }

    this.selectSlot.emit(_p);
    //this._setNextPrevDate();
  }

  /**
   * 
   */
  onClick_ChangeStartTime() {
    this.is_start_slot_selected = false;
    this.generateSlots(this.slots_data);
  }

  /**
   * 
   * @param cal_id 
   * @param min_ts 
   * @param slot_string 
   */
  _setSelectedSlotParams(cal_id, min_ts, slot_string, cal_name) {
    this.start_slot_timestamp = min_ts;
    this.start_slot_name = slot_string;
    this.calendar_name = cal_name;
    this.calendar_id = cal_id;
    this.start_timestamp = moment.unix(min_ts * 60).utc().unix();
    this.start_datetime = moment.unix(Number(min_ts * 60)).utc().format("LLL");
  }

  /**
   * 
   */
  reset() {
    this.is_start_slot_selected = false;
  }

}