<div>
	<section class="height-20 flush-with-above">
		<div class="container">
			<div class="row justify-content-between align-items-center">
				<div class="col-12 col-md-4 mb-4 col-lg-4">
					<img alt="Image" src="assets/img/graphic-imac-interface.svg" class="w-100">
				</div>
				<div class="col-12 col-md-8 col-lg-8 mb-4 text-center text-md-left">
					<h1 class="display-5">Thank you for using Bookit24. </h1>
					<hr>
					<h2 class="lead">If you would like to come back or have any suggestions to improve our services, please drop us an email at support@bookit24.com</h2>
				</div>
			</div>
		</div>
	</section>
  </div>