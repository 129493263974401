import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import { ParamsHelper } from '../_helper/params';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CustomerService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function CustomerService(http) {
        this.http = http;
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    CustomerService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     * @param username
     * @param password
     */
    CustomerService.prototype.getAll = function (_p) {
        if (_p === void 0) { _p = null; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/customer/poll/all", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param id
     */
    CustomerService.prototype.get = function (id) {
        return this._getById(id);
    };
    /**
     *
     */
    CustomerService.prototype.getById = function (id) {
        return this._getById(id);
    };
    /**
     *
     */
    CustomerService.prototype._getById = function (id) {
        var _p = {
            "id": id
        };
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/customer/poll/by-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CustomerService.prototype.getAllCards = function () {
        var body = this.params.make(this._getBody());
        return this.http.post(environment.server + "/customer/account/poll/get-all-cards", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    CustomerService.prototype.create = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/customer/do/save", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    CustomerService.prototype.save = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/customer/account/do/save", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    CustomerService.prototype.sendMsg = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/customer/message/do/save", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    CustomerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(i0.ɵɵinject(i1.HttpClient)); }, token: CustomerService, providedIn: "root" });
    return CustomerService;
}());
export { CustomerService };
