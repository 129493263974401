import { Component, OnInit } from '@angular/core';
import { UserService } from '../../_service/user.service';
import { AlertService } from '../../_service/alert.service';
import { StringHelper } from '../../_helper/string';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { BusinessService } from 'src/app/_service/business.service';
import { temporaryAllocator } from '@angular/compiler/src/render3/view/util';

@Component({
  selector: 'app-config-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  /**
   * 
   */
  public is_loading = false;
  public all_users = [];
  public is_user_selected = false;
  public title = "";
  public user_details = "";
  public is_add_new_user = false;
  public selected_user: any;
  public plan;
  public username = "";
  public password = "";
  public confirm_password = "";
  public first_name = "";
  public last_name = "";
  public plan_stats: any;
  public status = [{ "value": "active" }, { "value": "inactive" }];
  public boolean_status = [{ "value": "1" }, { "value": "0" }];

  /**
   * 
   * @param userService 
   */
  constructor(
    public userService: UserService,
    private businessService: BusinessService,
    public alertService: AlertService,
    public translate: TranslateService,
    public string: StringHelper,
    public modal: NgxSmartModalService
  ) {

    this._getAllUsers();
    this._resetUserForm();
  }

  /**
   * 
   */
  ngOnInit() {
    this._getBusiness();
  }

  /**
   * 
   */
  _getBusiness(){
    this.businessService.getWhosLoggedIn().subscribe(data=>{
      let temp:any;
      temp = data;
      if(temp.data.plan){
        this.plan_stats = JSON.parse(JSON.parse(temp.data.plan).payload);
      }
    });
  }


  /**
   * 
   */
  _resetUserForm() {
    this.selected_user = {
      "id": "", "username": "", "password": "",
      "first_name": "", "last_name": "", "is_allowed_new_booking": 0, "is_allowed_config": 0,
      "is_allowed_open_cal": 0, "is_allowed_payment": 0, "is_allowed_customer": 0
    };
    this.is_add_new_user = true;
    this.title = this.translate.instant("lbl-user");

  }

  /**
   * 
   */
  _getAllUsers() {
    this.is_loading = true;
    this.userService.getAll().subscribe(data => {
      let temp: any;
      temp = data;
      this.all_users = temp.data;
      this.is_loading = false;
    });

  }

  onClick_TryDelete() {
    this.modal.getModal('user_id').open();
  }

  onClick_ModalClose() {
    this.modal.getModal('user_id').close();
  }


  /**
   * 
   */
  onClick_Delete() {
    this.is_loading = true;

    this.userService.delete(this.selected_user).subscribe(data => {
      let temp: any;
      temp = data;
      this._getAllUsers();
      this.is_user_selected = false;
      this._resetUserForm();
      this.alertService.success(temp.msg);
      this.is_loading = false;
      this.is_add_new_user = true;
    });
    this.modal.getModal('user_id').close();

  }

  /**
   * 
   * @param event 
   * @param name 
   * @param id 
   */
  onClick_User(event, id) {
    this.is_loading = true;

    this.userService.getById(id).subscribe(data => {
      this.is_user_selected = true;
      let temp: any;
      temp = data;
      this.is_add_new_user = false;
      this.selected_user = temp.data;
      this.selected_user.password = "";
      this.title = this.translate.instant("lbl-user") + ": " + temp.data.username;
      this.is_loading = false;
    });
  }

  /**
   * 
   */
  onClick_AddUser() {
    this._resetUserForm();

  }

  /**
   * 
   */
  onClick_Save() {

    if (!this.string.isValidEmail(this.selected_user.username)) {
      this.alertService.error("Email must be a valid email");
      return false;
    }
    if (this.password != this.confirm_password) {
      this.alertService.error("Password does not match Confirm Password");
      return false;
    }
    if (this.is_add_new_user == true) {
      if (this.password.trim() == "") {
        this.alertService.error("Password cannot be blank");
        return false;
      }
    }

    this._save();
  }

  /**
   * 
   */
  _save() {
    this.is_loading = true;
    this.selected_user.password = this.password;
    this.userService.save(this.selected_user).subscribe(data => {
      let temp: any;
      temp = data;
      this._getAllUsers();
      //      this._resetUserForm();
      this.password = "";
      this.confirm_password = "";
      if (temp.success) {
        this.alertService.success(temp.msg);
      }
      else {
        this.alertService.error(temp.msg);
      }
      this.is_loading = false;
    });
  }
}
