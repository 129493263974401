import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CalendarComponent } from '../calendar/calendar.component';
import { ServiceComponent } from '../service/service.component';
import { CalendarService } from '../../_service/calendar.service';
import { AlertService } from '../../_service/alert.service';
import { ServiceService } from '../../_service/service.service';
import { AuthHelper } from '../../_helper/auth';
import { GlobalComponent } from '../global/global.component';
import { BusinessService } from 'src/app/_service/business.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, AfterViewInit {

  @ViewChild(CalendarComponent,{ static: false }) calendar: CalendarComponent;
  @ViewChild(ServiceComponent,{ static: false }) service: ServiceComponent;
  @ViewChild(GlobalComponent,{ static: false }) global: GlobalComponent;
  
  is_customer = true;

  constructor(private calendarService: CalendarService,
    private serviceService: ServiceService,
    public auth: AuthHelper,
    private businessService: BusinessService,
    private alertService: AlertService) {

    if (auth.getUser().user_type == 'provider' || auth.getUser().user_type == 'agent') {
      this.is_customer = false;
    }

    // always reload business permission here, this is used when provider cancel or change plan
    // this page is reloaded and reset curent plan in session
    if(!this.is_customer){
      this.businessService.getBySubdomain(auth.getUser().business.subdomain).subscribe(data=>{
        let temp:any;
        temp = data;
        auth.updateBusiness(temp.data);
        
      });
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  onClick_Calendar() {
    this.calendar._getAllCal();
  }
  
  onClick_Service() {
    this.service._init();
  }

  onClick_Misc(){
    this.global.getSettings();
  }

}
