import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  msg_1 = "Hi there, you landed on something which does not exists yet!";
  msg_2 = "If this is not how this web page suppose to show up, let us know. We can look into it right away.";
  x = "";

  /**
   * 
   * @param route 
   */
  constructor(
    public route: ActivatedRoute
  ) {

    this.route.queryParams.subscribe(params => {
      this.x = params['msg'];
    });

  }

  /**
   * 
   */
  ngOnInit() {
    if(this.x == 'cnf'){
      this.msg_1 = "Customer profile not found or has been deleted !";
    }
  }


}
