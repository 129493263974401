<section class="space-xs">
  <div class="container">
    <h3 class="text">Contact Us</h3>
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="card card-sm">
          <div class="card-body p-4">

            <div class="row form-group pb-0 mb-0">
              <div class="col title-decorative pb-0 mb-0">
                Please select a Query type:
              </div>
            </div>

            <div class="row form-group">
              <div class="col">
                <select [(ngModel)]="query_type" class="form-control form-control-lg col-6">
                  <option value=""> -- Please select an option -- </option>
                  <option value="1"> Query regarding a bookings</option>
                  <option value="2"> Query regarding my profile</option>
                  <option value="3"> I have a suggestion </option>
                </select>
              </div>
            </div>

            <div class="row form-group mt-3 pb-0 mb-0">
              <div class="col title-decorative pb-0 mb-0">
                Your Query:
              </div>
            </div>
            <div class="row form-group">
              <div class="col">
                <textarea [(ngModel)]="query" rows='10' maxlength='5000' class="form-control " placeholder=""
                  name="query"></textarea>
              </div>
            </div>

          </div>
          <div class="card-footer bg-light">
            <button (click)="onSubmit()" class="btn btn-lg btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>