import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../../_helper/http.form.codec';
import { ParamsHelper } from '../../_helper/params';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LogService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function LogService(http) {
        this.http = http;
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    LogService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     */
    LogService.prototype.getAll = function () {
        return this.http.get(environment.apiv2_server + "/su/log", { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    LogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogService_Factory() { return new LogService(i0.ɵɵinject(i1.HttpClient)); }, token: LogService, providedIn: "root" });
    return LogService;
}());
export { LogService };
