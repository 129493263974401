import { Component, OnInit } from '@angular/core';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { BookingService } from '../../_service/booking.service';
import { BusinessService } from '../../_service/business.service';
import { UserService } from '../../_service/user.service';
import { AlertService } from '../../_service/alert.service';
import { CustomerService } from '../../_service/customer.service';
import { AuthHelper } from '../../_helper/auth';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
import { NgxSmartModalService } from 'ngx-smart-modal';

import * as moment from "moment-timezone";
import { DateHelper } from 'src/app/_helper/date';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  payPalConfig?: PayPalConfig;
  booking_id = 0;
  booking: any;
  allow_status_change = false;
  refund_amount = 0;
  refund_percentage = 0;
  is_refunding = false;
  show_payment_form = false;
  business: any;
  is_loading = false;
  map_q = "";
  card_options = 1;
  existing_cards: any;
  save_new_card = "1";
  boolean_status = [{ "value": "1" }, { "value": "0" }];
  selected_existing_card = "";
  allow_cancel = false;

  //stripe = { "number": "4242424242424242", "exp_month": "12", "exp_year": "22", "cvc": "123" };
  stripe = { "number": "", "exp_month": "", "exp_year": "", "cvc": "" };

  status_map = [
    { "id": "1", "value": "CANCELLED BY CUSTOMER" },
    { "id": "2", "value": "CANCELLED BY PROVIDER" },
    { "id": "3", "value": "NO SHOW" },
    { "id": "4", "value": "CONFIRMED" },
    { "id": "5", "value": "FINISHED" }
  ];


  /**
   * 
   * @param activatedRoute 
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private modal: NgxSmartModalService,
    public date: DateHelper,
    private customerService: CustomerService,
    private businessService: BusinessService,
    private bookingService: BookingService,
    private userService: UserService,
    private alertService: AlertService,
    private auth: AuthHelper
  ) {
    this.route.queryParams.subscribe(params => {
      this.booking_id = params['id'];
    });
    this.booking = {};
  }

  /**
   * 
   */
  ngOnInit() {
    this._getBooking();
  }


  _getSavedCards() {
    let _p = { "customer_id": this.booking.customer.id };
    this.customerService.getAllCards().subscribe(data => {
      let temp: any;
      temp = data;
      this.existing_cards = temp.data;

    });
  }

  /**
   * account Salg-facilitator@bookit24.no
    client it
    ARWEOt_GKN0Nq4EFrDkXnGTU4l7R-R2zqhtVVeMSmr-hSfynR2RFnRmT1WYXJei1qBsiQ0aZij1e3Kcw
    s-ecret:
    EPaBVayJTcg4N4ErOIo2zvFmemVMqmEU1Yp5kh6Ksfp4ue20OMvKVW2CO9GqtEDqcccl97XJjYydyN-7

    ok, for live account:
    Salg@bookit24.no
    client id: AdTkg9_AOi3ngKEOQfWPCAI4m1PjLhqdYT2rKLzRi-tar6vCV63DqkmaCQj8bYB4kmgmgA-ieUaiq2fM
    s-ecret: ED33_tpQMaOa1_YYZ4FxrUO1GbNfhf2Bz6P3xyuYo2RpOpLZWEtFJ0QlkYXLLp3bXsYy9kq95OicAHVn

   * 
   */
  initPaypalConfig() {

    if (this.booking.business.paypal != null) {
      //console.log(this.booking.business.paypal);

      this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PayPalEnvironment.Production, {
        commit: true,
        client: {
          production: this.booking.business.paypal.client_id
        },
        button: {
          label: 'pay',
          size: "large",
          shape: 'rect',
          color: 'black',
          tagline: false
        },
        onPaymentComplete: (data, actions) => {
          this._onPaymentSuccess(data);
        },
        onCancel: (data, actions) => {
          this._onPaymentCancel(data);
        },
        onError: (data) => {
          this._onPaymentError(data);
        },
        transactions: [{
          amount: {
            currency: 'NOK',
            total: 1
          }
        }]
      });
    }
    else {
    }
  }

  initStripeConfig() {

  }

  /**
   * 
   */
  _onPaymentSuccess(data) {
    this.alertService.success(this.translate.instant("alert-success"));
    this._updatePayment(data, "success");
  }

  /**
   * 
   */
  _onPaymentCancel(data) {
    this._updatePayment(data, "cancel");
    //  this.alertService.success("Payment was successful");
  }

  /**
   * 
   */
  _onPaymentError(data) {
    this.alertService.success(this.translate.instant("alert-error"));
    this._updatePayment(data, "error");
  }

  /**
   * 
   * @param payload 
   */
  _updatePayment(payload, status) {
    let _p = { "id": this.booking.id, "status": status, "payload": JSON.stringify(payload) };
    this.bookingService.updatePayment(_p).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        if (status != 'cancel') {
          this.alertService.success(temp.msg);
        }
      } else {
        this.alertService.error(temp.msg);
      }
      this._getBooking();
    });
  }

  /**
   * 
   */
  _getBooking() {
    //this.selected_date = moment(v).utcOffset(0,true).unix();
    //this.starting_week = moment.unix(this.selected_date).utc().startOf('isoWeek').unix();      
    this.allow_status_change = false;
    this.is_loading = true;
    this.bookingService.get(this.booking_id).subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      this.booking = temp.data;
      let z = this.booking.business;

      this.map_q = encodeURIComponent(z.street + "," + z.city + "," + z.state + "," + z.country + "," + z.zipcode);
      this.booking.payment_made = Number(this.booking.price) - Number(this.booking.balance);
      if (this.booking.service.service_type == '1' || this.booking.service.service_type == '2') {
        this.booking.starts = moment.unix((Number(this.booking.start_timestamp_in_minute) * 60)).utc().format("LLL");
        this.booking.ends = moment.unix((Number(this.booking.end_timestamp_in_minute) * 60)).utc().format("LLL");
      }
      if (this.booking.service.service_type == '3') {
        this.booking.starts = moment.unix((Number(this.booking.start_timestamp_in_minute) * 60)).utc().format("LL") + " " + this.booking.service.checkin;
        this.booking.ends = moment.unix((Number(this.booking.end_timestamp_in_minute) * 60)).utc().format("LL") + " " + this.booking.service.checkout;
      }
      if (this.booking.balance == null || this.booking.balance == '') {
        this.booking.balance = 0;
      }

      if (this.booking.service.minimum_time_before_cancel > 0) {
        let min_time = this.booking.service.minimum_time_before_cancel * 60 * 60;
        let time_now = moment().unix();
        let check = this.booking.start_timestamp_in_minute * 60 - time_now;
        if (check > min_time) {
          this.allow_cancel = true;
        }
      }

      
      /** 
      if (this.booking.payment_token != "") {
        this.booking.payment_token = JSON.parse(this.booking.payment_token);
      }
      */
      // flag status change
      if (this.auth.getUser().user_type != 'customer' && this.booking.status == '4') {
        this.allow_status_change = true;
      }

      if (this.booking.business.main_vendor_for_payment == 'Paypal') {
        this.initPaypalConfig();
      }

      if (this.booking.business.main_vendor_for_payment == 'Stripe') {
        this.initStripeConfig();
      }

      this._getSavedCards();


    });
  }


  /**
   * 
   */
  onClick_Refund() {
    if (this.refund_amount < 1) {
      this.alertService.error(this.translate.instant("alert-refund-must-be-greater-than-1"));
    }
    else {
      this.is_loading = true;
      let _p = {
        "booking_id": this.booking.id,
        "amount": this.refund_amount
      }
      this.is_refunding = true;
      this.bookingService.refund(_p).subscribe(data => {
        let temp: any;
        temp = data;
        this.is_loading = false;
        this.is_refunding = false;
        this._getBooking();
        this.modal.getModal('book_id').close();
        if (temp.success) {
          this.alertService.success(this.translate.instant("alert-success"));
        }
        else {
          this.alertService.error(this.translate.instant("alert-error"));
        }
      },
        error => {
          this.is_loading = false;
          this.alertService.error(this.translate.instant("alert-error"));
        });
    }
  }

  onClick_TryCancelBooking() {
    this.modal.getModal('cancel_booking').open();
  }

  onClick_CancelBookingModalClose() {
    this.modal.getModal('cancel_booking').close();
  }

  onClick_CancelBooking() {
    this.booking.status = 1;
    this.onClick_Update();
    this.modal.getModal('cancel_booking').close();

  }

  onClick_TryRefund() {
    this.modal.getModal('book_id').open();
  }

  onClick_ModalClose() {
    this.modal.getModal('book_id').close();
  }

  onChange_RefundAmount(event) {
    let temp = Number(event.target.value) * 100 / this.booking.payment_made;
    this.refund_percentage = Number(temp.toFixed(2));

  }

  onClick_TryEditNotes() {
    this.modal.getModal('notes_modal').open();
  }

  onClick_NotesModalClose() {
    this.modal.getModal('notes_modal').close();
  }

  onClick_EditNotes() {
    this.onClick_Update();
    this.modal.getModal('notes_modal').close();

  }

  /**
   * 
   */
  onClick_Update() {
    let _p = {
      "id": this.booking.id,
      "notes": this.booking.notes,
      "status": this.booking.status
    }
    this._update(_p);
  }

  /**
 * 
 */
  onClick_MarkAsPaid() {
    let _p = {
      "id": this.booking.id,
    }
    this._markAsPaid(_p);
  }

  /**
   * 
   * @param _p 
   */
  _update(_p) {
    this.bookingService.update(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this._getBooking();
      if (temp.success) {
        this.alertService.success(this.translate.instant("alert-success"));
      }
      else {
        this.alertService.error(this.translate.instant("alert-error"));
      }
    },
      error => {
        this.alertService.error(this.translate.instant("alert-error"));
        this.is_loading = false;
      });
  }

  /**
   * 
   * @param _p 
   */
  _markAsPaid(_p) {
    this.bookingService.markAsPaid(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this._getBooking();
      if (temp.success) {
        this.alertService.success(this.translate.instant("alert-success"));
      }
      else {
        this.alertService.error(this.translate.instant("alert-error"));
      }
    },
      error => {
        this.alertService.error(this.translate.instant("alert-error"));
        this.is_loading = false;
      });

  }


  onClick_PayNowWithStripe() {
    if (this._validate()) {
      this.is_loading = true;
      let _p = { "description": this.booking.service_name, "business_id": this.booking.business.id, "price": this.booking.price, "selected_existing_card": this.selected_existing_card, "card_options": this.card_options, "save_new_card": this.save_new_card, "payment_settings": true, "credit_cc_num": this.stripe.number, "credit_cc_month": this.stripe.exp_month, "credit_cc_year": this.stripe.exp_year, "credit_cc_cvc": this.stripe.cvc };
      this.bookingService.registerCardAndMakePayment(_p).subscribe(data => {
        let temp: any;
        temp = data;
        this.is_loading = false;
        if (temp.success) {
          this.alertService.success(this.translate.instant("alert-success"));
          this._updatePayment(temp.data, "success");
        }
        else {
          this.alertService.error(this.translate.instant("alert-error"));
          this.alertService.error(temp.msg);
          //this._updatePayment(temp.data, "error");
        }
      }, error => {
        this.is_loading = false;
        this.alertService.error(this.translate.instant("alert-error"));
      });
    }
  }

  _validate() {
    if (this.card_options == 2) {
      if (this.selected_existing_card == "") {
        this.alertService.error(this.translate.instant("alert-please-select-a-card"));
        return false;
      }
    }
    return true;
  }


  /**
   * 
   */
  onClick_ViewCustomer() {
    this.router.navigateByUrl("customer.detail?id=" + this.booking.customer.id);
  }
}
