import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CalendarService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function CalendarService(http) {
        this.http = http;
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    CalendarService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     */
    CalendarService.prototype.save = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/calendar/do/save-step-1", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CalendarService.prototype.autoGenerateCalendar = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/calendar/do/auto-generate", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CalendarService.prototype.setSchedule = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/calendar/do/save-step-4", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CalendarService.prototype.delete = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("calendar_id", _p.id).append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/calendar/do/delete", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param temp
     * @param _p
     */
    CalendarService.prototype._append = function (temp, _p) {
        Object.keys(_p).forEach(function (k) {
            var t = temp.append(k, _p[k]);
            // below line is important because temp only return
            // it does not update temp 
            temp = t;
        });
        return temp;
    };
    /**
     *
     */
    CalendarService.prototype.toggleService = function (assign, service_id, cal_id) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("service_id", service_id)
            .append("calendar_id", cal_id).append("auth_token", user.data.auth_token);
        var api = "deassign-service";
        if (assign) {
            api = "assign-service";
        }
        return this.http.post(environment.server + "/provider/calendar/do/" + api, body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CalendarService.prototype.getAll = function (bypass_id, _p) {
        if (bypass_id === void 0) { bypass_id = null; }
        if (_p === void 0) { _p = null; }
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("bypass_id", bypass_id).append("auth_token", user.data.auth_token);
        var body;
        if (_p != null) {
            body = this._append(temp, _p);
        }
        else {
            body = temp;
        }
        return this.http.post(environment.server + "/provider/calendar/poll/all", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CalendarService.prototype.getAllResources = function (bypass_id, _p) {
        if (bypass_id === void 0) { bypass_id = null; }
        if (_p === void 0) { _p = null; }
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("bypass_id", bypass_id).append("auth_token", user.data.auth_token);
        var body;
        if (_p != null) {
            body = this._append(temp, _p);
        }
        else {
            body = temp;
        }
        return this.http.post(environment.server + "/provider/calendar/poll/all-resources", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CalendarService.prototype.getSchedule = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/calendar/availability/get", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CalendarService.prototype.getById = function (id) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("id", id).append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/calendar/poll/by-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CalendarService.prototype.getOpen = function () {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/calendar/poll/open", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    CalendarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalendarService_Factory() { return new CalendarService(i0.ɵɵinject(i1.HttpClient)); }, token: CalendarService, providedIn: "root" });
    return CalendarService;
}());
export { CalendarService };
