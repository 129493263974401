/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signin-layout.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../../node_modules/angular-notifier/angular-notifier.ngfactory";
import * as i4 from "angular-notifier";
import * as i5 from "./signin-layout.component";
import * as i6 from "@ngx-translate/core";
var styles_SigninLayoutComponent = [i0.styles];
var RenderType_SigninLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SigninLayoutComponent, data: {} });
export { RenderType_SigninLayoutComponent as RenderType_SigninLayoutComponent };
export function View_SigninLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "notifier-container", [["class", "notifier__container"]], null, null, null, i3.View_ɵf_0, i3.RenderType_ɵf)), i1.ɵdid(3, 245760, null, 0, i4.ɵf, [i1.ChangeDetectorRef, i4.ɵg, i4.NotifierService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
export function View_SigninLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signin-layout", [], null, null, null, View_SigninLayoutComponent_0, RenderType_SigninLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i5.SigninLayoutComponent, [i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SigninLayoutComponentNgFactory = i1.ɵccf("app-signin-layout", i5.SigninLayoutComponent, View_SigninLayoutComponent_Host_0, {}, {}, []);
export { SigninLayoutComponentNgFactory as SigninLayoutComponentNgFactory };
