<nav aria-label="breadcrumb" role="navigation"
class="bg-primary text-white">
<div class="container">
  <div class="row justify-content-center">
    <div class="col">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0);" translate>txt-payments</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" translate>txt-listing-all-your-payments</li>
      </ol>
    </div>
    <!--end of col-->
  </div>
  <!--end of row-->
</div>
<!--end of container-->
</nav>
<section class="space-xs">
    <div class="container">
          <ng2-smart-table                      
          (userRowSelect)="onClick_Booking($event)" [settings]="settings" [source]="payments" translate ></ng2-smart-table>
    </div>
</section>
