<!doctype html>
<html lang="en">
    <head>
        <meta  charset="utf-8" name="description"
            content="{{business?.description}}">
        <title>{{business?.name}} - Online Booking System</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link
            href="https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500"
            rel="stylesheet">
        <link rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.9.0/fullcalendar.min.css">
        <link rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
            integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
            crossorigin="anonymous">
    </head>
    <body>
        <div class="nav-container">
            <div class="bg-dark navbar-dark" data-sticky="top">
                <div class="container">


                    <nav class="navbar navbar-expand-lg">
                        <a href="javascript:void(0);" class="navbar-brand"
                            [routerLink]="['/']">
                            <img src="/assets/img/bookit24_whitelogo.png"
                                class="responsive responsive-fluid" width="110">
                        </a>
                        <button class="navbar-toggler" type="button"
                            data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <i class="icon-menu h4"></i>
                        </button>
                        <div class="collapse navbar-collapse
                            justify-content-between" id="navbarNav">
                            <ul class="navbar-nav" *ngIf="auth.isLoggedIn() ==
                                true && auth.getUser().is_force_reset_password
                                == '0'">
                                <li class="nav-item">
                                    <a href="javascript:void(0);"
                                        [routerLink]="['/dashboard']"
                                        class="nav-link" translate>btn-dashboard</a>
                                </li>
                                <!-- <li class="nav-item">
                                <a href="javascript:void(0);" [routerLink]="['/booking.create']" class="nav-link" 
                                *ngIf="auth.getUser().user_type == 'provider' || auth.getUser().user_type == 'customer' || (auth.getUser().user_type == 'agent' && auth.getUser().is_allowed_new_booking == '1') "
                                translate >btn-new-booking</a>
                                 </li>
                                -->
                                <li class="nav-item"
                                    *ngIf="(((auth.getUser().user_type ==
                                    'agent' &&
                                    auth.getUser().is_allowed_new_booking ==
                                    '1') || auth.getUser().user_type ==
                                    'provider') &&
                                    auth.getUser().business.plan!='' &&
                                    auth.getUser().business.current_plan!='') ||
                                    auth.getUser().user_type == 'customer'">
                                    <a href="javascript:void(0);"
                                        [routerLink]="['/booking.new']"
                                        class="nav-link" translate>btn-new-booking</a>
                                </li>
                                <li class="nav-item"
                                    *ngIf="auth.getUser().user_type ==
                                    'provider' || (auth.getUser().user_type ==
                                    'agent' &&
                                    auth.getUser().is_allowed_customer == '1')">
                                    <a href="javascript:void(0);"
                                        [routerLink]="['/customer']"
                                        class="nav-link" translate>btn-customers</a>
                                </li>
                                <li class="nav-item"
                                    *ngIf="auth.getUser().user_type ==
                                    'provider' || (auth.getUser().user_type ==
                                    'agent' && auth.getUser().is_allowed_payment
                                    == '1')">
                                    <a href="javascript:void(0);"
                                        [routerLink]="['/payment']"
                                        class="nav-link" translate>btn-payments</a>
                                </li>
                                <!-- 
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="javascript:void(0);"  id="pagesDropdown" role="button" data-toggle="dropdown">Messages</a>
                                    <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                                        <a class="dropdown-item" [routerLink]="['/conversation/chat']">
                                            <span class="h6 mb-0">Conversation</span>
                                            <p class="text-small text-muted">all your chat messages records</p>
                                        </a>
                                        <a class="dropdown-item" [routerLink]="['/conversation/sms']">
                                            <span class="h6 mb-0">SMS</span>
                                            <p class="text-small text-muted">all your sms messages records</p>
                                        </a>
                                    </div>
                                </li>-->
                                <li class="nav-item"
                                    *ngIf="auth.getUser().user_type ==
                                    'customer'">
                                    <a href="javascript:void(0);"
                                        [routerLink]="['/config']"
                                        class="nav-link" translate>btn-my-account</a>
                                </li>
                                <li class="nav-item"
                                    *ngIf="auth.getUser().user_type ==
                                    'provider' || (auth.getUser().user_type ==
                                    'agent' && auth.getUser().is_allowed_config
                                    == '1')">
                                    <a href="javascript:void(0);"
                                        [routerLink]="['/config']"
                                        class="nav-link" translate>btn-config</a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:void(0);"
                                        [routerLink]="['/signout']"
                                        class="nav-link text text-danger"
                                        translate>btn-logout</a>
                                </li>
                            </ul>
                            <ul class="navbar-nav" *ngIf="auth.isLoggedIn() ==
                                true">
                                <li class="nav-item dropdown">
                                    {{auth.getUser().first_name}}
                                    {{auth.getUser().last_name}}
                                </li>
                            </ul>
                            <ul class="navbar-nav" *ngIf="auth.isLoggedIn() ==
                                false">
                                <li></li>
                            </ul>

                            <ul class="navbar-nav">
                                <li class="nav-item dropdown"
                                    *ngIf="auth.isLoggedIn() == false">
                                    <a href="javascript:void(0);"
                                        [routerLink]="['/signin']"
                                        class="nav-link" translate>btn-signin</a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a href="javascript:void(0);"
                                        (click)="onClick_ChangeLanguage()"
                                        class="nav-link">{{current}}</a>
                                </li>
                            </ul>
                        </div>
                        <!--end nav collapse-->
                    </nav>
                </div>
                <!--end of container-->
            </div>
        </div>
        <div class="main-container">

            <router-outlet></router-outlet>

            <!--end of section-->
            <footer class="footer-short mt-5">
                <div class="container">
                    <hr>
                    <nav class="row justify-content-between align-items-center">
                        <small>
                            <div class="col-auto">
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        <a class="text-muted"
                                            href="javascript:void(0);"
                                            [routerLink]="['/privacy']"
                                            translate>btn-privacy-policy</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a class="text-muted"
                                            href="javascript:void(0);"
                                            [routerLink]="['/tnc']" translate>btn-terms-n-conditions</a>
                                    </li>
                                    <li class="list-inline-item" *ngIf="is_customer">
                                        <a class="text-muted"
                                            [routerLink]="['/contact']" routerLinkActive="router-link-active" 
                                             translate>btn-contact-us</a>
                                    </li>
                                    <li class="list-inline-item" *ngIf="!is_customer">
                                        <a class="text-muted"
                                            href="https://bookit24.zendesk.com/hc/no/requests/new"
                                            target="_blank" translate>btn-contact-us</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a class="text-primary"
                                            href="https://www.bookit24.no"
                                            target="_blank" translate>https://www.bookit24.no</a>
                                    </li>

                                </ul>

                            </div>
                        </small>
                    </nav>
                    <!--end of row-->
                    <div class="row">
                        <div class="col">
                            <small>&copy; 2021 Bookit24. All Rights Reserved.</small>
                        </div>
                        <!--end of col-->
                    </div>
                    <!--end of row-->
                </div>
                <!--end of container-->
            </footer>

        </div>

    </body>

</html>

<div class="footer pl-4 pr-4 bg-dark" >
    <div class="container">
        <nav *ngIf="auth.isLoggedIn() && show_cookie_banner" class="row pt-2 pb-2">
        <div class="col-12 text-light mb-2 " translate="">
            cookie-lbl
        </div>

        <div class="col-12">
            <span (click)="onClick_Cookie(1)" class="btn btn-primary float-left"
                translate="">cookie-accept</span>
            <span [routerLink]="['/privacy']" class="btn btn-outline-primary
                 float-left ml-2" translate="">cookie-view-privacy</span>
            <span (click)="onClick_Cookie(0)" class="btn btn-outline-secondary
                text-light float-right" translate="">cookie-deny</span></div>
    </nav>
</div>
</div>

<notifier-container></notifier-container>
