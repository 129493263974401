<!doctype html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <title >Bookit24 - SU Dashboard</title>

    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500" rel="stylesheet">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.9.0/fullcalendar.min.css">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
        crossorigin="anonymous">

</head>
<body>

    <div class="nav-container">
        <div class="bg-dark navbar-dark shadow" data-sticky="top">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg pl-0 pr-0" >
                    <a href="javascript:void(0);" class="navbar-brand" [routerLink]="['/']">
                        <img src="/assets/img/bookit24_whitelogo.png" class="responsive responsive-fluid" width="110">
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="icon-menu h4"></i>
                    </button>
                    <div class="collapse navbar-collapse justify-content-between" id="navbarNav" >
                        <ul class="navbar-nav" >
                        </ul>
                        <ul class="navbar-nav" >
                            <li class="nav-item dropdown" *ngIf="auth.isLoggedIn() == false">
                                <a href="javascript:void(0);" [routerLink]="['/signin']" class="nav-link" translate >btn-signin</a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0);" [routerLink]="['/signout']" class="btn btn-sm btn-outline-danger" translate >btn-logout</a>
                            </li>
                        </ul>
                    </div>
                    <!--end nav collapse-->
                </nav>
            </div>
            <!--end of container-->
        </div>
    </div>
    <div class="main-container">
        <section class="space-xs">
            <div class="container-fluid">    
                <div class="row">
                    <div class="col-2">
                        <div class="list-group">
                            <a href="javascript:void(0);" [routerLink]="['/su/dashboard']" class="list-group-item list-group-item-action">
                            <i class="fas fa-tachometer-alt"></i> Dashboard
                            </a>
                            <a href="javascript:void(0);" [routerLink]="['/su/business']" class="list-group-item list-group-item-action"><i class="fas fa-briefcase"></i> Providers</a>
                            <a href="javascript:void(0);" [routerLink]="['/su/booking']" class="list-group-item list-group-item-action"><i class="fas fa-clock"></i> Bookings</a>
                            <a href="javascript:void(0);" [routerLink]="['/su/payment']" class="list-group-item list-group-item-action"><i class="fas fa-money-check-alt"></i> Payments</a>
                            <a href="javascript:void(0);" [routerLink]="['/su/user']" class="list-group-item list-group-item-action"><i class="fas fa-users"></i> Users</a>
                            <a href="javascript:void(0);" [routerLink]="['/su/log']" class="list-group-item list-group-item-action"><i class="fas fa-file-alt"></i> Logs</a>
                        </div>
                    </div>
                    <div class="col-10">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </section>

        <!--end of section-->
        <footer class="footer-short">
            <div class="container-fluid">
                <hr>
                <nav class="row justify-content-between align-items-center">
                    <small>
                        <div class="col-auto">
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    <a href="javascript:void(0);" [routerLink]="['/privacy']" translate  >btn-privacy-policy</a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="javascript:void(0);" [routerLink]="['/tnc']" translate >btn-terms-n-conditions</a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="javascript:void(0);" [routerLink]="['/faqs']" translate >btn-faqs</a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="https://bookit24.zendesk.com/hc/no/requests/new" target="_blank"  translate >btn-contact-us</a>
                                </li>
                            </ul>
                        </div>
                    </small>
                </nav>
                <!--end of row-->
                <div class="row">
                    <div class="col">
                        <small>&copy; 2021 Bookit24. All Rights Reserved.</small>
                    </div>
                    <!--end of col-->
                </div>
                <!--end of row-->
            </div>
            <!--end of container-->
        </footer>

    </div>

</body>

</html>
<notifier-container></notifier-container>
