import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType, IPayPalTransactionItem } from 'ngx-paypal';
import { AlertService } from '../../../_service/alert.service';
import { ServiceService } from '../../../_service/service.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-booking-create-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.css']
})
export class PayComponent implements OnInit {

  @Output() backBtn = new EventEmitter<object>();
  @Output() success = new EventEmitter<object>();
  @Output() cancel = new EventEmitter<object>();
  @Output() error = new EventEmitter<object>();
  @Input() is_loading: boolean;
  @Input() business: any;
  @Input() services: any;
  @Input() customer: any;
  @Input() total_price = 0;
  @Input() payload: any;

  notes = "";
  sub_total: any;
  tax: any;
  invoice: any;
  description: any;
  is_a_final_go = 0;

  public payPalConfig: any;
  is_show_paypal_btn = false;
  is_show_confirm_btn = false;

  constructor(
    private alertService: AlertService,
    private serviceService: ServiceService,
  ) { }

  ngOnInit() {
  }

  /**
   * 
   */
  initPaypalConfig(payload) {

    this.payload = payload;
    this.total_price = payload.total_price;
    this.invoice = this._getInvoice();
    this._checkService();

  }

  _checkService() {
    this.is_loading = true;
    this.serviceService.validateForBooking(this.payload).subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      if (temp.success) {
        this.is_a_final_go = temp.data.flag;
        this.description = temp.data.desc;
        this.makeForPaypal();
      }
      else {
        this.alertService.error("Service not available at request time.");
      }
    });
  }

  /**
   * 
   */
  makeForPaypal() {
    this._setButton();

    if (this.is_a_final_go == 1 && this.is_show_paypal_btn == true) {
      this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PayPalEnvironment.Production, {
        commit: true,
        client: {
          production: this.business.paypal.client_id
        },
        button: {
          label: 'pay',
          size: 'large',
          shape: 'rect',
          color: 'black',
          tagline: false
        },
        onPaymentComplete: (data, actions) => {
          this._onPaymentSuccess(data);
        },
        onCancel: (data, actions) => {
          this._onPaymentCancel(data);
        },
        onError: (err) => {
          this._onPaymentError(err);
        },
        transactions: [{
          amount: {
            currency: 'NOK',
            total: this.total_price,
            details: {
              subtotal: this.sub_total,
              tax: this.tax,
              shipping: 0,
              handling_fee: 0,
              shipping_discount: 0,
              insurance: 0
            }
          },
          invoice_number: this.invoice,
          item_list: {
            items: [{
              name: this.description,
              currency: "NOK",
              price: this.sub_total,
              quantity: 1,
              description: this.description,
              tax: this.tax
            }
            ]
          }
        }]
      });
    };
  }

  _setButton() {
    if (this.is_a_final_go == 1 && this.payload.is_require_online_payment == true) {
      this.is_show_paypal_btn = true;
    }
    else {
      this.is_show_confirm_btn = true;
    }
  }
  
  _getInvoice() {
    this.sub_total = this.total_price - (Number(this.total_price) * (Number(this.business.vat) / 100)),
      this.tax = Number(this.total_price) * (Number(this.business.vat) / 100);
    let inv = this.payload.selected_customer + "." + this.business.id + "." + this.payload.calendar_id + "." + this.payload.selected_services_id + "." + this.total_price + "." + moment().unix();
    inv = inv.replace(/,/g, '').replace(/ /g, '');
    return inv;
  }

  /**
  * 
  * @param event 
  */
  onClick_BackBtnOnPay(event) {
    this.is_show_paypal_btn = false;
    this.is_show_confirm_btn = false;
    this.backBtn.emit(event);
  }

  onClick_Confirm() {
    this.is_loading = true;
    this.is_show_paypal_btn = false;
    this.is_show_confirm_btn = false;
    this.payload.payment_token = false;
    this.payload.notes = this.notes;
    this.success.emit(this.payload);

  }
  /**
   * 
   */
  _onPaymentSuccess(data) {

    this.is_show_paypal_btn = false;
    this.is_show_confirm_btn = false;
    this.payload.payment_token = data;
    this.payload.notes = this.notes;
    this.success.emit(this.payload);
  }

  /**
   * 
   */
  _onPaymentCancel(data) {
    this.is_show_paypal_btn = false;
    this.is_show_confirm_btn = false;
    this.payload.payment_token = data;
    this.cancel.emit(this.payload);

  }

  /**
   * 
   */
  _onPaymentError(data) {
    this.payload.payment_token = data;
    this.error.emit(this.payload);
  }

}
