import * as moment from 'moment-timezone';
import { Injectable } from "@angular/core";

@Injectable()
export class DateHelper {

	constructor() { }

	public norway_time_format = "HH:mm" 
	public norway_date_format = "DD.MM.YYYY" 
	public norway_datetime_format = "DD.MM.YYYY HH:mm" 

	/**
	 * time.date, datetime
	 * 
	 * @param time 
	 * @param type 
	 */
	formatForNorway(ts, type = 'datetime') {

		if (type == "" || type == undefined || type == null) {
			type = ' datetime';
		}
		if (type == 'time') {
			return moment.unix(Number(ts)).utc().format("HH:mm");
		}
		if (type == 'date') {
			return moment.unix(Number(ts)).utc().format("DD.MM.YYYY");
		}
		if (type == 'datetime') {
			return moment.unix(Number(ts)).utc().format("DD.MM.YYYY HH:mm");
		}

	}

	/**
	 * 
	 * @param y 
	 * @param m 
	 * @param d 
	 */
	makeDate(y,m,d){
		m = moment().utc().year(y).month(m).date(d).hour(0).minute(0).second(0).unix();
		return m;
	}
}
