<div class="card card-sm">
    <div class="card-header bg-secondary d-flex justify-content-between align-items-center">
        <span *ngIf="is_loading == false" >{{title}}</span> 
        <span class="" *ngIf="is_loading == true" translate>
            <img src="assets/img/ajax-loader-dark-gray.gif" translate> 
            txt-loading
        </span>
    </div>
    <div class="card-body">
        <div *ngIf="is_date_selected === false" class="alert alert-danger" translate >
            txt-select-a-date</div>

        <div class="alert alert-primary" *ngIf="is_date_selected === true" translate >
            <span translate="">txt-how-calendar-works-information</span>
        <span *ngIf="is_date_selected === true && (cal_service_type == '1' || cal_service_type == '2' )" translate >
            txt-example-0000-0000-to-make-non-working-day-OR-0000-2400-to-make-full-working-day</span>
        </div>
        <table class="table table-bordered table-sm " *ngIf="is_date_selected === true">
            <thead class="bg-secondary">
                <tr>
                    <th width="5%" scope="col" class="text-center" translate >lbl-day</th>
                    <th width="5%" scope="col-1" class="text-center" translate >lbl-working</th>
                    <th width="20%" scope="col" *ngIf="cal_service_type == '1' || cal_service_type == '2' " class="text-center" translate >lbl-hours</th>
                    <th width="20%" scope="col" *ngIf="cal_service_type == '1' || cal_service_type == '2' " class="text-center" translate >lbl-breaks</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let day of weekdays; let index = index; trackBy:trackByIndex;" >
                    <td class="text-center" style="vertical-align: middle;" >{{day.name}}</td>
                    <td class="text-center" style="vertical-align: middle;" >
                        <span class="custom-control mb-0 text-center custom-checkbox custom-checkbox-switch">
                            <input type="hidden" [(ngModel)]="weekdays[index].is_working_day">
                            <input type="checkbox" class="custom-control-input" 
                            [checked]="weekdays[index].is_working_day === boolean_status[0].value" 
                            (change)="$event.target.checked? (weekdays[index].is_working_day = boolean_status[0].value) :
                                (weekdays[index].is_working_day = boolean_status[1].value);"
                            id="lbl{{index}}">
                            <label class="custom-control-label" for="lbl{{index}}" >&nbsp;</label>
                        </span>
                    </td>
                    <td class="text-center"   *ngIf="cal_service_type == '1' || cal_service_type == '2' " >
                        <div class="" >
                            <div class="col-12">
                                <select class="form-control form-control-sm" [(ngModel)]="weekdays[index].starts" >
                                    <option *ngFor="let slot of slots" [value]="slot">{{slot}}</option>
                                </select>
                            </div>
                            <div class="col-12 mt-1">
                                <select class="form-control form-control-sm" [(ngModel)]="weekdays[index].ends" >
                                    <option *ngFor="let slot of slots" [value]="slot">{{slot}}</option>
                                </select>
                            </div>
                        </div>
                    </td>
                    <td class="text-center"   *ngIf="cal_service_type == '1' || cal_service_type == '2' ">
                        <div class="" >
                            <div class="col-12">
                                <select class="form-control form-control-sm" [(ngModel)]="start_break[index]" >
                                    <option *ngFor="let slot of slots" [value]="slot">{{slot}}</option>
                                </select>
                            </div>
                            <div class="col-12 mt-1">
                                <select class="form-control form-control-sm" [(ngModel)]="end_break[index]" >
                                    <option *ngFor="let slot of slots" [value]="slot">{{slot}}</option>
                                </select>
                            </div>
                            <div class="col-12 mt-1">
                                <button class="btn btn-sm btn-primary" (click)="onClick_AddBreaks(index)" translate >btn-add</button>
                            </div>
                            <!-- WARNING - if we change h5 below, we must change in controller as well-->
                            <small class="breaks_list_{{index}}"></small>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="row form-group" *ngIf="is_date_selected === true">                                    
            <div class="col" >
                <div class="custom-control custom-checkbox custom-checkbox-switch">
                    <input type="hidden" [(ngModel)]="follow_this_for_future_weeks">
                    <input type="checkbox" class="custom-control-input" 
                    [checked]="follow_this_for_future_weeks === boolean_status[0].value" 
                    (change)="$event.target.checked? (follow_this_for_future_weeks = boolean_status[0].value) :
                        (follow_this_for_future_weeks = boolean_status[1].value);"
                    id="follow_this_for_future_weeks">
                    <label class="custom-control-label" for="follow_this_for_future_weeks"  translate >lbl-follow-these-schedule-for-future-as-well</label>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer bg-light" *ngIf="is_date_selected === true">   
        <a href="javascript:void(0);" (click)="onClick_BulkEdit()" class="float-right title-decorative" translate >btn-bulk-edit</a>
        <p *ngIf="is_bulk_editing==true" class="text text-danger" translate >
            txt-this-will-overwrite-existing-schedule-for-below-selected-calendars-on-selected-dates
        </p>
        <div class="row col-12" *ngIf="is_bulk_editing==true">
            <div class="col-4" *ngFor="let cal of all_calendars" >
                <div class="custom-control custom-checkbox custom-checkbox-switch">
                    <input type="hidden" [(ngModel)]="bulk_cal_stack[cal.id]">
                    <input type="checkbox" class="custom-control-input" 
                    (change)="$event.target.checked? (bulk_cal_stack[cal.id] = boolean_status[0].value) :
                        (bulk_cal_stack[cal.id] = boolean_status[1].value);"
                        id="bulk_cal_{{cal.id}}">
                    <label class="custom-control-label text-muted" for="bulk_cal_{{cal.id}}" >{{cal.name}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer bg-light" *ngIf="is_date_selected === true">   
       <button *ngIf="is_loading" class="btn btn-lg text text-muted btn-light" translate> 
            <img src="assets/img/ajax-loader-dark-gray.gif" translate > txt-loading
        </button>                                        
    
        <button *ngIf="!is_loading"   class=" btn btn-lg btn-primary" (click)="onClick_Save()"  translate >btn-save</button>                          
    </div>
</div>

