import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 *
 */
var SupportService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function SupportService(http) {
        this.http = http;
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    SupportService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     * @param temp
     * @param _p
     */
    SupportService.prototype._append = function (temp, _p) {
        Object.keys(_p).forEach(function (k) {
            var t = temp.append(k, _p[k]);
            temp = t;
        });
        return temp;
    };
    /**
     *
     */
    SupportService.prototype.forgotPassord = function (_p) {
        var body = this._getBody().append("email", _p.email).append("domain", _p.domain).append("subdomain", _p.subdomain);
        return this.http.post(environment.server + "/application/support/forgot-password", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    SupportService.prototype.findResetPasswordToken = function (_p) {
        var body = this._getBody().append("token", _p.token).append("domain", _p.domain);
        return this.http.post(environment.server + "/application/support/find-reset-password-token", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    SupportService.prototype.resetPassword = function (_p) {
        var body = this._getBody().append("password", _p.password).append("token", _p.token);
        return this.http.post(environment.server + "/application/support/reset-password", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    SupportService.prototype.cookie = function (_p) {
        var temp = this._getBody();
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/application/support/cookie", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    SupportService.prototype.checkCookieBanner = function (_p) {
        var temp = this._getBody();
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/application/support/check-cookie-banner", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    SupportService.prototype.contact = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/application/support/contact", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    SupportService.prototype.plans = function (_p) {
        var temp = this._getBody();
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/application/support/plans", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    SupportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SupportService_Factory() { return new SupportService(i0.ɵɵinject(i1.HttpClient)); }, token: SupportService, providedIn: "root" });
    return SupportService;
}());
export { SupportService };
