<nav aria-label="breadcrumb" role="navigation" class="bg-primary text-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);" translate>txt-profile</a>
                    </li>
                    <li class="breadcrumb-item active" *ngIf="is_loading ==
                        true" aria-current="page" translate>
                        <img src="assets/img/ajax-loader-blue.gif"> txt-loading
                    </li>
                    <li class="breadcrumb-item active" aria-current="page"
                        *ngIf="is_loading == false">{{user.first_name}}
                        {{user.last_name}}</li>
                </ol>
            </div>
        </div>
    </div>
</nav>
<section class="space-sm">
    <div class="container space pb-0 bg-wh">
        <div class="card">
            <div class="card-body">
                <div class="row justify-content-center" *ngIf="is_loading ==
                    true">
                    <h3 class="title-decorative" translate>
                        <img src="assets/img/ajax-loader-light-gray.gif">
                        txt-loading
                    </h3>
                </div>
                <div class="row">
                    <div class="col-lg-6 mb-4 mb-sm-0 text-center">
                        <img *ngIf="user.image != null && user.image != ''"alt="Image"
                            src="{{user.image}}" class="rounded img-fluid"
                            width="200">
                        <img *ngIf="user.image == null || user.image == '' ||
                            user.image != undefined"alt="Image"
                            src="assets/img/user.png" class="rounded img-fluid"
                            width="200">
                    </div>
                    <div class="col-lg-6 d-flex flex-column
                        justify-content-between mr-auto ml-auto">
                        <div>
                            <h1 class="mb-2">{{user.first_name}}
                                {{user.last_name}}</h1>
                            <h3 class="lead">
                                <a href="mailto@{{user.username}}">{{user.username}}</a>
                            </h3>
                            <h3 class="">{{user.country_mobile_code}}
                                {{user.mobile}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer bg-light">
                <div class="row text-center">
                    <div class="col">
                        <div class="title-decorative" translate>heading-bookings-done</div>
                        <h3 class="text text-primary">{{total_bookings}}</h3>
                    </div>
                    <div class="col">
                        <div class="title-decorative" translate>heading-revenue-generated</div>
                        <h3 class="text text-primary">{{total_revenue}} NOK</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <!--end of col-->
            <div class="col-12 col-md-3">
                <div class="card card-sm">
                    <ul class="list-group">
                        <li class="list-group-item bg-light">
                            <button class="btn btn-block btn-link text
                                text-muted" (click)="onClick_Bookings()"
                                translate>
                                btn-bookings
                            </button>
                        </li>
                        <li class="list-group-item bg-light">
                            <button class="btn btn-block btn-link text
                                text-muted" (click)="onClick_Email()" translate>btn-email</button>
                        </li>
                        <li class="list-group-item bg-light" *ngIf="is_customer
                            == false">
                            <button class="btn btn-block btn-link text
                                text-muted" (click)="onClick_SMS()" translate>
                                btn-sms
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="card card-sm" *ngIf="is_customer == false">
                    <ul class="list-group">
                        <li class="list-group-item bg-light">
                            <button class="btn btn-block btn-link text
                                text-muted" (click)="onClick_EmailHistory()"
                                translate>btn-email-history</button>
                        </li>
                        <li class="list-group-item bg-light">
                            <button class="btn btn-block btn-link text
                                text-muted" (click)="onClick_SMSHistory()"
                                translate>
                                btn-sms-history
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="card card-sm" *ngIf="is_customer == false">
                    <ul class="list-group">
                        <li class="list-group-item bg-light">
                            <button class="btn btn-block btn-link text
                                text-danger" (click)="onClick_TryDelete()"
                                translate>
                                btn-delete-customer
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-9">
                <div class="card card-sm" *ngIf="is_on_sms && is_customer ==
                    false">
                    <div class="card-header bg-secondary d-flex
                        justify-content-between align-items-center">
                        <div>
                            <h6 translate>heading-send-sms</h6>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row form-group">
                            <div class="col">
                                <label translate>txt-max-charactor-140</label><br>
                                <input class="form-control form-control-lg" maxlength="140"
                                    type="text" [(ngModel)]="sms" placeholder=""
                                    />
                                <div>
                                    <small class="text text-muted" translate>
                                        txt-sending-an-sms-will-use-credits-from-your-account
                                    </small>
                                    <small translate>
                                        txt-total-credits-left-are</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-light">
                        <button *ngIf="is_loading == true" class="btn btn-lg
                            text text-muted btn-light" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif"
                                translate> txt-loading
                        </button>
                        <button class="btn btn-lg btn-primary" *ngIf="is_loading
                            == false" (click)="onClick_SendSMS()" translate>btn-send</button>
                    </div>
                </div>
                <div class="card card-sm" *ngIf="is_on_email">
                    <div class="card-header bg-secondary d-flex
                        justify-content-between align-items-center">
                        <div>
                            <h6 translate>heading-send-email</h6>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row form-group">
                            <div class="col-12"><button class="text text-muted
                                    btn btn-sm btn-light"
                                    (click)="onClick_SendWelcomeEmail()"
                                    translate>
                                    btn-click-here-to-resent-welcome-email</button></div>
                        </div>

                        <div class="row form-group">
                            <div class="col">   

                                <label>{{email.length}}/5000
                                </label><br>

                                <textarea [(ngModel)]="email" class="col
                                    form-control" rows="10" maxlength='5000'></textarea>
                                <!-- 
                                <p-editor class="form-control form-control-lg" [(ngModel)]="email" [style]="{'height':'320px'}">
                                    <p-header>
                                        <span class="ql-formats">
                                            <button class="ql-bold" aria-label="Bold"></button>
                                            <button class="ql-italic" aria-label="Italic"></button>
                                            <button class="ql-underline" aria-label="Underline"></button>
                                        </span>
                                    </p-header>
                                </p-editor>
                                -->
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-light">
                        <button *ngIf="is_loading == true" class="btn btn-lg
                            text text-muted btn-light" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif"
                                translate> txt-loading
                        </button>

                        <button class="btn btn-lg btn-primary" *ngIf="is_loading
                            == false" (click)="onClick_SendEmail()" translate>btn-send</button>
                    </div>
                </div>
                <div class="" *ngIf="is_on_email">
                    <div class="title-decorative mb-2 pb-0 mt-0 pt-0" translate>btn-preview</div>
                    <div class="row form-group">
                        <div class="col">
                            <span *ngIf="is_customer == false"><span translate>txt-dear</span>
                                {{user.first_name}} {{user.last_name}},</span>
                            <span *ngIf="is_customer == true" translate>txt-dear-bookit-support</span>
                            <br><br>
                            <small class="text text-muted" *ngIf="email.length
                            <1 || email == ''">
                                <span translate>txt-typein-your-email-content-above-it-will-preview-here</span>
                                <span *ngIf="is_customer == false" translate>
                                    txt-you-can-include-html-code-as-well
                                </span>
                            </small>
                            <p [innerHTML]="email"> </p>
                            <br><br>
                            <span translate>txt-thank-you</span><br>
                            <span *ngIf="is_customer == true">{{user.first_name}}.</span>
                        </div>
                    </div>
                </div>
                <div class="" *ngIf="is_on_booking">
                    <div class="row">
                        <div class="col">
                            <div class="title-decorative mb-0 mt-0 pt-0 pb-0"
                                translate>txt-click-on-a-booking-to-see-details</div>
                            <ng2-smart-table class="table-responsive"
                                (userRowSelect)="onClick_Booking($event)"
                                [settings]="booking_table_settings"
                                [source]="event_list"></ng2-smart-table>
                        </div>
                    </div>
                </div>
                <div class="" *ngIf="is_on_email_history">
                    <div class="row">
                        <div class="col">
                            <ng2-smart-table class="table-responsive"
                                [settings]="email_table_settings"
                                [source]="email_history_list"></ng2-smart-table>
                        </div>
                    </div>
                </div>
                <div class="" *ngIf="is_on_sms_history">
                    <div class="row">
                        <div class="col">
                            <ng2-smart-table class="table-responsive"
                                [settings]="sms_table_settings"
                                [source]="sms_history_list"></ng2-smart-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<ngx-smart-modal identifier="cust_id">
    <h6 class="h4 mb-2 text text-danger" translate>heading-are-you-sure</h6>
    <hr class="mt-2 mb-2">
    <div class="h6 alert"translate="">
        txt-warning-this-may-delete-or-disabled-some-data-related-to-this-item
    </div>
    <div class="fixed-bottom p-2 border-top">
        <button (click)="onClick_ModalClose();" class="btn btn-lg btn-secondary
            float-left" translate="">btn-cancel</button>
        <button (click)="onClick_Delete();" class="btn btn-lg btn-danger
            float-right" translate="">btn-submit</button>
        <!--   <button (click)="onClick_Delete();deleteModal.close()"  class="btn btn-md btn-danger float-right" >Confirm !</button>
  -->
    </div>
</ngx-smart-modal>