<div>
  <section class="height-20 bg-white flush-with-above">
      <div class="container">
          <div class="row justify-content-between align-items-center">
              <div class="col-12 col-md-6 mb-2">
                  <img alt="Image" src="assets/img/404.png" class="w-100">
              </div>
              <div class="col-12 col-md-6 col-lg-5 mb-4 text-center text-md-left">
                  <h1 class="display-5">{{msg_1}}</h1>
                  <hr>
                  <h2 class="lead">{{msg_2}}</h2>
              </div>
          </div>
      </div>
  </section>
</div>