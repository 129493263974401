<!doctype html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500" rel="stylesheet">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
        integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous">

</head>

<body>
    <div class="main-container">
        <section class="space-sm">
            <div class="container align-self-start">
                <div class="row mb-2">
                    <div class="col text-center">
                        <a href="javascript:void(0);">
                            <img src="/assets/img/bookit24_black.png" class="responsive responsive-fluid" width="180">
                        </a>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 col-lg-7">
                        <div class="card card-sm shadow-sm border rounded text-center">
                            <div class="card-header  bg-light">
                                <h3 class="title-decorative h2" translate>heading-signin</h3>
                            </div>
                            <div class="card-body">
                                <div class="row no-gutters justify-content-center">
                                    <div class="text-left col-lg-12">
                                        <div class="form-group">
                                            <label for="email" translate>lbl-email</label>
                                            <input [(ngModel)]="email" autocomplete="off" class="form-control form-control-lg"
                                                (keyup.enter)="onSubmit_Signin()"
                                                placeholder="{{ 'lbl-email' | translate }}" />
                                        </div>
                                        <div class="form-group">
                                            <label for="password" translate>lbl-password</label>
                                            <input [(ngModel)]="password" autocomplete="off" class="form-control form-control-lg"
                                                type="password" (keyup.enter)="onSubmit_Signin()"
                                                placeholder="{{ 'lbl-password' | translate }}" />
                                        </div>
                                        <div>
                                            <div class="custom-control custom-checkbox align-items-center">
                                                <input type="checkbox" autocomplete="off" class="custom-control-input" value="1"
                                                    name="remember-me" checked id="check-remember"
                                                    (keyup.enter)="onSubmit_Signin()">
                                                <label class="custom-control-label text-muted" for="check-remember"
                                                    translate>lbl-remember-me</label>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <span *ngIf="is_loading == true"  
                                                class="btn btn-light btn-lg btn-muted" translate><img src="assets/img/ajax-loader-light-gray.gif" 
                                                translate > txt-loading</span>                                  
                                            <button (click)="onSubmit_Signin()" *ngIf="is_loading == false" (keyup.enter)="onSubmit_Signin()"
                                                class="btn btn-lg btn-primary" translate>btn-signin</button>
                                            <span class="text-small float-right"><a (click)="onClick_ForgotPassword()"
                                                    href="javascript:void(0);" translate>txt-forgot-password</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end of row-->
                            <div class="card-footer bg-success text-center"
                                *ngIf="business.enable_internal_booking != '1' && !url.isLandingSubdomain(url.getSubdomain())">
                                <span class="text-small text-center"><a href="javascript:void(0);"
                                        (click)="onClick_Signup()" class="btn btn-lg active btn-success"
                                        translate>btn-as-a-customer</a>
                                </span>
                            </div>
                            <div class="card-footer bg-success text-center"
                                *ngIf="url.isLandingSubdomain(url.getSubdomain())">
                                <span class="text-small text-center"><a href="javascript:void(0);"
                                        (click)="onClick_Signup()" class="btn btn-lg active btn-success"
                                        translate>btn-as-a-business</a>
                                </span>
                            </div>
                        </div>
                        <small>
                            <a href="javascript:void(0);" (click)="onClick_ChangeLanguage('en')"
                                class="text text-muted">English</a>&nbsp;
                            <a href="javascript:void(0);" (click)="onClick_ChangeLanguage('nb')"
                                class="text text-muted">Norwegian</a>
                        </small>
                        <small class="float-right"><a class="text text-muted"
                                href="https://{{subdomain}}.{{url.getJustDomainName()}}">https://{{subdomain}}.{{url.getJustDomainName()}}</a></small>
                    </div>
                    <!--end of col-->
                </div>
                <!--end of row-->
            </div>
            <!--end of container-->
        </section>
        <!--end of section-->
    </div>


    <footer class="footer-short">
        <div class="container">
            <hr>
            <nav class="row justify-content-between align-items-center">
                <small>
                    <div class="col-auto">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a class="text-muted" href="javascript:void(0);" [routerLink]="['/privacy']"
                                    translate>btn-privacy-policy</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" href="javascript:void(0);" [routerLink]="['/tnc']"
                                    translate>btn-terms-n-conditions</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" href="https://bookit24.zendesk.com/hc/no/requests/new" target="_blank"  translate>btn-contact-us</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-primary" href="https://www.bookit24.no" target="_blank"  translate>https://www.bookit24.no</a>
                            </li>

                        </ul>
                    </div>
                </small>
            </nav>
            <!--end of row-->
            <div class="row">
                <div class="col">
                    <small>&copy; 2021 Bookit24. All Rights Reserved.</small>
                </div>
                <!--end of col-->
            </div>
            <!--end of row-->
        </div>
        <!--end of container-->
    </footer>
</body>

</html>