<div class="container">
    <div class="row">
        <!--end of col-->
        <div class="col-12 col-md-3">
            <div class="card card-sm">
                <ul class="list-group" *ngIf="!is_schedule">
                    <li class="list-group-item bg-light">
                        <button class="btn  btn-block btn-link text-muted" (click)="onClick_AddCalendar()"
                            translate>lbl-add-calendar</button>
                    </li>

                    <li class="list-group-item" id="cal{{cal.id}}" *ngFor="let cal of all_calendars"
                        (click)="onClick_Calendar($event,cal.name,cal.id)"><a href="javascript:void(0);"
                            id="cal{{cal.id}}">{{cal.name}}</a>
                            <div>
                                <small *ngIf="cal.is_enabled=='1'" class="badge badge-light border text-muted">active</small>
                                <small *ngIf="cal.is_enabled=='0'" class="badge badge-secondary">inactive</small>
                            </div>
        
                    <!-- <div>
                        <small *ngIf="cal.services.length < 1" class="text text-danger">{{cal.services.length}} <span translate>lbl-service</span></small>     
                        <small *ngIf="cal.services.length > 0" class="text text-muted">{{cal.services.length}} <span translate>lbl-service</span></small>     
                    </div>
                    -->
                    
                    </li>
                </ul>
                <div *ngIf="is_schedule" class="text text-center">
                    <ul class="list-group" *ngIf="is_schedule">
                        <li class="list-group-item" (click)="onClick_BackToEditCalendar()" translate>
                            <a href="javascript:void(0);" translate><i style="padding-top: 4px;"
                                    class="float-left icon-chevron-left" translate></i>{{selected_calendar.name}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <p-calendar *ngIf="is_schedule" (onSelect)="onClick_DatetimeChange($event)" [(ngModel)]="datetime"
                [utc]=true [locale]="no" [inline]="true"></p-calendar>

        </div>
        <!--end of col-->
        <div class="col-12 col-md-9" *ngIf="is_schedule">
            <app-config-calendar-schedule [dt]="changeDt" [slot]="selected_calendar.slot"
                [cal_service_type]="selected_calendar.cal_service_type" [name]="selected_calendar.name"
                [id]="selected_calendar.id"></app-config-calendar-schedule>
        </div>
        <div class="col-12 col-md-9" *ngIf="!is_schedule">
            <div class="card card-sm " >
                <div class="card-header bg-secondary d-flex justify-content-between align-items-center">
                    <span class="float-left">
                        <span *ngIf="is_loading == false" translate="">btn-calendar</span>
                        <span class="" *ngIf="is_loading == true" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif" translate>
                            txt-loading
                        </span>
                    </span>
                    <a href="javascript:void(0);" *ngIf="is_calendar_selected == true" class=" btn btn-sm btn-primary pt-0 pb-0 float-right"
                        (click)="onClick_Schedule()" translate>lbl-schedule-calendar</a>
                </div>
                <div class="card-header bg-light" style="padding-top:18px"
                    *ngIf="cal_service_type == '1' || cal_service_type == '2' ">
                    <div class="row col-12">
                        <div class="col">
                            <div class="custom-control custom-radio">
                                <input id="slot-15" type="radio" class="custom-control-input" [value]="15"
                                    [(ngModel)]="slot">
                                <label class="custom-control-label" for="slot-15" translate>lbl-15-min-slot</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-radio">
                                <input id="slot-30" type="radio" class="custom-control-input" [value]="30"
                                    [(ngModel)]="slot">
                                <label class="custom-control-label" for="slot-30" translate>lbl-30-min-slot</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-radio">
                                <input id="slot-60" type="radio" class="custom-control-input" [value]="60"
                                    [(ngModel)]="slot">
                                <label class="custom-control-label" for="slot-60" translate>lbl-60-min-slot</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body" *ngIf="is_calendar_selected == true || is_add_new_calendar == true">
                    <div class="alert alert-info" *ngIf="selected_calendar?.is_scheduled_at_least_once == '0'">
                        <div translate="">txt-please-schedule-calendar-days-to-allow-bookings-on-this-calendar</div>
                    </div>
                    <div class="alert alert-danger" *ngIf="plan_stats?.no_of_calendars != 999">
                        <div ><span translate="">lbl-max-calendar-you-can-enable</span><strong>: {{plan_stats?.no_of_calendars}}</strong></div>
                    </div>

                    <div class="row form-group">
                        <div class="col">
                            <input autocomplete="off"  class="form-control form-control-lg" type="text" [(ngModel)]="name"
                                placeholder="{{ 'lbl-name' | translate }}" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <input  autocomplete="off" class="form-control form-control-lg" type="text" [(ngModel)]="description"
                                placeholder="{{ 'lbl-description' | translate }}" />
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-light" style="padding-top:18px ">
                    <div class="row ">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="custom-control custom-checkbox custom-checkbox-switch">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="is_enabled"
                                    id="is_enabled">
                                <label class="custom-control-label" for="is_enabled"
                                    translate>lbl-enable-calendar</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="custom-control custom-checkbox custom-checkbox-switch">
                                <input type="checkbox" class="custom-control-input"
                                    [(ngModel)]="is_available_for_customers" id="is_available_for_customers">
                                <label class="custom-control-label" for="is_available_for_customers"
                                    translate>lbl-is-available-for-customer</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6" >
                            <div class="custom-control custom-checkbox custom-checkbox-switch">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="is_a_resource_calendar"
                                    id="is_a_resource_calendar">
                                <label class="custom-control-label" for="is_a_resource_calendar"
                                    translate>lbl-is-a-resource-calendar</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6" [hidden]="selected_calendar.cal_service_type != '3' && selected_calendar.cal_service_type != '2'">
                            <div class="custom-control custom-checkbox custom-checkbox-switch">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="is_enable_exivo_lock"
                                    id="is_enable_exivo_lock">
                                <label class="custom-control-label" for="is_enable_exivo_lock"
                                    translate>lbl-is-enable-exivo-lock</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="selected_calendar.cal_service_type != '3' && selected_calendar.cal_service_type != '2'">
                    <div class="card-footer" style="padding-top:18px " [hidden]="!is_enable_exivo_lock">
                        <div class="row">
                            <div class="col-12">
                                <div class="title-decorative" translate>heading-exivo-lock-settings
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <small class="float-right"
                                            translate>txt-if-using-exivo-you-must-also-enabled-it-from-misc-tab</small>
                                    </div>
                                </div>

                                <div class="card bg-light">
                                    <div class="card-body">
                                        <div class="" translate>lbl-exivo-component</div>
                                        <div class="row form-group">
                                            <div class="col-md-10 col-sm-12 col-lg-10">
                                                <input  autocomplete="off" class="form-control form-control-md" type="text" maxlength="200"
                                                    [(ngModel)]="exivo_component" />
                                            </div>
                                            <div class="col-md-2 col-sm-12 col-lg-2">
                                                <button class="btn btn-md btn-secondary" translate
                                                    (click)="onClick_AddComponent()">btn-add</button></div>
                                        </div>
                                        <div class="component_list"></div>
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-lg-12">
                                                <small class="text text-danger" translate>
                                                    txt-simply-click-component-to-delete-dont-forget-to-click-save-button-below
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="card bg-light">
                                    <div class="card-body">
                                        <div class="row ">
                                            <div class="col-sm-12 col-md-6 col-lg-6">
                                                <div class="custom-control custom-checkbox custom-checkbox-switch">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [(ngModel)]="is_enable_exivo_sms" id="is_enable_exivo_sms">
                                                    <label class="custom-control-label" for="is_enable_exivo_sms"
                                                        translate>lbl-enable-sms-code</label>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="row form-group" [hidden]="!is_enable_exivo_sms">
                                            <div class="col-md-12 col-sm-12 col-lg-12 ">
                                                <label class="" translate>lbl-exivo-component-sms</label>
                                                    <input  autocomplete="off" class="form-control form-control-md" type="text" maxlength="2000"
                                            [(ngModel)]="exivo_component_sms" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card bg-light">
                                    <div class="card-body">

                                        <div class="row ">
                                            <div class="col-sm-12 col-md-6 col-lg-6">
                                                <div class="custom-control custom-checkbox custom-checkbox-switch">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [(ngModel)]="is_enable_medium" id="is_enable_medium">
                                                    <label class="custom-control-label" for="is_enable_medium"
                                                        translate>lbl-enable-medium</label>
                                                </div>
                                            </div>
                                        </div>
            
                                        <div class="" [hidden]="!is_enable_medium">

                                            <div translate>lbl-exivo-access-group</div>
                                            <div class="row form-group">
                                                <div class="col-12">
                                                    <input  autocomplete="off" class="form-control" type="text" maxlength="200"
                                                        [(ngModel)]="exivo_access_group" />
                                                </div>
                                            </div>

                                            <div class="" translate>lbl-exivo-medium</div>                                                    
                                            <div class="row form-group">
                                                <div class="col-md-10 col-sm-12 col-lg-10">
                                                    <input  autocomplete="off" class="form-control form-control-md" type="text" maxlength="200"
                                                        [(ngModel)]="exivo_medium" />
                                                </div>
                                                <div class="col-md-2 col-sm-12 col-lg-2">
                                                    <button class="  btn btn-md btn-secondary" (click)="onClick_AddMedium()"
                                                        translate>btn-add</button></div>
                                            </div>
                                            <div class="medium_list"></div>
                                            <small class="text text-danger" translate>
                                                txt-simply-click-medium-to-delete-dont-forget-to-click-save-button-below
                                            </small>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col media flex-wrap mb-2 align-items-center">
                            <img *ngIf="selected_calendar.image != null && selected_calendar.image !=''" alt=""
                                style="background-color:#eee" [src]="selected_calendar.image" width="160"
                                class="rounded img-fluid">
                            <div class="media-body alert">
                                <label class="custom-file mb-2" for="file2">
                                    <input type="file" (change)="selectImage($event)" id="calendar_image"
                                        class="btn btn- btn-sm">
                                    &nbsp;
                                    <button class="btn btn-light text text-danger btn-sm"
                                        (click)="onClick_DeleteImage()" *ngIf="is_image_exists == true"
                                        translate>btn-delete-current-image</button>
                                </label>
                                <div class="text text-muted">
                                    <small translate>lbl-for-best-result-used-x-x-image
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-footer bg-light">
                    <button *ngIf="is_loading == true" class="btn btn-lg text text-muted btn-light" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif" translate> txt-loading
                    </button>
                    <button class=" btn btn-lg btn-primary" *ngIf="is_loading == false" (click)="onClick_Save()"
                        translate>btn-save</button>
                    <button *ngIf="is_calendar_selected == true && is_loading == false"
                        class="btn btn-lg btn-outline-danger float-right" (click)="onClick_TryDelete()"
                        translate>btn-delete</button>
                </div>
            </div>
            <div class="card card-sm" *ngIf="is_calendar_selected == true">
                <div class="card-header bg-secondary d-flex justify-content-between align-items-center">
                    <span *ngIf="is_loading == false" translate>txt-assign-service</span>
                    <span *ngIf="is_loading == true" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif">
                        txt-loading
                    </span>
                </div>
                <div class="">
                    <ul class="list-group">
                        <!-- <li *ngIf="is_enable_exivo_lock" class="list-group-item"><div class="text text-danger" translate>
                            txt-you-have-exivo-enabled-for-this-calendar-note-that-it-will-be-activated-for-all-below-assigned-services</div></li>
                        -->
                        <ng-container *ngFor="let service of all_services;let i = index">
                            <li class="list-group-item" id="ser_li_{{service.id}}"
                            *ngIf="service.service_type!='4'"><span
                                id="ser_span_{{service.id}}">{{service.name}}. <span class="text text-muted">
                                    {{service.price}} NOK.</span>
                                
                                    <span class="float-right custom-control custom-checkbox custom-checkbox-switch">
                                        <input type="checkbox" class="custom-control-input" (change)="onClick_SelectService($event,i,service)"
                                         [checked]="service.title=='btn-assign'"  id="ser{{service.id}}">
                                        <label class="custom-control-label" for="ser{{service.id}}"></label>
                                    </span>
                                
                                    <!-- 
                                    <button id="ser{{service.id}}" class="{{service.class}}"
                                    (click)="onClick_SelectService($event,i,service.service_type)"
                                    translate>{{service.title}}</button>
                                    -->

                                </span> <br> <small class="text text-muted">
                                {{service.duration}} <span translate="">lbl-mins</span></small> 
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!--end of row-->
</div>
<!--end of container-->

<ngx-smart-modal  identifier="cal_id" >
    <h6 class="h4 mb-2 text text-danger" translate>heading-are-you-sure</h6>
    <hr class="row row mt-2 mb-2">
    <div class="h6 alert " translate="">
      txt-warning-this-may-delete-or-disabled-some-data-related-to-this-item
    </div>
    <div class="fixed-bottom p-2 border-top">
    <button  (click)="onClick_ModalClose();" class="btn btn-lg btn-secondary float-left" translate="">btn-cancel</button>
    <button (click)="onClick_Delete();"  class="btn btn-lg btn-danger float-right" translate="">btn-submit</button>
    </div>

    <!--   <button (click)="onClick_Delete();deleteModal.close()"  class="btn btn-md btn-danger float-right" >Confirm !</button>
  -->
  </ngx-smart-modal>