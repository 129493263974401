import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '../../_service/category.service';
import { PaymentService } from '../../_service/payment.service';
import { BusinessService } from '../../_service/business.service';
import { AlertService } from '../../_service/alert.service';
import { PlanHelper } from '../../_helper/plan';
import { AuthHelper } from 'src/app/_helper/auth';
import * as moment from "moment-timezone";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { SupportService } from 'src/app/_service/support.service';


@Component({
  selector: 'app-config-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  /**
   * 
   */
  is_loading = true;
  all_cards = [];
  selected_card = { "id": "", "gateway_name": "", "card_type": "", "card_number": "", "card_name": "" };
  is_on_paypal = false;
  is_on_stripe = false;
  is_on_setting = true;
  is_add_new_card = false;
  is_card_detail = false;


  title = this.translate.instant("lbl-settings");
  card_details = "";
  business = { "main_vendor_for_payment": "", "enable_paypal": "0", "enable_stripe": "0" };
  is_enable_paypal = "0";
  is_enable_stripe = "0";
  name = "";
  number = "";
  exp_month = "";
  exp_year = "";
  cvc = "";
  status = [{ "value": "active" }, { "value": "inactive" }];
  boolean_status = [{ "value": "1" }, { "value": "0" }];
  vendors = [{ "name": "Paypal" }, { "name": "Stripe" }];
  payment_type = "1";

  /**
   * 
   * @param calendarService 
   */
  constructor(
    private categoryService: CategoryService,
    private paymentService: PaymentService,
    public auth: AuthHelper,
    private supportService: SupportService,
    public translate: TranslateService,
    private modal: NgxSmartModalService,
    public planHelper: PlanHelper,
    private alertService: AlertService,
    private router: Router,
    private businessService: BusinessService
  ) {
    if (this.auth.getUser().user_type == 'customer') {
      this.is_card_detail = true;

    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this._getBusiness();
    this._resetPayment();

  }

  /**
   * 
   */
  _reset() {
    this.is_add_new_card = false;
    this.is_on_setting = false;
    this.is_on_paypal = false;
    this.is_on_stripe = false;
    this.is_card_detail = false;

  }

  /**
   * 
   */
  ngOnInit() {

  }

  /**
   * 
   */
  _getBusiness() {
    this.is_loading = true;
    this.businessService.getWhosLoggedIn().subscribe(data => {
      let temp: any;
      temp = data;
      //console.log(JSON.parse(temp.data.plan));
      this.is_enable_paypal = this.business.enable_paypal = temp.data.enable_paypal;
      this.is_enable_stripe = this.business.enable_stripe = temp.data.enable_stripe;
      this.business.main_vendor_for_payment = temp.data.main_vendor_for_payment;
      this.all_cards = temp.data.cards;
      this.is_loading = false;
    });

  }


  /**
   * 
   */
  _resetPayment() {
    this.title = this.translate.instant("lbl-settings");
    this._reset();
    this.is_on_setting = true;
    this.number = "";
    this.exp_month = "";
    this.exp_year = "";
    this.name = "";
    this.cvc = "";

  }

  /**
  * 
  */
  onClick_Paypal() {
    this._reset();
    this.is_on_paypal = true;

  }

  /**
  * 
  */
  onClick_Stripe() {
    this._reset();
    this.is_on_stripe = true;

  }

  /**
  * 
  */
  onClick_Setting() {
    this.title = this.translate.instant("lbl-settings");
    this._reset();
    this.is_on_setting = true;

  }

  /**
   * 
   */
  onClick_Radio() {
    //console.log(this.payment_type);
  }


  /**
   * 
   */
  onClick_DeleteCard() {
    this.is_loading = true;

    this.paymentService.delete(this.selected_card).subscribe(data => {
      let temp: any;
      temp = data;

      this._getBusiness();
      this._resetPayment();
      this.alertService.success(temp.msg);
      this.is_loading = false;

    });
  }


  /**
   * 
   * @param event 
   * @param name 
   * @param id 
   */
  onClick_Card(event, gateway_name, card_number, card_name, card_type, id) {
    this.is_add_new_card = false;
    this.is_card_detail = true;
    this.is_on_paypal = false;
    this.is_on_setting = false;
    this.is_on_stripe = false;

    this.title = card_type + " Card " + card_name;
    this.selected_card.card_name = card_name;
    this.selected_card.gateway_name = gateway_name;
    this.selected_card.card_number = card_number;
    this.selected_card.id = id;
    this.selected_card.card_type = card_type;
    this.card_details = "This is a " + card_type + " card ending ****" + card_number;
  }

  /**
   * 
   */
  onClick_AddCard() {
    this.title = this.translate.instant("btn-add-card");

    this.is_on_setting = false;
    this.is_card_detail = false;
    this.is_on_paypal = false;
    this.is_on_stripe = false;
    this.is_add_new_card = true;
    this._getBusiness();
  }

  /**
   * 
   */
  onClick_SaveCard() {

    if(!this._isValidCardInfo()){
      this.alertService.error(this.translate.instant("alert-error"));
      return false;
    }
    this.is_loading = true;
    let _p = {
      "number": this.number,
      "name": this.name,
      "exp_month": this.exp_month,
      "exp_year": this.exp_year,
      "cvc": this.cvc
    }
    this.number = this.name = this.exp_month = this.exp_year = this.cvc = "";

    if (this.auth.getUser().user_type == 'customer') {
      this.paymentService.saveForCustomer(_p).subscribe(data => {
        let temp: any;
        temp = data;
        this._getBusiness();
        if (temp.success) {
          this.alertService.success(temp.msg);
        }
        else {
          this.alertService.error(temp.msg);
        }
        this.is_loading = false;
      });
    } else {
      this.paymentService.saveForProvider(_p).subscribe(data => {
        let temp: any;
        temp = data;
        this._getBusiness();
        if (temp.success) {
          this.alertService.success(temp.msg);
        }
        else {
          this.alertService.error(temp.msg);

        }
        this.is_loading = false;
      });
    }
  }

  _isValidCardInfo(){
    let flag = true;
    if(this.name == ""){
      flag = false;
    }
    if(this.number == ""){
      flag = false;
    }
    if(this.exp_month == ""){
      flag = false;
    }
    if(this.exp_year == ""){
      flag = false;
    }
    if(this.cvc == ""){
      flag = false;
    }

    return flag;

  }




  /**
   * 
   * @param event 
   * @param name 
   * @param id 
   */
  onClick_SaveSetting() {
    this.is_loading = true;
    let _p = {
      "main_vendor_for_payment": this.business.main_vendor_for_payment,
      "enable_paypal": this.business.enable_paypal,
      "enable_stripe": this.business.enable_stripe
    };
    this.businessService.save(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this._resetPayment();
      this._getBusiness();
      this.alertService.success(temp.msg);
      this.is_loading = false;
    });
  }




}
