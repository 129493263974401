import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModule, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AuthHelper } from '../../../_helper/auth';
import { AlertService } from '../../../_service/alert.service';
import * as moment from 'moment-timezone';

const now = new Date();

@Component({
  selector: 'app-booking-create-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.css']
})
export class AccommodationComponent implements OnInit {

  @Input() current_date: any;
  @Output() selectSlot = new EventEmitter<object>();

  datetime: NgbDateStruct;
  err: any;
  slots: any;
  slots_data: any;
  is_show_error = false;

  max_duration: 0;
  how_far_ahead: 0;
  service: any;
  calendar_name = "";
  calendar_id = "";

  is_customer = true;
  is_guest = false;
  price = 0;
  cleaning_fee = 0;
  duration = 0;
  today: any;
  is_pay_now_ready = false;
  start_datetime: string;
  end_datetime: string;
  start_timestamp: number;
  end_timestamp: number;
  is_ready_to_select_customer = false;
  modal_title = "";
  modal_image = "";
  modal_description = "";
  no: any;

  constructor(
    private router: Router,
    private auth: AuthHelper,
    private modalService: NgbModal,
    private alertService: AlertService
  ) {
    this.today = new Date();

    if (this.auth.isLoggedIn()) {
      if (this.auth.getUser().user_type != 'customer') {
        this.is_customer = false;
      }
    }
    else{
      this.is_guest = true;
      this.is_customer = false;
    }

    this.no = {
      firstDayOfWeek: 1,
      dayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
      dayNamesShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
      dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
      monthNames: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
      today: 'I dag',
      clear: 'Klar'
    };

  }

  ngOnInit() {
  }

  open(modal,item) {
    this.modal_title = item.name;
    this.modal_image = item.image;
    this.modal_description = item.description;
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  /**
   * 
   * @param data 
   */
  generateSlots(data) {
    this.datetime = this.current_date.toDate();

    this.slots_data = data;
    this.slots = [];
    let breaks: any;
    data.data.forEach((cal, index) => {
      // reset few params for service
      this.service = cal.service;
      this.setDefaultsParams();
      breaks = this.findBreaks(cal.breaks);
      this.slots.push(this.findSlot(cal, breaks));
    });

    // for customer we show only one calendar.
    if (this.is_customer || this.is_guest) {
      // uncomment below to show all calendar for this service
      this.slots = [];
      let new_cal = { "id": 0, "name": "Calendar" };
      breaks = this.findBreaks(data._x);
      this.slots.push(this.findSlot(new_cal, breaks));
    }
    if (this.slots.length < 1) {
      this.is_show_error = true;
      this.err = "Service not available for booking. Please check that service is assign to a Calendar and Calendar has been properly scheduled.";
    } else {
      this.is_show_error = false;
      this.err = "";
    }
  }

  /**
   * 
   */
  setDefaultsParams() {
    this.price = this.service.price;
    this.cleaning_fee = this.service.cleaning_fee;
    this.max_duration = this.service.max_duration;
    this.how_far_ahead = this.service.how_far_ahead;
  }

  /**
   * 
   */
  findBreaks(breaks) {
    let br = 0;
    let bk = [];
    if (breaks != null) {
      breaks.forEach(v => {
        bk.push(new Date(Number(v) * 1000));
      });
    }
    //let i,k=0;

    // not sure what below is for
    // or if its even working
    //for (i = this.total_duration ; i < 1 ; i++){
    //  k = bk[0];
    //  bk.push(0,0,i);
   // }
    //**/
    return bk;

  }


  /**
   * 
   */
  findSlot(cal, breaks) {
    let i = 0;
    let stack: any;
    stack = [];
    stack.id = cal.id;
    stack.name = cal.name;
    stack.image = cal.image;
    stack.description = cal.description;
    stack.invalid_dates = breaks;
    return stack;
  }

  /**
   * 
   * @param event 
   */
  onClick_Rental24CalendarDate(cal_id, cal_name) {
    //  this._resetSummary();
    if (this.datetime[cal_id][0] != null && this.datetime[cal_id][1] == null) {
      this._startDateRental24(this.datetime[cal_id][0], cal_id, cal_name);
    }
    if (this.datetime[cal_id][0] != null && this.datetime[cal_id][1] != null) {
      this._endDateRental24(this.datetime[cal_id][1], cal_id, cal_name);
    }

  }

  /**
   * 
   * @param date 
   * @param cal_id 
   */
  _startDateRental24(date, cal_id, cal_name) {
    let ts = moment(date).utcOffset(0, true).unix();
    this._setSelectedStartDateParams(cal_id, ts, cal_name);
  }

  /**
   * 
   * @param date 
   * @param cal_id 
   */
  _endDateRental24(date, cal_id, cal_name) {
    let ts = moment(date).utcOffset(0, true).unix();
    this._setSelectedEndDateParams(cal_id, ts, cal_name);
  }

  /**
   * 
   * @param cal_id 
   * @param min_ts 
   * @param slot_string 
   */
  _setSelectedStartDateParams(cal_id, ts, cal_name) {
    this.calendar_name = cal_name;
    this.calendar_id = cal_id;
    this.start_timestamp = ts;
    this.duration = 0;
    // below offset was + before, i did - recently , not sure + or -, which is correct.
    this.start_datetime = moment.unix(ts).utc().format("LL") + " " + this.service.checkin;
    this.validateStart();
  }

  /**
   * 
   * @param cal_id 
   * @param min_ts 
   * @param slot_string 
   */
  _setSelectedEndDateParams(cal_id, ts, cal_name) {
    this.end_datetime = moment.unix(ts).utc().format("LL") + " " + this.service.checkout;
    this.end_timestamp = ts;
    this.duration = ts - this.start_timestamp;
    this.calendar_id = cal_id;
    this.calendar_name = cal_name;
    this.price = Number(this.price);
    this.is_ready_to_select_customer = true;
    let _p = {
      "total_duration": this.duration / 60,
      "end_timestamp": this.end_timestamp,
      "end_datetime": this.end_datetime,
      "start_timestamp": this.start_timestamp,
      "start_datetime": this.start_datetime,
      "calendar_id": this.calendar_id,
      "calendar_name": this.calendar_name,
      "qty": (this.duration) / (60 * 60 * 24),
      "cleaning_fee":this.cleaning_fee,
      "price": this.price
    }

    let _v = this.validateEnd();
    if (_v == true) {
      // need to set calendar id again
      // its randomly choosen for customer during validation
      _p.calendar_id = this.calendar_id;
      _p.calendar_name = this.calendar_name;
      this.selectSlot.emit(_p);
    }
  }

  /**
   * 
   */
  validateEnd() {
    // max duration    
    if (this.duration / 60 > this.max_duration) {
      this.alertService.error("Max duration allowed is " + this.max_duration / (60 * 24) + " Days.");
      return false;
    }
    // how far ahead    
    let today_ts = moment(this.today).utcOffset(0, true).unix();
    let that_far = today_ts + this.how_far_ahead * 60 * 60 * 24;
    if (this.start_timestamp > that_far) {
      this.alertService.error("Cannot book that far. You can book max " + this.how_far_ahead + " days ahead.");
      return false;
    }

    // for customer
    // check that atleast one calendar have such booking and set it here.
    // if we dont do it, calendar id 0 will be sent
    let flag: any;
    if (this.is_customer || this.is_guest) {
      flag = this._validateCustomerBooking();
      if (flag == false) {
        this.alertService.error("Cannot book for dates selected. Service not available in between one of selected dates.");
        return false;
      }
    }
    else {
      // for provider check if selected range is ok
      flag = this._validateProviderBooking();
      if (flag == false) {
        this.alertService.error("Cannot book for dates selected. Service not available in between one of selected dates.");
        return false;
      }
    }
    // else all ok
    return true;
  }

  /**
   * 
   */
  _validateProviderBooking() {
    let flag = true;
    let dates = this._getTimestampDateUserWantToBook();
    this.slots_data.data.forEach((k, i) => {
      if (k.id == this.calendar_id) {
        dates.forEach(l => {
          if ($.inArray(Number(l), k.breaks) > -1) {
            flag = false;
          }
        });
      }
    });

    return flag;
  }

  /**
   * 
   */
  _getTimestampDateUserWantToBook() {
    let stack = [];
    let i;
    for (i = this.start_timestamp; i < this.end_timestamp;) {
      stack.push(i);
      i = i + (60 * 60 * 24);
    }

    return stack;

  }

  /**
   * 
   */
  _validateCustomerBooking() {
    let flag = false;
    let stack = this._getTimestampDateUserWantToBook();
    let invalid_cal = [];
    //console.log(this.slots_data.data);
    this.slots_data.data.forEach((k, i) => {
      stack.forEach(i => {
        if ($.inArray(Number(i), k.breaks) > -1) {
          invalid_cal.push(Number(k.id));
        }
      });
    });
    // cant use cal id from this list;
    var temp = $.unique(invalid_cal);
    // we now need to check if there are more calendar apart from temp
    this.slots_data.data.forEach((k, i) => {
      if ($.inArray(Number(k.id), temp) == -1) {
        this.calendar_id = k.id;
        this.calendar_name = k.name;
        //console.log("=");
        flag = true;
      }
    });
    // else return true 
    return flag;
  }

  /**
   * 
   */
  validateStart() {

    // how far ahead    
    let today_ts = moment(this.today).utcOffset(0, true).unix();
    let that_far = today_ts + this.how_far_ahead * 60 * 60 * 24;
    if (this.start_timestamp > that_far) {
      this.alertService.error("Cannot book that far ahead. You can book max " + this.how_far_ahead + " days ahead.");
      return false;
    }

    // else all ok
    return true;
  }


}
