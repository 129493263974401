import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../../_service/payment.service';
import { AlertService } from '../../../_service/alert.service';

@Component({
  selector: 'app-config-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css']
})
export class StripeComponent implements OnInit {

  secret = "";
  publishable = "";
  is_loading = false;

  /**
   * 
   */
  constructor(private paymentService: PaymentService, private alertService: AlertService) { 

    this._resetForm();
    this._getStripeInfo();
  }
  
  /**
   * 
   */
  ngOnInit() {
  }

  /**
   * 
   */
  _resetForm(){
    this.secret = "";
    this.publishable = "";
  }

  /**
   * 
   */
  _getStripeInfo(){
    this.paymentService.getStripeInfo().subscribe(data=>{
        let temp:any;
        temp = data;
        if(temp.data.payload_json != null){
          this.secret = temp.data.payload_json.secret;
          this.publishable = temp.data.payload_json.publishable;
        }
    });
  }

  /**
   * 
   */
  onClick_Save(){
    let _p  = {
      "secret" : this.secret,
      "publishable": this.publishable
    };
    this.paymentService.saveStripe(_p).subscribe(data=>{
      let temp:any;
      temp = data;
      this.alertService.success(temp.msg);
      this._getStripeInfo();

    });
  }

  /**
   * 
   */
  onClick_Delete(){
    this.paymentService.deleteStripe({}).subscribe(data=>{
      let temp:any;
      temp = data;
      this.alertService.success(temp.msg);
      this._resetForm();
    });
  }


}
