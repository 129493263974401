import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../../_helper/http.form.codec';
import { ParamsHelper } from '../../_helper/params';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BookingService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function BookingService(http) {
        this.http = http;
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
    }
    /**
     *
     */
    BookingService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     */
    BookingService.prototype.get = function (id) {
        return this.http.get(environment.apiv2_server + "/su/booking/" + id, { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.getAll = function () {
        return this.http.get(environment.apiv2_server + "/su/booking", { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.getAllWithPaymentDone = function () {
        return this.http.get(environment.apiv2_server + "/su/booking?onlyPaymentDone=1", { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.getAllByCustomerId = function (id) {
        return this.http.get(environment.apiv2_server + "/su/booking?customer_id=" + id, { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
 *
 * @param _p
 */
    BookingService.prototype.update = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.put(environment.apiv2_server + "/su/booking", body, { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    BookingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookingService_Factory() { return new BookingService(i0.ɵɵinject(i1.HttpClient)); }, token: BookingService, providedIn: "root" });
    return BookingService;
}());
export { BookingService };
