import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BusinessService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function BusinessService(http) {
        this.http = http;
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    BusinessService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     */
    BusinessService.prototype.getWhosLoggedIn = function () {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("id", user.data.business_id).append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/account/poll/business-by-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BusinessService.prototype.getBySubdomain = function (subdomain) {
        var body = this._getBody().append("subdomain", subdomain);
        return this.http.post(environment.server + "/acl/v1/business/get-by-subdomain", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    BusinessService.prototype.save = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("id", user.data.business_id).append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/acl/v1/business/update", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    BusinessService.prototype.choosePlan = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("id", user.data.business_id).append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/choose-plan", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    BusinessService.prototype.cancelPlan = function () {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("id", user.data.business_id).append("auth_token", user.data.auth_token);
        var body = this._append(temp, {});
        return this.http.post(environment.server + "/provider/account/do/cancel-plan", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    BusinessService.prototype.update = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/save", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    BusinessService.prototype.delete = function () {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, {});
        return this.http.post(environment.server + "/acl/v1/auth/delete", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    BusinessService.prototype.deleteCustomer = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/customer/do/delete", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
    *
    * @param username
    * @param password
    */
    BusinessService.prototype.makePayment = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/make-payment", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    BusinessService.prototype.updateSMSCredits = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("id", user.data.business_id).append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/update-sms-credits", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
 *
 */
    BusinessService.prototype.getAllCards = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("id", user.data.business_id).append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/poll/get-all-cards", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param temp
     * @param _p
     */
    BusinessService.prototype._append = function (temp, _p) {
        Object.keys(_p).forEach(function (k) {
            var t = temp.append(k, _p[k]);
            // below line is important because temp only return
            // it does not update temp 
            temp = t;
        });
        return temp;
    };
    BusinessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessService_Factory() { return new BusinessService(i0.ɵɵinject(i1.HttpClient)); }, token: BusinessService, providedIn: "root" });
    return BusinessService;
}());
export { BusinessService };
