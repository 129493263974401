import { Component, OnInit } from '@angular/core';
import { FormsModule, FormControl } from '@angular/forms';
import { AlertService } from '../../_service/alert.service';
import { AuthService } from '../../_service/auth.service';
import { BusinessService } from '../../_service/business.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UrlHelper } from '../../_helper/url';
import { LocaleHelper } from '../../_helper/locale';
import { first } from 'rxjs/operators';

import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { TranslateService } from '@ngx-translate/core';

interface Response {
  success: boolean;
  data: any;
  msg: string
}


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  submitted = false;
  loading = false;
  email: string;
  password: string;
  subdomain = "";
  business = { "enable_internal_booking": "1" };
  is_customer_signup = false;
  is_provider_signup = false;
  is_provider_signup_plan = false;
  is_loading = false;
  plan_choosen:any;

  constructor(
    private alertService: AlertService,
    private router: Router,
    private locale: LocaleHelper,
    public url: UrlHelper,
    private translate: TranslateService,
    private businessService: BusinessService,
    private route: ActivatedRoute,
    private authService: AuthService) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.route.queryParams.subscribe(params => {
      if (this.url.isLandingSubdomain(this.url.getSubdomain())) {
        this.is_provider_signup_plan = true;
      }
      if (!this.url.isLandingSubdomain(this.url.getSubdomain())) {
        this.is_customer_signup = true;
      }
    });

  }


  onClick_ChangeLanguage(lang) {
    this.locale.setLocale(lang);
    this.translate.use(lang);
  }


  ngOnInit() {
    this.translate.use(this.locale.getLocale());
    this.authService.signout();
    this.subdomain = this.url.getSubdomain();
    this._findBusiness();

  }

  /**
   * 
   */
  _findBusiness() {
    this.is_loading = true;
    this.businessService.getBySubdomain(this.subdomain).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success == true) {
        if (temp.data.subdomain != this.subdomain) {
          this.alertService.error(this.translate.instant("alert-invalid-business-account"));
        }
        else {
          this.business = temp.data;
        }
      }
      this.is_loading = false;
    });
  }

  onSubmit_Plan(e){
    this.plan_choosen = e;
    this.is_provider_signup_plan = false;
    this.is_provider_signup = true;

  }
  onClick_BackToPlan(){
    this.is_provider_signup = false;
    this.is_provider_signup_plan = true;
  }

  onSubmit_Signin() {
    this.submitted = true;
    this.loading = true;
    let _p = {};

    /*
    this.authService.signup().pipe(first()).subscribe(
      (res: Response)  => {
        if(res.success){
            this.router.navigate([""]); 
        } else {
          this.alertService.error(res.msg);
        }
      },
      error => {
         // console.log(error);
          this.alertService.error(error);
          this.loading = false;
      });

      */

  }

  onClick_Pp() {

  }

  onClick_Tnc() {

  }

  onClick_NeedHelp() {

  }

}
