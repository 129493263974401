import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertService } from './../../../_service/alert.service';
import { AuthHelper } from '../../../_helper/auth';
import { UrlHelper } from '../../../_helper/url';
import { ServiceService } from '../../../_service/service.service';
import { CategoryService } from '../../../_service/category.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-booking-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

  @Input() config: any;
  @Output() emit_config = new EventEmitter<any>();
  @Output() runtime = new EventEmitter<any>();

  categories: any;
  services = [];
  c_id = 0;
  show_next_btn = false;
  title = "Service";
  is_service_disabled = false;

  constructor(
    public alertService: AlertService,
    public authHelper: AuthHelper,
    public translate: TranslateService,
    public categoryService: CategoryService,
    public serviceService: ServiceService,
    public urlHelper: UrlHelper
  ) { }

  ngOnInit() {
    this.is_service_disabled = false;
    if(this.config.business.is_service_disabled == '1'){
      this.is_service_disabled = true;
    }
    else{
    if (this.config.url_id != 0) {
      this.serviceService.getById(this.config.url_id).subscribe(data => {
        let temp: any;
        temp = data;
        this.config.url_id = 0;
        if (temp.data.service_type == "2") {
          temp.data.duration = temp.data.duration * (60);
          temp.data.max_duration = temp.data.max_duration * (60);
        }
        if (temp.data.service_type == "3") {
          temp.data.duration = temp.data.duration * (60 * 24);
          temp.data.max_duration = temp.data.max_duration * (60 * 24);
        }
        this.onClick_Service(temp.data);
      });
    }
    //else {
      let _p = { "subdomain": this.urlHelper.getSubdomain() };
      this.categoryService.getAllByBusinessDomain(_p).subscribe(data => {
        let temp: any;
        temp = data;
        if (temp.success) {
          this.categories = temp.data;
          if (this.categories.length < 1) {
            this.alertService.error(this.translate.instant("alert-category-not-found"));
          }
          else {
            this._processConfig();
          }
        }
        else {
          this.alertService.error(this.translate.instant("alert-category-not-found"));
        }
      });
      this._setupServices();
    }
  }

  onClick_ClearSelection() {
    if (this.services.length > 0) {
      this.services.forEach(k => {
        k.active_class = "";
      });
    }
    this.config.service = [];
    this.config.type = 0;
    this.config.calendar_id = 0;
    this.config.customer = { "is_new": 0, "username": "", "id": 0, "first_name": "", "last_name": "", "mobile": "" };
    this.config.summary = { "service": "", "price": 0, "starts": "", "start_ts": 0, "ends": "", "end_ts": 0, "customer": "", "duration": 0 };
  }

  _processConfig() {
    this.c_id = this.categories[0].id;
    this._markSelectedCategoryAsActive(this.categories[0]);
    this._clickCategory();
  }

  onClick_Category(c) {
    this.c_id = c.id;
    this._markSelectedCategoryAsActive(c);
    this._clickCategory();
  }

  _markSelectedCategoryAsActive(c) {
    this.categories.forEach(k => {
      k.active_class = "";
    });
    c.active_class = "active";
  }

  _clickCategory() {
    this.config.is_loading = true;
    let _p = { "id": this.c_id };

    this.serviceService.getAllByCategoryId(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.config.is_loading = false;
      if (temp.success) {
        this.services = temp.data;
        if (this.services.length < 1) {
          //this.alertService.error(this.translate.instant("alert-service-not-found"));
        }
        else {
          this._setupServices();
        }
      }
      else {
        this.alertService.error(this.translate.instant("alert-service-not-found"));
      }
    });
  }

  _setupServices() {
    this._checkNextButton();
    this._markSelectedAsActive();
  }

  _markSelectedAsActive() {
    let selected = [];
    this.config.service.forEach(k => {
      selected.push(k.id);
    });
    this.services.forEach(k => {
      k.active_class = "";
      if (selected.includes(k.id)) {
        k.active_class = "active";
      }
    })
  }

  onClick_Service(s) {
   // console.log(s);
    // if not clicked appointment ,first clear all
    if (s.service_type != '1') {
      this.onClick_ClearSelection();
      this.config.service = [];
    }
    this._makeConfig(s);
  }

  _makeConfig(s) {
    if (this._validateServiceType(s)) {
      this._addOrRemoveService(s);
      this._markSelectedAsActive();
      this._makeString();
      this._checkIfPaymentNeeded();
      if (s.service_type == 1) {
        this._checkNextButton();
        this._fixPrice();
        this.runtime.emit(this.config);
      }
      else {
        this.onClick_Next();
      }
    }
    //console.log(this.config);
  }

  // only work for appointment, 
  // for rental and accommodation, we do it when user select end slot
  _fixPrice() {
    let p = 0;
    this.config.service.forEach(k => {
      p = Number(p) + Number(k.price);
    });
    this.config.payment.balance = p;
    this.config.summary.price = p;

  }

  _validateServiceType(s) {
    let st = 0;
    let flag = true;
    this.config.service.forEach(k => {
      if (s.service_type != k.service_type) {
        flag = false;
      }
    });
    if (!flag) {
      if (s.service_type == "1") {
        this.alertService.error(this.translate.instant("alert-cannot-select-appointment-service-with-other-service"));
      }
      if (s.service_type == "2") {
        this.alertService.error(this.translate.instant("alert-cannot-select-rental-service-with-other-service"));
      }
      if (s.service_type == "3") {
        this.alertService.error(this.translate.instant("alert-cannot-select-accommodation-service-with-other-service"));
      }
    }

    return flag;
  }

  _addOrRemoveService(s) {
    let add = true;
    let _id = 0;
    this.config.service.forEach((k, i) => {
      if (k.id == s.id) {
        add = false;
        _id = i;
      }
    });
    if (add) {
      this.config.service.push(s);
    }
    else {
      s.active_class = "";
      this.config.service.splice(_id, 1);
    }

  }

  _makeString() {
    // add to string now
    let _s = "";
    let _type = 0;
    let _d = 0;
    this.config.service.forEach((k, i) => {
      _s = _s + k.name + ", ";
      _type = k.service_type;
      _d = _d + Number(k.duration);
    });
    this.config.summary.duration = _d;
    this.config.type = _type;
    this.config.summary.service = _s;

  }


  _checkIfPaymentNeeded() {
    // add to string now
    let _flag = false;

    this.config.service.forEach((k, i) => {
      if (k.is_require_online_payment == '1') {
        _flag = true;
      }
    });
    this.config.payment.required = _flag;

  }

  _checkNextButton() {
    if (this.config.service.length > 0) {
      this.show_next_btn = true;
    }
    else {
      this.show_next_btn = false;
    }
  }

  onClick_Next() {
    if (this.config.service.length > 0) {
      this.config.step = "datetime";
      this.emit_config.emit(this.config);
    }
  }

}
