import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../../_helper/http.form.codec';
import { ParamsHelper } from '../../_helper/params';

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    params: any;
    /**
     * 
     */
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) {
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();

    }

    /**
     * 
     */
    _getBody() {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     */
    get(id) {
        return this.http.get(environment.apiv2_server + "/su/booking/" + id, { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(data => {
                return data;
            }));
    }
    /**
     * 
     */
    getAll() {
        return this.http.get(environment.apiv2_server + "/su/booking",  { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllWithPaymentDone() {
        return this.http.get(environment.apiv2_server + "/su/booking?onlyPaymentDone=1",  { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllByCustomerId(id) {
        return this.http.get(environment.apiv2_server + "/su/booking?customer_id="+id,  { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(data => {
                return data;
            }));
    }
        /**
     * 
     * @param _p 
     */
    update(_p) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.put(environment.apiv2_server + "/su/booking", body, { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(data => {
                return data;
            }));

    }


}
