import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {  NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';
import * as moment from 'moment-timezone';
//import { containsElement } from '@angular/animations/browser/src/render/shared';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

const now = new Date();

@Component({
    selector: 'app-booking-create-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {

    @Input() current_date: any;
    @Output() selectSlot = new EventEmitter<object>();
    datetime: NgbDateStruct;

    err = "Bookings Not Available";
    slots = [];
    is_show_error = true;
    modal_title = "";
    modal_image = "";
    modal_description = "";
    min_duration = 0;
    no:any;

    /**
     * 
     * @param router 
     */
    constructor(private router: Router, private modalService: NgbModal,
    ) { 

        this.no = {
            firstDayOfWeek: 1,
            dayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
            dayNamesShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
            dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
            monthNames: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
            monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
            today: 'I dag',
            clear: 'Klar'
          };
    }

    /**
     * 
     */
    ngOnInit() { }

    open(modal, item) {
        this.modal_title = item.name;
        this.modal_image = item.image;
        this.modal_description = item.description;
        this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        }, (reason) => {
        });
    }



    /**
     * 
     * @param data 
     */
    generateSlots(data,p) {
        this.datetime = this.current_date.toDate();
        this.is_show_error = true;
        this.slots = [];
        let breaks: any;
        let item: any;
        this.min_duration = Number(p.min_duration) + Number(p.break_before);
        data.data.forEach((cal, index) => {
            breaks = this.findBreaks(cal);
            item = this.findSlot(cal, breaks);
            if (this.is_show_error == false) {
                this.slots.push(item);
            }
        });
    }

    /**
     * 
     * @param cal 
     */
    findBreaks(cal) {
        let br = 0;
        let breaks = [];
        //25675680

        $.each(cal.breaks, function (bk, bv) {
            for (br = bv.start_timestamp_in_minute; br < bv.end_timestamp_in_minute;) {
                breaks.push(Number(br));
                br = Number(br) + Number(1);
            }
        });
        return breaks;
    }

    /**
     * 
     * @param date_string 
     */
    _getTs(date_string) {
        return moment(date_string).utcOffset(0, true).startOf("day").unix();
    }

    /**
     * 
     */
    onClick_JumpToDate(date_string) {
        this.current_date = moment(date_string).utcOffset(0, true).startOf("day");
        let ts = this._getTs(date_string);
        let _p = {
            "ts": ts,
            "current_date": this.current_date,
            "jump_to_date": true
        }
        this.selectSlot.emit(_p);
        //this._setNextPrevDate();

    }

    /**
     * 
     * @param cal 
     * @param breaks 
     */
    findSlot(cal, breaks) {
        let i, k = 0;
        let stack: any;
        stack = [];
        stack.name = "";
        stack.image = "";
        stack.description = "";
        stack.slots = [];
        let m = "";
        //25675680 = 08:00
        for (i = cal.start_timestamp_in_minute; i <= cal.end_timestamp_in_minute;) {
            // below line takes care of back in time booking restriction
            if (i > cal.current_time_at_provider_location) {
                if ($.inArray(Number(i), breaks) == -1) {
                    stack.name = cal.name;
                    stack.image = cal.image;
                    stack.description = cal.description;
                    m = moment.unix(Number(i * 60)).utc().format('LT');
                    let s = {
                        "cal_name": cal.name,
                        "cal_id": cal.id,
                        "slot_minute": i,
                        "slot_string": m
                    };
                   // console.log(s);
                    stack.slots.push(s);
                    this.is_show_error = false;
                }
            }
            i = Number(i) + Number(cal.slot);
        }
        // check if min time is available to book for each slot now
        // if not then remove that slot
        stack = this._fixMinDuration(stack,breaks);
        return stack;
    }

    /**
     * 
     * @param stack 
     */
    _fixMinDuration(stack,breaks){

        let remove_slot = [];
        let remove_string = [];
        let temp = stack.slots;
        temp.forEach((k,i)=>{
            if(i < temp.length-1){
                let sum = Number(k.slot_minute) + Number(this.min_duration);
                // make sure loop does not go infiniti                
                if(k.slot_minute < sum){
                    for(let i2 = k.slot_minute ; i2 < sum; i2++ ){
                        if($.inArray(i2,breaks) != -1){
                            if(!remove_slot.includes(k.slot_minute)){
                                remove_slot.push(k.slot_minute);
                                remove_string.push(k);
                            }
                        }
                    }
                }
            }
        });
        remove_slot.forEach((j)=>{
           stack.slots.forEach((l,m)=>{
                if(j == l.slot_minute){
                    stack.slots.splice(m,1);
                }
           });
        });
        
        return stack;
        
    }   

    /**
     * 
     * @param calendar_id 
     * @param min_ts 
     * @param slot_name 
     * @param calendar_name 
     */
    onClick_Slot(calendar_id, min_ts, slot_name, calendar_name) {
        let _p = {
            "slot_timestamp": min_ts,
            "slot_name": slot_name,
            "calendar_name": calendar_name,
            "calendar_id": calendar_id,
            "start_timestamp": moment.unix(min_ts * 60).utc().unix(),
            "start_datetime": moment.unix(Number(min_ts * 60)).utc().format("LLL")
        }
        this.selectSlot.emit(_p);
    }

}