<div class="card-body">
	<div class="row mb-4 mt-4">
		<div class="col-sm-12 col-md-12 col-lg-12" *ngIf="config.payment.required &&
			!config.payment.success">
			<h3 class="text text-danger" translate>heading-payment-error</h3>
			<div translate>txt-payment-error-information</div>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-12" *ngIf="!config.payment.required">
			<h3 class="text text-danger" translate>heading-unknown-error</h3>
			<div translate>txt-unknown-error-while-trying-to-book</div>
		</div>
	</div>
</div>
<div class="card-footer bg-light">
	<button class="btn btn-lg btn-outline-secondary"
		(click)="onClick_StartAgain()" translate>
		<i class="icon icon-chevron-left"></i> btn-start-again
	</button>
</div>
