import { Injectable } from "@angular/core";
@Injectable()
export class AuthHelper {
	
	constructor() {}
	
	public isLoggedIn(): boolean{
		let temp: any;
		temp =  JSON.parse(localStorage.getItem("currentUser"));
		if(temp != undefined){
			if(temp.data != undefined){
				if(temp.data.id != undefined){
					if(temp.data.auth_token != undefined || temp.data.auth_token != ""){
						return true;
					}
				}
			}		
		}
		return false;
	}
	public getUser(): any {
		let temp :any;
		temp =  JSON.parse(localStorage.getItem("currentUser"));
		if(temp != null){
			return temp.data;
		}
		else{
			// set default object if not logged, else it give undefined property error on landing page
			return temp = {"data":{"is_force_reset_password":0}};
		}
	}
	public setUser(_new_data:any): any {
		let temp:any;
		// if we do temp = [] below, it will not work
		// because [] makes it an array
		temp = {};
		temp.data = _new_data;
		localStorage.setItem('currentUser', JSON.stringify(temp));
	}

	public updateBusiness(business){
		let temp :any;
		temp =  JSON.parse(localStorage.getItem("currentUser"));
		temp.business = business;
		localStorage.setItem('currentUser', JSON.stringify(temp));

	}

	public getLocale():string{
		let temp :any;
		temp =  JSON.parse(localStorage.getItem("currentUser"));
		
		if(temp.data.locale == undefined || temp.data.locale == ""){
			return "nb";	
		}
		return temp.data.locale;
	}

	
}