import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BookingService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function BookingService(http) {
        this.http = http;
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    BookingService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     */
    BookingService.prototype.save = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp;
        if (_p.business_id == null || _p.business_id == undefined || _p.business_id == "") {
            temp = this._getBody().append("business_id", user.data.business_id).append("auth_token", user.data.auth_token);
        }
        else {
            temp = this._getBody().append("auth_token", user.data.auth_token);
        }
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/do/save", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.create = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp;
        temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/do/create", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    BookingService.prototype.refund = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/do/refund", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    BookingService.prototype.markAsPaid = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/do/mark-as-paid", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    BookingService.prototype.update = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/do/update", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    BookingService.prototype.updatePayment = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/do/update-payment", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    BookingService.prototype.registerCardAndMakePayment = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/customer/booking/do/pay", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    BookingService.prototype.confirmPi = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/customer/booking/do/confirm-pi", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    BookingService.prototype.checkPiStatus = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/customer/booking/do/check-pi-status", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     */
    BookingService.prototype.getAllForCustomer = function (_p) {
        if (_p === void 0) { _p = {}; }
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/customer/booking/poll/all", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.getAllByCustomerId = function (cust_id) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("customer_id", cust_id).append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/booking/poll/by-customer-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.getAllWithPaymentDone = function (_p) {
        if (_p === void 0) { _p = {}; }
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/poll/with-payment-done", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.getAllByCalendarId = function (cal_id, _p) {
        if (_p === void 0) { _p = null; }
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("calendar_id", cal_id).append("auth_token", user.data.auth_token);
        var body;
        if (_p != null) {
            body = this._append(temp, _p);
        }
        else {
            body = temp;
        }
        return this.http.post(environment.server + "/provider/booking/poll/by-calendar-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.get = function (id) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("id", id).append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/booking/poll/by-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.reschedule = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/do/reschedule", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    BookingService.prototype.getStats = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/dashboard/get-stats", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param temp
     * @param _p
     */
    BookingService.prototype._append = function (temp, _p) {
        Object.keys(_p).forEach(function (k) {
            var t = temp.append(k, _p[k]);
            temp = t;
        });
        return temp;
    };
    BookingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookingService_Factory() { return new BookingService(i0.ɵɵinject(i1.HttpClient)); }, token: BookingService, providedIn: "root" });
    return BookingService;
}());
export { BookingService };
