import { Injectable } from "@angular/core";
@Injectable()
export class PlanHelper {
	
	options = [
		{"class":"bg-light","name":"p1","label":"Start","mp":149,"yp":1999,"user":1,"calendar":1,"service":10,"booking":"unlimited"},
		{"class":"bg-secondary","name":"p2","label":"Medium","mp":299,"yp":3999,"user":2,"calendar":2,"service":"unlimited","booking":"unlimited"},
		{"class":"bg-dark","name":"p3","label":"Full","mp":599,"yp":4999,"user":100,"calendar":"unlimited","service":"unlimited","booking":"unlimited"}
	];

}
