import { Component, OnInit, Input, ElementRef, ViewEncapsulation } from '@angular/core';
import { CalendarService } from '../../../_service/calendar.service';
import { AlertService } from '../../../_service/alert.service';
import { CalendarHelper } from '../../../_helper/calendar';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
const now = new Date();


@Component({
  selector: 'app-config-calendar-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']

})
export class ScheduleComponent implements OnInit {


  @Input() id: number;
  @Input() slot: string;
  @Input() name: string;
  @Input() cal_service_type: string;
  @Input() dt: Subject<any>;

  offset: number;
  is_date_selected = false;
  all_calendars = [];
  bulk_cal_stack = [];
  is_loading = false;

  follow_this_for_future_weeks = "0";
  boolean_status = [{ "value": "1" }, { "value": "0" }];
  start_break = [];
  selected_date = 0;
  end_break = [];
  is_bulk_editing = false;
  slots: any;
  title = this.translate.instant("txt-select-a-date");
  starting_week = 0;
  default_starts = "08:00";
  default_ends = "18:00";
  /**
   * Monday = Mandag
    Tuesday = Tirsdag
    Wednesday = Onsdag
    Thursday = Torsdag
    Friday = Fredag
    Saturday = Lørdag
    Sunday = Søndag
   */
  weekdays = [
    { "timestamp": 0, "name": "Mandag", "is_working_day": "0", "follow_this_for_future_weeks": "0", "starts": "", "ends": "", "breaks": [] },
    { "timestamp": 0, "name": "Tirsdag", "is_working_day": "0", "follow_this_for_future_weeks": "0", "starts": "", "ends": "", "breaks": [] },
    { "timestamp": 0, "name": "Onsdag", "is_working_day": "0", "follow_this_for_future_weeks": "0", "starts": "", "ends": "", "breaks": [] },
    { "timestamp": 0, "name": "Torsdag", "is_working_day": "0", "follow_this_for_future_weeks": "0", "starts": "", "ends": "", "breaks": [] },
    { "timestamp": 0, "name": "Fredag", "is_working_day": "0", "follow_this_for_future_weeks": "0", "starts": "", "ends": "", "breaks": [] },
    { "timestamp": 0, "name": "Lørdag", "is_working_day": "0", "follow_this_for_future_weeks": "0", "starts": "", "ends": "", "breaks": [] },
    { "timestamp": 0, "name": "Søndag", "is_working_day": "0", "follow_this_for_future_weeks": "0", "starts": "", "ends": "", "breaks": [] }
  ];


  /**
   * 
   */
  constructor(
    private calendarService: CalendarService,
    private elementRef: ElementRef,
    public translate: TranslateService,
    private calendarHelper: CalendarHelper,
    private alertService: AlertService
  ) {
  }

  /**
   * 
   */
  ngOnInit() {
    this.slots = this.calendarHelper.getSlot(this.slot);
    this.dt.subscribe(v => {
      this.follow_this_for_future_weeks = "0";
      this.bulk_cal_stack = [];
      this._getAllCal();
      this.is_date_selected = true;
      this.selected_date = moment(v).utcOffset(0, true).unix();
      this.starting_week = moment.unix(this.selected_date).utc().startOf('isoWeek').unix();
      this._makeDefaultSchedule();
    });
  }

  /**
   * 
   */
  _getAllCal() {
    //console.log(this.id);
    this.is_loading = true;
    this.calendarService.getAll(this.id).subscribe(data => {
      let temp: any;
      temp = data;
      this.all_calendars = temp.data;
      this.is_loading = false;
    });
  }

  /**
   * 
   */
  onClick_BulkEdit() {
    if (this.is_bulk_editing == false) {
      this.is_bulk_editing = true;
    }
    else {
      this.is_bulk_editing = false;
    }
  }

  /**
   * 
   */
  _makeDefaultSchedule() {
    this.weekdays[0].timestamp = Number(this.starting_week);
    this.weekdays[1].timestamp = Number(this.starting_week) + Number(60 * 60 * 24 * 1);
    this.weekdays[2].timestamp = Number(this.starting_week) + Number(60 * 60 * 24 * 2);
    this.weekdays[3].timestamp = Number(this.starting_week) + Number(60 * 60 * 24 * 3);
    this.weekdays[4].timestamp = Number(this.starting_week) + Number(60 * 60 * 24 * 4);
    this.weekdays[5].timestamp = Number(this.starting_week) + Number(60 * 60 * 24 * 5);
    this.weekdays[6].timestamp = Number(this.starting_week) + Number(60 * 60 * 24 * 6);

    // set default
    this.weekdays.forEach(k => {
      k.starts = this.default_starts;
      k.ends = this.default_ends;
    });

    // not get schedule for each
    this._cleanupBreaks();
    for (let i = 0; i < 7; i++) {
      this._getScheduleForDay((this.weekdays[i].timestamp) / (60 * 60 * 24), i);
    }
  }

  /**
   * 
   */
  _getScheduleForDay(ts, day) {
    this.is_loading = true;
    let _p = {
      "timestamp_in_day": ts,
      "calendar_id": this.id
    }
    this.calendarService.getSchedule(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.weekdays[day].is_working_day = "0";
      if (temp.data.is_working_day != null) {
        this.weekdays[day].is_working_day = temp.data.is_working_day;
      }
      this.weekdays[day].follow_this_for_future_weeks = "0";
      if (temp.data.follow_this_for_future_weeks != null) {
        this.follow_this_for_future_weeks = temp.data.follow_this_for_future_weeks;
      }
      if (temp.data.starts != null) {
        this.weekdays[day].starts = temp.data.starts;
      }
      if (temp.data.ends != null) {
        this.weekdays[day].ends = temp.data.ends;
      }
      if (temp.data.breaks != null) {
        this.weekdays[day].breaks = temp.data.breaks;
      }

      this.weekdays[day].breaks.forEach(b => {
        if (!this._checkIfSameBreakExistsInHtml(day, b.starts, b.ends)) {
          this._setupBreaks(day, b.starts, b.ends);
        }
      });
      this.is_loading = false;
      this.title = this.translate.instant("lbl-schedule-calendar") + ": "+ this.name + ", for week starting " + moment.unix(this.starting_week).format("DD MMMM YYYY");
    });

  }


  /**
   * 
   * @param v 
   */
  onClick_AddBreaks(index) {
    let s = this.start_break[index];
    let e = this.end_break[index];
    if (s != undefined && e != undefined) {
      if (!this._checkIfSameBreakExistsInHtml(index, s, e)) {
        this._setupBreaks(index, s, e);
      }
    }
    else {
      this.alertService.error(this.translate.instant("alert-invalid-value-selected"));
    }
  }

  /**
   * 
   * @param index 
   * @param s 
   * @param e 
   */
  _checkIfSameBreakExistsInHtml(index, s, e) {
    let ts = this.weekdays[index].timestamp;
    let se = s + e;
    let id = "breaks_id_" + index + "" + ts + "" + se.replace(":", "").replace(":", "");
    var el = this.elementRef.nativeElement.querySelector("#" + id);
    if (el != null && el != undefined) {
      return true;
    }
    return false;
  }

  /**
   * 
   * @param index 
   * @param s 
   * @param e 
   */
  _checkIfSameBreakExistsInStack(index, s, e) {
    let flag = false;
    this.weekdays[index].breaks.forEach((v, i) => {
      if (v.starts == s && v.ends == e) {
        flag = true;
      }
    });
    return flag;
  }

  /**
   * 
   */
  _cleanupBreaks() {

    let el = this.elementRef.nativeElement.querySelectorAll('h5[class^="breaks_list_"]');
    if (el != null || el != undefined) {
      el.forEach(e => {
        e.innerHTML = "";
      });
    }
  }

  /**
   * 
   * @param index 
   * @param s 
   * @param e 
   */
  _setupBreaks(index, s, e) {
    let ts = this.weekdays[index].timestamp;
    let se = s + e;
    let id = "breaks_id_" + index + "" + ts + "" + se.replace(":", "").replace(":", "");

    let bl = this.weekdays[index].breaks.length;
    if (!this._checkIfSameBreakExistsInStack(index, s, e)) {
      this.weekdays[index].breaks.splice(bl, 0, { "starts": s, "ends": e });
    }

    var d1 = this.elementRef.nativeElement.querySelector(".breaks_list_" + index);
    d1.insertAdjacentHTML('beforeend', '<div class="border bg-secondary rounded p-1 mt-1" id="' + id + '" class="btn btn-sm text-danger">' + s + ' - ' + e + ' <i class="fas fa-times"></i></button>');
    this.elementRef.nativeElement.querySelector('#' + id).addEventListener('click', this.onClick_DeleteBreak.bind(this));

  }
  /**
   * 
   * @param event 
   */
  onClick_DeleteBreak(event) {

    // remove html el
    let elid = event.srcElement.id;
    let el = this.elementRef.nativeElement.querySelector("#" + elid);
    el.remove();

    // now remove from stack
    let id = elid.split("breaks_id_")[1];
    let index = id.substr(0, id.length - 18);
    let tsse = id.substr(id.length - 18);
    let ts = tsse.substr(0, 10);
    let se = tsse.substr(tsse.length - 8);

    this.weekdays[index].breaks.forEach((b, i) => {
      let s = b.starts.replace(":", "");
      let e = b.ends.replace(":", "");
      if (se == s + e) {
        delete this.weekdays[index].breaks[i];
      }
    });

  }

  /**
   * 
   */
  onClick_Save() {
    this.is_loading = true;

    let _p = [];
    let _bulk_ids = "";
    this.bulk_cal_stack.forEach((k, i) => {
      if (this.bulk_cal_stack[i] == '1') {
        _bulk_ids = _bulk_ids + "," + i;
      }
    });
    _p['bulk_ids'] = _bulk_ids;
    _p["calendar_id"] = this.id;
    _p["selected_date"] = this.selected_date;
    _p["follow_this_for_future_weeks"] = this.follow_this_for_future_weeks;
    this.weekdays.forEach(k => {
      k.breaks.forEach((b, i) => {
        _p["break_starts[" + k.timestamp + "][" + i + "]"] = b.starts;
        _p["break_ends[" + k.timestamp + "][" + i + "]"] = b.ends;
      });
      _p["working_day[" + k.timestamp + "]"] = k.is_working_day;
      _p["day_start[" + k.timestamp + "]"] = k.starts;
      _p["day_end[" + k.timestamp + "]"] = k.ends;
    });

    this.calendarService.setSchedule(_p).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        this.alertService.success(temp.msg);
      }
      else {
        this.alertService.error(temp.msg);
      }
      this.is_loading = false;

    });
  }



}
