/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./business.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng2-smart-table/ng2-smart-table.ngfactory";
import * as i3 from "ng2-smart-table";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./business.component";
import * as i6 from "@angular/router";
import * as i7 from "../../_helper/date";
import * as i8 from "../../_service/su/business.service";
import * as i9 from "../../_helper/auth";
var styles_BusinessComponent = [i0.styles];
var RenderType_BusinessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BusinessComponent, data: {} });
export { RenderType_BusinessComponent as RenderType_BusinessComponent };
export function View_BusinessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "h5"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Providers (", ")"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ng2-smart-table", [["translate", ""]], null, [[null, "userRowSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userRowSelect" === en)) {
        var pd_0 = (_co.userRowSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵbo_0, i2.RenderType_ɵbo)), i1.ɵdid(3, 573440, null, 0, i3.ɵbo, [], { source: [0, "source"], settings: [1, "settings"] }, { userRowSelect: "userRowSelect" }), i1.ɵdid(4, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.businesses; var currVal_2 = _co.settings; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = ""; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.businesses == null) ? null : _co.businesses.length); _ck(_v, 1, 0, currVal_0); }); }
export function View_BusinessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-business", [], null, null, null, View_BusinessComponent_0, RenderType_BusinessComponent)), i1.ɵdid(1, 114688, null, 0, i5.BusinessComponent, [i4.TranslateService, i6.Router, i7.DateHelper, i8.BusinessService, i9.AuthHelper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BusinessComponentNgFactory = i1.ɵccf("app-business", i5.BusinessComponent, View_BusinessComponent_Host_0, {}, {}, []);
export { BusinessComponentNgFactory as BusinessComponentNgFactory };
