<div class="container" *ngIf="is_loading == true">
    <div class="row justify-content-center space-lg">
        <h3 class="title-decorative" translate><img
                src="assets/img/ajax-loader-light-gray.gif"> txt-loading</h3>
    </div>
</div>
<div *ngIf="is_valid_subdomain == true && is_loading == false">
    <div class="col-12 bg-light space-lg" style="height:380px">
        <img alt="Loading ..." [src]="business?.banner" class="bg-image shadow-sm border-bottom rounded">
        <div class="container card-body">
            <h2 class="bg-parent" [style.color]="business?.tagline1_color"
                style="line-height:4.0rem"
                [style.font-size]="business?.tagline1_size">{{business?.tagline1}}</h2>
            <h5 class="bg-parent" [style.color]="business?.tagline2_color"
                [style.font-size]="business?.tagline2_size">{{business?.tagline2}}</h5>
        </div>
        <hr class="mt-0 mb-0 pt-0 pb-0">
    </div>
    <section class="space-sm">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-12 col-md-8 col-lg-8">
                    <div class="row">
                        <div *ngIf="has_logo == true" class="col align-items-end
                            mb-4">
                            <img alt="Loading ..." src="{{business?.logo}}"
                                width="160" class="img-fluid responsive ">
                        </div>
                        <div class="col text-right" >
                            <ul class="list-inline">
                                <li class="list-inline-item"
                                    *ngIf="business?.twitter_handle != null &&
                                    business?.twitter_handle != ''">
                                    <a class="text text-muted" target="_blank"
                                        href="{{business?.twitter_handle}}"><i
                                            class="socicon-twitter h5 text-muted border rounded pl-2 pr-2 pt-1 pb-1 "></i></a>
                                </li>
                                <li class="list-inline-item"
                                    *ngIf="business?.facebook_page_url != '' &&
                                    business?.facebook_page_url != null">
                                    <a class="text text-muted" target="_blank"
                                        href="{{business?.facebook_page_url}}"><i
                                            class="socicon-facebook h5 text-muted  border rounded  pl-2 pr-2 pt-1 pb-1"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div><h2 class="mb-0 h1 text-dark">{{business?.name}}</h2></div>
                    <div class="text-dark mt-3 mb-3">{{business?.description}}</div>
                    <div class="clearfix"></div>
                    <hr>
                    <div *ngIf="business?.enable_internal_booking == '1'">
                        <div class="card card-sm shadow-sm border" *ngIf="!auth.isLoggedIn()">
                            <ul class="list-group list-group-flush ">
                                <li class="list-group-item bg-light">
                                    <div class="title-decorative text text-muted " translate="">
                                        btn-signin
                                    </div>
                                </li>
                                <li class="list-group-item ">
                                    <div>
                                        <input autocomplete="off" [(ngModel)]="email" class="mb-2 form-control border rounded form-control-lg" placeholder="{{ 'lbl-email' | translate }}">
                                        <input autocomplete="off" (keyup.enter)="onSubmit_Signin()" [(ngModel)]="password" type='password' class="mb-2 form-control border rounded form-control-lg" placeholder="{{ 'lbl-password' | translate }}">
                                        <button  (click)="onSubmit_Signin()" class="mt-2 btn btn-lg btn-primary " translate="">btn-signin</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div *ngIf="is_show_categories == true && categories?.length > 0">
                        <h5 class="title-decorative mb-1 text text-dark"
                            translate>heading-our-service-categories</h5>
                        <div *ngIf="is_loading">Loading Services ...</div>
                        <div class="accordion mt-2" id="acc">

                            <div class="" *ngFor="let c of categories">
                                <div *ngIf="c.services.length > 0" class="p-0  mb-1" id="_{{c.id}}">
                                    <h2 class="mb-0">
                                        <button class="btn btn-light text
                                            btn-block
                                            text-muted border p-2
                                            title-decorative collapsed text-left"
                                            type="button" data-toggle="collapse"
                                            [attr.data-target]="'#x' + c.id"
                                            aria-expanded="false"
                                            [attr.aria-controls]="'x' + c.id">
                                            <small class="fa fa-chevron-right"></small> {{c.name}} <small>{{c.services.length}} Services</small>
                                        </button>
                                    </h2>
                                </div>
                                <div *ngIf="c.services.length > 0" id="x{{c.id}}" class="collapse"
                                    [attr.aria-labelledby]="'_'+c.id"
                                    data-parent="#acc">
                                    <div class="card ml-3 border rounded">
                                        <ul class="list-group list-group-flush">
                                            <ng-container *ngFor="let s of
                                            c.services">
                                            <li
                                                class="list-group-item"
                                                 *ngIf="s.status=='active'">

                                                <a *ngIf="s.service_type!='4' && s.status=='active'" class="d-flex
                                                    justify-content-between"
                                                    [routerLink]="['/booking.new']"
                                                    [queryParams]="{id: s.id}">
                                                    
                                                    <div *ngIf="s.service_type=='1' || s.service_type=='2'">{{s.name}} 
                                                        <small
                                                            class="text
                                                            text-muted">{{s.price}}
                                                            NOK</small>,
                                                        <small class="text
                                                            text-muted">{{s.duration}}
                                                            mins</small>
                                                    </div>
                                                    <div *ngIf="s.service_type=='3'">{{s.name}} 
                                                        <small
                                                            class="text
                                                            text-muted">{{s.price}} NOK / day
                                                            </small>
                                                    </div>
                                                </a>
                                                <a target="_blank" *ngIf="s.service_type=='4' && s.status=='active'" class="d-flex
                                                    justify-content-between"
                                                    href="{{s.description}}"
                                                    >
                                                    <div>{{s.name}}
                                                    </div>
                                                </a>
                                            </li>
                                        </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card shadow-sm border" *ngIf="is_show_services == true && services?.length > 0">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-secondary">
                                <div class="title-decorative text text-dark"
                                    translate="">
                                    heading-our-services
                                </div>
                            </li>
                            <ng-container  *ngFor="let s of
                            services">

                            <li *ngIf="s.status=='active'" [hidden]="s.is_require_online_payment == '1' &&
                                business?.enable_paypal=='0' &&
                                business?.enable_stripe=='0'"
                                class="list-group-item">
                                <a  *ngIf="s.service_type!='4' && s.status=='active'" class="d-flex justify-content-between"
                                    [routerLink]="['/booking.new']"
                                    [queryParams]="{id: s.id}">

                                    <div *ngIf="s.service_type=='1' || s.service_type=='2'">{{s.name}} 
                                        <small class="text
                                        text-muted">{{s.price}} NOK</small>,
                                        <small class="text text-muted">{{s.duration}}
                                        <span translate="">lbl-mins</span></small>

                                    </div>

                                    <div *ngIf="s.service_type=='3'">{{s.name}} 
                                        <small class="text
                                        text-muted">{{s.price}} NOK / day</small>
                                    </div>
                                
                                </a>
                                <a target="_blank" *ngIf="s.service_type=='4' && s.status=='active'" class="d-flex
                                    justify-content-between"
                                    href="{{s.description}}"
                                    >
                                    <div>{{s.name}}</div>
                                </a>
                            </li>
                        </ng-container>

                        </ul>
                    </div>

                    <h5 class="title-decorative mt-4 mb-1 text text-dark" *ngIf="business?.is_show_about_us == 1"
                        translate>heading-about-us</h5>
                    <article *ngIf="business?.is_show_about_us == 1">
                        <p class="text-muted" [innerHtml]="business?.about_us_page"></p>
                    </article>
                </div>
                <!--end of col-->
                <div class="col-12 col-md-4 col-lg-4" >
                    
                    <div class="card shadow-sm border"  *ngIf="business?.is_show_map_address == 1">
                        <div *ngIf="show_map">
                                <agm-map [mapTypeControl]="true"
                                (mapReady)="mapReady($event)"
                                [latitude]="business?.lat"
                                [longitude]="business?.lng" [zoom]="14">
                                <agm-marker (markerClick)="openWindow()"
                                    [latitude]="business?.lat"
                                    [longitude]="business?.lng">
                                    <agm-info-window [latitude]="business?.lat"
                                        [longitude]="business?.lng">{{business?.name}}</agm-info-window>
                                </agm-marker>
                            </agm-map>
        
                        </div>
                        <div class="card-body" >
                            <h5>{{business?.name}}</h5>
                            <p>
                                {{business?.street}}
                                <br>{{business?.zipcode}} {{business?.city}}
                                {{business?.state}}
                                <br>{{business?.country}}
                            </p>
                        </div>
                    </div>

                    <div class="bg-secondary text-muted card card-body card-sm"
                        *ngIf="business?.is_show_working_hours == 1">
                        <div class="title-decorative text text-muted" translate>heading-opening-hours</div>
                        <div class="row">
                            <span class="col-6">{{ 'txt-monday' | translate }}</span>
                            <span class="col-6 text text-danger"
                                *ngIf="mon_working_hours_alt=='Closed' || mon_working_hours_alt=='Stengt' ">{{mon_working_hours_alt}}</span>
                            <span class="col-6 text text-success"
                                *ngIf="mon_working_hours_alt!='Closed' && mon_working_hours_alt!='Stengt'">{{mon_working_hours_alt}}</span>
                        </div>
                        <div class="row">
                            <span class="col-6">{{ 'txt-tuesday' | translate }}</span>
                            <span class="col-6 text text-danger"
                                *ngIf="tue_working_hours_alt=='Closed' || tue_working_hours_alt=='Stengt'">{{tue_working_hours_alt}}</span>
                            <span class="col-6 text text-success"
                                *ngIf="tue_working_hours_alt!='Closed' && tue_working_hours_alt!='Stengt'">{{tue_working_hours_alt}}</span>
                        </div>
                        <div class="row">
                            <span class="col-6">{{ 'txt-wednesday' | translate
                                }}</span>
                            <span class="col-6 text text-danger"
                                *ngIf="wed_working_hours_alt=='Closed' || wed_working_hours_alt=='Stengt'">{{wed_working_hours_alt}}</span>
                            <span class="col-6 text text-success"
                                *ngIf="wed_working_hours_alt!='Closed' && wed_working_hours_alt!='Stengt'">{{wed_working_hours_alt}}</span>
                        </div>
                        <div class="row">
                            <span class="col-6">{{ 'txt-thursday' | translate }}</span>
                            <span class="col-6 text text-danger"
                                *ngIf="thu_working_hours_alt=='Closed' || thu_working_hours_alt=='Stengt'">{{thu_working_hours_alt}}</span>
                            <span class="col-6 text text-success"
                                *ngIf="thu_working_hours_alt!='Closed' && thu_working_hours_alt!='Stengt'">{{thu_working_hours_alt}}</span>
                        </div>
                        <div class="row">
                            <span class="col-6">{{ 'txt-friday' | translate }}</span>
                            <span class="col-6 text text-danger"
                                *ngIf="fri_working_hours_alt=='Closed' || fri_working_hours_alt=='Stengt'">{{fri_working_hours_alt}}</span>
                            <span class="col-6 text text-success"
                                *ngIf="fri_working_hours_alt!='Closed' && fri_working_hours_alt!='Stengt'">{{fri_working_hours_alt}}</span>
                        </div>
                        <div class="row">
                            <span class="col-6">{{ 'txt-saturday' | translate }}</span>
                            <span class="col-6 text text-danger"
                                *ngIf="sat_working_hours_alt=='Closed' || sat_working_hours_alt=='Stengt'">{{sat_working_hours_alt}}</span>
                            <span class="col-6 text text-success"
                                *ngIf="sat_working_hours_alt!='Closed' && sat_working_hours_alt!='Stengt'">{{sat_working_hours_alt}}</span>
                        </div>
                        <div class="row">
                            <span class="col-6">{{ 'txt-sunday' | translate }}</span>
                            <span class="col-6 text text-danger"
                                *ngIf="sun_working_hours_alt=='Closed' || sun_working_hours_alt=='Stengt'">{{sun_working_hours_alt}}</span>
                            <span class="col-6 text text-success"
                                *ngIf="sun_working_hours_alt!='Closed' && sun_working_hours_alt!='Stengt'">{{sun_working_hours_alt}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
