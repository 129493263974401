import { Component, OnInit } from '@angular/core';
import { CustomerService } from './../../_service/customer.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthHelper } from '../../_helper/auth';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  customers: any;
  is_loading = false;
  settings = {
    noDataMessage : this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions:false,
    columns: {
      username: {
        // note that belpw instant will show correctly when you load some other page first then load this page
        title: this.translate.instant("lbl-email")
      },
      mobile: {
        title: this.translate.instant("lbl-mobile")
      },
      first_name: {
        title: this.translate.instant("lbl-first-name")
      },
      last_name: {
        title: this.translate.instant("lbl-last-name")
      }
    }
  };


  constructor(
    public customerService: CustomerService,
    public router: Router,
    public auth: AuthHelper,
    private translate: TranslateService

  ) { 

    if(this.auth.getUser().is_allowed_customer!='1'){
      this.router.navigate(['/dashboard']);
    }

  }

  ngOnInit() {
    this.is_loading = true;
    this.customerService.getAll().subscribe(data => {
      let temp:any;
      temp = data;
      this.is_loading = false;
      this.loadCustomers();
    });


  }

  loadCustomers(){
    this.is_loading = true;
    this.customerService.getAll().subscribe(data => {
      this._formatForCal(data);
      this.is_loading = false;
    });
  }

  _formatForCal(data){
    this.customers = data.data;
  }

  onClick_User(event){
    this.router.navigateByUrl("customer.detail?id="+event.data.id);     
    
  }


}
