import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../../_helper/http.form.codec';
import { ParamsHelper } from '../../_helper/params';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    params: any;
    /**
     * 
     */
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) {
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    }

    /**
     * 
     */
    _getBody() {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     */
    getAll() {
        return this.http.get(environment.apiv2_server + "/su/user", { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    get(id) {
        return this.http.get(environment.apiv2_server + "/su/user/" + id, { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param _p 
     */
    update(_p) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.put(environment.apiv2_server + "/su/user", body, { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(data => {
                return data;
            }));

    }


}
