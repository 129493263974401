import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_service/auth.service';
import { AlertService } from '../../../_service/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { StringHelper } from '../../../_helper/string';
import { UrlHelper } from '../../../_helper/url';
import { LocaleHelper } from '../../../_helper/locale';

@Component({
  selector: 'app-customer-signup',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  country_code = {
    "47":"Norway",
    "46":"Sweden",
    "45":"Denmark"
  };

  /**
   * 
   */
  first_name = "";
  last_name = "";
  mobile = "";
  country_mobile_code = "";
  username = "";
  password = "";
  confirm_password = "";
  is_signup_pending = true;
  is_loading = false;
  tnc = false;

  subdomain = "";
  
  /**
   * 
   * @param categoryService 
   */
  constructor(
    private authService: AuthService,
    private url: UrlHelper,
    private locale: LocaleHelper,
    private string: StringHelper,
    private translate: TranslateService,
    private alertService:AlertService
  ) { 

    this.subdomain = this.url.getSubdomain();
    this._resetForm();
  
  }

  /**
   * 
   * @param lang 
   */
  onClick_ChangeLanguage(lang) {
    this.locale.setLocale(lang);
    this.translate.use(lang);
  }


  ngOnInit() {
    this.translate.use(this.locale.getLocale());
  }
  
  /**
   * 
   */
  _resetForm(){

  }

  onClick_Signup(){ 
    let flag = true;  
    if(this.username.trim() == "" || this.username.trim().length < 6 || (this.username.split(' ').length-1) > 0 ){
      this.alertService.error(this.translate.instant("alert-valid-email-is-required"));
      flag = false;
    }
    if(!this.string.isValidNorwayMobile(this.mobile)){
      this.alertService.error(this.translate.instant("alert-valid-mobile-is-required"));
      flag = false;
    }
    if(this.country_mobile_code == ""){
      this.alertService.error(this.translate.instant("A valid Country Mobile Code is required"));
      flag = false;
    }

    if (this.first_name == "" || this.last_name == "") {
      this.alertService.error(this.translate.instant("alert-first-last-are-required"));
      flag = false;
    }

    if(this.password.trim() == "" || (this.password.split(' ').length-1) > 0 || this.password.trim().length < 8){
      this.alertService.error(this.translate.instant("alert-min-password-length-8-is-required"));
      flag = false;
    }
    if(this.password.trim() != this.confirm_password.trim()){
      this.alertService.error(this.translate.instant("alert-confirm-password-does-not-match"));
      flag = false;
    }
    if(this.subdomain.trim() == ""){
      this.alertService.error(this.translate.instant("alert-valid-domain-is-required"));
      flag = false;
    }
    if(!this.tnc){
      this.alertService.error(this.translate.instant("alert-please-agree-with-terms"));
      flag = false;
    }
    

    if(flag == true){
      this._save();
    }
    
  }

  /**
   * 
   */
  _save(){
    let _p = {
      "first_name":this.first_name,
      "last_name":this.last_name,
      "mobile":this.mobile,
      "country":this.country_code[this.country_mobile_code],
      "country_mobile_code":this.country_mobile_code,
      "subdomain":this.subdomain,
      "username":this.username,
      "password":this.password
    };
    this.is_loading = true;
    this.authService.customerSignup(_p).subscribe(data => {
      let temp:any;
      temp = data;
      this.is_loading = false;
      if(temp.success){
        this.is_signup_pending = false;
      }else{
        this.alertService.error(this.translate.instant(temp.msg));
      }
    },
    error=>{
      this.is_loading = false;
      this.alertService.error(this.translate.instant("alert-invalid-username-password"));
    });

  }

}