import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { FormsModule, FormControl } from '@angular/forms';
import { AlertService } from '../../../_service/alert.service';
import { CustomerService } from '../../../_service/customer.service';
import { AuthService } from '../../../_service/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { first } from 'rxjs/operators';

import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { UrlHelper } from '../../../_helper/url';

interface Response {
  success: boolean;
  data: any;
  msg: string
}

@Component({
  selector: 'app-booking-create-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Output() backBtn = new EventEmitter<object>();
  @Output() login = new EventEmitter<object>();
  @Input() business: any;
  submitted = false;
  loading = false;
  returnUrl: string;
  email = "";
  username = "";
  password = "";
  new_password = "";
  first_name = "";
  last_name = "";
  mobile = "";
  subdomain = "";

  constructor(
    private alertService: AlertService,
    private router: Router,
    private customerService: CustomerService,
    private url: UrlHelper,
    private route: ActivatedRoute,
    private authService: AuthService) {

  }

  ngOnInit() {
    this.subdomain = this.url.getSubdomain();
  }

  onClick_Signin() {
    this.submitted = true;
    this.loading = true;

    if (this.subdomain == "") {
      this.alertService.error("Invalid Account Subdomain Provided.");
    }
    else {
      // check if only exsitin cstomers are allowed to login
      // 
      let _x:any;
      if(Number(this.business.enable_internal_booking) == 1){
        _x = "1";
      }
      else{
        _x = "0";
      }
      this.authService.signin(this.email, this.password, this.subdomain,"1",_x).pipe(first()).subscribe(
        (res: Response) => {
          if (res.success) {
            let temp: any;
            temp = res.data;
            this.login.emit(temp);
          } else {
            this.alertService.error(res.msg);
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
    }

  }


  /**
   * 
   */
  onClick_Signup(){
    let _p = {
      "first_name":this.first_name,
      "last_name":this.last_name,
      "mobile":this.mobile,
      "password":this.new_password,
      "country":"Norway",
      "business_id":this.business.id,
      "country_mobile_code":"40",
      "username":this.username
    };
    this.authService.customerSignup(_p).subscribe(data => {
      let temp:any;
      temp = data;
      if(temp.success == false){
        this.alertService.error(temp.msg);
      }else{
        this.login.emit(temp.data);
      }
      
    });
  }

  /**
   * 
   * @param event 
   */
  onClick_BackBtnOnLogin(event) {
    this.backBtn.emit(event);
  }


}
