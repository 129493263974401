import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import { ParamsHelper } from '../_helper/params';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CategoryService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function CategoryService(http) {
        this.http = http;
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    CategoryService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     */
    CategoryService.prototype.getAll = function (business) {
        if (business === void 0) { business = null; }
        var _p;
        if (business != null) {
            _p = {
                "id": business.id,
                "business_id": business.id
            };
        }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/poll/all", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CategoryService.prototype.getAllByBusinessId = function (_p) {
        if (_p === void 0) { _p = null; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/poll/all-by-business-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
 *
 */
    CategoryService.prototype.getAllByBusinessDomain = function (_p) {
        if (_p === void 0) { _p = null; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/poll/all-by-business-domain", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CategoryService.prototype.save = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/do/save", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CategoryService.prototype.delete = function (_p) {
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/do/delete", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CategoryService.prototype.getAllWithServices = function (_p) {
        if (_p === void 0) { _p = null; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/poll/all-with-services", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     */
    CategoryService.prototype.getAllWithServicesByBusinessId = function (_p) {
        if (_p === void 0) { _p = null; }
        var body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/category/poll/all-with-services-by-business-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    CategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryService_Factory() { return new CategoryService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoryService, providedIn: "root" });
    return CategoryService;
}());
export { CategoryService };
