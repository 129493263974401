import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClientModule,HttpParams,HttpHeaders } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import { sample } from 'rxjs/internal/operators/sample';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

    /**
     * 
     */
    headers: HttpHeaders;
    
    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) { 

        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    }

    /**
     * 
     */
    _getBody(){
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     */
    getAll() {
      let user = JSON.parse(localStorage.getItem("currentUser"));
      let body = this._getBody().append("auth_token",user.data.auth_token);
      return this.http.post(environment.server+"/provider/account/poll/config-by-business-id",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param _p 
     */
    save(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token",user.data.auth_token);        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/acl/v1/business/update",body, { headers: this.headers })
              .pipe(map(data => {
                  return data;
              }));
    }

      
    /**
     * 
     * @param temp 
     * @param _p 
     */
    _append(temp,_p){

        Object.keys(_p).forEach(k=>{
            let t = temp.append(k,_p[k]);   
            // below line is important because temp only return
            // it does not update temp 
            temp = t;
        });
        return temp;
    }
    
}
