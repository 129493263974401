import { Component, OnInit } from '@angular/core';
import { AuthHelper } from '../../_helper/auth';
import { LocaleHelper } from '../../_helper/locale';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../_service/auth.service';


@Component({
  selector: 'app-su-layout',
  templateUrl: './su-layout.component.html',
  styleUrls: ['./su-layout.component.css']
})
export class SuLayoutComponent implements OnInit {

  current = "nb";

  constructor(
    public auth: AuthHelper,
    private authService: AuthService,
    private locale: LocaleHelper,
    private meta: Meta,
    private router: Router,
    private translate: TranslateService
  ) {


  }

  /**
   * 
   * @param lang 
   */
  onClick_ChangeLanguage() {
    if (this.current == 'en') {
      this.current = 'nb';
    }
    else {
      this.current = 'en';
    }
    this.translate.use(this.current);
    this.locale.setLocale(this.current);
  }

  /**
   * 
   */
  ngOnInit() {
    //console.log(this.auth.getUser().user_type);
    if(this.auth.getUser().user_type != 'su'){
        this.router.navigateByUrl("/");
    }
    this.current = this.locale.getLocale();
    this.translate.use(this.locale.getLocale());

  }
}