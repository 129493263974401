import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment-timezone';
import { DateHelper } from 'src/app/_helper/date';

@Component({
  selector: 'app-new-booking-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})

export class FinishComponent implements OnInit {

  @Input() config: any;
  title = "Finish";
  notes = "";
  
  constructor(
    public date: DateHelper
  ) { }

  ngOnInit() {

  }


}
