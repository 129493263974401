<div class="card-header bg-secondary pl-2 pr-2">
	<a (click)="onClick_Back()" class="float-left btn btn-sm
		btn-outline-secondary" href="javascript:void(0);"
		translate=""><i class="icon-chevron-left"></i> btn-back</a>
	<div class="text text-center text-muted title-decorative mt-1" translate>
		heading-datetime<img src="assets/img/ajax-loader-dark-gray.gif" class="float-right"
			*ngIf="config.is_loading"></div>
</div>
<div class="card-body pt-0 pl-2 pr-2">
	<div class="card-header mb-2 row bg-light">
		<div class="col-lg-4 col-md-4 col-sm-6"><span class="title-decorative
				text-muted mb-0" translate="">lbl-item</span><span class="text
				text-primary">{{config.service[0].name}}</span></div>
		<div class="col-lg-4 col-md-4 col-sm-6"><span class="title-decorative
				text-muted mb-0" translate="">MIN VARIGHET (TIMER)</span><span class="text
				text-primary">{{config.service[0].duration/60}}</span></div>
		<div class="col-lg-4 col-md-4 col-sm-6"><span class="title-decorative
				text-muted mb-0" translate="">lbl-price</span><span class="text
				text-primary">{{config.service[0].price}}</span><span class="text
				text-primary"> NOK/</span><span class="text text-primary" translate="">hr</span></div>
		<div class="col-lg-12 col-md-12 col-sm-12"><small class="text text-muted">{{config.service[0].description}}</small></div>
	</div>

	<div class="row">
		<div class="col-sm-12 col-md-6 col-lg-4 text-center">
			<div> <p-calendar (onSelect)="onClick_DatetimeChange($event)" [minDate]="minDate"
					[utc]=true [locale]="no" [inline]="true"></p-calendar>
			</div>
			<div class="mt-2">
				<a href="javascript:void(0);" (click)="onClick_ResetDates()" class="btn
					 text-secondary btn-light border" translate="">btn-reset</a>
			</div>
		</div>
		<div class="col-sm-12 col-md-6 col-lg-8">
			<div *ngIf="cals.length<1">
				<div class="text text-danger" translate="">txt-no-slots-available</div>
			</div>
			<div *ngIf="cals.length> 0">
				<div *ngFor="let cal of cals">
					<div class="card shadow-sm  card-sm">
						<div class="card-header pl-2 pr-2 pt-2 pb-2">
							<span class="float-right">
								<img *ngIf="cal.image != '' && cal.image != null && cal.image != 'null'
									&& cal.image != 'undefined'" (click)="open(model_id,cal)"
									class="thumbnail rounded img-fluid" [src]="cal.image">
							</span>
							<span class="float-left title-decorative text-muted mb-0">{{cal.name}}</span>
							<br>
							<small class="text-muted">{{cal.description}}</small>
						</div>
						<div class="card-body pl-2 pr-2 pt-2 pb-2">
								<div *ngIf="cal.slots.length <1"class="text text-danger" translate="">txt-no-slots-available</div>
								<div class="row" *ngIf="cal.slots.length> 0">
								<div class="col-lg-3 col-md-6 col-sm-6 col-6 mb-1" *ngFor="let s of
									cal.slots">
									<h5>
										<a href="javascript:void(0);" (click)="onClick_Slot(s,cal)" class="btn
											btn-md btn-light">{{s.string}}</a>
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #model_id let-modal>
	<div class="modal-header">
		<div class="modal-title" id="modal-basic-title">{{modal_data.name}}</div>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<img src="{{modal_data.image}}" class="shadow rounded img-fluid">
			</div>
		</div>
		<br>
		<div>{{modal_data.description}}</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.close()"
			translate>btn-close</button>
	</div>
</ng-template>