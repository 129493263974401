<div class="card-footer" *ngIf="slots != '' && is_slots_available == true">
    <div class="row ">
        <div class="col-lg-2 col-sm-12">
            <img *ngIf="services?.image != '' && services?.image != null && services?.image != 'null' && services?.image != 'undefined'" class="thumbnail rounded img-fluid" [src]="services?.image">
        </div>
        <div class="col-lg-4 text text-muted">
            {{services?.description}}
        </div>
        <div class="col-lg-3 col-sm-12">
            <span class="title-decorative mb-0 text-muted" translate>lbl-item <span class="text text-primary">{{services?.name}}</span></span>
        </div>
        <div class="col-lg-3 col-sm-12">
            <span class="title-decorative mb-0 text-muted" translate>lbl-max-people <span class="text text-primary">{{services?.max_number_of_people}}</span></span>
        </div>
    </div>
</div>

<div class="card-header" >
    <div class="row">
        <div class="col-lg-12 col-sm-12">
            <button (click)="onClick_ChangeStartTime()" *ngIf="is_start_slot_selected == true" class="btn btn-light text active text-muted"  translate >
                btn-change-start-date</button>
            <p-calendar (onSelect)="onClick_CalendarStartDate($event)" *ngIf="is_start_slot_selected == false" [locale]="no"
                [(ngModel)]="datetime" [showIcon]="true"></p-calendar>
        </div>
    </div>
</div>

<div class="" *ngFor="let cal of slots">
    <hr class="mb-0 pb-0 pt-0 mt-0">
    <div class="card-header bg-light title-decorative text-primary">
        <div class="row">
            <span class="col-lg-2 col-sm-4">
                <img *ngIf="cal?.image != '' && cal?.image != 'null' && cal?.image != null " (click)="open(model_id,cal)" class="thumbnail  rounded img-fluid"
                    [src]="cal.image">
            </span>
            <span class="col-lg-8 col-sm-4">
                {{cal.name}}<br>
                <small class="text text-muted">{{cal.description}}</small>
            </span>
            <span class="col-lg-2 col-sm-4">
                <small class="text text-muted">{{current_date.format('LL')}}</small>
            </span>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-3 col-3" id="slot_id_{{slot.slot_minute}}" *ngFor="let slot of cal.slots">
                <h5>
                    <a href="javascript:void(0);"  style="margin-bottom: 12px" class="btn btn-md btn-light  text text-primary"
                        (click)="onClick_Slot(slot.cal_id,slot.slot_minute,slot.slot_string,cal.name)">{{slot.slot_string}}</a>
                </h5>
            </div>
        </div>
    </div>
</div>

<div *ngIf="is_show_error == true" class="card-body ">
    <div class="alert alert-danger">{{this.err}}</div>
</div>


<ng-template #model_id let-modal>
    <div class="modal-header">
        <div class="modal-title" id="modal-basic-title">{{modal_title}}</div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <img src="{{modal_image}}" class=" shadow rounded img-fluid">
            </div>
        </div>

        <br>
        <div>{{modal_description}}</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')" translate>btn-close</button>
    </div>
</ng-template>