import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarService } from '../../_service/calendar.service';
import { ServiceService } from '../../_service/service.service';
import { AlertService } from '../../_service/alert.service';
import { CalendarModule } from 'primeng/calendar';
import { FileHelper } from '../../_helper/file';
import { NgxSmartModalService } from 'ngx-smart-modal';

import { Subject } from 'rxjs/Subject';
import { TranslateService } from '@ngx-translate/core';
import { BusinessService } from 'src/app/_service/business.service';


@Component({
  selector: 'app-config-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  changeDt: Subject<boolean> = new Subject();

  /**
   * 
   */
  is_loading = false;
  all_calendars = [];
  name = "";
  description = "";
  service_temp_image = "";
  slot = 30;
  cal_service_type = "";
  is_image_exists = false;
  selected_calendar: any;
  all_services: any;
  is_calendar_selected = false;
  title = "";
  title_schedule = "";
  is_add_new_calendar = false;
  is_available_for_customers = 1;
  is_enabled = 0;
  is_enable_medium = 0;
  is_enable_exivo_sms = 0;
  is_schedule = false;
  no: any;
  en: any;
  is_a_resource_calendar = 0;
  is_enable_exivo_lock = 0;
  exivo_access_group = "";
  exivo_component = "";
  exivo_component_sms = "";
  exivo_medium = "";
  exivo_component_stack = [];
  exivo_medium_stack = [];
  plan_stats: any;

  /**
   * 
   * @param calendarService 
   */
  constructor(
    private router: Router,
    private calendarService: CalendarService,
    private serviceService: ServiceService,
    public translate: TranslateService,
    public businessService: BusinessService,
    private elementRef: ElementRef,
    public modal: NgxSmartModalService,
    public file: FileHelper,
    private alertService: AlertService
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this._resetCalendarForm();
    this._getAllCal();

  }

  /**
   * 
   */
  ngOnInit() {
    this.en = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear'
    };
    this.no = {
      firstDayOfWeek: 1,
      dayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
      dayNamesShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
      dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
      monthNames: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
      today: 'I dag',
      clear: 'Klar'
    };

    this._getBusiness();
  }

  /**
   * 
   */
  _getBusiness() {
    this.businessService.getWhosLoggedIn().subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.data.plan) {
        this.plan_stats = JSON.parse(JSON.parse(temp.data.plan).payload);
      }
    });
  }

  /**
   * 
   * @param dt 
   */
  onClick_DatetimeChange(dt) {
    this.changeDt.next(dt);
  }

  /**
   * 
   */
  _resetCalendarForm() {
    this.selected_calendar = {
      "id": "", "name": "", "slot": 30, "description": "", "is_enable_exivo_lock": 0,
      "is_enable_medium": 0,
      "is_enable_exivo_sms": 0,
      "exivo_access_group": this.exivo_access_group,
      "exivo_component": this.exivo_component,
      "exivo_medium": this.exivo_medium,
      "exivo_component_stack": this.exivo_component_stack,
      "exivo_component_sms": this.exivo_component_sms,
      "exivo_medium_stack": this.exivo_medium_stack,
      "is_a_resource_calendar": 0,
      "image": "",
      "is_enabled": 1,
      "is_available_for_customers": 1
    };
    this.is_enabled = 1;
    this.is_enable_exivo_lock = 0;
    this.is_enable_medium = 0;
    this.is_enable_exivo_sms = 0;
    this.exivo_access_group = "";
    this.exivo_component = "";
    this.exivo_component_stack = [];
    this.is_a_resource_calendar = 0;
    this.exivo_medium = "";
    this.exivo_medium_stack = [];
    this.cal_service_type = "";
    this.is_add_new_calendar = true;
    this.is_calendar_selected = false;
    this.is_available_for_customers = 1;
    this.title = "Add Calendar";
    this.name = "";
    this.description = "";
    this.slot = 30;

  }

  /**
   * 
   */
  _getAllCal() {
    this.is_loading = true;
    this.calendarService.getAll().subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      this.all_calendars = temp.data;
    });

  }

  /**
  * 
  * @param event 
  */
  selectImage(event: any) {
    let self = this;
    this.file.onFileChanged(event, function (url, filedata) {
      self.selected_calendar.image = url;
      self.service_temp_image = filedata;
    });
  }

  /**
   * 
   */
  onClick_DeleteImage() {
    this.is_loading = true;
    this.selected_calendar.image = "";
    this.calendarService.save(this.selected_calendar).subscribe(data => {
      let temp: any;
      temp = data;
      //this._getAllService();
      if (temp.success) {
        this.alertService.success(temp.msg);
        // this._get(temp.data.id);
      } else {
        this.alertService.error(temp.msg);
      }
      this.is_loading = false;
    });

  }

  /**
   * 
   */
  onClick_AddComponent() {
    var check = this._hasWhiteSpace(this.exivo_component);
    if (check) {
      this.alertService.error(this.translate.instant("alert-invalid-value"));
      return;
    }

    if (this.exivo_component != '') {
      this._queueComponentList();
      this.exivo_component = "";
    }
    else {
      this.alertService.error(this.translate.instant("alert-invalid-value"));
    }
  }

  /**
   * 
   */
  _queueComponentList() {

    if (this._checkIfSameComponentExistsInStack()) {
      this.alertService.error(this.translate.instant("alert-value-already-exists"));
      return;
    }

    this.exivo_component_stack.push(this.exivo_component);
    this._makeHtmlList(this.exivo_component_stack, ".component_list");

  }

  /**
   * 
   * @param index 
   * @param s 
   * @param e 
   */
  _checkIfSameComponentExistsInStack() {
    let flag = false;
    var _t = this;
    this.exivo_component_stack.forEach((v, i) => {
      if (v == _t.exivo_component) {
        flag = true;
      }
    });
    return flag;
  }

  /**
   * 
   */
  onClick_DeleteComponent(event) {
    // remove html el
    let elid = event.srcElement.id;
    let el = this.elementRef.nativeElement.querySelector("#" + elid);
    el.remove();

    // now remove from stack
    let id = elid;

    this.exivo_component_stack.forEach((b, i) => {
      if ("_" + b.replace(/-/g, "") == id) {
        this.exivo_component_stack.splice(i, 1);
      }
    });
  }


  /**
   * 
   */
  onClick_AddMedium() {
    var check = this._hasWhiteSpace(this.exivo_medium);
    if (check) {
      this.alertService.error(this.translate.instant("alert-invalid-value"));
      return;
    }
    if (this.exivo_medium != '') {
      this._queueMediumList();
      this.exivo_medium = "";
    }
    else {
      this.alertService.error(this.translate.instant("alert-invalid-value"));
    }
  }

  /**
   * 
   */
  _queueMediumList() {

    if (this._checkIfSameMediumExistsInStack()) {
      this.alertService.error(this.translate.instant("alert-value-already-exists"));
      return;
    }

    this.exivo_medium_stack.push(this.exivo_medium);
    this._makeHtmlList(this.exivo_medium_stack, ".medium_list");
  }

  /**
   * 
   * @param stack 
   * @param el 
   */
  _makeHtmlList(stack, el) {
    var d1 = this.elementRef.nativeElement.querySelector(el);
    d1.innerHTML = "";
    stack.forEach((v, i) => {
      var trim_id = v.replace(/-/g, "");
      d1.insertAdjacentHTML('beforeend', '<div class="form-group"><div class=""><button id="_' + trim_id + '" class="btn btn-outline-secondary btn-md">' + v + '</button></div></div>');
      if (el == '.medium_list') {
        this.elementRef.nativeElement.querySelector('#_' + trim_id).addEventListener('click', this.onClick_DeleteMedium.bind(this));
      }
      else {
        this.elementRef.nativeElement.querySelector('#_' + trim_id).addEventListener('click', this.onClick_DeleteComponent.bind(this));
      }
    });

  }

  /**
   * 
   * @param index 
   * @param s 
   * @param e 
   */
  _checkIfSameMediumExistsInStack() {
    let flag = false;
    var _t = this;
    this.exivo_medium_stack.forEach((v, i) => {
      if (v == _t.exivo_medium) {
        flag = true;
      }
    });
    return flag;
  }

  /**
   * 
   */
  onClick_DeleteMedium(event) {
    // remove html el
    let elid = event.srcElement.id;
    let el = this.elementRef.nativeElement.querySelector("#" + elid);
    el.remove();

    // now remove from stack
    let id = elid;

    this.exivo_medium_stack.forEach((b, i) => {
      if ("_" + b.replace(/-/g, "") == id) {
        this.exivo_medium_stack.splice(i, 1);
      }
    });
  }

  /**
   * 
   * @param s 
   */
  _hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
  }

  /**
   * 
   */
  onClick_Save() {
    if (this.name.trim() == "") {
      this.alertService.error(this.translate.instant("alert-name-is-required"));
      return false;
    }
    this.is_loading = true;
    this.selected_calendar.name = this.name;
    this.selected_calendar.description = this.description;
    this.selected_calendar.is_available_for_customers = this.is_available_for_customers ? 1 : 0;
    this.selected_calendar.is_enable_exivo_lock = this.is_enable_exivo_lock ? 1 : 0;
    this.selected_calendar.is_enable_medium = this.is_enable_medium ? 1 : 0;
    this.selected_calendar.is_enable_exivo_sms = this.is_enable_exivo_sms ? 1 : 0;
    this.selected_calendar.exivo_access_group = this.exivo_access_group;
    this.selected_calendar.exivo_component_stack = this.exivo_component_stack;
    this.selected_calendar.exivo_component_sms = this.exivo_component_sms;
    this.selected_calendar.exivo_medium_stack = this.exivo_medium_stack;
    this.selected_calendar.slot = this.slot;
    this.selected_calendar.is_a_resource_calendar = this.is_a_resource_calendar ? 1 : 0;
    this.selected_calendar.is_enabled = this.is_enabled ? 1 : 0;
    this.calendarService.save(this.selected_calendar).subscribe(data => {
      let temp: any;
      temp = data;
      this.selected_calendar.id = temp.data.id;
      this.is_loading = false;
      this._getAllCal();
      this._getCal(this.selected_calendar.id);
      if (temp.success) {
        this.alertService.success(temp.msg);
      }
      else {
        this.alertService.error(temp.msg);
      }
      // this._resetCalendarForm();
      // this.is_add_new_calendar = true;
      // this.is_calendar_selected = false;

    });
  }

  onClick_TryDelete() {
    this.modal.getModal('cal_id').open();
  }

  onClick_ModalClose() {
    this.modal.getModal('cal_id').close();
  }

  /**
   * 
   */
  onClick_Delete() {
    this.is_loading = true;
    this.calendarService.delete(this.selected_calendar).subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      this._getAllCal();
      this._resetCalendarForm();
      this.alertService.success(temp.msg);
    });
    this.modal.getModal('cal_id').close();


  }

  /**
   * 
   */
  onClick_AddCalendar() {
    this._resetCalendarForm();
    this.title = this.translate.instant("btn-add-calendar");

    this.is_add_new_calendar = true;
    this.is_calendar_selected = false;

    // below 2 lines will give error if put in resetCalendarForm method
    // why? --> reset calendar form method is also called in constructor, and that time html component does not exits 
    this._makeHtmlList([], ".component_list");
    this._makeHtmlList([], ".medium_list");


  }

  /**
   * 
   * @param event 
   * @param name 
   * @param id 
   */
  onClick_Calendar(event, name, id) {
    this.is_loading = true;
    this._getCal(id);
  }

  /**
   * 
   * @param id 
   */
  _getCal(id) {

    // clear component and medium list
    this.calendarService.getById(id).subscribe(data => {
      this.is_calendar_selected = true;
      let temp: any;
      temp = data;
      this.selected_calendar = temp.data;
      if (this.selected_calendar.image != "" && this.selected_calendar.image != "null" && this.selected_calendar.image != null) {
        this.is_image_exists = true;
      }
      else {
        this.is_image_exists = false;
      }
      //console.log(this.selected_calendar);
      this.name = this.selected_calendar.name;
      this.description = this.selected_calendar.description;
      this.is_a_resource_calendar = Boolean(Number(this.selected_calendar.is_a_resource_calendar)) ? 1 : 0;
      this.is_available_for_customers = Boolean(Number(this.selected_calendar.is_available_for_customers)) ? 1 : 0;
      this.is_enabled = Boolean(Number(this.selected_calendar.is_enabled)) ? 1 : 0;
      this.is_enable_exivo_lock = Boolean(Number(this.selected_calendar.is_enable_exivo_lock)) ? 1 : 0;
      this.is_enable_medium = Boolean(Number(this.selected_calendar.is_enable_medium)) ? 1 : 0;
      this.is_enable_exivo_sms = Boolean(Number(this.selected_calendar.is_enable_exivo_sms)) ? 1 : 0;
      this.exivo_access_group = this.selected_calendar.exivo_access_group;
      this.exivo_component_sms = this.selected_calendar.exivo_component_sms;

      this._makeHtmlList([], ".component_list");
      this._makeHtmlList([], ".medium_list");
      this.exivo_component_stack = [];
      this.exivo_medium_stack = [];
      if (this.selected_calendar.cal_service_type != '1' && this.selected_calendar.exivo_component != null && this.selected_calendar.exivo_component != 'null' && this.selected_calendar.exivo_component != "") {
        this.exivo_component_stack = this.selected_calendar.exivo_component.split(",");
        this._makeHtmlList(this.exivo_component_stack, ".component_list");
      }
      if (this.selected_calendar.cal_service_type != '1' && this.selected_calendar.exivo_medium != null && this.selected_calendar.exivo_medium != 'null' && this.selected_calendar.exivo_medium != "") {
        this.exivo_medium_stack = this.selected_calendar.exivo_medium.split(",");
        this._makeHtmlList(this.exivo_medium_stack, ".medium_list");
      }

      this.slot = Number(this.selected_calendar.slot);
      this.cal_service_type = this.selected_calendar.cal_service_type;

      this.title = "Edit Calendar: " + temp.data.name;
      this.title_schedule = "Edit Schedule For " + temp.data.name;

      this._getAllServices();
    });
  }



  /**
   * 
   */
  _getAllServices() {

    this.serviceService.getAllWithCalendars(this.selected_calendar.cal_service_type).subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;

      if (temp.data != null) {
        temp.data.forEach(k => {
          k.class = "btn-secondary float-right btn btn-sm";
          k.title = "btn-unassign";
          Object.keys(k).forEach(k2 => {
            if (k2 == 'calendars') {
              k[k2].forEach(cal => {
                if (cal.id == this.selected_calendar.id) {
                  k.class = "btn-light text-muted border float-right btn btn-sm";
                  k.title = "btn-assign";
                }
              });
            }
          });
        });
      }
      else {
        temp.data = [];
      }
      this.all_services = temp.data;
    });

  }
  /**
   * 
   */
  onClick_Schedule() {
    this.is_schedule = true;
  }

  /**
   * 
   */
  onClick_BackToEditCalendar() {
    this.is_schedule = false;
    this._getCal(this.selected_calendar.id);
  }

  /**
   * 
   * @param event 
   * @param i 
   */
  onClick_SelectService(event, i, service) {
    let el = $(event.target);
    let id = event.target.id.substr(3);
    let assign = false;
    if (service.title == 'btn-unassign') {
      assign = true;
    }
    else {
      assign = false;
    }
    this.is_loading = true;
    this.calendarService.toggleService(assign, id, this.selected_calendar.id).subscribe(data => {
      let temp: any;
      temp = data;
      this.alertService.success(temp.msg);
      this.is_loading = false;
      if (assign == true) {
        this.selected_calendar.cal_service_type = service.service_type;
      } else {
        this.selected_calendar.cal_service_type = 0;
      }
      this.all_services = [];
      this._getAllCal();
      this._getCal(this.selected_calendar.id);
    });

  }

}
