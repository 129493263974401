import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signin-layout',
  templateUrl: './signin-layout.component.html',
  styleUrls: ['./signin-layout.component.css']
})
export class SigninLayoutComponent implements OnInit {
  current = "nb";
  
  constructor(private translate: TranslateService) {
    translate.use(this.current);
  }

  ngOnInit() {

  }

}
