<div class="card-body">
	<div class="row">
		<div class="col-sm-12 col-md-12 col-lg-12">
			<h3 translate>heading-booked-successfully</h3>
			<div translate>txt-booked-successfully-information</div>
			<hr>
			<div class="card borderless card-header bg-secondary" *ngFor="let b of config.confirmed_bookings">
				<div class="row">
					<div class="col">
						<span class="">{{b.service_name}}</span>
						<span class="float-right text-muted">
							<h4>{{b.price}} NOK</h4>
						</span>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<span class="text text-primary">{{date.formatForNorway(b.start_timestamp_in_minute * 60)}}</span>
						<span class="btn text text-muted btn-sm"> TO </span>
						<span class="text text-primary">{{date.formatForNorway(b.end_timestamp_in_minute * 60)}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
