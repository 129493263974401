import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateHelper } from 'src/app/_helper/date';
import { AuthHelper } from 'src/app/_helper/auth';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/_service/su/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  stats:any;
  is_loading = false;

  constructor(
    public translate: TranslateService,
    public date: DateHelper,
    public dashboard: DashboardService,
    public auth: AuthHelper,
    public router: Router

  ) { }

  ngOnInit() {
    this.is_loading = true;
    this.dashboard.getAll().subscribe(data=>{
      let temp:any;
      temp = data;
      this.is_loading = false;
      this.stats = temp.payload;
    });
  }

}
