import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ParamsHelper } from '../_helper/params';

import { HttpFormEncodingCodec } from './../_helper/http.form.codec';

@Injectable({
    providedIn: 'root'
})
export class ServiceService {

    params: any;
    /**
     * 
     */
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient, ) {
        this.params = new ParamsHelper();

        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    }

    /**
     * 
     */
    _getBody() {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     * @param _p 
     */
    save(_p) {
        let body = this.params.make(this._getBody(), _p);

        return this.http.post(environment.server + "/provider/service/do/save-step-1", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param _p 
     */
    delete(_p) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/do/delete", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     */
    getAll(_p = null) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/all", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    validateForBooking(_p = null) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/do/validate-for-booking", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllByBusinessId(_p = null) {

        let body = this.params.make(this._getBody(), _p);

        return this.http.post(environment.server + "/provider/service/poll/all-by-business-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }



    /**
     * 
     */
    getById(id) {
        let _p = {
            "id":id
        }
        let body = this.params.make(this._getBody(), _p);

        return this.http.post(environment.server + "/provider/service/poll/by-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     */
    getAllByCalendarId(id) {
        let _p = {
            "calendar_id":id
        }
        let body = this.params.make(this._getBody(), _p);

        return this.http.post(environment.server + "/provider/service/poll/all-by-calendar-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     */
    getAllWithCalendars(type = null) {
        let _p = {
            "type":type
        }
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/all-with-calendars", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllByCategoryId(_p = null) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/all-by-category-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
     getAvailableSlots2(_p = {}) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/polll/get-available-slots", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
     getAvailableRooms(_p = {}) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/polll/get-available-rooms", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     * @param username 
     * @param password 
     */
    getAvailableSlots(selected_services_id, date, business = null) {
        let _p = {
            "selected_services_id":selected_services_id,
            "date":date,
            "business_id":business.id
        }
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/get-available-slots", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAvailableDates____(selected_services_id, date) {
        let _p = {
            "selected_services_id":selected_services_id,
            "date":date
        }
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/get-available-dates-for-rental-24-hr", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    getAvailableSlotsForReschedule(_p) {
        let body = this.params.make(this._getBody(), _p);
        return this.http.post(environment.server + "/provider/service/poll/get-available-slots-for-reschedule", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     */
    toggleCategory(assign, category_id, id) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("category_id", category_id)
            .append("service_id", id).append("auth_token", user.data.auth_token);
        let api = "deassign-category";
        if (assign) {
            api = "assign-category";
        }
        return this.http.post(environment.server + "/provider/service/do/" + api, body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


}
