<nav aria-label="breadcrumb" role="navigation" class="bg-primary text-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);" translate>txt-dashboard</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page" translate>
                        txt-calendar-booking-view-and-graphical-reports
                    </li>
                    <li class="breadcrumb-item active" *ngIf="is_loading ===
                        true" aria-current="page" translate>
                        <img src="assets/img/ajax-loader-blue.gif" translate>
                        txt-loading
                    </li>
                </ol>
            </div>
            <!--end of col-->
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</nav>

<!--<section class="bg-white space-sm pb-4">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-auto">
                <h1 class="h2">{{title}} {{current_calendar_title}}
                </h1>
            </div>
        </div>
    </div>
</section>
-->
<section class="flush-with-above space-0">
    <div class="bg-white">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" *ngIf="auth.getUser().user_type != 'customer'">
                            <a class="nav-link active " id="resource-tab" data-toggle="tab" href="#resource" role="tab"
                                aria-controls="resource" (click)="onClick_Tab('resource')"  aria-selected="true" translate>btn-resource-cal</a>
                        </li>
                        <li class="nav-item " *ngIf="auth.getUser().user_type != 'customer'">
                            <a class="nav-link " id="calendar-tab" data-toggle="tab" href="#calendar" role="tab"
                                aria-controls="calendar" (click)="onClick_Tab('calendar')" aria-selected="true" translate>btn-calendar</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link {{mark_customer_tab_active}}" id="bookings-tab" data-toggle="tab"
                                href="#bookings" role="tab" (click)="onClick_Tab('history')" aria-controls="bookings" aria-selected="false"
                                translate>btn-history</a>
                        </li>
                        <li class="nav-item" *ngIf="auth.getUser().user_type != 'customer'">
                            <a class="nav-link" id="logs-tab" data-toggle="tab" href="#logs" role="tab"
                                aria-controls="logs" aria-selected="false" translate>btn-logs</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="space-0" *ngIf="auth.getUser().user_type != 'customer'">
    <div class="bg-white">
        <div class="container pt-2 ">
            <div class="">
                <div class="col-12 form-check form-check-inline mb-1">
                    <div class="custom-control custom-checkbox mr-2
                        custom-checkbox-switch">
                        <input type="hidden" [(ngModel)]="filters.confirmed">
                        <input type="checkbox" class="custom-control-input" [checked]="filters.confirmed
                        === boolean_status[0].value" (change)="$event.target.checked?
                        (filters.confirmed=
                        boolean_status[0].value) :
                        (filters.confirmed=
                        boolean_status[1].value);loadEvents()" id="confirmed">
                        <label class="custom-control-label" for="confirmed" translate>CONFIRMED</label>
                    </div>
                    <div class="custom-control custom-checkbox mr-2
                        custom-checkbox-switch">
                        <input type="hidden" [(ngModel)]="filters.finished">
                        <input type="checkbox" class="custom-control-input" [checked]="filters.finished
                        === boolean_status[0].value" (change)="$event.target.checked?
                        (filters.finished=
                        boolean_status[0].value) :
                        (filters.finished=
                        boolean_status[1].value);loadEvents()" id="finished">
                        <label class="custom-control-label" for="finished" translate>FINISHED</label>
                    </div>
                    <div class="custom-control custom-checkbox mr-2
                        custom-checkbox-switch">
                        <input type="hidden" [(ngModel)]="filters.cancelled">
                        <input type="checkbox" class="custom-control-input" [checked]="filters.cancelled
                        === boolean_status[0].value" (change)="$event.target.checked?
                        (filters.cancelled=
                        boolean_status[0].value) :
                        (filters.cancelled=
                        boolean_status[1].value);loadEvents()" id="cancelled">
                        <label class="custom-control-label" for="cancelled" translate>CANCELLED</label>
                    </div>
                    <div class="custom-control custom-checkbox mr-2
                        custom-checkbox-switch">
                        <input type="hidden" [(ngModel)]="filters.unpaid">
                        <input type="checkbox" class="custom-control-input" [checked]="filters.unpaid
                        === boolean_status[0].value" (change)="$event.target.checked?
                        (filters.unpaid=
                        boolean_status[0].value) :
                        (filters.unpaid=
                        boolean_status[1].value);loadEvents()" id="unpaid">
                        <label class="custom-control-label" for="unpaid" translate>UNPAID</label>
                    </div>
                    <div class="custom-control custom-checkbox mr-2
                        custom-checkbox-switch">
                        <input type="hidden" [(ngModel)]="filters.breaks">
                        <input type="checkbox" class="custom-control-input" [checked]="filters.breaks
                        === boolean_status[0].value" (change)="$event.target.checked?
                        (filters.breaks=
                        boolean_status[0].value) :
                        (filters.breaks=
                        boolean_status[1].value);loadEvents()" id="breaks">
                        <label class="custom-control-label" for="breaks" translate>BREAKS</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="mt-2 flush-with-above height-80 d-block">
    <div class="tab-content">

        <div *ngIf="auth.getUser().user_type != 'customer'" class="tab-pane fade active   show" id="resource"
            role="tabpanel" aria-labelledby="overview-tab">
            <div class="container">

                <div class="col" *ngIf="is_slot_available == false">
                    <div class="row ">
                        <full-calendar *ngIf="resourceCalendarOptions" #resourcefullcalendar class=""
                            [timeZone]="resourceCalendarOptions.timeZone"
                            schedulerLicenseKey="0480519846-fcs-1573644876"
                            [defaultView]="resourceCalendarOptions.defaultView"  height='parent'
                            [locale]="resourceCalendarOptions.locale" [events]="resourceCalendarOptions.events"
                            [resources]="resourceCalendarOptions.resources" [header]="resourceCalendarOptions.header"
                            [customButtons]="resourceCalendarOptions.customButtons" 
                            [plugins]="resourceCalendarOptions.plugins" (eventClick)="eventClick($event)"
                            (clickButton)="clickButton($event)"></full-calendar>

                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="auth.getUser().user_type != 'customer'" class="tab-pane fade  show  " id="calendar" role="tabpanel"
            aria-labelledby="overview-tab">
            <div class="container">
                <!-- <div class="text text-muted mb-2" *ngIf="is_show_trial_ends_warning"><small>
                    Your trial period ends in {{trial_ends}} days. We will try to charge CC payment after trial ends.</small></div>-->
                <div class="row" *ngIf="auth.getUser().user_type != 'customer'">
                    <div class="col-8">
                        <div class="input-group">
                            <select class="form-control col-sm-12 col-md-8
                                col-lg-8 custom-select" (change)="changeCalendar($event.target.value)"
                                [(ngModel)]="current_calendar">
                                <option *ngFor="let cal of all_calendars" [value]="cal.id">{{cal.name}}</option>
                            </select>
                            <div class="input-group-append">
                                <span class="input-group-text title-decorative" style="display:flex">
                                    <img src="assets/img/ajax-loader-dark-gray.gif" *ngIf="is_loading == true">
                                    &nbsp;{{calendar_title}}&nbsp;<span translate="">btn-bookings</span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-right">
                        <a [routerLink]="['/booking.new']" class="col-sm-12
                            col-md-8 col-lg-8 btn btn-success" translate>
                            <i class="fas fa-plus"></i> btn-new</a>
                    </div>
                </div>

                <hr class="mb-2 mt-2" *ngIf="auth.getUser().user_type !=
                    'customer'">

                <div class="row" *ngIf="is_slot_available == false">
                    <div class="col-12">
                        <full-calendar *ngIf="calendarOptions" #fullcalendar [timeZone]="calendarOptions.timeZone"
                            schedulerLicenseKey="0480519846-fcs-1573644876" [defaultView]="calendarOptions.defaultView"
                            [editable]="false" height='parent' [locale]="calendarOptions.locale"
                            [events]="calendarOptions.events" [resources]="calendarOptions.resources"
                            [header]="calendarOptions.header" [customButtons]="calendarOptions.customButtons"
                             [plugins]="calendarOptions.plugins"
                            (eventClick)="eventClick($event)" (clickButton)="clickButton($event)"></full-calendar>

                        <!-- 
                        <div *ngIf="calendarOptions" class="col-12">
                            <ng-fullcalendar #ucCalendar
                                [options]="calendarOptions"
                                [(eventsModel)]="events"
                                (eventClick)="eventClick($event.detail)"
                                (eventDrop)="updateEvent($event.detail)"
                                (clickButton)="clickButton($event.detail)"></ng-fullcalendar>
                        </div>
                        -->

                    </div>
                </div>

                <div class="row" *ngIf="is_slot_available == true">
                    <div class="col">
                        <div class="card bg-secondary card-sm">
                            <div class="card-body">
                                <span class="title-decorative" translate>heading-reschedule-booking</span>
                                <div class="row">
                                    <div class="col-lg-4 col-sm-12 col-md-4">
                                        <span class="text text-muted" translate>lbl-new-date</span>
                                        <span class="text text-primary">: {{reschedule_selected_date_string}}</span>
                                    </div>
                                    <div class="col-sm-12 col-lg-4 col-md-4">
                                        <span class="text text-muted" translate>lbl-service</span>
                                        <span class="text text-primary">:
                                            {{selected_booking_for_reschedule.service}}</span>
                                    </div>
                                    <div class="col-sm-12 col-lg-4 col-md-4">
                                        <span class="text text-muted" translate>lbl-customer</span>
                                        <span class="text text-primary">:
                                            {{selected_booking_for_reschedule.email}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card card-sm" [hidden]="!is_loading">
                            <div class="card-body">
                                <div class="row col ">
                                    <button class="btn btn-lg text text-muted">
                                        <img class="responsive img-responsive"
                                            src="assets/img/ajax-loader-light-gray.gif"> &nbsp; please wait ...
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card card-sm" [hidden]="is_loading" *ngFor="let cal of slots">
                            <div class="card-header title-decorative
                                text-primary">{{cal.name}}
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 col-sm-3
                                        col-3" id="slot_id_{{slot.id}}" *ngFor="let slot of cal.slots">
                                        <h5>
                                            <a href="javascript:void(0);" style="margin-bottom: 12px" class="btn btn-md btn-light text
                                                text-primary"
                                                (click)="onClick_RescheduleSlot(slot.cal_id,slot.slot_minute,slot.slot_string,cal.name)">{{slot.slot_string}}</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn btn-lg btn-secondary" [hidden]="is_loading" (click)="onClick_RescheduleCancel()"
                            translate>lbl-cancel</div>
                    </div>
                </div>
            </div>
        </div>

        <!--end of tab pane-->
        <div class="tab-pane fade {{mark_customer_tab_active}} show" id="bookings" role="tabpanel"
            aria-labelledby="notifications-tab">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-3 col-lg-3" *ngIf="auth.getUser().user_type != 'customer'">
                        <div class="card shadow-sm border rounded card-sm ">
                            <ul class="list-group">
                                <li class="list-group-item bg-light">
                                    <span class="title-decorative" translate>heading-advanced-search</span>
                                </li>
                                <li class="list-group-item">
                                    <select class="form-control custom-select"
                                        (change)="changeCalendar($event.target.value)" [(ngModel)]="current_calendar">
                                        <option [value]="0" translate>txt-all</option>
                                        <option *ngFor="let cal of
                                            all_calendars" [value]="cal.id">{{cal.name}}</option>
                                    </select>
                                    <!-- 
                                    <div style="margin-top: 8px;" class="custom-control custom-checkbox
                                        custom-checkbox-switch">
                                        <input type="hidden" [(ngModel)]="show_deleted">
                                        <input type="checkbox" class="custom-control-input" [checked]="show_deleted ===
                                            boolean_status[0].value" (change)="onChange_AllCal($event)"
                                            id="show_deleted">
                                        <label class="custom-control-label" for="show_deleted"
                                            translate>lbl-include-deleted</label>
                                    </div>
                                    -->
                                </li>
                                <li class="list-group-item">
                                    <p-calendar (onSelect)="onClick_RunAdvancedSearch()" [(ngModel)]="from_date"
                                        placeholder="{{'lbl-from'|translate}}"></p-calendar>
                                </li>
                                <li class="list-group-item">
                                    <p-calendar (onSelect)="onClick_RunAdvancedSearch()" [(ngModel)]="to_date"
                                        placeholder="{{'lbl-to'|translate}}"></p-calendar>
                                </li>
                                <li class="list-group-item bg-light">
                                    <button class="btn btn-secondary btn-md" (click)="onClick_DownloadCsv()"><i
                                            class="fas fa-download"></i>
                                        CSV</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-12" *ngIf="auth.getUser().user_type != 'customer'">
                        <div class="row" *ngIf="auth.getUser().user_type !=
                            'customer'">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <span class="title-decorative">{{calendar_title}}</span>
                            </div>
                           <!-- <div class="col-lg-6 col-md-3 col-sm-6 text-right">
                                <a [routerLink]="['/booking.new']" class="btn
                                    btn-success" translate><i class="fas
                                        fa-plus"></i>
                                    btn-new
                                </a>
                            </div>
                        -->
                        </div>
                        <hr class="mb-2 mt-2" *ngIf="auth.getUser().user_type !=
                            'customer'">
                        <div class="row">
                            <div class="col">
                                <ng2-smart-table class="table-responsive" noDataMessage="No Data"
                                    (userRowSelect)="onClick_Booking($event)" [settings]="settings"
                                    [source]="event_list"></ng2-smart-table>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="auth.getUser().user_type == 'customer'">
                        <div class="row ">
                            <div class="col">
                                <ng2-smart-table class="table-responsive" noDataMessage="No Data"
                                    (userRowSelect)="onClick_Booking($event)" [settings]="settings"
                                    [source]="event_list"></ng2-smart-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade show" id="logs" role="tabpanel" aria-labelledby="notifications-tab">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-lg-3 col-sm-12" *ngIf="auth.getUser().user_type != 'customer'">
                        <div class="card card-sm">
                            <ul class="list-group">
                                <li class="list-group-item bg-light">
                                    <button class="btn btn-block btn-link text
                                        text-muted" (click)="onClick_SMSLogs()" translate>btn-sms-logs <span
                                            class="text text-primary">({{sms_history_list.length}})</span></button>
                                </li>
                                <li class="list-group-item bg-light">
                                    <button class="btn btn-block btn-link text
                                        text-muted" (click)="onClick_EmailLogs()" translate>btn-email-logs <span
                                            class="text text-primary">({{email_history_list.length}})</span></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9 col-sm-12 col-lg-9">
                        <div class="row" *ngIf="is_on_sms_logs">
                            <div class="col">
                                <small class="text text-muted" translate=""
                                    translate="">txt-displays-all-sms-sent-by-you</small>
                                <ng2-smart-table class="table-responsive" [settings]="sms_table_settings"
                                    [source]="sms_history_list"></ng2-smart-table>
                            </div>
                        </div>
                        <div class="row" *ngIf="is_on_email_logs">
                            <div class="col">
                                <small class="text text-muted"
                                    translate="">txt-displays-all-emails-received-or-sent-by-you</small>
                                <ng2-smart-table class="table-responsive" [settings]="email_table_settings"
                                    [source]="email_history_list"></ng2-smart-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end of tabs content-->
</div>
<!--end of section-->