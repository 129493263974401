 <div class="row">
	<div class="col-3">
		<div class="card card-sm shadow-sm border">
			<div class="card-header p-2 title-decorative bg-light">Providers
				<img src="assets/img/ajax-loader-light-gray.gif" *ngIf="is_loading">
			</div>
			<div class="card-body pt-0">
				<div class="text-center text text-muted h2 mt-0 pt-0">{{stats?.providers['total']}}</div>
				<hr class="m-1 p-1">
				<div class="text text-danger">Trial = {{stats?.providers['trial']}}</div>
				<div class="text text-danger">No Tax ID = {{stats?.providers['non_tax_id']}}</div>
				<div class="text text-primary">Exivo = {{stats?.providers['exivo']}}</div>
				<div class="text text-muted">NonExivo = {{stats?.providers['non_exivo']}}</div>
			</div>
		</div>
	</div>
	<div class="col-3">
		<div class="card card-sm shadow-sm border">
			<div class="card-header p-2 title-decorative bg-light">Bookings (new/old)
				<img src="assets/img/ajax-loader-light-gray.gif" *ngIf="is_loading">
			</div>
			<div class="card-body pt-0">
				<div class="text-center text text-muted h2 mt-0 pt-0">{{stats?.bookings['new_total']}}/{{stats?.bookings['old_total']}}</div>
				<hr class="m-1 p-1">
				<div class="text text-primary">Confirmed/Open = {{stats?.bookings[4]}}</div>
				<div class="text text-success">Finished = {{stats?.bookings[5]}}</div>
				<div class="text text-muted">Cancelled By Customer = {{stats?.bookings[1]}}</div>
				<div class="text text-muted">Cancelled By Provider = {{stats?.bookings[2]}}</div>
			</div>
		</div>
	</div>
	<div class="col-3">
		<div class="card card-sm shadow-sm border">
			<div class="card-header p-2 title-decorative bg-light">Customers</div>
			<div class="card-body pt-0">
				<div class="text-center text text-muted h2 mt-0 pt-0">{{stats?.customers['active']+stats?.customers['inactive']}}</div>
				<hr class="m-1 p-1">
				<div class="text text-primary">Active = {{stats?.customers['active']}}</div>
				<div class="text text-danger">Inactive = {{stats?.customers['inactive']}}</div>
				<div class="text text-muted">Never Booked = ...</div>
				<div class="text text-muted">Never Logged in = ...</div>
			</div>
		</div>
	</div>
	<div class="col-3">
		<div class="card card-sm shadow-sm border">
			<div class="card-header p-2 title-decorative bg-light">Transactions (NOK)</div>
			<div class="card-body pt-0">
				<div class="text-center text text-muted h2 mt-0 pt-0">{{stats?.payments['payment_total']}}</div>
				<hr class="m-1 p-1">
				<div class="text text-primary">Paypal/Stripe = {{stats?.payments['payment_paypal']}}/{{stats?.payments['payment_stripe']}}</div>
				<div class="text text-primary">Via Offline = {{stats?.payments['payment_offline']}}</div>
				<div class="text text-danger">Balance = {{stats?.payments['payment_balance']}}</div>
				<div class="text text-muted">ReFunds = {{stats?.payments['payment_refund']}}</div>
			</div>
		</div>
	</div>
</div>
