import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { PlanHelper } from '../../_helper/plan';
import { SupportService } from 'src/app/_service/support.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-provider-signup-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {

  plan_stack = [];

  @Output() submit = new EventEmitter();

  constructor(
    public planHelper: PlanHelper,
    private router: Router,
    public supportService: SupportService,
    public modal: NgxSmartModalService
  ) { }

  ngOnInit() {
    let _t = this;
    this.supportService.plans({}).subscribe(data => {
      let temp: any;
      temp = data;

      if (temp.success) {
        temp.data.forEach(element => {
          _t.plan_stack.push({ "id": element.id, "payload": JSON.parse(element.payload) });
        });
        //console.log(_t.plan_stack); 
      }

    });
    //this.plan_stack = this.planHelper.options;
  }

  onClick_Plan(plan) {
    //console.log(this.plan_stack[plan-1]);
      this.submit.emit(this.plan_stack[plan - 1]);

  }



}
