<div id="step_category" >
    <div class="card card-sm">
        <div class="card-header bg-secondary">
            <div class="text text-center title-decorative" *ngIf="is_loading == true" translate>
                <img src="assets/img/ajax-loader-light-gray.gif" > txt-loading
            </div>
            <div class="text text-center  title-decorative text-muted" *ngIf="is_loading == false" translate> {{title}}</div>
        </div>
        <div class="">
            <div class="alert alert-danger"
                *ngIf="is_available == false">{{this.err}}</div>
            <div class="" *ngIf="is_available == true">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" id="cat{{cat.id}}"
                    *ngFor="let cat of categories"
                    (click)="onSelect($event,cat.name)"><a href="javascript:void(0);" 
                        id="cat{{cat.id}}">{{cat.name}}<i style="padding-top:4px"
                        class="float-right icon-chevron-right" id="cat{{cat.id}}"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
