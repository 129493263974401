<nav aria-label="breadcrumb" role="navigation"
class="bg-primary text-white">
<div class="container">
  <div class="row justify-content-center">
    <!--end of col-->
  </div>
  <!--end of row-->
</div>
<!--end of container-->
</nav>
<section class="space-xs">
    <div class="container">
          <h3 class="text">Terms And Conditions</h3>
          <hr>
          <a  class="btn btn-default border bg-light" target='_blank' href="/assets/terms.pdf" routerLinkActive="router-link-active" >
            <span class="fa fa-download"></span> Click here to download Bookit24 Terms and Conditions</a>
    </div>
</section>
