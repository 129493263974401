<div class="card-header bg-secondary pl-2 pr-2">
	<a (click)="onClick_Back()" class="float-left btn btn-sm
		btn-outline-secondary" href="javascript:void(0);"
		translate=""><i class="icon-chevron-left"></i> btn-back</a>
	<div class="text text-center text-muted title-decorative mt-1" translate>
		heading-confirm</div>
</div>
<div class="card-body">
	<div class="row mb-4 mt-2">
		<div class="col-sm-12 col-md-12 col-lg-12">
			<label class=" mb-0" translate="">lbl-notes-for-booking</label>
			<textarea class="form-control" [(ngModel)]="notes" rows="4"
				placeholder=""></textarea>
		</div>
	</div>
</div>
<div class="card-footer bg-light">
	<button class="btn btn-lg btn-outline-secondary"
		(click)="onClick_StartAgain()" translate>
		<i class="icon icon-chevron-left"></i> btn-start-again
	</button>

	<button class="btn btn-success btn-lg float-right"
		(click)="onClick_Confirm()"
		translate>
		btn-confirm-booking
	</button>
</div>
