import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaymentService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function PaymentService(http) {
        this.http = http;
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    PaymentService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     * @param _p
     */
    PaymentService.prototype.delete = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("card_id", _p.id).append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/delete-card", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    PaymentService.prototype.saveForCustomer = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/customer/account/do/save-card", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    PaymentService.prototype.saveForProvider = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/save-card", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    PaymentService.prototype.savePaypal = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/save-paypal", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    PaymentService.prototype.deletePaypal = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/delete-paypal", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    PaymentService.prototype.saveStripe = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/save-stripe", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    PaymentService.prototype.deleteStripe = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/do/delete-stripe", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param temp
     * @param _p
     */
    PaymentService.prototype._append = function (temp, _p) {
        Object.keys(_p).forEach(function (k) {
            var t = temp.append(k, _p[k]);
            temp = t;
        });
        return temp;
    };
    /**
     *
     * @param username
     * @param password
     */
    PaymentService.prototype.getPaypalInfo = function () {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/account/poll/paypal", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    PaymentService.prototype.getStripeInfo = function () {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/account/poll/stripe", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param username
     * @param password
     */
    PaymentService.prototype.getAll = function () {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/customer/account/poll/cards", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    PaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.ɵɵinject(i1.HttpClient)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}());
export { PaymentService };
