import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/_service/alert.service';
import { BookingService } from 'src/app/_service/su/booking.service';
import { DateHelper } from 'src/app/_helper/date';
import { TranslateService } from '@ngx-translate/core';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  is_loading = false;
  booking:any;
  booking_id = 0;
  booking_cols = [
    {
      "name": "business_id",
      "disabled": true,
    },
    {
      "name": "user_id",
      "disabled": true,
    },
    {
      "name": "calendar_id",
      "disabled": true,
    },
    {
      "name": "service_type",
      "disabled": true,
      "comment":"1 = Appointment, 2 = Rental, 3 = Accommodation"
    },
    {
      "name": "service_name",
      "disabled": true,
    },
    {
      "name": "service_description",
      "disabled": true,
    },
    {
      "name": "service_duration",
      "disabled": true,
      "comment":"This is in seconds, actual duration in minute = divide above by 60"
    },
    {
      "name": "service_vat",
      "disabled": true,
    },
    {
      "name": "price",
      "comment":"Payment made will always be calculated i.e. ( PRICE - BALANCE )"
    },
    {
      "name": "balance",
    },
    {
      "name": "payment_mode",
      "disabled": true,
    },
    {
      "name": "payment_date",
      "disabled": true,
    },
    {
      "name": "payment_token",
      "disabled": true,
    },
    {
      "name": "payment_vendor",
      "disabled": true,
    },
    {
      "name": "start_timestamp_in_minute",
      "comment":"These are in minutes, e.g. add 60 to above number to extend start time for an hr"
    },
    {
      "name": "end_timestamp_in_minute",
    },
    {
      "name": "refund_notes",
    },
    {
      "name": "refund_amount",
      "disabled": true,
    },
    {
      "name": "refund_response",
      "disabled": true,
    },
    {
      "name": "refund_time",
      "disabled": true,
    },
    {
      "name": "notes",
    },
    {
      "name": "status",
      "comment":"1 = Cancel By Customer, 2 = Cancel By Provider, 3 = No Show, 4 = Confirm, 5 = Finished, 6 = Refunded"
    },
    {
      "name": "mark_for_delete",
    },
    {
      "name": "dated",
      "disabled": true,
    },
  ]

  constructor(
    private route: ActivatedRoute,
    private date: DateHelper,
    public translate: TranslateService,
    private bookingService: BookingService,
    private alert: AlertService
  ) {
    this.route.queryParams.subscribe(params => {
      this.booking_id = params['id'];
    });

  }

  /**
   * 
   */
  ngOnInit() {
    this._getBooking();
  }

  /**
   * 
   */
  _getBooking(){
    this.bookingService.get(this.booking_id).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        let payload = temp.payload;
        this.booking = payload;
        this.booking_cols.forEach((k, i) => {
          this.booking_cols[i]['value'] = payload[k.name];
        })
      }
    });
  }
  
  /**
   * 
   */
  onClick_Update() {
    let _p = {};
    this.booking_cols.forEach((k, i) => {
      if (!k.disabled) {
        _p[k.name] = k["value"];
      }
    });

    _p["id"] = this.booking_id;

    this.bookingService.update(_p).subscribe(data => {
      let temp:any;
      temp = data;
      if(temp.success){
        this.alert.success(temp.msg);
      } 
      else{
        this.alert.error(temp.msg);

      }     
    })
  }


}
