import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConfigService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function ConfigService(http) {
        this.http = http;
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    ConfigService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     */
    ConfigService.prototype.getAll = function () {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var body = this._getBody().append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/account/poll/config-by-business-id", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param _p
     */
    ConfigService.prototype.save = function (_p) {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        var temp = this._getBody().append("auth_token", user.data.auth_token);
        var body = this._append(temp, _p);
        return this.http.post(environment.server + "/acl/v1/business/update", body, { headers: this.headers })
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     *
     * @param temp
     * @param _p
     */
    ConfigService.prototype._append = function (temp, _p) {
        Object.keys(_p).forEach(function (k) {
            var t = temp.append(k, _p[k]);
            // below line is important because temp only return
            // it does not update temp 
            temp = t;
        });
        return temp;
    };
    ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i1.HttpClient)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
