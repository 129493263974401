<div class="h5">Booking Details: <span class=" text text-muted">{{booking?.service_name}}</span></div>
<div class="row">
	<div class="col-12">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-12">
						<button class="btn btn-primary float-right" (click)="onClick_Update()">Submit</button>
					</div>
				</div>
				<div class="row "><div class="col-12"><hr class="mb-3 mt-3"></div></div>
				<div class="row mb-2" *ngFor="let b of booking_cols">
					<div class="col-3 pt-1 text-right">
						<label>{{b.name}}</label>
					</div>
					<div class="col-9">
						<input *ngIf="!b.disabled" class="form-control" [(ngModel)]="b.value"> 
						<input *ngIf="b.disabled" readonly="readonly" [(ngModel)]="b.value" class="form-control">
						<div class="text text-danger" *ngIf="b.comment!='' || b.comment !='undefined'">{{b.comment}}</div> 
					</div>
				</div>
			</div>
		</div>
	</div>
</div>