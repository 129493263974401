<div class="card-header  bg-secondary pl-2 pr-2">
	<a (click)="onClick_Back()"  class="float-left btn btn-sm
		btn-outline-secondary" href="javascript:void(0);"
		translate=""><i class="icon-chevron-left"></i> btn-back</a>
	<div class="text text-center text-muted title-decorative mt-1" translate>heading-customers</div>
</div>
<div class="card-body  pl-2 pr-2">
	<div class="row">
		<div class="col-sm-12 col-md-12 col-lg-12 mt-1 text text-muted" translate="">
			<div class="card card-sm">
				<div class="card-body row no-gutters align-items-center p-1">
					<div class="col-auto">
						<i class="icon-magnifying-glass h4 text-body"></i>
					</div>
					<div class="col">
						<ng-template #rt let-r="result" let-t="term"> {{r.username}}
							({{r.first_name}} {{r.last_name}})
						</ng-template>
						<input autocomplete="off" #instance="ngbTypeahead" id="typeahead-template"
							(selectItem)="onSelect_SelectCustomer($event)"
							type="text" class="form-control form-control-lg form-control-borderless"
							placeholder="{{ 'lbl-search-for-customer' | translate }}"
							[ngbTypeahead]="search" [resultTemplate]="rt"
							[inputFormatter]="formatter" />
					</div>
				</div>
			</div>
		</div>
		
		<div class="col-sm-12 col-md-12 col-lg-12"  >
			<div class="pb-3 text-center">
				<div class="text text-center title-decorative">- OR -</div>
			</div>
		</div>
		
		<div class="col-sm-12 col-md-12 col-lg-12" >
			<div class="row bg-secondary card">
				<div class="card-body">
					<div class="text">
						<h6 class="title-decorative mb-2" translate>heading-create-new-customer</h6>
					</div>

					<div class="form-row">
						<div class="form-group col-md-6 col-lg-4 col-sm-12">
							<input autocomplete="off" autocomplete="off" class="form-control form-control-lg" type="text"
								[(ngModel)]="customer.username"
								placeholder="{{ 'lbl-email' | translate }}" />
						</div>
						<div class="form-group col-md-6 col-lg-4 col-sm-12">
							<input autocomplete="off" class="form-control form-control-lg" type="text"
								[(ngModel)]="customer.first_name"
								placeholder="{{ 'lbl-first-name' | translate }}" />
						</div>
						<div class="form-group col-md-6 col-lg-4 col-sm-12">
							<input  autocomplete="off" class="form-control form-control-lg" type="text"
								[(ngModel)]="customer.last_name"
								placeholder="{{ 'lbl-last-name' | translate }}" />
						</div>
						<div class="form-group col-md-6 col-lg-4 col-sm-12">
							<input  autocomplete="off" class="form-control form-control-lg" type="text"
								[(ngModel)]="customer.mobile"
								placeholder="{{ 'lbl-mobile' | translate }}" />
						</div>
						<div class="form-group col-md-6 col-lg-4 col-sm-12">
							<input  autocomplete="off" class="form-control form-control-lg" type="text"
								[(ngModel)]="customer.street"
								placeholder="{{ 'lbl-street-address' | translate }}" />
						</div>
						<div class="form-group col-md-6 col-lg-4 col-sm-12">
							<input autocomplete="off"  class="form-control form-control-lg" type="text"
								[(ngModel)]="customer.city"
								placeholder="{{ 'lbl-city' | translate }}" />
						</div>
						<div class="form-group col-md-6 col-lg-4 col-sm-12">
							<input  autocomplete="off" class="form-control form-control-lg" type="text"
								[(ngModel)]="customer.state"
								placeholder="{{ 'lbl-state' | translate }}" />
						</div>
						<div class="form-group col-md-6 col-lg-4 col-sm-12">
							<input  autocomplete="off" class="form-control form-control-lg" type="text"
								[(ngModel)]="customer.zipcode"
								placeholder="{{ 'lbl-zipcode' | translate }}" />
						</div>
						<div class="form-group col-md-6 col-lg-4 col-sm-12">
							<input  autocomplete="off" class="form-control form-control-lg" readonly type="text"
								[(ngModel)]="customer.country"
								placeholder="{{ 'lbl-country' | translate }}" />
						</div>
					</div>
					<br>
					<div class="row form-group">
						<div class="col">
							<button class="btn btn-lg btn-primary"
								(click)="onClick_CreateNewCustomer()" type="submit" translate>btn-submit</button>
						</div>
					</div>
					<div class="">
						<span class="text-small text-muted" translate>txt-by-clicking-link-i-aggree-to-terms-of-use
							<a [routerLink]="['/tnc']" translate>btn-terms-n-conditions</a>,
							<a [routerLink]="['/privacy']" translate>btn-privacy-policy</a>
						</span>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</div>