
<div class="card card-sm">
    <div class="card-header bg-secondary d-flex justify-content-between align-items-center">
        <div>
            <h6 translate="">heading-category</h6>
        </div>
    </div>
    
    <div class="card-body" >
        <div >
            <div class="row form-group">                                
                <div class="col-12">
                    <input class="form-control form-control-lg" type="text" autocomplete="off" [(ngModel)]="selected_category.name" 
                    placeholder="{{ 'lbl-category-name' |  translate }}" />
                </div>
                <div class="col-12 mt-3 text-right" *ngIf="is_category_selected == true">
                    <small class="text-danger" translate="">lbl-category-can-be-deleted-only-if-no-services-are-assigned-to-it</small>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer card-header ">
        <div class="row">
            <div class="col-12">
            <span *ngIf="is_loading == true"  
            class="btn btn-light btn-lg btn-muted" translate=""><img src="assets/img/ajax-loader-light-gray.gif" 
            translate=""> txt-loading</span>
  
            <button *ngIf="is_loading == false" class=" btn btn-lg btn-primary float-left" (click)="onClick_Save()" translate >btn-save</button>
            <button *ngIf="is_loading == false && is_category_selected == true" class=" btn btn-lg btn-outline-danger float-right" (click)="onClick_Delete()"  translate >btn-delete</button>
            <button  *ngIf="is_loading == false" class=" btn btn-lg btn-secondary float-right mr-2" (click)="onClick_Reset()"  translate >btn-cancel</button>
            </div>
        </div>
    </div>
    <div class="card-body bg-light" *ngIf="all_categories?.length > 0">
        <h6 class="title-decorative mb-2" translate >heading-click-a-category-to-edit</h6>                        
        <div class="">
            <button class="btn btn-md m-1 bg-light border  text-muted " (click)="onClick_Category($event,cat.name,cat.id)" *ngFor="let cat of all_categories" >{{cat.name}}</button>
        </div>
    </div>
</div>
    
