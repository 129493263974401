import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClientModule,HttpParams,HttpHeaders } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';

/**
 * 
 */
@Injectable()
export class UserService {

    /**
     * 
     */
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) { 

        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    }

    /**
     * 
     */
    _getBody(){
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }
 
    /**
     * 
     * @param temp 
     * @param _p 
     */
    _append(temp,_p){
        
        Object.keys(_p).forEach(k=>{
            let t = temp.append(k,_p[k]);   
            temp = t;
        });
        return temp;

    }
            
    /**
     * 
     */
    save(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token",user.data.auth_token);        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/provider/user/do/save",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    update(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token",user.data.auth_token);        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/provider/account/do/save",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    delete(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("calendar_id",_p.id).append("auth_token",user.data.auth_token);        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/provider/user/do/delete",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    
    /**
     * 
     */
    getAll() {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("business_id",user.data.business_id).append("auth_token",user.data.auth_token);
        return this.http.post(environment.server+"/provider/user/poll/all",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    get(id){
        return this._getById(id);
    }

    /**
     * 
     */
    getById(id) {
        return this._getById(id);
    }

    /**
     * 
     */
    _getById(id) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("id",id).append("auth_token",user.data.auth_token);
        return this.http.post(environment.server+"/provider/user/poll/by-id",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

        
    /**
     * 
     * @param username 
     * @param password 
     */
    getByAuthToken() {
      let user = JSON.parse(localStorage.getItem("currentUser"));
      let body = this._getBody().append('auth_token',user.data.auth_token );
      return this.http.post(environment.server+"/acl/v1/auth/get-by-auth-token",body, { headers: this.headers })
          .pipe(map(data => {
              localStorage.setItem('currentUser', JSON.stringify(data));
              return data;
          }));
    }
 
    /**
     * 
     */
    signout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }

}