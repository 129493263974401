<div class="container">
    <div class="row">
        <!--end of col-->
        <div class="col-12 col-md-3">
            <div class="card card-sm">
                <ul class="list-group">
                    <li class="list-group-item bg-light"
                        (click)="onClick_Changeitem('profile')">
                        <button class="btn btn-block btn-link text text-muted"
                            translate>btn-profile</button>
                    </li>
                    <li class="list-group-item bg-light"
                        (click)="onClick_Changeitem('plan')"
                        *ngIf="auth.getUser().user_type != 'customer' &&
                        auth.getUser().business.id != '6' &&
                        auth.getUser().business.id != '8'">
                        <button class="btn btn-block btn-link text text-muted"
                            translate>btn-plan</button>
                    </li>
                    <li class="list-group-item bg-light"
                        (click)="onClick_Changeitem('pwd')">
                        <button class="btn btn-block btn-link text text-muted"
                            translate>btn-change-password</button>
                    </li>
                </ul>
            </div>
            <!--
            <div class="alert alert-primary text-small" role="alert"
                *ngIf="is_customer == false">
                <a href="javascript:void(0);" [routerLink]="['/privacy']"
                    translate class="text-primary"> txt-see-our-privacy-policy</a>
            </div>
            <div class="alert alert-primary text-small" role="alert"
                *ngIf="is_customer == false">
                <span translate>
                    lbl-help-info-about-timezone
                </span>
            </div>
            -->
        </div>
        <div class="col-12 col-md-9" *ngIf="is_on_security">
            <div class="card card-sm">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <span *ngIf="is_loading == true" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif"
                            translate>
                        txt-loading
                    </span>
                    <span *ngIf="is_loading == false" translate="">btn-change-password</span>
                </div>
                <div class="card-body">
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-current-password</label> <br>
                            <input autocomplete="off"  class="form-control form-control-lg"
                                type="password" autocomplete="off"
                                [(ngModel)]="current_password" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-new-password</label> <br>
                            <input  autocomplete="off" class="form-control form-control-lg"
                                type="password" autocomplete="off"
                                [(ngModel)]="new_password" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-confirm-new-password</label><br>
                            <input  autocomplete="off" class="form-control form-control-lg"
                                type="password" autocomplete="off"
                                (keyup.enter)="onClick_SavePwd()"
                                [(ngModel)]="confirm_password" />
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-light">
                    <button *ngIf="is_loading == true" class="btn btn-lg text
                        text-muted btn-light" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif"
                            translate> txt-loading
                    </button>

                    <button class="btn btn-lg btn-primary" *ngIf="is_loading ==
                        false" (click)="onClick_SavePwd()" translate>btn-save</button>
                </div>
            </div>
        </div>
        <!--end of row-->

        <div class="col-12 col-md-9"*ngIf="is_on_profile">
            <div class="card card-sm">
                <div class="bg-white p-2 ">
                    <div *ngIf="!is_trying_deleting" class="container">
                        <div class="row mb-1 mt-3">
                            <div class="col">
                                <span *ngIf="is_customer == false"
                                    class="title-decorative" translate>lbl-contact-person
                                </span>
                                <img
                                    src="assets/img/ajax-loader-light-gray.gif"
                                    *ngIf="is_loading == true">
                            </div>
                            <!--end of col-->
                        </div>
                        <!--end of row-->
                        <div class="row">
                            <!--end of col-->
                            <div class="col-12 col-md-12">
                                <div class="">
                                    <div class="form-row form-group">
                                        <div class="col">
                                            <input autocomplete="off"  class="form-control
                                                form-control-lg" type="text"
                                                [(ngModel)]="first_name"
                                                placeholder="{{ 'lbl-first-name'
                                                | translate }}" />
                                        </div>
                                        <div class="col">
                                            <input  autocomplete="off" class="form-control
                                                form-control-lg" type="text"
                                                [(ngModel)]="last_name"
                                                placeholder="{{ 'lbl-last-name'
                                                | translate }}" />
                                        </div>
                                    </div>
                                    <div class="form-row form-group">
                                        <div class="col">
                                            <input  autocomplete="off"  readonly class="form-control
                                                form-control-lg" type="email"
                                                [(ngModel)]="username"
                                                placeholder="{{ 'lbl-email' |
                                                translate }}" />
                                        </div>
                                    </div>
                                    <div class="form-row form-group">
                                        <div class="col">
                                            <input  autocomplete="off" class="form-control
                                                form-control-lg" type="text"
                                                [(ngModel)]="mobile"
                                                placeholder="{{ 'lbl-mobile' |
                                                translate }}" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end of col-->
                        </div>
                        <!--end of row-->
                        <div class="row mb-1 mt-4" *ngIf="is_customer == false">
                            <div class="col">
                                <span class="title-decorative" translate>heading-business-information
                                    <img
                                        src="assets/img/ajax-loader-light-gray.gif"
                                        *ngIf="is_loading == true">
                                </span>
                            </div>
                            <!--end of col-->
                        </div>
                        <!--end of row-->
                        <div class="row">
                            <!--end of col-->
                            <div class="col-12 col-md-12" *ngIf="is_customer ==
                                false">
                                <div class="">
                                    <div class="form-row form-group">
                                        <div class="col">
                                            <input class="form-control
                                                form-control-lg" type="text"
                                                [(ngModel)]="name"
                                                placeholder="{{
                                                'lbl-business-name' | translate
                                                }}" />
                                        </div>
                                        <div class="col">
                                            <select required class="form-control
                                                form-control-lg"
                                                [(ngModel)]="timezone">
                                                <option [value]="tz.timezone"
                                                    *ngFor="let tz of zones">{{tz.timezone}}
                                                    ( {{tz.offset}} UTC )</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-row form-group">
                                        <div class="col">
                                            <input class="form-control
                                                form-control-lg" type="text"
                                                [(ngModel)]="website"
                                                placeholder="{{ 'lbl-website' |
                                                translate }}" />
                                        </div>
                                        <div class="col">
                                            <input class="form-control
                                                form-control-lg" type="text"
                                                [(ngModel)]="telephone"
                                                placeholder="{{ 'lbl-telephone'
                                                | translate }}" />
                                        </div>
                                    </div>
                                    <div class="form-row form-group">
                                        <div class="col">
                                            <textarea rows='6'
                                                class="form-control
                                                form-control-lg"
                                                [(ngModel)]="description"
                                                placeholder="{{
                                                'lbl-business-description' |
                                                translate }}"></textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-4 mb-1">
                                            <span class="title-decorative">BUSINESS LOGO</span></div>
                                        <div class="col-12 media flex-wrap mb-2
                                            align-items-center">
                                            <img alt="" *ngIf="logo!=null &&
                                                logo!='null' && logo!=''"
                                                style="background-color:#eee"
                                                [src]="logo" width="160"
                                                class="rounded responsive
                                                responsive-fluid border
                                                rounded shadow-sm img-fluid">
                                            <div class="media-body alert">
                                                <label class="custom-file mb-2"
                                                    for="file2">
                                                    <input type="file"
                                                        (change)="selectImage($event)"
                                                        id="service_image"
                                                        class="btn btn-light
                                                        btn-sm">&nbsp;
                                                    <button class="btn btn-sm
                                                        btn-outline-danger"(click)="onClick_DeleteImage()"
                                                        *ngIf="is_logo_exists ==
                                                        true" translate>btn-delete-current-image</button>
                                                </label>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="is_customer == true">
                            <div class="col-12 media flex-wrap mb-2
                                align-items-center">
                                <img alt="" *ngIf="image!=null && image!='null'
                                    && image!=''" style="background-color:#eee"
                                    [src]="image" width="160"
                                    class="rounded img-fluid">
                                <div class="media-body alert">
                                    <label class="custom-file mb-2" for="file2">
                                        <input type="file"
                                            (change)="selectImage($event)"
                                            id="image" class="btn btn-light
                                            btn-sm">
                                        &nbsp;
                                        <button class="btn btn-sm
                                            btn-outline-danger"(click)="onClick_DeleteImage()"
                                            *ngIf="is_image_exists"
                                            translate>btn-delete-current-image</button>
                                    </label>
                                    <div class="text text-muted">
                                        <small translate>txt-for-best-result-use-x-x-size
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1 mt-5 card-footer border-bottom bg-light">
                        <div class="">
                            <button *ngIf="is_loading == true" class="btn
                                btn-lg text text-muted btn-light" translate>
                                <img
                                    src="assets/img/ajax-loader-dark-gray.gif">
                                txt-loading
                            </button>
                            <button *ngIf="is_loading == false" class="btn
                                btn-lg btn-primary"
                                (click)="onClick_ProfileSave()" translate>
                                btn-save</button>
                        </div>
                    </div>
                    <div class="row mb-4 mt-5">
                        <div class="col-12">
                            <span *ngIf="is_loading == false" class="btn
                                btn-lg
                                btn-outline-danger float-right"
                                (click)="onClick_TryDelete()" translate>btn-delete-my-account</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-12 col-md-9" *ngIf="is_on_cancel_subscription">
            <div class="card card-sm">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <span *ngIf="is_loading == false">{{title}}</span>
                    <span *ngIf="is_loading == true" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif">
                        txt-loading
                    </span>
                </div>
                <div class="card-body">
                    <div class="text text-danger p-3 h5" translate="">
                        txt-cancel-subscription
                    </div>
                </div>
                <div class="card-footer bg-light">
                    <button *ngIf="is_loading == true" class="btn btn-lg text
                        text-muted
                        btn-light" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif">
                        txt-loading
                    </button>

                    <button *ngIf="is_loading == false" class="btn btn-lg
                        float-left
                        btn-primary" (click)="onClick_CancelSubscription()"
                        translate>btn-confirm</button>
                    <button *ngIf="is_loading == false" class="btn btn-lg
                        float-right
                        btn-secondary"
                        (click)="onClick_CancelSubsctiptionDeletion()"
                        translate>btn-cancel</button>
                </div>
            </div>
        </div>

        <div *ngIf="is_trying_deleting" class="col-12 col-md-9">
            <div class="card card-sm">
                <div class="card-body">
                    <h6 class="h4 mb-2 text text-danger" translate>heading-are-you-sure</h6>
                    <hr class="mt-2 mb-2">
                    <div class="alert h6 alert-danger" translate=""
                        *ngIf="is_customer == false">
                        txt-information-for-provider-account-deletion-1
                    </div>
                    <div class="alert h6"translate="" *ngIf="is_customer ==
                        false">
                        txt-information-for-provider-account-deletion-2
                    </div>
                    <div class="alert" translate="" *ngIf="is_customer == true">
                        txt-information-for-customer-account-deletion
                    </div>
                    <hr class="mt-2 mb-2">
                    <button (click)="onClick_CancelDeletion();" class="btn
                        btn-lg btn-secondary
                        float-left" translate="">btn-cancel</button>
                    <button (click)="onClick_Delete();" class="btn btn-lg
                        btn-danger
                        float-right" translate="">btn-submit</button>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-9" *ngIf="is_on_plan == true &&
            is_cannot_downgrade">
            <div class="card card-sm">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <span>Information</span>
                </div>
                <div class="card-body">
                    <div class="text text-danger h6">En feil oppstod ved endring
                        av abonnement</div>
                    <div class="text text-dark">Ditt abonnement kan ikke
                        nedgraderes i øyeblikket. Vennligst sjekk om du har:</div>
                    <ul>
                        <li>
                            For mange ansatte registrert i systemet
                        </li>
                        <li>
                            For mange kalendere
                        </li>
                    </ul>
                    <div class="mt-3 mb-4">
                        Grensen for antall ansatte og antall kalendere med våre
                        abonnement finner du på siden for endring av abonnement.
                        Du kan da deaktivere/slette ansatte/kalendere og
                        deretter endre abonnement.
                    </div>
                    <!-- 
                    <div class="text text-danger h5">Error while changing your plan.</div>
                    <div class="text text-dark">Your plan cannot be downgraded at the moment. This could be due to one of below reasons:</div>
                    <div>
                        <ul>
                            <li>The number of current active users are more than what maximum you can have in plan you choose. 
                                You must first disable users to match the new plan.
                            </li>
                            <li>The number of current active calendars are more than what maximum you can have in plan you choose. 
                                You must first disable calendars to match the new plan.
                            </li>
                        </ul>
                    </div>
                    <div class="mt-3 mb-4">
                        <div class="h6">If you further unable to change your plan, let us know. Will we set it up for you.</div> 
                    </div>
                    -->
                </div>
            </div>
        </div>


        <div class="col-12 col-md-9" *ngIf="!is_cannot_downgrade && is_on_plan
            == true &&
            auth.getUser().user_type != 'customer'">
            <div class="card card-sm">
                <div class="card-header bg-light" style="padding-top:18px"
                    *ngIf="is_show_trial_ends_warning">
                    <div class="row col-12 text text-danger" translate="">
                        <span>lbl-remaining-trial-days</span>{{trial_ends}}
                        <span *ngIf="all_cards.length <1" translate="">txt-you-must-add-card-to-continue-after-trial-period</span>
                    </div>
                </div>
                <div class="card-header bg-light" style="padding-top:18px">
                    <div class="row col-12">
                        <div class="col">
                            <div class="custom-control custom-radio">
                                <input id="monthly" type="radio"
                                    class="custom-control-input" value="1"
                                    [(ngModel)]="payment_type">
                                <label class="custom-control-label"
                                    for="monthly" translate>lbl-monthly-payment</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-radio">
                                <input id="yearly" type="radio"
                                    class="custom-control-input"
                                    value="2" [(ngModel)]="payment_type">
                                <label class="custom-control-label" for="yearly"
                                    translate>lbl-annually-payment</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 text-center">

                            <table class="table shadow-sm table-bordered
                                table-responsive pricing rounded">
                                <thead>
                                    <tr class='bg-secondary rounded'>
                                        <th scope="col text-muted"><span
                                                class="h5 title-decorative"
                                                translate>plan-page-features</span></th>
                                        <th scope="col bg-dark">
                                            <span class="h5" translate="">plan-page-basic</span>
                                        </th>
                                        <th scope="col">
                                            <span class="h5" translate="">plan-page-standard</span>
                                        </th>
                                        <th scope="col">
                                            <span class="h5" translate="">plan-page-pro</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row"><span class="h5"
                                                translate="">plan-page-monthly-price</span></th>
                                        <td>
                                            <span class="display-4"
                                                [ngStyle]="{'font-size':'2.4em'}">149
                                                NOK</span>
                                        </td>
                                        <td>
                                            <span class="display-4"
                                                [ngStyle]="{'font-size':'2.4em'}">299
                                                NOK</span>
                                        </td>
                                        <td>
                                            <span class="display-4"
                                                [ngStyle]="{'font-size':'2.4em'}">599
                                                NOK</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><span class="h5"
                                                translate="">plan-page-yearly-price</span></th>
                                        <td>
                                            <span class="h5">1499 NOK</span>
                                        </td>
                                        <td>
                                            <span class="h5">2999 NOK</span>
                                        </td>
                                        <td>
                                            <span class="h5">5999 NOK</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" translate="">Calendars
                                            <sup>1</sup></th>
                                        <td>1
                                        </td>
                                        <td>5
                                        </td>
                                        <td translate="">10
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-employees-login</th>
                                        <td>1</td>
                                        <td>2</td>
                                        <td translate="">10</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-bookings</th>
                                        <td translate="">Unlimited
                                        </td>
                                        <td translate="">Unlimited
                                        </td>
                                        <td translate="">Unlimited
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-rental-services</th>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-appointment-services</th>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-accommodation-services</th>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-internal-booking</th>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-online-payment-stripe-n-paypal</th>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-sms-reminders<sup>2</sup></th>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-email-reminders</th>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-customer-database</th>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-bookings-csv-exports</th>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="text-right"
                                            translate="">plan-page-exivo-integration
                                            <sup>3</sup></th>
                                        <td><i class="icon-circle-with-cross
                                                text-red"></i>
                                        </td>
                                        <td><i class="icon-circle-with-cross
                                                text-red"></i>
                                        </td>
                                        <td><i class="icon-check text-green"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row"></th>
                                        <td *ngFor="let plan of plan_stack">
                                            <span class="btn btn-lg border
                                                btn-default shadow-sm"
                                                *ngIf="current_plan.name ==
                                                plan.payload.name" translate>btn-current-plan</span>
                                            <a *ngIf="current_plan.name !=
                                                plan.payload.name"
                                                href="javascript:void(0);"
                                                (click)="onClick_SelectPlan(plan)"
                                                class="btn
                                                btn-lg btn-success shadow"
                                                translate="">btn-choose-plan</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12">
                            <div class="rounded p-3 border mt-3 bg-light mb-4  shadow-sm">

                                <div class="h5 text-dark mb-2">Looking for a much bigger plan with higher
                                    resources like Calendar, Employee or Booking
                                    ?</div>
                                <div class=" text-muted"> We offer custom plans to allow large businesses with high
                                    volume of bookings. Feel free to contact us to
                                    discuss further.</div>
                                <hr>
                                <a class="btn btn-lg btn-primary shadow" target="_blank"
								href="https://www.bookit24.no/foresporsel" translate>btn-contact-us</a>
                            </div>
                            <!--end of col-->
                            <div class="col-12 row no-gutters card-group
                                text-secondary mb-2" translate="">
                                <div class="mt-0" translate="">
                                    <sup>1</sup> <span translate="">plan-page-msg-1</span>
                                </div>
                                <div class="">
                                    <sup>2</sup> <span translate="">plan-page-msg-2</span>
                                </div>
                                <div class="">
                                    <sup>3</sup> <span translate="">plan-page-msg-3</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card-footer bg-light mt-5">
                    <div class="col">
                        <button *ngIf="is_loading == true" class="btn btn-lg
                            text text-muted btn-light" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif"
                                translate> txt-loading
                        </button>

                        <button *ngIf="is_loading == false && current_plan?.name
                            != undefined" class="btn btn-lg
                            float-right btn-outline-danger"
                            (click)="onClick_TryCancelSubscription()"
                            translate>btn-cancel-subscription</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
