import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { ServiceService } from './../../../_service/service.service';
import { BusinessService } from '../../../_service/business.service';
import { CommonComponent } from './../common/common.component';
import { UrlHelper } from '../../../_helper/url';
import { AlertService } from './../../../_service/alert.service';

@Component({
    selector: 'app-booking-create-service-list',
    templateUrl: './service.component.html',
    styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

    @Output() backBtn = new EventEmitter<object>();
    @Output() selectService = new EventEmitter<object>();
    @Output() nextBtn = new EventEmitter<any>();

    @Input() is_loading: boolean;

    is_available = true;
    category_services: any;
    title = "Service";

    min_duration = 0;
    max_duration = 0;
    break_before = 0;
    break_after = 0;
    price = 0;

    is_ready_to_select_datetime = false;

    services = [];
    services_name = "";
    services_id: any;
    service_type: any;
    toggle_class: any;
    err = "";
    subdomain = "";
    business:any;

    /**
     * 
     * @param serviceService 
     * @param alertService 
     */
    constructor(
        private serviceService: ServiceService,
        private businessService: BusinessService,
        private url:UrlHelper,
        private alertService: AlertService
    ){

    }


    /**
     * 
     */
    ngOnInit() {

    }

    /**
     * 
     */
    getAllServices(id,business) {
        this.business = business;
        this.is_available = true;
        this.err = "";
        this.is_loading = true;
        let _p = {
            "id" : id,
            "business_id":this.business.id
        }

        this.serviceService.getAllByCategoryId(_p).subscribe(
            (res) => {

                this.is_loading = false;
                let response = JSON.parse(JSON.stringify(res));
                if (response.success) {
                    this.category_services = response.data;
                    this.category_services.forEach((v, i) => {
                        if (v.service_type == "1") {
                            v.desc = "approax " + v.duration + " minutes";
                        }
                        if (v.service_type == "2") {
                            v.desc = "Minimum booking " + Number(v.duration) / 60 + " hrs";
                        }
                        if (v.service_type == "3") {
                            v.desc = "Check-in " + v.checkin + ". Check-out " + v.checkout;
                        }

                    });
                    if (this.category_services.length > 0) {
                        this._fixState();
                        this.toggle_class = {
                            "float-right": true,
                            "btn": true,
                            "btn-sm": true
                        };
                    } else {
                        this.is_available = false;
                        this.err = "No Service available";
                    }
                } else {
                    this.err = response.msg;
                }
                
            },
            error => {
                this.is_loading = false;
                this.alertService.error(error);
            });


    }

    /**
     * 
     * @param event 
     * @param name 
     * @param price 
     */
    onSelectService(event, index, name, price, type, return_type, min_duration, max_duration,s) {
        let el = $(event.target);
        let id = event.target.id.substr(3);
        //price = Number(price) + Number(s.cleaning_fee);
        let serv = {
            "s":s,
            "min_duration": min_duration,
            "max_duration": max_duration,
            "break_before":Number(s.break_before),
            "break_after":Number(s.break_after),
            "is_require_online_payment":s.is_require_online_payment,
            "is_return_same_day": return_type,
            "type": type,
            "name": name,
            "price": price,
            "id": id
        };
        this.service_type = type;
        let flag = this._checkSelection(el, serv, id);
        this._resetSummary();
        if(flag){
            if(s.service_type != 1){
                this.onClick_NextBtn();
            }
        }
    }

    /**
     * 
     * @param el 
     * @param serv 
     * @param id 
     */
    _checkSelection(el, serv, id) {
        if (el.hasClass("btn-light")) {
            let flag = true;
            flag = this._isAllowedMultiple(serv);
            if (!flag) {
                if (serv.type == "1") {
                    this.alertService.error("Cannot add more services with current service");
                } else {
                    this.alertService.error("This Rental service need to be booked separately");
                }
                
                return false;
            }

            el.removeClass("btn-light").addClass("btn-success");
            this.services.push(serv);

        } else {
            el.removeClass("btn-success").addClass("btn-light");
            this.services.forEach((v, i) => {
                if (v.id == id) {
                    this.services.splice(i, 1);
                }
            });
            return false;
        }

        return true;

    }

    /**
     * 
    */
    _isAllowedMultiple(serv) {
        let flag = true;
        let count = this.services.length;
        this.services.forEach((v, i) => {
            if (v.type != serv.type) {
                flag = false;
            }
            if (count > 0 && (serv.type == "2" || serv.type == "3")) {
                flag = false;
            }
        });
        return flag;
    }

    /**
    * 
    */
    _resetSummary() {
        this.price = 0;
        this.min_duration = 0;
        this.break_after = 0;
        this.break_before = 0;
        this.services_name = "";
        this.services_id = "";
        this.is_ready_to_select_datetime = false;

        if (Object.keys(this.services).length > 0) {
            this.is_ready_to_select_datetime = true;
            let t = this;
            this.services.forEach(function (v, i) {
                t.max_duration = Number(v.max_duration);
                $.each(v, function (i2, v2) {
                    if (i2 == "min_duration") {
                        t.min_duration = t.min_duration + Number(v.min_duration);
                    }
                    if (i2 == "break_before") {
                        t.break_before = t.break_before + Number(v.break_before);
                    }
                    if (i2 == "break_after") {
                        t.break_after = t.break_after + Number(v.break_after);
                    }
                    if (i2 == "price") {
                        t.price = t.price + Number(v.price);
                    }
                    if (i2 == "name") {
                        t.services_name = t.services_name + v.name + ", ";
                    }
                    if (i2 == "id") {
                        t.services_id = t.services_id + v.id + ", ";
                    }
                });
            });
        } else {
            this.is_ready_to_select_datetime = false;
        }

        let _p = {
            "price": this.price,
            "service_type":this.service_type,
            "max_duration":this.max_duration,
            "min_duration": this.min_duration,
            "services_name": this.services_name,
            "break_before":this.break_before,
            "break_after":this.break_after,
            "services_id": this.services_id
        };

        this.selectService.emit(_p);
    }

    /**
     * 
     */
    _fixState() {
        let com = new CommonComponent();
        this.category_services = com.fixState(this.category_services, this.services);
    }

    /**
     * 
     */
    onClick_NextBtn() {
        // below is sample what is sent
        // for any service type
        let _sample: any;
        _sample = [{
            id: "4",
            is_return_same_day: "0",
            max_duration: "",
            min_duration: "33",
            name: "massage",
            price: "33",
            type: "1"
        }];

        let _emit = {
            "offline_payment":[],
            "price":0,"is_appointment":false,
            "is_require_online_payment":false,
            "is_rental":false,"is_rental_24hr":false,
            "is_rental_same_day_return":false,
            "break_before":0,"break_after":0,
            "service_type":this.service_type,
            "min_duration":0,"max_duration":0
        };

        _emit.is_appointment = false;
        _emit.is_rental = false;
        _emit.is_rental_24hr = false;
        _emit.is_rental_same_day_return = false;
        _emit.price = this.price;
        // we loop below service but remember
        // rental services will only be book single only
        // only apointment booking can be booked multiple
        // so for rental below loop will run only once.
        this.services.forEach((v, i) => {
            _emit.break_after = v.break_after;
            _emit.break_before = v.break_before;
            if (v.type == "1") {
                _emit.is_appointment = true;
                _emit.min_duration = v.min_duration;
            }
            // below variables only apply to rental
            if (v.type == "2") {
                _emit.is_rental = true;
                _emit.max_duration = Number(v.max_duration) * 60;
                _emit.min_duration = Number(v.min_duration) * 60;
                if (v.is_return_same_day == "1") {
                    _emit.is_rental_same_day_return = true;
                } else {
                    _emit.is_rental_same_day_return = false;
                }
            }
            if (v.type == "3") {
                _emit.is_rental_24hr = true;
            }
        });

        // recalculate for is_require_online_payment
        // reset the price and flag 
        let _emit_reset = this.calculateOfflinePayment(_emit); 
        this.nextBtn.emit(_emit_reset);
    }

    /**
     * 
     * @param _p 
     */
    calculateOfflinePayment(_p){

        this.services.forEach((k,i)=>{
            if(k.is_require_online_payment == '1'){
                _p.is_require_online_payment = true;
            }
            else{
                _p.offline_payment.push(k);
            }

        });
        return _p;
    }

    /**
     * 
     * @param event 
     */
    onClick_BackBtn(event) {
        this.backBtn.emit(event);
    }

}