<div class="card card-sm">
    <div class="card-header bg-secondary">
        <a href="javascript:void(0);" (click)="onClick_BackBtnOnCustomer($event)" class="float-left text text-danger" translate ><i
                style="padding-top: 4px;" class=" icon-chevron-left"></i> btn-back</a>
        <div class="text text-center title-decorative" *ngIf="is_loading == true" translate>
            <img src="assets/img/ajax-loader-light-gray.gif" translate > txt-loading
        </div>

        <div class="title-decorative text-center text-muted" *ngIf="is_loading == false">{{title}}
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-12 ">
                <div class="card card-sm">
                    <div class="card-body row no-gutters align-items-center">
                        <div class="col-auto">
                            <i class="icon-magnifying-glass h4 text-body"></i>
                        </div>
                        <!--end of col-->
                        <div class="col">
                            <ng-template #rt let-r="result" let-t="term"> {{r.username}}
                                ({{r.first_name}} {{r.last_name}})
                            </ng-template>
                            <input  #instance="ngbTypeahead" id="typeahead-template" (selectItem)="onSelect_SelectCustomer($event)"
                                type="text" class="form-control form-control-lg form-control-borderless" [(ngModel)]="search_customer"
                                placeholder="{{ 'lbl-search-for-customer' | translate }}" [ngbTypeahead]="search" [resultTemplate]="rt"
                                [inputFormatter]="formatter" />

                        </div>
                    </div>
                </div>
                <div [innerHtml]="customer_info"></div>
            </div>
        </div>
        <br>
        <small class="text text-primary" translate >txt-search-customer-or-create-new-customer-below</small>
        <div class="row bg-secondary card">
            <div class=" card-body">
                <div class="text">
                    <h6 class="title-decorative mb-2" translate >heading-create-new-customer</h6>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 col-lg-6 col-sm-12">
                        <input autocomplete="off" class="form-control form-control-lg" type="text" [(ngModel)]="new_customer_email"
                            placeholder="{{ 'lbl-email' | translate }}" />
                    </div>
                    <div class="form-group col-md-6 col-lg-6 col-sm-12">
                        <input autocomplete="off" class="form-control form-control-lg" type="text" [(ngModel)]="new_customer_first_name"
                            placeholder="{{ 'lbl-first-name' | translate }}" />
                    </div>
                    <div class="form-group col-md-6 col-lg-6 col-sm-12">
                        <input autocomplete="off" class="form-control form-control-lg" type="text" [(ngModel)]="new_customer_last_name"
                            placeholder="{{ 'lbl-last-name' | translate }}" />
                    </div>
                    <div class="form-group col-md-6 col-lg-6 col-sm-12">
                        <input autocomplete="off" class="form-control form-control-lg" type="text" [(ngModel)]="new_customer_mobile"
                            placeholder="{{ 'lbl-mobile' | translate }}" />
                    </div>
                    <div class="form-group col-md-6 col-lg-6 col-sm-12">
                        <input autocomplete="off" class="form-control form-control-lg" type="text" [(ngModel)]="new_customer_street"
                            placeholder="{{ 'lbl-street-address' | translate }}" />
                    </div>
                    <div class="form-group col-md-6 col-lg-6 col-sm-12">
                        <input autocomplete="off" class="form-control form-control-lg" type="text" [(ngModel)]="new_customer_city"
                            placeholder="{{ 'lbl-city' | translate }}" />
                    </div>
                    <div class="form-group col-md-6 col-lg-6 col-sm-12">
                        <input autocomplete="off" class="form-control form-control-lg" type="text" [(ngModel)]="new_customer_state"
                            placeholder="{{ 'lbl-state' | translate }}" />
                    </div>
                    <div class="form-group col-md-6 col-lg-6 col-sm-12">
                        <input autocomplete="off" class="form-control form-control-lg" type="text" [(ngModel)]="new_customer_zipcode"
                            placeholder="{{ 'lbl-zipcode' | translate }}" />
                    </div>
                    <div class="form-group col-md-6 col-lg-6 col-sm-12">
                        <input autocomplete="off" class="form-control form-control-lg" readonly type="text" [(ngModel)]="new_customer_country"
                            placeholder="{{ 'lbl-country' |  translate }}" />
                    </div>
                </div>
                <br>
                <div class="row form-group">
                    <div class="col">
                        <button class="btn btn-lg btn-primary" (click)="onClick_CreateNewCustomer()" type="submit"  translate >btn-submit</button>
                    </div>
                </div>
                <div class="">
                    <span class="text-small text-muted" translate>txt-by-clicking-link-i-aggree-to-terms-of-use
                            <a [routerLink]="['/tnc']" translate>btn-terms-n-conditions</a>, 
                            <a [routerLink]="['/privacy']" translate>btn-privacy-policy</a>
                    </span>
                </div>
            </div>
        </div>
    </div> 
    <div class="card-footer bg-light" *ngIf="is_loading == false">
        <textarea [(ngModel)]="notes" class="form-control " rows="2" placeholder="Comments for booking">
        </textarea>
    </div>
    
    <div class="card-footer bg-secondary">
        <button class="btn btn-secondary btn-lg float-left" (click)="onClick_StartAgain()"  translate >
            <i class="icon icon-chevron-left"></i> btn-start-again
        </button>
        <span *ngIf="is_loading == true"  
            class="btn btn-light btn-lg btn-muted  float-right" translate><img src="assets/img/ajax-loader-light-gray.gif" 
            translate > txt-loading</span> 

        <button class="btn btn-success btn-lg float-right" (click)="onClick_ConfirmBookingBtnOnCustomer()" 
                *ngIf="is_pay_now_ready && is_loading == false" translate >
                btn-confirm-booking
        </button>
    </div>
</div>