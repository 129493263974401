/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./finish.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./finish.component";
import * as i5 from "../../../_helper/date";
var styles_FinishComponent = [i0.styles];
var RenderType_FinishComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FinishComponent, data: {} });
export { RenderType_FinishComponent as RenderType_FinishComponent };
function View_FinishComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "card borderless card-header bg-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "float-right text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " NOK"])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "text text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "btn text text-muted btn-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" TO "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "text text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.service_name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.price; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.date.formatForNorway((_v.context.$implicit.start_timestamp_in_minute * 60)); _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.date.formatForNorway((_v.context.$implicit.end_timestamp_in_minute * 60)); _ck(_v, 15, 0, currVal_3); }); }
export function View_FinishComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "col-sm-12 col-md-12 col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["heading-booked-successfully"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["txt-booked-successfully-information"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinishComponent_1)), i1.ɵdid(11, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 4, 0, currVal_0); var currVal_1 = ""; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.config.confirmed_bookings; _ck(_v, 11, 0, currVal_2); }, null); }
export function View_FinishComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-booking-finish", [], null, null, null, View_FinishComponent_0, RenderType_FinishComponent)), i1.ɵdid(1, 114688, null, 0, i4.FinishComponent, [i5.DateHelper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FinishComponentNgFactory = i1.ɵccf("app-new-booking-finish", i4.FinishComponent, View_FinishComponent_Host_0, { config: "config" }, {}, []);
export { FinishComponentNgFactory as FinishComponentNgFactory };
