import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessService } from './../../_service/business.service';
import { ServiceService } from './../../_service/service.service';
import { CategoryService } from './../../_service/category.service';
import { BookingService } from './../../_service/booking.service';
import { LocaleHelper } from '../../_helper/locale';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { AlertService } from './../../_service/alert.service';
import { AuthHelper } from '../../_helper/auth';
import { UrlHelper } from '../../_helper/url';
import { StringHelper } from 'src/app/_helper/string';
import { AuthService } from 'src/app/_service/auth.service';


interface Response {
  success: boolean;
  data: any;
  msg: string;
}
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  business: any;
  services: any;
  categories: any;
  is_show_services_or_categories_list = false;
  is_show_categories = false;
  is_show_services = false;
  map: any;
  is_loading = false;
  mon_working_hours_alt = "";
  tue_working_hours_alt = "";
  wed_working_hours_alt = "";
  thu_working_hours_alt = "";
  fri_working_hours_alt = "";
  sat_working_hours_alt = "";
  sun_working_hours_alt = "";
  is_valid_subdomain = false;
  has_logo = false;
  logo_class = "";
  show_map = true;

  email = "";
  password = "";

  /**
   * 
   * @param businessService 
   * @param auth 
   */
  constructor(
    private businessService: BusinessService,
    public auth: AuthHelper,
    public alertService: AlertService,
    public authService: AuthService,
    private translate: TranslateService,
    private locale: LocaleHelper,
    private str: StringHelper,
    private categoryService: CategoryService,
    private serviceService: ServiceService,
    private router: Router,
    private url: UrlHelper
  ) {
    this.business = {};
    this.business.lat = 0;
    this.business.lng = 0;
  }

  /**
   * 
   */
  ngOnInit() {
    this.getBusiness();

  }

  /**
   * 
   * @param map 
   */
  mapReady(map) {
    // when map ready, call the setCenter
    this.map = map;
    this._setMap();
  }

  /**
   * 
   */
  _setMap() {
    this.map.setCenter({ lat: this.business.lat, lng: this.business.lng });
  }

  /**
   * 
   */
  onClick_BookAService() {
    this.router.navigateByUrl("booking.create");
  }

  /**
   * 
   */
  onSubmit_Signin() {
    this.is_loading = true;

    this.authService.signin(this.email, this.password, this.url.getSubdomain()).pipe(first()).subscribe(
      (res: Response) => {
        this.is_loading = false;

        if (res.success) {
          var is_force_reset_password = this.auth.getUser().is_force_reset_password;
          var x = this.auth.getUser().reset_password_token;
          if (is_force_reset_password == 1) {
            // c = 911 means we are forcing to change the password and 
            // need to show helpful information why we are asking to change it.
            this.router.navigate(['/reset-password'], { queryParams: { "c": "911", "x": x } });
          }
          else {
            if (this.auth.getUser().user_type == 'customer') {
              this.router.navigate(['/booking.new']);
            }
            else {
              this.router.navigate(['/dashboard']);
            }
          }
        } else {
          this.alertService.error(this.translate.instant("alert-error"));
        }
      },
      error => {
        this.is_loading = false;
        this.alertService.error(error);
      });
  }

  /**
   * 
   */
  getBusiness() {
    this.is_loading = true;

    let subdomain = this.url.getSubdomain();

    if(this.url.isLandingSubdomain(subdomain)){
      this.router.navigate(["/signup"]);
    }
    this.businessService.getBySubdomain(subdomain).subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      if (temp.data != undefined) {
        if (temp.data.id != undefined) {
          this.is_valid_subdomain = true;
          this.business = temp.data;
         // console.log(this.business.lat);
          if (this.str.hasSomething(this.business.lat) && this.str.hasSomething(this.business.lng)) {
            this.business.lat = parseFloat(this.business.lat);
            this.business.lng = parseFloat(this.business.lng);
          }
          else {
            this.show_map = false;
          }
          if (!this.str.hasSomething(this.business.banner)) {
            this.business.banner = "assets/img/banner/1.jpg";
          }
          if (this.str.hasSomething(this.business.logo)) {
            this.has_logo = true;
            this.logo_class = "mt--8";
          }
          else {
            this.has_logo = false;
            this.logo_class = "";
          }
          this._setWorkingHrs();
          this._fixWorkingTime();

          // there are chances that map is not loaded yet,
          // but in case its loaded, below will work
          if (this.map != undefined) {
            this._setMap();
          }

          // get all services which are available for customer
          if (Number(this.business.enable_internal_booking) == 1) {
            if (this.auth.isLoggedIn() == true) {
              this.is_show_services_or_categories_list = true;
            }
          } else {
            this.is_show_services_or_categories_list = true;
          }

          // if category is enabled
          if (this.is_show_services_or_categories_list) {
            if (this.business.enable_categories == '1') {
              this.is_show_categories = true;
              this._getCategories();
            }
            else {
              this.is_show_services = true;
              this._getServices();
            }
          }

        }
        else {
          this.router.navigate(["/page-not-found"]);
        }
      }
    });
  }

  /**
   * 
   */
  _getServices() {
    let _p = {
      "is_available_to_customers": true,
      "business_id": this.business.id,
      "active_only":1,
      "bpa": true
    }
    this.is_loading = true;
    this.serviceService.getAllByBusinessId(_p).subscribe(data => {
      let temp: any
      temp = data;
      this.is_loading = false;
      this.services = temp.data;

    });
  }

  /**
   * 
   */
  _getCategories() {
    let _p = {
      "business_id": this.business.id,
      "active_only":1,
      "bpa": true
    }
    this.is_loading = true;
    this.categoryService.getAllWithServicesByBusinessId(_p).subscribe(data => {
      let temp: any
      temp = data;
      this.is_loading = false;
      this.categories = temp.data;

    });
  }


  /**
   * 
   */
  onInput_FixTime() {
    this._setWorkingHrs();
    this._fixWorkingTime();
  }

  /**
   * 
   */
  _setWorkingHrs() {
    this.mon_working_hours_alt = this.business.mon_working_hours;
    this.tue_working_hours_alt = this.business.tue_working_hours;
    this.wed_working_hours_alt = this.business.wed_working_hours;
    this.thu_working_hours_alt = this.business.thu_working_hours;
    this.fri_working_hours_alt = this.business.fri_working_hours;
    this.sat_working_hours_alt = this.business.sat_working_hours;
    this.sun_working_hours_alt = this.business.sun_working_hours;
    if (this.mon_working_hours_alt == "" || this.mon_working_hours_alt == null) {
      this.mon_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if (this.tue_working_hours_alt == "" || this.tue_working_hours_alt == null) {
      this.tue_working_hours_alt = this.translate.instant("lbl-closed");;
    }
    if (this.wed_working_hours_alt == "" || this.wed_working_hours_alt == null) {
      this.wed_working_hours_alt = this.translate.instant("lbl-closed");;
    }
    if (this.thu_working_hours_alt == "" || this.thu_working_hours_alt == null) {
      this.thu_working_hours_alt = this.translate.instant("lbl-closed");;
    }
    if (this.fri_working_hours_alt == "" || this.fri_working_hours_alt == null) {
      this.fri_working_hours_alt = this.translate.instant("lbl-closed");;
    }
    if (this.sat_working_hours_alt == "" || this.sat_working_hours_alt == null) {
      this.sat_working_hours_alt = this.translate.instant("lbl-closed");;
    }
    if (this.sun_working_hours_alt == "" || this.sun_working_hours_alt == null) {
      this.sun_working_hours_alt = this.translate.instant("lbl-closed");;
    }

  }

  /**
   * 
   */
  _fixWorkingTime() {
    if (this.mon_working_hours_alt == "") {
      this.mon_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if (this.tue_working_hours_alt == "") {
      this.tue_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if (this.wed_working_hours_alt == "") {
      this.wed_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if (this.thu_working_hours_alt == "") {
      this.thu_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if (this.fri_working_hours_alt == "") {
      this.fri_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if (this.sat_working_hours_alt == "") {
      this.sat_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if (this.sun_working_hours_alt == "") {
      this.sun_working_hours_alt = this.translate.instant("lbl-closed");
    }
    if (this.mon_working_hours_alt == "00:00-00:00") {
      this.mon_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if (this.tue_working_hours_alt == "00:00-00:00") {
      this.tue_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if (this.wed_working_hours_alt == "00:00-00:00") {
      this.wed_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if (this.thu_working_hours_alt == "00:00-00:00") {
      this.thu_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if (this.fri_working_hours_alt == "00:00-00:00") {
      this.fri_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if (this.sat_working_hours_alt == "00:00-00:00") {
      this.sat_working_hours_alt = this.translate.instant("lbl-any-time");
    }
    if (this.sun_working_hours_alt == "00:00-00:00") {
      this.sun_working_hours_alt = this.translate.instant("lbl-any-time");
    }
  }


}
