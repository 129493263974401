import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '../../_service/category.service';
import { ServiceService } from '../../_service/service.service';
import { CalendarService } from '../../_service/calendar.service';
import { AlertService } from '../../_service/alert.service';
import { BusinessService } from '../../_service/business.service';
import { FileHelper } from '../../_helper/file';
import { AuthHelper } from '../../_helper/auth';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-config-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit, AfterViewInit {


  /**
   * 
   */
  is_loading = false;
  all_services = [];
  selected_service: any;
  all_categories: any;
  // this feature is disbled
  auto_generate_calendar = '1';
  is_service_selected = false;
  is_add_new_service = false;
  title = "";
  title_category = "";
  service_temp_image = "";
  color: any;
  is_image_exists = false;
  is_on_category = false;
  is_on_service = true;

  status = [{ "value": "active" }, { "value": "inactive" }];
  boolean_status = [{ "value": "1" }, { "value": "0" }];
  /**
   * 
   * @param calendarService 
   */
  constructor(
    public categoryService: CategoryService,
    public serviceService: ServiceService,
    public calendarService: CalendarService,
    public auth: AuthHelper,
    public translate: TranslateService,
    public modal: NgxSmartModalService,
    public alertService: AlertService,
    public businessService: BusinessService,
    public file: FileHelper,
    public router: Router

  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this._resetServiceForm();
    this._getAllService();
    this._getAllCategory();
    this.is_on_service = true;
  }

  ngAfterViewInit() {
  }

  /**
   * 
   */
  ngOnInit() {

  }

  _init() {
    this._resetServiceForm();
  }

  /**
   * 
   */
  _getAllService() {
    this.is_loading = true;
    this.serviceService.getAll().subscribe(data => {
      let temp: any;
      temp = data;
      this.all_services = temp.data;
      this.is_loading = false;
    });
  }

  /**
   * 
   */
  _getAllCategory() {
    this.is_loading = true;
    this.categoryService.getAll().subscribe(data => {
      let temp: any;
      temp = data;
      this.all_categories = temp.data;
      this.is_loading = false;
    });

  }

  /**
   * 
   */
  _resetServiceForm() {
    // WARNING NOTES
    // gap_between_next_booking this is set - 0 or 1, 0 mean 0 gap 1 mean 1 day
    // only enabled for accommodation 
    this.selected_service = {
      "id": "", "service_type": "0", "name": "", "description": "", "detailed_description": "", "color": "", "is_public": "0", "price": "0",
      "duration": "1", "max_duration": "10", "break_after": "0", "break_before": "0", "is_cancellable_by_customer": "0", "minimum_time_before_cancel": "24",
      "checkin": "", "checkout": "", "max_number_of_people": "0", "discount_on_total": "0", "discount_percent": "0", "minimum_time_needed_before_booking": "1",
      "is_allowed_in_waiting_list": "0", "image": "", "is_need_approval": "0", "how_far_ahead": "30", "vat": "", "is_require_online_payment": "0",
      "max_qty_allowed_per_slot": "0", "is_send_notification_for_new_booking": "1", "gap_between_next_booking": "0", "qty": "1", "status": "inactive", "cleaning_fee": "0"
    };
    this.is_add_new_service = true;
    this.is_service_selected = false;
    this.title = this.translate.instant("heading-add-service");
  }

  /**
   * 
   */
  onClick_AddService() {
    this.is_on_category = false;
    this.is_on_service = true;
    this.is_add_new_service = true;
    this.is_service_selected = false;
    this._resetServiceForm();
    this._getAllCategory();
    this.title = this.translate.instant("heading-add-service");
  }

  /**
   * 
   * @param event 
   */
  selectImage(event: any) {
    let self = this;
    this.file.onFileChanged(event, function (url, filedata) {
      self.selected_service.image = url
      self.service_temp_image = filedata;
    });
  }

  /**
   * 
   */
  onClick_DeleteImage() {
    this.is_loading = true;
    this.selected_service.image = "";
    this.serviceService.save(this.selected_service).subscribe(data => {
      let temp: any;
      temp = data;
      this._getAllService();
      if (temp.success) {
        this.alertService.success(temp.msg);
        this._getService(temp.data.id);
      } else {
        this.alertService.error(temp.msg);
      }
      this.is_loading = false;
    });

  }

  /**
   * 
   */
  onClick_Save() {
    if (this._validate()) {
      this._save();
    }

  }

  _validate() {
    // only validate if service is enabled

    let flag = true;

    if (this.selected_service.name == "") {
      flag = false;
      this.alertService.error("Service Name cannot be empty");
    }
    if (this.selected_service.description == "" && this.selected_service.service_type == '4') {
      flag = false;
      this.alertService.error("Please provider External Service URL");
    }
    if (this.selected_service.description != "" && this.selected_service.service_type == '4') {
      if (this.selected_service.description.substring(0, 4).toLowerCase() != 'http') {
        flag = false;
        this.alertService.error("Please prefix HTTPS:// or HTTP:// in the external URL ");
      }
    }

    if (Number(this.selected_service.how_far_ahead) > 365) {
      flag = false;
      this.alertService.error("To far ahead booking not allowed. Max value 365.");
    }

    if (Number(this.selected_service.duration) == 0) {
      flag = false;
      this.alertService.error("Min Duration cannot be 0");
    }

    if (!this.selected_service.category_id && this.is_add_new_service) {
      flag = false;
      this.alertService.error("Category is required");
    }

    if (this.selected_service.is_require_online_payment == "1") {
      if (Number(this.selected_service.price) < 1) {
        flag = false;
        this.alertService.error("Price cannot be 0");
      }
    }

    if (this.selected_service.service_type == "2") {
      if (Number(this.selected_service.max_duration) == 0) {
        flag = false;
        this.alertService.error("Max Duration cannot be 0");
      }
      if (Number(this.selected_service.max_duration) < Number(this.selected_service.min_duration)) {
        flag = false;
        this.alertService.error("Max Duration need to be greater than Min Duration");
      }

    }

    if (this.selected_service.service_type == "3") {
      if (Number(this.selected_service.max_duration) == 0) {
        flag = false;
        this.alertService.error("Max Duration cannot be 0");
      }
      if (this.selected_service.checkin == "") {
        flag = false;
        this.alertService.error("Please specify checkin time.");
      }
      if (this.selected_service.checkout == "") {
        flag = false;
        this.alertService.error("Please specify checkout time.");
      }
      if (Number(this.selected_service.max_duration) > 365) {
        flag = false;
        this.alertService.error("Max Duration cannot be more than an year");
      }

    }
    return flag;
  }

  _save() {
    this.is_loading = true;
    if (this.selected_service.service_type == "4") {
      this.selected_service.color = '000';
    }
    this.serviceService.save(this.selected_service).subscribe(data => {
      let temp: any;
      temp = data;
      this._getAllService();
      if (temp.success) {
        if (this.is_add_new_service == true) {
          if (this.selected_service.service_type == '1' || this.selected_service.service_type == '4') {
            this.auto_generate_calendar = '0';
          }
          if (this.auto_generate_calendar == "1") {
            this._autoGenerateCalendar(temp.data.id);
          }
          this.is_add_new_service = false;
        }
        this.alertService.success(temp.msg);
        this._getService(temp.data.id);
      } else {
        this.alertService.error(temp.msg);
      }
      this.is_loading = false;

    });
  }

  /**
   * 
   */
  onChange_ServiceQuantity(e) {
    if (Number(e) > 100) {
      this.alertService.error("Error: Maximum Quantity allowed is 100");
      this.selected_service.qty = 100;
    }
  }

  /**
   * 
   */
  _checkAuthGenerateFlag() {
    if (this.selected_service.service_type == '2' || this.selected_service.service_type == '3') {
      if (this.is_add_new_service) {
        this.auto_generate_calendar = '1';
      }
    }
  }

  /**
   * 
   * @param id 
   */
  _autoGenerateCalendar(id) {
    this.is_loading = true;

    let _p = {
      "service_id": id,
      'qty': this.selected_service.qty
    }
    this.calendarService.autoGenerateCalendar(_p).subscribe(data => {

      let temp: any;
      temp = data;
      if (temp.success) {
        //this.alertService.success(temp.msg);
        this.alertService.success("Don't forget to Schedule your calendar Days !");

      }
      else {
        this.alertService.error(temp.msg);
      }
      this.is_loading = false;

    });
  }


  onClick_TryDelete() {
    this.modal.getModal('ser_id').open();
  }

  onClick_ModalClose() {
    this.modal.getModal('ser_id').close();
  }
  /**
   * 
   */
  onClick_Delete() {
    this.is_loading = true;

    this.serviceService.delete(this.selected_service).subscribe(data => {
      let temp: any;
      temp = data;
      this._getAllService();
      this._resetServiceForm();
      this.alertService.success(temp.msg);
      this.is_loading = false;

    });
    this.modal.getModal('ser_id').close();
  }

  /**
   * 
   */
  onClick_ManageCategory() {
    this.is_on_category = true;
    this.is_on_service = false;
  }

  /**
   * 
   * @param event 
   * @param name 
   * @param id 
   */
  onClick_Service(event, name, id) {
    this.is_on_category = false;
    this.is_on_service = true;
    this.is_service_selected = false;
    this.is_add_new_service = false;
    this._getService(id);
  }

  /**
   * 
   * @param id 
   */
  _getService(id) {
    this.is_loading = true;
    this.serviceService.getById(id).subscribe(data => {
      this.is_service_selected = true;
      let temp: any;
      temp = data;
      this.selected_service = temp.data;
      if (this.selected_service.image != "" && this.selected_service.image != "null" && this.selected_service.image != null) {
        this.is_image_exists = true;
      }
      else {
        this.is_image_exists = false;
      }
      this.selected_service.service_type = Number(this.selected_service.service_type);
      //console.log(this.selected_service);
      this.title = this.translate.instant("lbl-service") + ": " + temp.data.name;
      this.title_category = this.translate.instant("lbl-category");

      this.categoryService.getAllWithServices().subscribe(data => {
        let temp: any;
        temp = data;
        temp.data.forEach(k => {
          k.class = "btn-secondary float-right btn btn-sm";
          k.title = "btn-unassign";
          Object.keys(k).forEach(k2 => {
            if (k2 == 'services') {
              if (k[k2] != null) {
                k[k2].forEach(cal => {
                  if (cal.id == this.selected_service.id) {
                    k.class = "btn-light text-muted border float-right btn btn-sm";
                    k.title = "btn-assign";
                  }
                });
              }
            }
          });
        });
        this.is_loading = false;
        this.all_categories = temp.data;
      });
    });

  }

  /**
   * 
   * @param event 
   */
  onClick_SelectCategory(event,cat) {
    this.is_loading = true;

    let el = $(event.target);
    let id = event.target.id.substr(3);
    let assign = false;
    if (cat.title == 'btn-unassign') {
      assign = true;
    }
    else {
      assign = false;
    }

    this.serviceService.toggleCategory(assign, id, this.selected_service.id).subscribe(data => {
      let temp = data;
      this.is_loading = false;
      this._getAllService();
      this._getService(this.selected_service.id);
    });
  }

  checkOther(c) {
    if (!c.target.checked) {
      this.selected_service.is_cancellable_by_customer = "0";
    }
  }
}
