import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../_service/category.service';
import { AlertService } from '../../_service/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-config-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  /**
   * 
   */
  all_categories = [];
  selected_category:any;
  is_category_selected = false;
  name = "";
  is_loading = false;

  /**
   * 
   * @param categoryService 
   */
  constructor(
    private categoryService: CategoryService,
    public translate: TranslateService,
    private alertService: AlertService
  ) { 

    this._resetCategoryForm();
    this._getAllCategory();
  
  }

  /**
   * 
   */
  ngOnInit() {
  }

  /**
   * 
   */
  _resetCategoryForm(){
    this.selected_category = {"id":"","name":""};
    this.name = "";
    this.is_category_selected = false;

  }

  /**
   * 
   */
  onClick_Category(event,name,id){
    this.selected_category.id = id;
    this.selected_category.name = name;
    this.is_category_selected = true; 
  }

  onClick_Reset(){
    this._resetCategoryForm();
  }

  /**
   * 
   */
  onClick_Save(){
    if(this.selected_category.name == ""){
      this.alertService.error("Please specify a name");
      return false;
    }
    this.is_loading = true;
    this.categoryService.save(this.selected_category).subscribe(data => {
      let temp:any;
      temp = data;
      this.is_loading = false;
      this._resetCategoryForm();
      this._getAllCategory();
      this.alertService.success(temp.msg);
    });

  }

  /**
   * 
   */
  onClick_Delete(){
    this.is_loading = true;
    this.categoryService.delete(this.selected_category).subscribe(data => {
      let temp:any;
      temp = data;
      this.is_loading = false;
      this._resetCategoryForm();
      this._getAllCategory();
      if(temp.success){
        this.alertService.success(this.translate.instant("alert-success"));
      }else{
        this.alertService.error(temp.msg);
      }
    },
    error=>{
      this.is_loading = false;
      this.alertService.error(this.translate.instant("alert-error"));
    });

  }

  /**
   * 
   */
  _getAllCategory(){
    this.is_loading = true;
    this.categoryService.getAll().subscribe(data => {
      let temp:any;
      temp = data;
      this.is_loading = false;
      this.all_categories = temp.data;
    });

  }
}