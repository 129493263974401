import { NullTemplateVisitor } from "@angular/compiler";
import { HttpClientModule,HttpParams,HttpHeaders } from '@angular/common/http'; 
import { AuthHelper } from "./auth";
import { UrlHelper } from "./url";

export class ParamsHelper {
    headers: HttpHeaders;

    make(body = null, _p = null) {
        let temp:any;
        temp = body;
        let user = JSON.parse(localStorage.getItem("currentUser"));
        if (user != undefined && user != null) {
            if (user.data != undefined && user.data != null) {
                if (user.data.auth_token != undefined && user.data.auth_token != null && user.data.auth_token != "") {
                    temp = body.append("auth_token", user.data.auth_token);
                }
            }
        }
        if (_p != null) {
            return this._append(temp, _p);
        }

        return temp;
    }

    /**
    * 
    * @param temp 
    * @param _p 
    */
    _append(temp, _p) {

        Object.keys(_p).forEach(k => {
            let t = temp.append(k, _p[k]);
            // below line is important because temp only return
            // it does not update temp 
            temp = t;
        });
        return temp;

    }

    makeHeadersWithAuth(){
        let auth = new AuthHelper();
        let token = auth.getUser().auth_token;
        let headers = new HttpHeaders().set('Authorization', 'Token '+token).append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        return headers;
    }

}
