<div class="card card-sm">
    <div class="card-header bg-secondary">
        <a href="javascript:void(0);" *ngIf="business?.enable_categories == '1'" (click)="onClick_BackBtn($event)" class="float-left text text-danger" translate ><i class=" icon-chevron-left"></i>btn-back</a>
        <a href="javascript:void(0);" (click)="onClick_NextBtn()" *ngIf="is_available == true && is_ready_to_select_datetime"
            class="float-right btn btn-sm btn-primary" translate >
            btn-select-datetime<i class=" icon-chevron-right"></i>
        </a>
        <div class="text text-center title-decorative" *ngIf="is_loading == true" translate  >
            <img src="assets/img/ajax-loader-light-gray.gif"  > txt-loading
        </div>
        <div class="title-decorative text text-center text-muted" *ngIf="is_loading == false" translate>{{title}}</div>
    </div>
    <div class="">
        <div class="alert alert-danger ml-4 mr-4 mt-2 mb-2" *ngIf="is_available==false" >{{this.err}}</div>
        <ul class="list-group list-group-flush" *ngIf="is_available==true" >
            <li  [hidden]="s.is_require_online_payment == '1' && business?.enable_paypal=='0' && business?.enable_stripe=='0'"
             class="list-group-item" id="ser{{s.id}}" *ngFor="let s of category_services;let i = index"><span id="ser{{s.id}}">{{s.name}}.
                    <span class="text text-muted"> {{s.price}} NOK <span  *ngIf="s.service_type == 3 && s.cleaning_fee > 0" class="text text-primary"> + Cleaning Fee: {{s.cleaning_fee}} NOK</span></span>
                    <button id="ser{{s.id}}" [ngClass]="toggle_class" class="{{s.class}}" (click)="onSelectService($event,i,s.name,s.price,s.service_type,s.is_return_same_day,s.duration,s.max_duration,s)"  translate >btn-select</button>
                </span> <br> <small class="text text-muted" translate>{{s.desc}}</small>
            </li>
        </ul>
    </div>
    <div class="card-footer bg-secondary">
        <a href="javascript:void(0);" *ngIf="business?.enable_categories == '1'" (click)="onClick_BackBtn($event)" class="float-left text text-danger" translate ><i class=" icon-chevron-left"></i>btn-back</a>
        <a href="javascript:void(0);" (click)="onClick_NextBtn($event)" *ngIf="is_available == true && is_ready_to_select_datetime"
            class="float-right  btn btn-sm btn-primary" translate >
            btn-select-datetime<i class=" icon-chevron-right"></i>
        </a>
        <div class="text text-center title-decorative" *ngIf="is_loading == true" translate  >
            <img src="assets/img/ajax-loader-light-gray.gif"  > txt-loading
        </div>
    </div>
</div>