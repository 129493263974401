import { Component, OnInit, Input, Output, EventEmitter, SecurityContext } from '@angular/core';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType, IPayPalTransactionItem } from 'ngx-paypal';
import * as moment from 'moment-timezone';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { DomSanitizer } from '@angular/platform-browser';
import { BusinessService } from '../../../../_service/business.service';
import { UrlHelper } from '../../../../_helper/url';
import { CustomerService } from '../../../../_service/customer.service';
import { BookingService } from '../../../../_service/booking.service';
import { AlertService } from '../../../../_service/alert.service';
import { StringHelper } from 'src/app/_helper/string';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
@Component({
  selector: 'app-new-booking-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.css']
})
export class PayComponent implements OnInit {

  elements: Elements;
  card: StripeElement;
  STRIPE_PAYMENT_INTENT_SUCCESS_FLAG = 'succeeded';

  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'no'
  };

  stripeTest: FormGroup;
  @Input() config: any;
  @Output() emit_config = new EventEmitter<any>();
  notes = "";
  public payPalConfig: any;
  sub_total = 0;
  tax = 0;
  invoice = "";
  has_paypal_payment_tried = false;
  has_stripe_payment_tried = false;
  is_cancelled = false;
  is_error = false;
  is_success = false;
  payment_type = "";
  card_options = 1;
  existing_cards: any;
  save_new_card = "0";
  boolean_status = [{ "value": "1" }, { "value": "0" }];
  selected_existing_card = "";
  stripe_3d_url = this.sanitizer.bypassSecurityTrustResourceUrl("");
  stripe_payload: any;
  stripe_3d_step = "1";
  stripe_finished = false;
  stripe_closed_manually = false;
  _3DCheck: any;
  stripe_3d_status = "requires_action";

  stripe = { "number": "", "exp_month": "", "exp_year": "", "cvc": "" };
  constructor(
    private alertService: AlertService,
    private customerService: CustomerService,
    private fb: FormBuilder,
    public translate: TranslateService,
    private string: StringHelper,
    private modal: NgxSmartModalService,
    private stripeService: StripeService,
    private sanitizer: DomSanitizer,
    private bookingService: BookingService,
    private businessService: BusinessService,
    private url: UrlHelper
  ) {


  }

  ngOnInit() {
    this._getInvoice();
    this._getPaymentInfo();
    this._getSavedCards();
  }

  _getSavedCards() {
    this.customerService.getAllCards().subscribe(data => {
      let temp: any;
      temp = data;
      this.existing_cards = temp.data;
    });
  }

  _getPaymentInfo() {
    this.businessService.getBySubdomain(this.url.getSubdomain()).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.data.main_vendor_for_payment == 'Stripe') {
        if (temp.data.enable_stripe == '1') {
          this.payment_type = "Stripe";
          this.makeForStripe();
        }
      }
      if (temp.data.main_vendor_for_payment == 'Paypal') {
        if (temp.data.enable_paypal == '1') {
          this.payment_type = "Paypal";
          this.makeForPaypal();
        }
      }
      if (!this.string.hasSomething(temp.data.main_vendor_for_payment)) {
        //console.log(temp.data);
        this.alertService.error(this.translate.instant("alert-business-services-are-disabled"));
      }
    });
  }

  onClick_Back() {
    this.config.step = "datetime";
    this.emit_config.emit(this.config);
  }

  makeForStripe() {

    //console.log("de");
    //const STRIPE_LIVE_S-ECRET = "sk_live_UdIidccFUhdMmJ90CmJrnJHj";
    //pk_live_EG36ibb61ftw3WWpZ7dWfAs1
    //pk_test_GdYCTvjyaWFNuDo1G2nxfmeW
    /*
    this.stripeService.setKey("pk_test_GdYCTvjyaWFNuDo1G2nxfmeW");
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#444',
                lineHeight: '60px',
                fontWeight: 300,
                fontSize: '18px',
                '::placeholder': {
                  color: '#999'
                }
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
      */

  }


  buy() {
    const name = this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          console.log(result.token);
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });

  }

  /**
   * 
   */
  makeForPaypal() {

    if (!this.string.hasSomething(this.config.business.paypal)) {
      console.log(this.config);
      this.alertService.error(this.translate.instant("alert-business-services-are-disabled"));
      this.payment_type = "";
      return false;
    }

    this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PayPalEnvironment.Production, {
      commit: true,
      client: {
        production: this.config.business.paypal.client_id
      },
      button: {
        label: 'pay',
        size: 'large',
        shape: 'rect',
        color: 'black',
        tagline: false
      },
      onPaymentComplete: (data, actions) => {
        this._onPaymentSuccess(data);
      },
      onCancel: (data, actions) => {
        this._onPaymentCancel(data);
      },
      onError: (err) => {
        this._onPaymentError(err);
      },
      transactions: [{
        amount: {
          currency: 'NOK',
          total: this.config.summary.price,
          details: {
            subtotal: this.sub_total,
            tax: this.tax,
            shipping: 0,
            handling_fee: 0,
            shipping_discount: 0,
            insurance: 0
          }
        },
        invoice_number: this.invoice,
        item_list: {
          items: [{
            name: this.config.summary.service,
            currency: "NOK",
            price: this.sub_total,
            quantity: 1,
            description: this.config.summary.service,
            tax: this.tax
          }]
        }
      }]
    });
  }

  /**
   * 
   */
  _getInvoice() {
    this.sub_total = this.config.summary.price - (Number(this.config.summary.price) * (Number(this.config.business.vat) / 100));
    this.tax = Number(this.config.summary.price) * (Number(this.config.business.vat) / 100);
    let inv = this.config.business.id + "." + this.config.calendar_id + "." + this.config.summary.price + "." + moment().unix();
    inv = inv.replace(/,/g, '').replace(/ /g, '');
    return inv;
  }


  /**
   * //  "payment": { "required": false, "success": true, "token":any, "vendor": "Paypal" },
   * 
   */
  _onPaymentSuccess(data) {
    this.has_paypal_payment_tried = true;
    this.alertService.success(this.translate.instant("alert-success"));
    this.config.notes = this.notes;
    this.config.payment.token = JSON.stringify(data);
    this.config.payment.success = true;
    this.config.payment.balance = 0;
    this.config.payment.vendor = this.payment_type;
    this.config.step = "payment-finish";
    this.is_success = true;
    this.emit_config.emit(this.config);
  }

  /**
   * 
   */
  _onPaymentCancel(data) {
    this.has_paypal_payment_tried = true;
    this.alertService.error(this.translate.instant("alert-error"));
    //    this.alertService.info("Please start over for new booking or contact us for further help.");
    this.config.notes = this.notes;
    this.config.payment.token = JSON.stringify(data);
    this.config.step = "payment-finish";
    this.config.payment.success = false;
    this.is_cancelled = true;
    //    this.emit_config.emit(this.config);
  }

  /**
   * 
   */
  _onPaymentError(data) {
    this.has_paypal_payment_tried = true;
    this.alertService.error(this.translate.instant("alert-error"));
    //this.alertService.info("Please contact with your booking details.");
    this.config.notes = this.notes;
    this.config.payment.token = JSON.stringify(data);
    this.config.step = "payment-finish";
    this.config.payment.success = false;
    this.is_error = true;
    //  this.emit_config.emit(this.config);
  }

  onClick_StartAgain() {
    this.config.step = "start-again";
    this.emit_config.emit(this.config);
  }

  _openStripe3D() {
    this.modal.getModal('stripe_3d').open();
    let _t = this;
    this._3DCheck = setInterval(function () {
      let _p = { "pi": _t.stripe_payload.payment_intent_id };
      _t.bookingService.checkPiStatus(_p).subscribe(data => {
        let temp:any;
        temp = data;
        console.log(temp);
        if(temp.data.status == "requires_payment_method"){
          clearInterval(_t._3DCheck);
          _t._stripe3DFailed();
          _t.modal.getModal('stripe_3d').close();


        }
        if(temp.data.status == "requires_confirmation"){
          _t.onClick_FinishedStripe3D();
          clearInterval(_t._3DCheck);
        }
      });
    }, 1000);

  }

  _stripe3DFailed(){
    this.alertService.error(this.translate.instant("alert-error"));
    this.config.step = "payment-finish";
    this.config.payment.success = false;
    this.is_error = true;

  }

  onClick_CloseStripe3D() {
    clearInterval(this._3DCheck);

    this.modal.getModal('stripe_3d').close();
    this.onClick_StartAgain();
  }

  onClick_FinishedStripe3D() {
    this.stripe_3d_step = "3";
    console.log("finished");
    this.modal.getModal('stripe_3d').close();
    this._try3DFinalStep();

  }

  _try3DFinalStep() {
    this.config.is_loading = true;
    let _p = { "confirmed_3d": true, "pi": this.stripe_payload.payment_intent_id, "services": JSON.stringify(this.config.service), "description": this.config.summary.service, "business_id": this.config.business_id, "price": this.config.summary.price, "selected_existing_card": this.selected_existing_card, "card_options": this.card_options, "save_new_card": this.save_new_card, "payment_settings": true, "credit_cc_num": this.stripe.number, "credit_cc_month": this.stripe.exp_month, "credit_cc_year": this.stripe.exp_year, "credit_cc_cvc": this.stripe.cvc };
    this.bookingService.confirmPi(_p).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success && temp.data.status == this.STRIPE_PAYMENT_INTENT_SUCCESS_FLAG) {
        this.alertService.success(this.translate.instant("alert-success"));
        this.config.payment.success = true;
        this.config.notes = this.notes;
        this.has_stripe_payment_tried = true;
        this.config.step = "payment-finish";
        this.config.payment.balance = 0;
        this.is_success = true;
        this.emit_config.emit(this.config);

      }
      else {
        //this.alertService.error(this.translate.instant("alert-error"));
        this.alertService.error(temp.msg);
        this.config.step = "payment-finish";
        this.config.payment.success = false;
        this.is_error = true;
        //this.emit_config.emit(this.config);        

      }

    }, error => {
      this.config.is_loading = false;
    });
  }


  onClick_PayWithStripe() {
    if (this._validate()) {
      this.config.is_loading = true;
      let _p = { "services": JSON.stringify(this.config.service), "description": this.config.summary.service, "business_id": this.config.business_id, "price": this.config.summary.price, "selected_existing_card": this.selected_existing_card, "card_options": this.card_options, "save_new_card": this.save_new_card, "payment_settings": true, "credit_cc_num": this.stripe.number, "credit_cc_month": this.stripe.exp_month, "credit_cc_year": this.stripe.exp_year, "credit_cc_cvc": this.stripe.cvc };

      this.bookingService.registerCardAndMakePayment(_p).subscribe(data => {
        let temp: any;
        temp = data;
        this.stripe_payload = temp.data;
        // when action required from stripe open in a modal like below
        //https://stackoverflow.com/questions/29516475/how-to-open-a-url-as-a-bootstrap-modal-window

        this.config.is_loading = false;
        if (temp.success) {

          // keep below outside if IF, its needed later
          this.config.payment.vendor = this.payment_type;
          this.config.payment.token = JSON.stringify(temp.data);

          if (temp.data.requires_action) {
            this.stripe_3d_step = "2";
            let url = temp.data.payment_intent.next_action.redirect_to_url.url;
            this.stripe_3d_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.alertService.success(this.translate.instant("alert-please-proceed-3d-verification"));
            this._openStripe3D();
          }
          else {
            this.alertService.success(this.translate.instant("alert-success"));
            this.config.payment.success = true;
            this.config.notes = this.notes;
            this.has_stripe_payment_tried = true;
            this.config.step = "payment-finish";
            this.config.payment.balance = 0;
            this.is_success = true;
            this.emit_config.emit(this.config);
          }
        }
        else {
          this.alertService.error(temp.msg);
          this.alertService.error(this.translate.instant("alert-error"));
          //this.has_stripe_payment_tried = true;
          this.config.notes = this.notes;
          this.config.step = "payment-finish";
          //this.config.payment.token = JSON.stringify(temp.data);
          this.config.payment.success = false;
          this.is_error = true;
          //this.emit_config.emit(this.config);

        }
      }, error => {
        this.config.is_loading = false;
      });
    }
  }

  _validate() {
    if (this.card_options == 2) {
      if (this.selected_existing_card == "") {
        this.alertService.error(this.translate.instant("alert-please-select-a-card"));
        return false;
      }
    }
    return true;
  }

}
