<div class="card card-lg">
  <div class="card-header text-center bg-light" *ngIf="is_signup_pending == true">
    <h2 class="title-decorative text-primary" translate>heading-signup-as-a-customer</h2>
  </div>
  <div class="card-body" *ngIf="is_signup_pending == false">
    <h3 translate>txt-you-are-registered-successfully</h3>
    <hr>
    <div translate>
      txt-account-has-been-activated
    </div>
    <br><br>
    <a [routerLink]="['/signin']" class="btn btn-lg btn-success btn-block"
      translate>
      btn-signin-now<i class="fas fa-chevron-right"></i></a>
  </div>
  <div class="card-body" *ngIf="is_signup_pending == true">
    <div class="row form-group">
      <div class="col"><input autocomplete="off" [(ngModel)]="first_name" placeholder="{{
          'lbl-first-name' | translate }}" class="form-control form-control-lg"
          />
      </div>
      <div class="col"><input autocomplete="off" [(ngModel)]="last_name" class="form-control
          form-control-lg" placeholder="{{ 'lbl-last-name' | translate }}" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <select autocomplete="off" class="form-control form-control-lg" [(ngModel)]="country_mobile_code">
          <option value="">-- Mobile Code --</option>
          <option value="47">Norway</option>
          <option value="46">Sweden</option>
          <option value="45">Denmark</option>
      </select>
      </div>
      <div class="col"><input autocomplete="off" [(ngModel)]="mobile" class="form-control
        form-control-lg" placeholder="{{ 'lbl-mobile' | translate }}" />
    </div>
  </div>
    <div class="bg-secondary card card-sm card-body">
      <div class="row form-group">
        <div class="col"><input autocomplete="off" [(ngModel)]="username" minlength="6"
            maxlength="60" class="form-control form-control-lg"
            placeholder="{{ 'lbl-email' | translate }}" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col">
          <input autocomplete="off" [(ngModel)]="password" type="password" minlength="8"
            maxlength="16" class="form-control form-control-lg"
            placeholder="{{ 'lbl-password' | translate }}" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col">
          <input autocomplete="off" [(ngModel)]="confirm_password" type="password" minlength="8"
            maxlength="16" class="form-control form-control-lg"
            placeholder="{{ 'lbl-confirm-password' | translate }}" />
        </div>
      </div>

    </div>
    <div class="form-group">
      <div class="custom-control custom-checkbox align-items-center">
        <input autocomplete="off" type="checkbox" class="custom-control-input" 
          name="tnc" checked id="tnc" [(ngModel)]="tnc">
        <label class="custom-control-label text-muted" for="tnc">I
          Accept <a href="javascript:void(0)" [routerLink]="['/tnc']" routerLinkActive="router-link-active" >Terms And Conditions</a></label>
      </div>
    </div>
    <br>
    <div class="">
      <span *ngIf="is_loading == true"
        class="btn btn-light btn-lg btn-muted" translate><img
          src="assets/img/ajax-loader-light-gray.gif"
          translate> txt-loading</span>
      <button (click)="onClick_Signup()" *ngIf="is_loading == false" class="btn
        btn-lg btn-primary" translate>btn-submit</button>
    </div>
  </div>
  <div class="card-footer bg-light"*ngIf="is_signup_pending == true">
    <a href="javascript:void(0);" (click)="onClick_NeedHelp()" class="btn
      btn-light float-right" translate>btn-need-help</a>
    <a href="javascript:void(0);" [routerLink]="['/signin']" class="btn
      btn-light float-left" translate> <i class="fas fa-chevron-left"></i>
      btn-back</a>

  </div>
</div>
<small>
  <a href="javascript:void(0);" (click)="onClick_ChangeLanguage('en')"
    class="text text-muted">English</a>&nbsp;
  <a href="javascript:void(0);" (click)="onClick_ChangeLanguage('nb')"
    class="text text-muted">Norwegian</a>
</small>
