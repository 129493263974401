import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthHelper } from 'src/app/_helper/auth';

@Component({
  selector: 'app-new-booking-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  @Input() config: any;
  @Output() emit_config = new EventEmitter<any>();
  notes = "";

  constructor(
    private auth: AuthHelper
  ) { }

  ngOnInit() {
  }

  onClick_Back() {
    if (this.auth.getUser().user_type != 'customer') {
      this.config.step = "customer";
    }
    else {
      this.config.step = "datetime";

    }
    this.emit_config.emit(this.config);
  }

  onClick_Confirm() {
    this.config.step = "finish";
    this.config.notes = this.notes;
    this.emit_config.emit(this.config);
  }

  onClick_StartAgain() {
    this.config.step = "start-again";
    this.emit_config.emit(this.config);
  }

}
