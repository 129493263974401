import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    /**
     * 
     */
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) {

        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    }

    /**
     * 
     */
    _getBody() {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    getAll() {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/message/poll/all", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllEmailByCustomerId(_p){
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/message/poll/inbox-chain-by-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllSMSByCustomerId(_p){
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/message/poll/sms-history-by-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllEmailByBusinessId(_p){
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/message/poll/inbox-chain", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllSMSByBusinessId(_p){
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/message/poll/sms-history", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param id 
     */
    get(id) {
        return this._getById(id);
    }

    /**
     * 
     */
    getById(id) {
        return this._getById(id);
    }

    /**
     * 
     */
    _getById(id) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("id", id).append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/message/poll/by-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    create(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/message/do/save", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    sendWelcomeEmail(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);

        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/message/do/resend-welcome-email", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     * @param temp 
     * @param _p 
     */
    _append(temp, _p) {

        Object.keys(_p).forEach(k => {
            let t = temp.append(k, _p[k]);
            // below line is important because temp only return
            // it does not update temp 
            temp = t;
        });
        return temp;


    }

}
