import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthHelper } from '../../_helper/auth';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/_service/booking.service';
import { DateHelper } from 'src/app/_helper/date';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  payments: any;
  is_loading = false;
  settings = {
    noDataMessage: this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions: false,
    columns: {
      service_name: {
        title: this.translate.instant("lbl-service")
      },
      price: {
        title: this.translate.instant("lbl-price")
      },
      balance: {
        title: this.translate.instant("lbl-balance-due")
      },
      refund_time: {
        title: this.translate.instant("lbl-status"),
        valuePrepareFunction: (value) => {
          let ret = "";
          if (value > 0) {
            return "Refunded";
          }
        }

      },
      start_timestamp_in_minute: {
        title: this.translate.instant("lbl-starts"),
        valuePrepareFunction: (value) => { return this.date.formatForNorway(value * 60) }
      }
    }
  };

  constructor(
    private translate: TranslateService,
    private router: Router,
    public date: DateHelper,
    private bookingService: BookingService,
    private auth: AuthHelper
  ) {

    if (this.auth.getUser().is_allowed_payment != '1') {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {

    this.is_loading = true;
    this.bookingService.getAllWithPaymentDone().subscribe(data => {
      this.is_loading = false;
      let temp: any;
      temp = data;
      this.payments = temp.data;

    });

  }

  /**
   * 
   */
  onClick_Booking(e) {
    this.router.navigateByUrl("booking.detail?id=" + e.data.id);
  }

}
