<div class="row justify-content-center">
	<div class="col">
		<div class="container align-self-start">
			<div class="row mb-2">
				<div class="col text-left mt-2">
					<a class="text-muted btn btn-muted border" href="javascript:void(0);" [routerLink]="['/signin']"  >
						<i class="fa fa-chevron-left"></i> Back To Login
					</a>
				</div>
				<div class="col text-right ">
					<a href="javascript:void(0);">
						<img src="/assets/img/bookit24_black.png" class="responsive
							responsive-fluid" width="120">
					</a>
				</div>
			</div>
			<table class="table shadow-sm table-bordered table-responsive pricing
				rounded">
				<thead>
					<tr class='bg-secondary rounded shadow'>
						<th width='20%' scope="col text-muted"><span class="h5 title-decorative"
								translate>plan-page-features</span></th>
						<th scope="col bg-dark">
							<span class="h5" translate="">plan-page-basic</span>
						</th>
						<th scope="col">
							<span class="h5" translate="">plan-page-standard</span>
						</th>
						<th scope="col">
							<span class="h5" translate="">plan-page-pro</span>
						</th>
						<th width='20%' scope="col">
							<span class="h5" translate="">plan-page-enterprise</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row"><span class="h5" translate="">plan-page-monthly-price</span></th>
						<td>
							<span class="display-4 text-primary" [ngStyle]="{'font-size':'2.4em'}">149 NOK</span>
						</td>
						<td>
							<span class="display-4 text-primary" [ngStyle]="{'font-size':'2.4em'}">299 NOK</span>
						</td>
						<td>
							<span class="display-4 text-primary" [ngStyle]="{'font-size':'2.4em'}">599 NOK</span>
						</td>
						<td rowspan="2">
							<span class="display-4 mt-3" [ngStyle]="{'font-size':'2.4em'}" translate="">lbl-custom</span>
						</td>
					</tr>
					<tr>
						<th scope="row"><span class="h5" translate="">plan-page-yearly-price</span></th>
						<td>
							<span class="h5">1499 NOK</span>
						</td>
						<td>
							<span class="h5">2999 NOK</span>
						</td>
						<td>
							<span class="h5">5999 NOK</span>
						</td>
					</tr>
					<tr>
						<th scope="row" translate="">Calendars <sup>1</sup></th>
						<td>1
						</td>
						<td>5
						</td>
						<td translate="">10
						</td>
						<td rowspan="13" class="text-left bg-light">
							<div class="mb-3 mt-3 text-dark" translate="">plan-page-custom-plan-information</div>
							<hr>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-employees-login</th>
						<td>1</td>
						<td>2</td>
						<td translate="">10</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-bookings</th>
						<td translate="">Unlimited
						</td>
						<td translate="">Unlimited
						</td>
						<td translate="">Unlimited
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-rental-services</th>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-appointment-services</th>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-accommodation-services</th>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-internal-booking</th>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-online-payment-stripe-n-paypal</th>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-sms-reminders<sup>2</sup></th>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-email-reminders</th>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-customer-database</th>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-bookings-csv-exports</th>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-right" translate="">plan-page-exivo-integration
							<sup>3</sup></th>
						<td><i class="icon-circle-with-cross text-red"></i>
						</td>
						<td><i class="icon-circle-with-cross text-red"></i>
						</td>
						<td><i class="icon-check text-green"></i>
						</td>
					</tr>
					<tr class="shadow">
						<th scope="row"></th>
						<td>
							<a class="btn btn-lg btn-success shadow" (click)="onClick_Plan(1)"
								href="javascript:void(0)" translate>btn-signup-now</a>
						</td>
						<td>
							<a class="btn btn-lg btn-success shadow" (click)="onClick_Plan(2)"
								href="javascript:void(0)" translate>btn-signup-now</a>
						</td>
						<td>
							<a class="btn btn-lg btn-success shadow" (click)="onClick_Plan(3)"
								href="javascript:void(0)" translate>btn-signup-now</a>
						</td>
						<td>
							<a class="btn btn-lg btn-primary shadow" target="_blank"
								href="https://www.bookit24.no/foresporsel" translate>btn-contact-us</a>
						</td>
					</tr>
				</tbody>
			</table>

		</div>
		<!--end of col-->
		<div class="col-12 row no-gutters card-group text-secondary mb-2"
			translate="">
			<div class="mt-0" translate="">
				<sup>1</sup> <span translate="">plan-page-msg-1</span>
			</div>
			<div class="">
				<sup>2</sup> <span translate="">plan-page-msg-2</span>
			</div>
			<div class="">
				<sup>3</sup> <span translate="">plan-page-msg-3</span>
			</div>
		</div>

	</div>
	<!-- 
<div class="row justify-content-center">
	
	<div class="col-12 row no-gutters card-group text-secondary mb-2">
		<span class="title-decorative" translate="">txt-lets-get-start-by-choosing-a-plan-for-you</span>
	</div>
	<div class="col-12 row no-gutters card-group text-secondary mb-2" translate="">
		txt-no-payment-required-to-finish-registration<span>.&nbsp;</span><span
			class="text text-dark" translate="">txt-all-plans-are-free-for-14-days</span></div>
	<div class="col-12 row no-gutters card-group">

		<div class="card pricing shadow-sm card-l col-lg-6">
			<div class="card-body">
				<h5 class="title-decorative mb-1 pb-1 text-muted"></h5>
				<span class="display-4 text-dark mb-0"></span>
				<ul class="list-group shadow-sm">
					<li class="list-group-item">
						<span translate="">btn-calendar </span> <sup>1</sup>
					</li>
					<li class="list-group-item">
						<span class="" translate="">lbl-employee-login</span>
					</li>
					<li class="list-group-item">
						<span translate="">btn-services</span>
					</li>
					<li class="list-group-item">
						<span translate="">btn-bookings</span>
					</li>
					<li class="list-group-item">
						<span translate="">Internal Booking</span>
					</li>
					<li class="list-group-item">
						<span translate="">Online Payment (Stripe & Paypal)</span>
					</li>
					<li class="list-group-item">
						<span translate="">SMS Reminders </span> <sup>2</sup>
					</li>
					<li class="list-group-item">
						<span translate="">Email Reminders</span>
					</li>
					<li class="list-group-item">
						<span translate="">Customer Database</span>
					</li>
					<li class="list-group-item">
						<span translate="">Booking CSV Exports</span>
					</li>
					<li class="list-group-item">
						<span translate="">Exivo Integration </span> <sup>3</sup>
					</li>
					<li class="list-group-item">
						<span translate=""> - </span>
					</li>
				</ul>
				<a href="javascript:void(0);"  class="btn
					btn-lg btn-success shadow" translate="">btn-signup-now</a>
			</div>
		</div>
		<div class="card pricing shadow-sm card-l  col-lg-6" *ngFor="let
			plan of plan_stack">
			<div class="card-body">
				<h5 class="title-decorative mb-1 pb-1 text-muted">{{plan.payload.name}}</h5>
				<span class="display-4 text-dark mb-0" [ngStyle]="{'font-size':'2.4em'}">{{plan.payload.stripe[0].price}}
					NOK
					<span class="text-small" translate="">lbl-per-month
					</span></span>
				<strong class="h5 mb-1 text-primary">or {{plan.payload.stripe[1].price}} NOK
					<span translate="">lbl-per-year</span></strong>
				<ul class="list-group shadow-sm">
					<li class="list-group-item">
						{{plan.payload.no_of_calendars <999 ? plan.payload.no_of_calendars :
							'Unlimited'}} <span translate="">btn-calendar </span> <sup>1</sup>
					</li>
					<li class="list-group-item">
						{{plan.payload.no_of_users <999 ? plan.payload.no_of_users : 'Unlimited'}}
						<span class="" translate="">lbl-employee-login</span>
					</li>
					<li class="list-group-item">
						{{plan.payload.no_of_services <999 ? plan.payload.no_of_services :
							'Unlimited'}} <span translate="">btn-services</span>
					</li>
					<li class="list-group-item">
						{{plan.payload.no_of_bookings <999999 ? plan.payload.no_of_bookings :
							'Unlimited'}} <span translate="">btn-bookings</span>
					</li>
					<li class="list-group-item">
						<span translate="">Internal Booking</span>
					</li>
					<li class="list-group-item">
						<span translate="">Online Payment (Stripe & Paypal)</span>
					</li>
					<li class="list-group-item">
						<span translate="">SMS Reminders </span> <sup>2</sup>
					</li>
					<li class="list-group-item">
						<span translate="">Email Reminders</span>
					</li>
					<li class="list-group-item">
						<span translate="">Customer Database</span>
					</li>
					<li class="list-group-item">
						<span translate="">Booking CSV Exports</span>
					</li>
					<li class="list-group-item"
						*ngIf="plan.payload.is_allowed_to_use_exivo_services">
						<span translate="">Exivo Integration </span> <sup>3</sup>
					</li>
					<li class="list-group-item"
						*ngIf="!plan.payload.is_allowed_to_use_exivo_services">
						<span translate=""> - </span>
					</li>
				</ul>
				<a href="javascript:void(0);" (click)="onClick_Plan(plan)" class="btn
					btn-lg btn-success shadow" translate="">btn-signup-now</a>
			</div>
		</div>
	</div>
</div>
-->
