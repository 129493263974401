import { Component, OnInit } from '@angular/core';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../_service/user.service';
import { CustomerService } from '../../_service/customer.service';
import { BookingService } from '../../_service/booking.service';
import { BusinessService } from '../../_service/business.service';
import { MessageService } from '../../_service/message.service';
import { AuthHelper } from '../../_helper/auth';
import { UrlHelper } from '../../_helper/url';
import { AlertService } from '../../_service/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

import * as moment from "moment-timezone";
import { EditorModule } from 'primeng/editor';


@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  is_customer = false;
  user_id = 0;
  user: any;
  email = "";
  sms = "";
  msg = "";
  emails: any;
  smses: any;
  bookings: any;
  title = "Send SMS";
  is_on_sms = false;
  is_on_email = false;
  is_on_sms_history = false;
  is_on_email_history = false;
  is_on_booking = true;
  is_loading = false;
  status_map = { "1": "CANCELLED BY CUSTOMER", "2": "CANCELLED BY PROVIDER", "3": "NO SHOW", "4": "CONFIRMED", "5": "FINISHED", "6": "REFUNDED" };


  booking_table_settings = {
    noDataMessage: this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions: false,
    columns: {
      service: {
        // note that belpw instant will show correctly when you load some other page first then load this page
        title: this.translate.instant('lbl-service'),
      },
      start: {
        title: this.translate.instant('lbl-starts'),
      },
      end: {
        title: this.translate.instant('lbl-ends'),
      },
      price: {
        title: this.translate.instant('lbl-price'),
      },
      balance: {
        title: this.translate.instant('lbl-balance'),
      },
      status: {
        title: this.translate.instant('lbl-status'),
      }
    }
  };
  sms_table_settings = {
    noDataMessage: this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions: false,
    columns: {
      dated: {
        title: this.translate.instant('lbl-dated'),
        width: "25%",
      },
      message: {
        title: this.translate.instant('lbl-message'),
        width: "75%",
      },
    }
  };
  email_table_settings = {
    noDataMessage: this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions: false,
    columns: {
      dated: {
        title: this.translate.instant('lbl-dated'),
        width: "25%",
      },
      message: {
        title: this.translate.instant('lbl-message'),
        width: "75%",
        type: "html",
        valuePrepareFunction: (cell) => {
          return cell;
        },
      },
    }
  };
  total_revenue = 0;
  total_bookings = 0;
  event_list: any;
  email_history_list: any;
  sms_history_list: any;


  /**
   * 
   * @param activatedRoute 
   */
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    protected sanitizer: DomSanitizer,
    public auth: AuthHelper,
    public url: UrlHelper,
    public modal: NgxSmartModalService,
    public messageService: MessageService,
    public businessService: BusinessService,
    public bookingService: BookingService,
    public customerService: CustomerService,
    public alertService: AlertService,
    public translate: TranslateService,
  ) {

    if (auth.getUser().user_type != 'customer') {
      if (this.auth.getUser().is_allowed_customer != '1') {
        this.router.navigate(['/dashboard']);
      }

      this.route.queryParams.subscribe(params => {
        this.user_id = params['id'];
      });
    }
    else {
      this.user_id = auth.getUser().id;
      this.is_customer = true;
    }
    this.user = {};
    this.bookings = {};
  }

  /**
   * 
   */
  ngOnInit() {
    this._getUser();
  }

  _reset() {
    this.is_on_booking = false;
    this.is_on_email = false;
    this.is_on_sms = false;
    this.is_on_email_history = false;
    this.is_on_sms_history = false;
    this.sms = "";
    this.email = "";
    this.msg = "";
  }

  /**
   * 
   */
  _getUser() {
    this.is_loading = true;
    this.customerService.get(this.user_id).subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      this.user = temp.data;
      if (temp.success) {
        this.bookingService.getAllByCustomerId(this.user_id).subscribe(data => {
          let temp: any;
          temp = data;
          this.is_loading = false;
          this.bookings = temp.data;
          this._formatBookings();
        });
        // load history only for provider
        if (this.is_customer == false) {
          this.businessService.getBySubdomain(this.url.getSubdomain()).subscribe(data => {
            let temp: any;
            temp = data;
            let _p = { "customer_id": this.user_id };
            this.messageService.getAllEmailByCustomerId(_p).subscribe(data => {
              let temp: any;
              temp = data;
              this.is_loading = false;
              this.emails = temp.data;
              this._formatEmail();
            });
            this.messageService.getAllSMSByCustomerId(_p).subscribe(data => {
              let temp: any;
              temp = data;
              this.is_loading = false;
              this.smses = temp.data;
              this._formatSMS();
            });
          });
        }
      }
      else {
        this.router.navigateByUrl("customer-not-found?msg=cnf");
      }
    });

  }

  /**
 * 
 * @param data 
 */
  _formatBookings() {
    this.event_list = [];
    let start2 = ""
    let end2 = "";
    if (this.bookings != null) {
      this.bookings.forEach(e => {

        if(e.status == '3' || e.status=='5'){
          this.total_revenue = this.total_revenue + Number(e.price);
        }
        this.total_bookings++;
        if (e.service_type == "3") {
          start2 = moment.unix(e.start_timestamp_in_minute * 60).utc().format("LL");
          end2 = moment.unix(e.end_timestamp_in_minute * 60).utc().format("LL");
        }
        else {
          start2 = moment.unix(e.start_timestamp_in_minute * 60).utc().format("LLL");
          end2 = moment.unix(e.end_timestamp_in_minute * 60).utc().format("LLL");

        }

        let item2 = {
          "id": e.id,
          "url": "#",
          "balance": e.balance,
          "email": e.customer_email,
          "price": e.price,
          "service": e.service_name,
          "title": e.customer,
          "start": start2,
          "end": end2,
          "status": this.status_map[e.status],
          "color": e.color
        };

        this.event_list.push(item2);
      });
    }
  }

  /**
   * 
   * @param data 
   */
  _formatEmail() {
    this.email_history_list = [];
    if (this.emails != null) {
      this.emails.forEach(e => {
        let item = {
          "id": e.id,
          "message": e.message,
          "dated": moment.unix(e.dated).utc().format("LLL")
        };
        this.email_history_list.push(item);
      });
    }
  }

  /**
   * 
   * @param data 
   */
  _formatSMS() {
    this.sms_history_list = [];
    if (this.smses != null) {
      this.smses.forEach(e => {
        let item = {
          "id": e.id,
          "message": e.message,
          "dated": moment.unix(e.dated).utc().format("LLL")
        };
        this.sms_history_list.push(item);
      });
    }
  }




  /**
   * 
   */
  onClick_SMS() {
    this._reset();
    this.is_on_sms = true;
    this.title = "Send SMS";

  }

  /**
   * 
   */
  onClick_Email() {
    this._reset();
    this.is_on_email = true;
    if (this.is_customer == true) {
      this.title = "Send Email to Bookit Support";
    }
    else {
      this.title = "Send Email to " + this.user.first_name + " " + this.user.last_name;
    }
  }

  onClick_SendSMS(type) {
    this.msg = this.sms;
    this._sendMessage('sms');
  }

  onClick_SendEmail(type) {
    this.msg = this.email;
    this._sendMessage('inbox');
  }

  /**
   * 
   */
  _sendMessage(type) {
    if (this.msg.replace(/\s+/, "").length < 1) {
      this.alertService.error(this.translate.instant("alert-invalid-contents"));
    }
    else {
      let _p = {
        "msg": this.msg,
        "platform": type,
        "to_bookit": this.is_customer,
        "customer_id": this.user_id
      }
      this.is_loading = true;
      if (this.is_customer == false) {
        this.messageService.create(_p).subscribe(data => {
          let temp: any;
          temp = data;
          this.is_loading = false
          if (temp.success) {
            this.alertService.success(temp.msg);
            this._getUser();
            this._reset();
            this.is_on_booking = true;
          } else {
            this.alertService.error(temp.msg);
          }
        },
          error => {
            this.is_loading = false;
            this.alertService.error("Unknown Error");
          });
      }
      else {
        this.customerService.sendMsg(_p).subscribe(data => {
          let temp: any;
          temp = data;
          this.is_loading = false;
          if (temp.success) {
            this.alertService.success(temp.msg);
            this._getUser();
            this._reset();
            this.is_on_booking = true;
          } else {
            this.alertService.error(temp.msg);
          }
        },
          error => {
            this.is_loading = false;
            this.alertService.error("Unknown Error");

          });
      }
    }
  }

  /**
   * 
   */
  onClick_SendWelcomeEmail() {
    let _p = {
      "customer_id": this.user.id
    }
    this.messageService.sendWelcomeEmail(_p).subscribe(data => {
      this.alertService.success("Send Successfully");
    });

  }

  /**
   * 
   */
  onClick_Bookings() {
    this._reset();
    this.is_on_booking = true;
    this.title = "Booking History";
  }

  /**
   * 
   */
  onClick_EmailHistory() {
    this._reset();
    this.is_on_email_history = true;
    this.title = "Email History";
  }

  /**
   * 
   */
  onClick_SMSHistory() {
    this._reset();
    this.is_on_sms_history = true;
    this.title = "SMS History";
  }

  /**
  * 
  */
  onClick_Booking(e) {
    this.router.navigateByUrl("booking.detail?id=" + e.data.id);
  }

  onClick_TryDelete() {
    this.modal.getModal('cust_id').open();
  }

  onClick_ModalClose() {
    this.modal.getModal('cust_id').close();
  }

  /**
   * 
   */
  onClick_Delete() {
    this.is_loading = true;
    this.modal.getModal('cust_id').close();
    let _p = { "customer_id": this.user_id };
    this.businessService.deleteCustomer(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      if (temp.success) {
        this.alertService.success(temp.msg);
      }
      else {
        this.alertService.error(temp.msg);
      }
      this.alertService.info("Redirecting ...");
      setTimeout(() => {
        this.router.navigate(["/customer"]);
      }, 3000);
    });

  }

}
