<section class="space-sm">
  <div class="container align-self-start">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-7">
        <div class="card shadow-sm border rounded card-lg">
          <div class="card-header bg-secondary" translate="">
            heading-reset-your-bookit24-password
          </div>
          <div class="card-body">
            <div class="row no-gutters">
              <div class="text-left col-lg-12">
                <div class="form-group">
                  <label for="email" translate="">lbl-email</label>
                  <input [(ngModel)]="email" autocomplete="off" class="form-control form-control-lg" (keyup.enter)="onSubmit()"
                    placeholder="" />
                </div>
                <small class="text text-muted" translate="">txt-you-will-received-reset-password-instruction-on-email</small>
                
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button (click)="onSubmit()"  class="btn btn-lg btn-primary float-left" translate="">btn-submit</button>
            <div class=""><a [routerLink]="['/signin']" class="btn btn-lg btn-light float-right" translate>
              btn-back-to-signin <i class="fas fa-chevron-right"></i>  </a></div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
