<div class="container">
    <div class="row">
        <!--end of col-->
        <div class="col-12 col-md-3">
            <div class="card card-sm">
                <ul class="list-group">
                    <li class="list-group-item bg-light">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_AddUser()"
                            translate>btn-add</button>
                    </li>
                    <li class="list-group-item" id="itm{{itm.id}}" *ngFor="let
                        itm of all_users"
                        (click)="onClick_User($event,itm.id)"><a
                            href="javascript:void(0);"
                            id="itm{{itm.id}}">{{itm.username}}</a>
                        <div>
                            <small *ngIf="itm.status=='active'" class="badge
                                badge-light border text-muted">{{itm.status}}</small>
                            <small *ngIf="itm.status=='inactive'" class="badge
                                badge-secondary">{{itm.status}}</small>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-md-9">
            <div class="card card-sm">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <span *ngIf="is_loading == false">{{title}}</span>
                    <span *ngIf="is_loading == true" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif">
                        txt-loading
                    </span>
                </div>
                <div class="card-header bg-light" *ngIf="plan_stats?.no_of_users
                    != 999">
                    <div class="text text-danger"><span translate="">lbl-max-user-you-can-enable</span><strong>: {{plan_stats?.no_of_users}}</strong></div>
                </div>
                <div class="card-header bg-white">
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-email</label> <br>
                            <input class="form-control form-control-lg"
                                type="text" autocomplete="off"
                                [(ngModel)]="selected_user.username" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-password</label><br>
                            <input class="form-control form-control-lg"
                                autocomplete="off"
                                type="password" [(ngModel)]="password" />
                        </div>
                        <div class="col">
                            <label translate>lbl-confirm-password</label><br>
                            <input class="form-control form-control-lg"
                                autocomplete="off"
                                type="password" [(ngModel)]="confirm_password"
                                />
                        </div>
                    </div>
                    <div *ngIf="is_add_new_user == false" class="text-danger"><small
                            translate>
                            txt-leave-password-empty-to-keep-old-password
                        </small></div>
                </div>
                <div class="card-body">
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-first-name</label><br>
                            <input class="form-control form-control-lg"
                                type="text"
                                [(ngModel)]="selected_user.first_name" />
                        </div>
                        <div class="col">
                            <label translate>lbl-last-name</label><br>
                            <input class="form-control form-control-lg"
                                type="text"
                                [(ngModel)]="selected_user.last_name" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="selected_user.is_allowed_new_booking">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="selected_user.is_allowed_new_booking
                                    === boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (selected_user.is_allowed_new_booking=
                                    boolean_status[0].value) :
                                    (selected_user.is_allowed_new_booking=
                                    boolean_status[1].value)"
                                    id="is_allowed_new_booking">
                                <label class="custom-control-label"
                                    for="is_allowed_new_booking"
                                    translate>lbl-is-allowed-to-book</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="selected_user.is_allowed_config">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="selected_user.is_allowed_config
                                    === boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (selected_user.is_allowed_config=
                                    boolean_status[0].value) :
                                    (selected_user.is_allowed_config=
                                    boolean_status[1].value)"
                                    id="is_allowed_config">
                                <label class="custom-control-label"
                                    for="is_allowed_config"
                                    translate>lbl-can-manage-config</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="selected_user.is_allowed_payment">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="selected_user.is_allowed_payment
                                    === boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (selected_user.is_allowed_payment=
                                    boolean_status[0].value) :
                                    (selected_user.is_allowed_payment=
                                    boolean_status[1].value)"
                                    id="is_allowed_payment">
                                <label class="custom-control-label"
                                    for="is_allowed_payment"
                                    translate>lbl-can-manage-payment</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="selected_user.is_allowed_customer">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="selected_user.is_allowed_customer
                                    === boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (selected_user.is_allowed_customer=
                                    boolean_status[0].value) :
                                    (selected_user.is_allowed_customer=
                                    boolean_status[1].value)"
                                    id="is_allowed_customer">
                                <label class="custom-control-label"
                                    for="is_allowed_customer"
                                    translate>lbl-can-manage-customer</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col" *ngIf='false'>
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="selected_user.is_allowed_open_cal">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="selected_user.is_allowed_open_cal
                                    === boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (selected_user.is_allowed_open_cal=
                                    boolean_status[0].value) :
                                    (selected_user.is_allowed_open_cal=
                                    boolean_status[1].value)"
                                    id="is_allowed_open_cal">
                                <label class="custom-control-label"
                                    for="is_allowed_open_cal"
                                    translate>lbl-can-manage-open-calendar</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="selected_user.status">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="selected_user.status ===
                                    status[0].value"
                                    (change)="$event.target.checked?
                                    (selected_user.status= status[0].value) :
                                    (selected_user.status= status[1].value)"
                                    id="status">
                                <label class="custom-control-label" for="status"
                                    translate>lbl-enable</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button *ngIf="is_loading == true" class="btn btn-lg text
                        text-muted btn-light" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif"
                            translate> txt-loading
                    </button>
                    <button *ngIf="is_loading == false" class="btn btn-lg
                        btn-primary" (click)="onClick_Save()"
                        translate>btn-save</button>
                    <button *ngIf="is_user_selected == true && is_loading ==
                        false"
                        class="btn btn-outline-danger btn-lg float-right"
                        (click)="onClick_TryDelete()"
                        translate>btn-delete</button>
                </div>

            </div>
        </div>
        <!--end of row-->
    </div>

    <!--end of container-->
    <ngx-smart-modal identifier="user_id">
        <h6 class="h4 mb-2 text text-danger" translate>heading-are-you-sure</h6>
        <hr class="mt-2 mb-2">
        <div class="h6 alert" translate="">
            txt-warning-this-may-delete-or-disabled-some-data-related-to-this-item
        </div>
        <div class="fixed-bottom p-2 border-top">
            <button (click)="onClick_ModalClose();" class="btn btn-lg
                btn-secondary
                float-left" translate="">btn-cancel</button>
            <button (click)="onClick_Delete();" class="btn btn-lg btn-danger
                float-right" translate="">btn-submit</button>
            <!--   <button (click)="onClick_Delete();deleteModal.close()"  class="btn btn-md btn-danger float-right" >Confirm !</button>
  -->
        </div>
    </ngx-smart-modal>