<section class="space-xs">
    <div class="container">
          <h3 class="text">About Us</h3>
          <hr>
          Bookit24 AS was founded in 2014 and has offices in Bergen and in England.
<br><br>
We have developed a platform for online booking that is very flexible. This allows us to tailor the solution to your needs.
<br><bR>
As a customer or user of the system, you are the one who knows your needs best. We therefore want to continuously develop the solution in close dialogue with our users.
<br><bR>
We have also chosen to develop the solution as a web-based service. This means that you can use the system from any PC / mobile device as long as you have access to the internet. This makes it easy for all users as it does not require any installation.
<br><bR>
Our upgrades are available to all users at the same time, and you don't have to do anything on your part to access new features.
    </div>
</section>
