import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClientModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    /**
     * 
     */
    headers: HttpHeaders;

    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) {

        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    }

    /**
     * 
     */
    _getBody() {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     */
    save(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp: any;
        if (_p.business_id == null || _p.business_id == undefined || _p.business_id == "") {
            temp = this._getBody().append("business_id", user.data.business_id).append("auth_token", user.data.auth_token);
        }
        else {
            temp = this._getBody().append("auth_token", user.data.auth_token);
        }
        let body = this._append(temp, _p);

        return this.http.post(environment.server + "/provider/booking/do/save", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    create(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp: any;
        temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);

        return this.http.post(environment.server + "/provider/booking/do/create", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    refund(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);

        return this.http.post(environment.server + "/provider/booking/do/refund", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    markAsPaid(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);

        return this.http.post(environment.server + "/provider/booking/do/mark-as-paid", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }


    /**
     * 
     * @param username 
     * @param password 
     */
    update(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);

        return this.http.post(environment.server + "/provider/booking/do/update", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    updatePayment(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);

        return this.http.post(environment.server + "/provider/booking/do/update-payment", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    registerCardAndMakePayment(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);

        return this.http.post(environment.server + "/customer/booking/do/pay", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    confirmPi(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);

        return this.http.post(environment.server + "/customer/booking/do/confirm-pi", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     * @param username 
     * @param password 
     */
    checkPiStatus(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);

        return this.http.post(environment.server + "/customer/booking/do/check-pi-status", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     */
    getAllForCustomer(_p = {}) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/customer/booking/poll/all", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllByCustomerId(cust_id) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("customer_id", cust_id).append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/booking/poll/by-customer-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllWithPaymentDone(_p = {}) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/poll/with-payment-done", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllByCalendarId(cal_id, _p = null) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("calendar_id", cal_id).append("auth_token", user.data.auth_token);
        let body: any;
        if (_p != null) {
            body = this._append(temp, _p);
        }
        else {
            body = temp;
        }

        return this.http.post(environment.server + "/provider/booking/poll/by-calendar-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    get(id) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("id", id).append("auth_token", user.data.auth_token);
        return this.http.post(environment.server + "/provider/booking/poll/by-id", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    reschedule(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/booking/do/reschedule", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getStats(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token", user.data.auth_token);
        let body = this._append(temp, _p);
        return this.http.post(environment.server + "/provider/account/dashboard/get-stats", body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }



    /**
     * 
     * @param temp 
     * @param _p 
     */
    _append(temp, _p) {
        Object.keys(_p).forEach(k => {
            let t = temp.append(k, _p[k]);
            temp = t;

        });

        return temp;


    }

}
