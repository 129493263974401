<div class="h5">Users Details: <span class=" text text-muted">{{user?.first_name}} {{user?.last_name}}, {{user?.username}}</span></div>
<div class="row">
	<div class="col-2">
	  <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
		<a class="nav-link border mb-1" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</a>
		<a class="nav-link border mb-1" id="v-pills-booking-tab" data-toggle="pill" href="#v-pills-booking" role="tab" aria-controls="v-pills-booking" aria-selected="false">Bookings</a>
		<a class="nav-link border mb-1" id="v-pills-security-tab" data-toggle="pill" href="#v-pills-security" role="tab" aria-controls="v-pills-security" aria-selected="false">Security</a>
	  </div>
	</div>
	<div class="col-10">
	  <div class="tab-content" id="v-pills-tabContent">
		<div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-12">
							<img  src="{{user?.image}}" class="img col-4">
							<button class="btn btn-primary float-right" (click)="onSubmit_Profile()">Submit</button>
						</div>
					</div>
					<div class="row "><div class="col-12"><hr class="mb-3 mt-3"></div></div>
					<div class="row mb-2" *ngFor="let u of user_cols">
						<div class="col-3 pt-1 text-right">
							<label>{{u.name}}</label>
						</div>
						<div class="col-9">
							<input *ngIf="!u.disabled" class="form-control" [(ngModel)]="u.value"> 
							<input *ngIf="u.disabled" readonly="readonly" [(ngModel)]="u.value" class="form-control"> 
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tab-pane fade" id="v-pills-booking" role="tabpanel" aria-labelledby="v-pills-booking-tab">
			<ng2-smart-table (userRowSelect)="userRowSelect($event)" [settings]="settings" [source]="bookings" translate ></ng2-smart-table>

		</div>
		<div class="tab-pane fade" id="v-pills-security" role="tabpanel" aria-labelledby="v-pills-security-tab">
			<div class="card">
				<div class="card-body">
					<div class="text text-muted">Type in below to set a new password. No Emails will be send to the user.</div>
					<br>
					<label>New Password:</label><br>
					<input autocomplete="off"  class="form-control col-4" [(ngModel)]="new_password" style="font-size:22px;">
					<br>
					<button class="btn btn-primary" (click)="onSubmit_NewPassword()">Submit</button>
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<div class="text text-muted">Clicking below will immediately auto-generate a secure Random Password and will send it to the user via email.
						<br>You will not be able to see the password.</div>
					<br>
					<button class="btn btn-outline-danger" (click)="onClick_SendRandomPassword()">Click to send a new random password to user !</button>
				</div>
			</div>
		</div>
	  </div>
	</div>
  </div>