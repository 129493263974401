<nav aria-label="breadcrumb" role="navigation" class="bg-primary text-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);"
                            translate>lbl-new-booking</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page"
                        translate>txt-follow-5-steps-to-booking
                    </li>
                </ol>
            </div>
        </div>
    </div>
</nav>
<section class="space-xs">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9 col-md-8 col-sm-12">
                <div id="step_category" [hidden]="!step_category">
                    <app-booking-create-category-list
                        (select)="onSelect_Category($event)"
                        [is_loading]="is_loading">
                    </app-booking-create-category-list>
                </div>
                <!-- STEP 2 -->
                <div id="step_service" [hidden]="!step_service">
                    <app-booking-create-service-list
                        (selectService)="onSelect_Service($event)"
                        (backBtn)="onClick_BackBtnOnService($event)"
                        (nextBtn)="onClick_NextBtnOnService($event)"
                        [is_loading]="is_loading">
                    </app-booking-create-service-list>
                </div>
                <!-- STEP 3 SLOT -->
                <div id="step_datetime" [hidden]="!step_datetime">
                    <div class="card card-sm">
                        <div class="card-header bg-secondary">
                            <a href="javascript:void(0);"
                                (click)="onClick_BackBtnOnDatetime($event)"
                                class="float-left text text-danger" translate><i
                                    class="icon-chevron-left"></i>btn-back</a>
                            <a href="javascript:void(0);"
                                (click)="onClick_NextSelectCustomer()"
                                *ngIf="is_ready_to_select_customer == true"
                                class="float-right text text-primary" translate>
                                btn-select-customer <i
                                    class="icon-chevron-right"></i>
                            </a>

                            <div class="text text-center title-decorative"
                                *ngIf="is_loading == true" translate>
                                <img
                                    src="assets/img/ajax-loader-light-gray.gif">
                                txt-loading
                            </div>

                            <div class="title-decorative text text-center
                                text-muted"
                                *ngIf="is_loading == false">{{title_datetime}}</div>
                        </div>

                        <div [hidden]="is_appointment == false">
                            <app-booking-create-appointment
                                [current_date]="current_date"
                                (selectSlot)="onSelect_Slot($event)"></app-booking-create-appointment>
                        </div>

                        <div [hidden]="is_rental == false">
                            <app-booking-create-rental [price]="price"
                                (selectSlot)="onSelect_Slot($event)"
                                [current_date]="current_date"></app-booking-create-rental>
                        </div>

                        <div class="" [hidden]="is_rental_24hr == false">
                            <app-booking-create-accommodation
                                (selectSlot)="onSelect_Slot($event)"
                                [current_date]="current_date"></app-booking-create-accommodation>
                        </div>

                        <div class="card-footer bg-secondary"
                            *ngIf="is_show_today_date == true && (is_appointment
                            == true || is_rental == true)">

                            <button class="btn btn-success float-left"
                                (click)="onClick_Today()" translate>btn-today</button>
                            <div class="btn-group float-right">
                                <button class="btn btn-light text text-muted"
                                    *ngIf="is_loading == true">
                                    <img
                                        src="assets/img/ajax-loader-light-gray.gif">
                                </button>
                                <button class="btn btn-primary"
                                    *ngIf="is_show_prev_date"
                                    (click)="onClick_PrevDate()"><i
                                        class="icon-arrow-left"></i>
                                    {{prev_date_string}}</button>

                                <button class="btn btn-primary active"
                                    *ngIf="is_show_next_date"
                                    (click)="onClick_NextDate()">{{next_date_string}}
                                    <i class="icon-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- STEP 3 SLOT -->

                <!-- STEP 4  -->
                <div id="step_customer" [hidden]="!step_customer">
                    <app-booking-create-customer
                        (startOver)="onClick_StartAgain()"
                        (confirmBooking)="onConfirmBooking($event)"
                        (backBtn)="onClick_BackBtnOnCustomer($event)"
                        (selectCustomer)="onSelect_Customer($event)"></app-booking-create-customer>
                </div>
                <!-- STEP 4 ENDS -->

                <!-- STEP 4.1 -->
                <div id="step_customer" [hidden]="!step_customer_login">
                    <app-booking-create-login [business]="business"  (backBtn)="onClick_BackBtnOnLogin($event)"
                        (login)="onLoginOrRegister($event)"></app-booking-create-login>
                </div>
                <!-- STEP 4.1 ENDS -->

                <!-- STEP 5 -->
                <div id="step_pay" [hidden]="!step_payment">
                    <app-booking-create-pay
                        (backBtn)="onClick_BackBtnOnPay($event)"
                        (success)="onSuccess_Pay($event)"
                        (cancel)="onCancel_Pay($event)"
                        (error)="onError_Pay($event)"
                        [total_price]="total_price"
                        [business]="business"></app-booking-create-pay>
                </div>
                <!-- STEP 5 ENDS -->
                <!-- STEP 6 -->
                <div id="step_done" *ngIf="is_payment_error == true">
                    <div class="card card-lg">
                        <div class="card-header bg-light">
                            <h3 class="text text-danger" translate>heading-payment-error</h3>
                            <div translate>txt-payment-error-information</div>
                        </div>
                    </div>
                </div>

                <div id="step_prepare" *ngIf="step_prepare">
                    <div class="card card-lg">
                        <div class="card-header">
                            <div class="text-center mt-4 mb-4">
                                <img src="assets/img/ajax-loader.gif"
                                    class="rounded">
                            </div>
                            <div class="text-center mt-4 mb-4">
                                <span class="h5">Preparing booking ... please
                                    wait </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="step_done" *ngIf="step_done">
                    <div class="card card-lg">
                        <div class="card-header bg-light">
                            <h3 translate><i class="text text-success
                                    icon-check"></i> heading-booked-successfully</h3>
                            <div translate>txt-booked-successfully-information</div>
                            <hr>
                            <div class="card borderless card-header
                                bg-secondary" *ngFor="let b of
                                confirmed_bookings">
                                <div class="row">
                                    <div class="col">
                                        <span class="">{{b.service_name}}</span>
                                        <span class="float-right text-muted">
                                            <h4>{{b.price}} NOK</h4>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <span class="text text-primary">{{b.starts}}</span>
                                        <span class="btn text text-muted btn-sm"
                                            *ngIf="is_appointment == false"> TO
                                        </span>
                                        <span class="text text-primary">{{b.ends}}</span>
                                    </div>
                                </div>
                            </div>
                            <a *ngIf="is_customer == true && is_guest == true"
                                class="btn btn-primary text-white float-right"
                                [routerLink]="['/dashboard']"
                                translate>btn-view-bookings</a>
                            <a *ngIf="is_customer == false && is_guest == false"
                                class="btn btn-primary text-white float-right"
                                [routerLink]="['/customer.detail']"
                                [queryParams]="{id:customer.id}" translate>btn-view-bookings</a>
                        </div>
                    </div>
                </div>
                <!-- STEP 6 ENDS -->
            </div>
            <div class="col col-lg-3 col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-body pt-2 pl-2 pr-2">
                        <div class="text">
                            <div class="title-decorative mb-0" translate>lbl-from</div>
                            <div class="text">{{start_datetime}}</div>
                            <hr class="row row mt-2 mb-2">
                            <div *ngIf="is_rental == true || is_rental_24hr ==
                                true">
                                <div class="title-decorative mb-0" translate>lbl-to</div>
                                <div class="text">{{end_datetime}}</div>
                                <hr class="row row mt-2 mb-2">
                            </div>
                            <div *ngIf="false">
                                <div class="title-decorative mb-0" translate>lbl-calendar</div>
                                <div class="text">{{calendar_name}}</div>
                                <hr class="row row mt-2 mb-2">
                            </div>
                            <div *ngIf="is_rental == true || is_rental_24hr ==
                                true">
                                <div class="title-decorative mb-0" translate>lbl-duration</div>
                                <div *ngIf="is_rental == true" class="text">{{total_duration/60}}
                                    <span translate>lbl-hrs</span>
                                </div>
                                <div *ngIf="is_rental_24hr == true" class="text"
                                    translate>{{total_duration/(60*24)}} <span
                                        translate>lbl-days</span>
                                </div>
                                <hr class="row row mt-2 mb-2">
                            </div>
                            <div class="title-decorative mb-0" translate>lbl-service</div>
                            <div class="text">{{services_name}}</div>
                            <hr class="row row mt-2 mb-2">
                            <div *ngIf="is_customer == false">
                                <div class="title-decorative mb-0" translate>lbl-customer</div>
                                <div class="text">{{customer.username}}</div>
                                <hr class="row row mt-2 mb-2">
                            </div>
                            <div class="title-decorative mb-0" translate>lbl-total</div>
                            <h4 class="text text-primary">{{total_price}} NOK</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end of container-->
</section>