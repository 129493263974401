<nav aria-label="breadcrumb" role="navigation" class="bg-primary text-white">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><a href="javascript:void(0);"
							translate>lbl-new-booking</a>
					</li>
					<li class="breadcrumb-item active" aria-current="page"
						translate>txt-follow-5-steps-to-booking
					</li>
				</ol>
			</div>
		</div>
	</div>
</nav>

<section class="space-xs">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-9 col-md-8 col-sm-12">
				<div class="card card-sm shadow-sm border">
					<app-new-booking-service *ngIf="step_service" (runtime)="_runtime($event)"
						(emit_config)="onEmit_ServiceConfig($event)" [config]="config"></app-new-booking-service>
					<app-new-booking-datetime-appointment *ngIf="step_datetime &&
						config.type == '1'"
						(emit_config)="onEmit_DatetimeConfig($event)" [config]="config"></app-new-booking-datetime-appointment>
					<app-new-booking-datetime-rental *ngIf="step_datetime &&
						config.type == '2'"
						(emit_config)="onEmit_DatetimeConfig($event)" [config]="config"></app-new-booking-datetime-rental>
					<app-new-booking-datetime-accommodation *ngIf="step_datetime &&
						config.type == '3'"
						(emit_config)="onEmit_DatetimeConfig($event)" [config]="config"></app-new-booking-datetime-accommodation>
					<app-new-booking-customer *ngIf="step_customer"
						(emit_config)="onEmit_CustomerConfig($event)" [config]="config"></app-new-booking-customer>
					<app-new-booking-customer-login *ngIf="step_customer_login"
						(emit_config)="onEmit_CustomerConfig($event)" [config]="config"></app-new-booking-customer-login>
					<app-new-booking-pay *ngIf="step_pay"
						(emit_config)="onEmit_PayConfig($event)" [config]="config"></app-new-booking-pay>
					<app-new-booking-confirm *ngIf="step_confirm"
						(emit_config)="onEmit_ConfirmConfig($event)" [config]="config"></app-new-booking-confirm>
					<app-new-booking-finish *ngIf="step_finish"
						(emit_config)="onEmit_FinishConfig($event)" [config]="config"></app-new-booking-finish>
					<app-new-booking-error *ngIf="step_error" (emit_config)="onEmit_StartOver($event)" 
						 [config]="config"></app-new-booking-error>
				</div>
			</div>
			<div class="col-lg-3 col-md-4 col-sm-12 ">
				<div class="list-group shadow-sm">
					<span class="list-group-item pl-2 pr-2">
						<span class="title-decorative" translate="">lbl-service</span>
						{{config.summary.service}}
					</span>
					<span class="list-group-item pl-2 pr-2" *ngIf="config.type=='1' || config.type=='2'">
						<span class="title-decorative mb-0" translate="">lbl-starts</span>
						<span class="h6 " *ngIf="config.summary.start_ts > 0">{{date.formatForNorway(config.summary.start_ts)}}</span>
					</span>
					<span class="list-group-item pl-2 pr-2" *ngIf="config.type=='2'">
						<span class="title-decorative mb-0" translate="">lbl-ends</span>
						<span class="h6 " *ngIf="config.summary.end_ts > 0">{{date.formatForNorway(config.summary.end_ts)}}</span>
					</span>
					<span class="list-group-item pl-2 pr-2" *ngIf="config.type=='3'">
						<span class="title-decorative mb-0" translate="">lbl-starts</span>
						<span class="h6 " *ngIf="config.summary.start_ts > 0">{{date.formatForNorway(config.summary.start_ts,'date')}} {{config.service[0].checkin}}</span>
					</span>
					<span class="list-group-item pl-2 pr-2" *ngIf="config.type=='3'">
						<span class="title-decorative mb-0" translate="">lbl-ends</span>
						<span class="h6 " *ngIf="config.summary.end_ts > 0">{{date.formatForNorway(config.summary.end_ts,'date')}} {{config.service[0].checkout}}</span>
					</span>
					<span class="list-group-item pl-2 pr-2">
						<span class="title-decorative mb-0" translate="">lbl-duration</span>
						<div *ngIf="config.type=='1'">{{config.summary.duration}}
							<span  translate="">lbl-mins</span>
						</div>
						<div *ngIf="config.type=='2' && config.summary.end_ts > 0 && config.summary.start_ts > 0">
							{{(config.summary.end_ts - config.summary.start_ts) / (60*60) }}
							<span  translate="">lbl-hrs</span>
						</div>
						<div *ngIf="config.type=='3'">{{config.summary.duration}}
							<span  translate="">lbl-days</span>
						</div>
					</span>
					<span class="list-group-item pl-2 pr-2">
						<span class="title-decorative" translate="">lbl-customer</span>
						{{config.summary.customer}}
					</span>
					<span class="list-group-item pl-2 pr-2">
						<span class="title-decorative mb-0" translate="">lbl-price</span>
						<span class="h2 mb-1 text-primary">{{config.summary.price}} NOK</span>
						<div *ngIf="config.type == '3' && config.service[0].cleaning_fee > 0" ><span class=" title-muted">{{config.service[0].cleaning_fee}} NOK <span translate="">lbl-cleaning-fee</span></span></div>
					</span>
				</div>
			</div>
		</div>
	</div>
	<!--end of container-->
</section>