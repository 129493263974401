<nav aria-label="breadcrumb" role="navigation" class="bg-primary text-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <ol class="breadcrumb" *ngIf="is_loading == false">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{booking.service_name}}
                            for
                            {{booking.customer.username}}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</nav>
<section class="space-xs">
    <div class="container" *ngIf="is_loading == true">
        <div class="row justify-content-center">
            <h3 class="title-decorative" translate><img
                    src="assets/img/ajax-loader-light-gray.gif"> txt-loading</h3>
        </div>
    </div>

    <div class="container" *ngIf="booking.is_this_a_break == '1'">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10">
                <div class="h5 text-muted text-center card-header mt-4 mb-4 pt-4
                    pb-4
                    bg-secondary" translate="">
                    txt-this-is-a-break-from
                    <span class="text text-primary">{{booking.starts}}</span> to
                    <span class="text text-primary">{{booking.ends}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="container"*ngIf="is_loading == false && booking.is_this_a_break
        == '0'">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10">
                <div class="card card-lg w-100 shadow-sm border">
                    <div class="row no-gutters justify-content-between
                        card-header bg-secondary shadow">
                        <div class="col-auto">
                            <strong>{{booking.business.name}}</strong>
                            <address>
                                {{booking.business.street}},
                                {{booking.business.city}},
                                <br>{{booking.business.state}}
                                {{booking.business.country}}
                                {{booking.business.zipcode}}
                                <a target="_blank"
                                    href="https://maps.google.com/maps?q={{map_q}}">
                                    <br><span translate="">btn-view-on-google-map</span></a>
                            </address>
                        </div>
                        <div class="col-auto text text-center">
                            <br>
                            <address><a href="mailto" class="">{{booking.business.email}}</a>
                                <br><span class="h4">{{booking.business.telephone}}
                                </span></address>
                        </div>
                        <div class="col-auto">
                            <img alt="" *ngIf="booking.business.logo"
                                style="background-color:#eee"
                                [src]="booking.business.logo" width="160"
                                class="border  rounded img-fluid">
                        </div>
                    </div>
                    <div class="row no-gutters text-center card-header
                        bg-light">
                        <span class="text text-center h5">
                            {{date.formatForNorway(booking.start_timestamp_in_minute
                            * 60)}}&nbsp;<span class="badge
                                badge-light"> TO </span>&nbsp;{{date.formatForNorway(booking.end_timestamp_in_minute
                            * 60)}}
                        </span>
                    </div>
                    <div class="row no-gutters card-header bg-light"
                        *ngIf="auth.getUser().user_type !='customer'">
                        <div class="text text-muted">
                            {{booking.notes}} <a *ngIf="auth.getUser().user_type
                                !='customer'" href="javascript:void(0);"
                                class="btn btn-sm btn-outline-secondary"
                                (click)="onClick_TryEditNotes()" translate="">btn-edit-comments</a>
                        </div>
                    </div>

                    <!--end of row-->
                    <div class="card-body">
                        <img alt="" *ngIf="booking.business.image"
                            style="background-color:#eee"
                            [src]="booking.calendar.image"
                            width="120" class="float-right rounded shadow
                            img-fluid">
                        <h4>
                            <span class="badge badge-success"
                                *ngIf="booking.payment_status=='PAID'">{{booking.payment_status|translate}}</span>&nbsp;<span
                                class="badge badge-danger"
                                *ngIf="booking.payment_status=='UNPAID'"
                                translate="">{{booking.payment_status|translate}}</span>&nbsp;<span
                                class="badge badge-secondary" translate="">{{booking.status_string
                                | translate}}</span>&nbsp;<span
                                class="badge badge-danger"
                                *ngIf="booking.refund_amount!='' &&
                                booking.refund_amount!=null &&
                                booking.refund_time!='' &&
                                booking.refund_time!=null" translate="">txt-refunded</span>
                        </h4>
                        <div class="row no-gutters justify-content-between">
                            <div class="col-12 col-sm-auto">
                                <span class="title-decorative mb-2" translate>heading-customer-details</span>
                                <span>
                                    {{booking.customer.first_name}}
                                    {{booking.customer.last_name}}
                                    <br>
                                    <a href="javascript:void(0);"
                                        (click)="onClick_ViewCustomer()">{{booking.customer.username}}
                                        <i class="icon-popup"></i>
                                    </a>
                                    <br>{{booking.customer.mobile}}
                                </span>
                            </div>
                            <!--end of col-->
                            <div class="col-12 col-md-5">
                                <span class="title-decorative mb-2" translate>heading-booking-details</span>
                                <ul class="list-unstyled">
                                    <li class="row"
                                        *ngIf="booking.service.service_type!='1'
                                        && booking.calendar.is_enable_exivo_lock
                                        == '1'">
                                        <span class="col-4 font-weight-bold"
                                            translate>lbl-exivo-code</span>
                                        <span *ngIf="booking.lock_code !=
                                            undefined && booking.lock_code !=
                                            false" class="col text">{{booking.lock_code.code}}</span>
                                    </li>
                                    <li class="row">
                                        <span class="col-4 font-weight-bold"
                                            translate>lbl-balance</span>
                                        <span class="col text text-danger">{{booking.balance}}
                                            NOK</span>
                                    </li>
                                    <li class="row">
                                        <span class="col-4 font-weight-bold"
                                            translate>lbl-paid</span>
                                        <span class="col">{{booking.payment_made}}
                                            NOK</span>
                                    </li>
                                    <li class="row">
                                        <span class="col-4 font-weight-bold"
                                            translate>lbl-mode</span>
                                        <span class="col">{{booking.payment_mode}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <table class="table text-right border-bottom">
                            <thead class="bg-secondary">
                                <tr>
                                    <th scope="col" translate>lbl-service</th>
                                    <th scope="col" class="text-right"
                                        translate>lbl-quantity</th>
                                    <th scope="col" class="text-right"
                                        translate>lbl-rate</th>
                                    <th scope="col" class="text-right"
                                        translate>lbl-amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{{booking.service_name}}</th>
                                    <td>1</td>
                                    <td>{{booking.price}}</td>
                                    <td>{{booking.price}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row card card-borderless col-lg-12
                            col-md-12">
                            <div class="text text-right">
                                <span translate>lbl-balance-due</span>
                                <span class="h3">{{booking.balance}} NOK</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="booking.payment_mode=='online' &&
                        (booking.payment_vendor=='Paypal' ||
                        booking.payment_vendor=='Stripe') &&
                        (booking.refund_amount=='' ||
                        booking.refund_amount==null) &&
                        (booking.refund_time=='' || booking.refund_time==null)
                        && booking.payment_made> 0 && auth.getUser().user_type
                        !='customer'" class="row no-gutters card-footer
                        bg-light">
                        <span>
                            <a href="javascript:void(0);" class="text
                                text-danger"
                                (click)="onClick_TryRefund()" translate="">btn-click-here-to-refund</a>
                        </span>
                    </div>
                    <!--end of card body-->
                    <div class="card-footer bg-light bg-light"
                        *ngIf="booking.refund_amount!='' &&
                        booking.refund_amount!=null &&
                        booking.refund_time!='' && booking.refund_time!=null">
                        <div class="" translate="">
                            <span translate="">lbl-booking-has-been-refunded</span>
                            {{booking.refund_amount}}
                            NOK
                        </div>
                    </div>

                    <div
                        *ngIf="booking.business.main_vendor_for_payment=='Stripe'
                        && booking.business.enable_stripe=='1' &&
                        auth.getUser().user_type == 'customer' &&
                        booking.balance != '' && booking.balance != null &&
                        booking.balance != '0'"
                        class="card-footer pt-2 pb-0 bg-secondary pl-2 pr-2 pb-0
                        mb-0 border-top"
                        style="padding-top:18px">
                        <div class="row pl-3 pr-3">
                            <div class="col-md-6 col-lg-6">
                                <div class="custom-control
                                    custom-radio">
                                    <input id="new" type="radio"
                                        class="custom-control-input"
                                        [value]="1"
                                        [(ngModel)]="card_options">
                                    <label
                                        class="custom-control-label"
                                        for="new" translate>lbl-pay-with-new-card</label>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="custom-control
                                    custom-radio">
                                    <input id="existing"
                                        type="radio"
                                        class="custom-control-input"
                                        [value]="2"
                                        [(ngModel)]="card_options">
                                    <label
                                        class="custom-control-label"
                                        for="existing"
                                        translate>lbl-pay-with-a-saved-card</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        *ngIf="booking.status != '1' && booking.status!='2' && booking.status!='6' && booking.business.main_vendor_for_payment=='Stripe'
                        && booking.business.enable_stripe=='1' &&
                        auth.getUser().user_type == 'customer' &&
                        booking.balance != '' && booking.balance != null &&
                        booking.balance != '0'"
                        class="card-body bg-secondary pb-0 pt-1">
                        <div class="row mb-0 mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="">
                                    <div class="text-muted" translate="">txt-all-payments-are-handled-by-stripe</div>
                                    <div class="card bg-secondary border
                                        shadow">
                                        <div class="card-body pt-3 pb-2"
                                            *ngIf="card_options == '1'">
                                            <div class="row
                                                form-group">
                                                <div class="col">
                                                    <label
                                                        translate>lbl-card-number</label>
                                                    <br>
                                                    <input autocomplete="off"
                                                        class="form-control
                                                        form-control-lg"
                                                        type="number"
                                                        [(ngModel)]="stripe.number"
                                                        />
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col">
                                                    <label translate>lbl-expiry-month</label><br>
                                                    <input autocomplete="off"
                                                        class="form-control
                                                        form-control-lg"
                                                        type="text"
                                                        mask="00"
                                                        [(ngModel)]="stripe.exp_month"
                                                        />
                                                </div>
                                                <div class="col">
                                                    <label
                                                        translate>lbl-expiry-year</label><br>
                                                    <input autocomplete="off"
                                                        class="form-control
                                                        form-control-lg"
                                                        type="text"
                                                        mask="00"
                                                        [(ngModel)]="stripe.exp_year"
                                                        />
                                                </div>
                                                <div class="col">
                                                    <label
                                                        translate>lbl-cvc</label><br>
                                                    <input autocomplete="off"
                                                        class="form-control
                                                        form-control-lg"
                                                        type="text"
                                                        mask="000"
                                                        [(ngModel)]="stripe.cvc"
                                                        />
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col">
                                                    <div
                                                        class="custom-control
                                                        custom-checkbox
                                                        custom-checkbox-switch">
                                                        <input autocomplete="off"
                                                            type="hidden"
                                                            [(ngModel)]="save_new_card">
                                                        <input autocomplete="off"
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            [checked]="save_new_card
                                                            ===
                                                            boolean_status[0].value"
                                                            (change)="$event.target.checked?
                                                            (save_new_card=boolean_status[0].value):(save_new_card=boolean_status[1].value)"
                                                            id="save_new_card">
                                                        <label
                                                            class="custom-control-label
                                                            text"
                                                            for="save_new_card"
                                                            translate>lbl-save-this-card</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body pt-3 pb-2"
                                            *ngIf="card_options == '2'">
                                            <div class="row col text-danger"
                                                *ngIf="existing_cards.stripe.length
                                            <1" translate="">
                                                lbl-card-not-found
                                            </div>
                                            <div class="row form-group"
                                                *ngIf="existing_cards.stripe.length>
                                                0">
                                                <div class="col">
                                                    <label
                                                        translate>lbl-select-a-card-for-payment</label>
                                                    <br>
                                                    <select
                                                        [(ngModel)]="selected_existing_card"
                                                        class="form-control">
                                                        <option
                                                            [value]="cc.gateway_token"
                                                            *ngFor="let cc of
                                                            existing_cards.stripe">{{cc.card_number}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="card-footer shadow-sm bg-light">
                        <div class="row">
                            <!-- <div class="mb-1 col-lg-3 col-sm-12 text text-muted"
                                *ngIf="auth.getUser().user_type !='customer'"
                                translate>
                                txt-this-booking-was-done-by-customer
                            </div>
                            -->
                            <div class="mb-1 col-lg-2 col-sm-12"
                                *ngIf="auth.getUser().user_type == 'customer' &&
                                booking.status=='4' && allow_cancel">
                                <button class="btn btn-outline-danger"
                                    (click)="onClick_TryCancelBooking()"
                                    translate>btn-cancel-booking</button>
                            </div>
                            <div class="mb-1 col-lg-3 col-sm-12"
                                *ngIf="allow_status_change">
                                <select class="form-control"
                                    [(ngModel)]="booking.status">
                                    <option *ngFor="let s of status_map"
                                        [value]="s.id">{{s.value | translate}}</option>
                                </select>
                            </div>
                            <div class="mb-1 col-lg-2 col-sm-12"
                                *ngIf="allow_status_change">
                                <button class="btn btn-primary"
                                    (click)="onClick_Update()" translate>btn-update</button>
                            </div>
                            <div class="mb-1 col-lg-2 col-sm-12"
                                *ngIf="auth.getUser().user_type != 'customer' &&
                                booking.balance != '' && booking.balance != null
                                && booking.balance != '0'">
                                <button class="btn btn-success"
                                    (click)="onClick_MarkAsPaid()" translate>btn-mark-paid</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer shadow-sm bg-light" *ngIf="booking.status != '1' && booking.status!='2' && booking.status!='6' && auth.getUser().user_type == 'customer'">
                        <div class="row text-right">
                            <div class="col-sm-12"
                                *ngIf="booking.status != '1' && booking.status!='2' && booking.status!='6' && auth.getUser().user_type == 'customer' &&
                                booking.balance != '' && booking.balance != null
                                && booking.balance != '0'">
                                <div
                                    *ngIf="booking.business.main_vendor_for_payment
                                    == 'Stripe' &&
                                    booking.business.enable_stripe == '1'">
                                    <button class="btn btn-dark btn-lg"
                                        (click)="onClick_PayNowWithStripe()"
                                        translate="">btn-pay-now</button>
                                </div>
                                <div
                                    *ngIf="booking.business.main_vendor_for_payment
                                    == 'Paypal' &&
                                    booking.business.enable_paypal == '1'">
                                    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end of col-->
        </div>
        <!--end of row-->
    </div>
</section>


<ngx-smart-modal identifier="book_id">
    <h6 class="h4 mb-2 text text-danger"
        translate>heading-information</h6>
    <hr class="row row mt-2 mb-2">
    <small class="bg-secondary rounded mb-2 pb-2 pt-2 pl-2 pr-2" translate=""
        *ngIf="is_loading == false">
        txt-refund-information
    </small>
    <div class="text-center mt-4 mb-4" *ngIf="is_refunding == true">
        <img src="assets/img/ajax-loader.gif" class="rounded">
    </div>
    <div class="text-center mt-4 mb-4" *ngIf="is_refunding == true">
        <h5 translate="">txt-refunding-please-wait</h5>
    </div>
    <div class="row form-group" *ngIf="is_loading == false">
        <div class="col-6">
            <label translate>lbl-refund-amount</label> <br>
            <input autocomplete="off" class="form-control form-control-lg" type="text"
                (keyup)="onChange_RefundAmount($event)"
                [(ngModel)]="refund_amount" />
        </div>
        <div class="col-6">
            <label class="text text-danger">&nbsp;</label> <br>
            <h2 class="text text-danger">{{refund_percentage}}%</h2>
        </div>
    </div>

    <div class="fixed-bottom p-2 border-top">
        <button (click)="onClick_ModalClose();" *ngIf="is_loading == false"
            class="btn
            btn-lg btn-secondary
            float-left" translate="">btn-cancel</button>
        <button (click)="onClick_Refund();" class="btn btn-lg btn-danger
            float-right" *ngIf="is_loading == false"
            translate="">btn-submit</button>
        <!--   <button (click)="onClick_Delete();deleteModal.close()"  class="btn btn-md btn-danger float-right" >Confirm !</button>
    -->
    </div>
</ngx-smart-modal>


<ngx-smart-modal identifier="notes_modal">
    <h6 class="h4 mb-2 text text-danger"
        translate>heading-information</h6>
    <hr class="row row mt-2 mb-2">
    <div class="row form-group mt-2 mb-2">
        <div class="col-12">
            <textarea class="form-control form-control-lg" rows="4"
                placeholder="Comments for bookings"
                [(ngModel)]="booking.notes"></textarea>
        </div>
    </div>
    <div class="fixed-bottom p-2 border-top">
        <button (click)="onClick_NotesModalClose();" class="btn
            btn-lg btn-secondary
            float-left" translate="">btn-cancel</button>
        <button (click)="onClick_EditNotes();" class="btn btn-lg btn-danger
            float-right"
            translate="">btn-submit</button>
    </div>
</ngx-smart-modal>

<ngx-smart-modal identifier="cancel_booking">
    <h6 class="h4 mb-2 text text-danger"
        translate>heading-information</h6>
    <hr class="row row mt-2 mb-2">
    <div class="row form-group mt-2 mb-2">
        <div class="col-12 pt-2 pb-2" translate="">
            txt-are-you-sure-you-want-to-cancel-this-booking
        </div>
    </div>
    <div class="fixed-bottom p-2 border-top">
        <button (click)="onClick_CancelBookingModalClose();" class="btn
            btn-lg btn-secondary
            float-left" translate="">btn-cancel</button>
        <button (click)="onClick_CancelBooking();" class="btn btn-lg btn-danger
            float-right"
            translate="">btn-confirm</button>
    </div>
</ngx-smart-modal>