import { Injectable } from "@angular/core";
@Injectable()
export class UrlHelper {

	domain = "";
	subdomain = "";
	landing_subdomains = ['portal', 'signup'];
	constructor() { }

	/**
	 * 
	 * @param subdomain 
	 */
	isLandingSubdomain(subdomain) {
		return this.landing_subdomains.includes(subdomain);
	}

	/**
	 * 
	 */
	getSubdomain() {

		const domain = window.location.hostname;
		let _f = domain.split('.')[0];
		if (domain.indexOf('.') < 0 || _f === 'bookitlocal' || _f === 'bookit24' || _f === 'lvh' || _f === 'www') {
			this.subdomain = '';
		} else {
			this.subdomain = domain.split('.')[0];
		}
		return this.subdomain;
	}

	/**
	 * 
	 */
	getDomainName() {
		return window.location.href.split("/#/")[0];
	}

	/**
	 * 
	 */
	getJustDomainName() {
		var temp = window.location.href.split("/#/")[0];
		return temp.split('.')[1] + "."+ temp.split('.')[2];

	}
}
