import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertService } from './../../../../_service/alert.service';
import { AuthHelper } from '../../../../_helper/auth';
import { UrlHelper } from '../../../../_helper/url';
import { ServiceService } from '../../../../_service/service.service';
import { CalendarModule } from 'primeng/calendar';
import * as moment from 'moment-timezone';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-booking-datetime-rental',
  templateUrl: './rental.component.html',
  styleUrls: ['./rental.component.css']
})
export class RentalComponent implements OnInit {

  minDate = new Date();

  @Input() config: any;
  @Output() emit_config = new EventEmitter<any>();
  en: any;
  no: any;
  modal_data: any;
  selected_date = 0;
  is_start_selected = false;
  is_end_selected = false;
  cals = [];
  title = "Datetime";

  constructor(
    public alertService: AlertService,
    public authHelper: AuthHelper,
    public translate: TranslateService,
    private modalService: NgbModal,
    public serviceService: ServiceService,
    public urlHelper: UrlHelper

  ) { }

  ngOnInit() {
    this.onClick_ResetDates();
    this.selected_date = moment().utc().unix();
    this.is_start_selected = false;
    this.is_end_selected = false;
    this.en = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear'
    };
    this.no = {
      firstDayOfWeek: 1,
      dayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
      dayNamesShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
      dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
      monthNames: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
      today: 'I dag',
      clear: 'Klar'
    };

    this._getSlots();
  }

  _getSlots() {
    this.config.is_loading = true;
    let _p: any;
    if (!this.is_start_selected) {
      _p = { "date": this.selected_date, config: JSON.stringify(this.config), "subdomain": this.urlHelper.getSubdomain() };
    }
    else {
      _p = { "find_rental_end_slot": true, "start_ts":this.config.summary.start_ts,"calendar_id": this.config.calendar_id, "date": this.selected_date, config: JSON.stringify(this.config), "subdomain": this.urlHelper.getSubdomain() };
    }
    
    this.serviceService.getAvailableSlots2(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.config.is_loading = false;
      this.cals = [];
      if (temp.success) {
        this.cals = temp.data;
      }
      else {
        this.alertService.error(temp.msg);
      }
    });
  }

  onClick_DatetimeChange(e) {
    this.selected_date = moment(e).utcOffset(0, true).unix();
    this._getSlots();
  }

  onClick_Slot(s, cal) {
    let st = false;
    let en = false;
    this.config.calendar_id = cal.id;
    if (!this.is_start_selected) {
      this.config.summary.starts = s.string;
      this.config.summary.start_ts = s.ts;
      st = true;
      this.alertService.success(this.translate.instant("alert-please-select-end-time"));
      
    }
    if (this.is_start_selected) {
      if (this._isEndSlotValid(s)) {
        this.config.summary.ends = s.string;
        this.config.summary.end_ts = s.ts;
        en = true;
      }
    }
    if (st) {
      this.is_start_selected = true;
      this._loadEndSlots();
    }
    if (en) {
      this.is_end_selected = true;
    }

    if (this.is_start_selected && this.is_end_selected) {
      this._remapServiceDuration();
      this._fixPrice();
      this.config.step = "customer";
      this.emit_config.emit(this.config);
    }
  }

  _fixPrice(){
    
    // get service price
    // it will always be one in loop
    let price = 0;
    this.config.service.forEach(k => {
      price = k.price;
    });

    // get price
    let p = 0;
    p = (this.config.summary.end_ts - this.config.summary.start_ts) * price/(60*60);
    this.config.payment.balance = p;
    this.config.summary.price = p;

  }

  _remapServiceDuration(){
    // must empty it
    let s = this.config.summary.start_ts;
    let e = this.config.summary.end_ts;
    this.config.service.forEach((k,i)=>{
      k.start_ts = s;
      k.end_ts = e;
    });
    //console.log(this.config);
  }  

  _loadEndSlots() {
    this._getSlots();
  }

  onClick_ResetDates() {
    this.is_start_selected = false;
    this.is_end_selected = false;
    this.config.summary.start_ts = 0;
    this.config.summary.start_ts = 0;
    this.config.summary.starts = "";
    this.config.summary.ends = "";
    this._getSlots();
  }

  _isEndSlotValid(s) {
    if (s.ts < this.config.summary.start_ts) {
      this.alertService.error(this.translate.instant("alert-invalid-end-slot"));
      return false;
    }
    return true;
  }


  onClick_Back() {
    this.config.step = "service";
    this.emit_config.emit(this.config);
  }

  open(modal, item) {
    this.modal_data = item;
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

}
