/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./error.component";
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
function View_ErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "col-sm-12 col-md-12 col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "text text-danger"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["heading-payment-error"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["txt-payment-error-information"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "col-sm-12 col-md-12 col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "text text-danger"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["heading-unknown-error"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["txt-unknown-error-while-trying-to-book"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row mb-4 mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "card-footer bg-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "btn btn-lg btn-outline-secondary"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick_StartAgain() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "icon icon-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" btn-start-again "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.config.payment.required && !_co.config.payment.success); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.config.payment.required; _ck(_v, 5, 0, currVal_1); var currVal_2 = ""; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-booking-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 114688, null, 0, i4.ErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorComponentNgFactory = i1.ɵccf("app-new-booking-error", i4.ErrorComponent, View_ErrorComponent_Host_0, { config: "config" }, { emit_config: "emit_config" }, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
