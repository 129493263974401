import { Component, OnInit } from '@angular/core';
import { BusinessService } from '../../_service/business.service';
import { AuthService } from '../../_service/auth.service';
import { AuthHelper } from '../../_helper/auth';
import { AlertService } from '../../_service/alert.service';
import { FileHelper } from '../../_helper/file';
import * as moment from 'moment-timezone';
import { StringHelper } from '../../_helper/string';
import { CustomerService } from '../../_service/customer.service';
import { UserService } from '../../_service/user.service';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SupportService } from 'src/app/_service/support.service';

@Component({
  selector: 'app-config-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  is_loading = false;
  is_on_profile = true;
  is_on_plan = false;
  is_on_security = false;
  username = "";
  first_name = "";
  last_name = "";
  mobile = "";
  logo = "";
  image = "";
  name = "";
  is_logo_exists = false;
  is_image_exists = false;
  website = "";
  street = "";
  city = "";
  state = "";
  telephone = "";
  zipcode = "";
  country = "";
  description = "";
  utc_offset = "";
  timezone = "";
  err = false;
  zones = [];
  is_customer = false;
  is_trying_deleting = false;
  is_cannot_downgrade = false;
  is_on_cancel_subscription = false;
  current_plan: any;
  payment_type = "1";
  plan_stack = [];
  trial_ends = 0;
  title = "";

  password = "";
  status = [{ "value": "active" }, { "value": "inactive" }];
  boolean_status = [{ "value": "1" }, { "value": "0" }];
  current_password = "";
  new_password = "";
  confirm_password = "";
  is_change_password = true;




  /**
   * 
   * @param businessService 
   */
  constructor(
    public businessService: BusinessService,
    public authService: AuthService,
    public auth: AuthHelper,
    public modal: NgxSmartModalService,
    private supportService: SupportService,
    public string: StringHelper,
    public router: Router,
    public customerService: CustomerService,
    public file: FileHelper,
    public alertService: AlertService,
    public userService: UserService
  ) {
    if (auth.getUser().user_type == "customer") {
      this.is_customer = true;
    }
    this._makeTimezoneArray();
    this._getProfile();
  }

  /**
   * 
   */
  ngOnInit() {
    this._getBusiness();
    let _t = this;
    this.supportService.plans({}).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success) {
        temp.data.forEach(element => {
          _t.plan_stack.push({ "id": element.id, "payload": JSON.parse(element.payload) });
        });
      }
    });

  }

  /**
   * 
   */
  _reset() {
    this.is_on_profile = false;
    this.is_on_plan = false;
    this.is_trying_deleting = false;
    this.is_on_security = false;
    this.is_on_cancel_subscription = false;
  }

  /**
   * 
   */
  _makeTimezoneArray() {
    let names = moment.tz.names();
    let i: any;
    let _m = "";
    names.forEach((k, ii) => {
      _m = moment.tz(k).format("Z");
      i = { "timezone": k, "offset": _m };
      this.zones.push(i);
    });

  }

  /**
   * 
   */
  onInput_FetchLatLng() {

  }

  /**
   * 
   */
  _getBusiness() {
    this.is_loading = true;
    this.businessService.getWhosLoggedIn().subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      if (!temp.data.plan) {
        this.current_plan = {};
      }
      else {
        this.current_plan = JSON.parse(JSON.parse(temp.data.plan).payload);
      }
      this.payment_type = "1";
      if (temp.data.payment_type == 'y') {
        this.payment_type = "2";
      }

    });

  }

  /**
 * 
 * @param event 
 * @param name 
 * @param id 
 */
  onClick_SavePwd() {
    let valid = true;
    valid = this._validatePwd();
    if (valid == true) {
      if (this.is_customer == true) {
        this._changePasswordCustomer();
      } else {
        this._changePassword();
      }
    }
  }

  /**
   * 
   */
  _validatePwd() {
    if (this.confirm_password != this.new_password) {
      this.alertService.error("Confirm password does not match New password.");
      return false;
    }
    return true;
  }

  /**
   * 
   */
  _set() {
    let _p = {
      "c_password": this.confirm_password,
      "password": this.new_password,
      "current_password": this.current_password,
      "security": true
    }

    return _p;

  }


  /**
   * 
   */
  _resetPwdForm() {
    this.title = "Change Password";
    this.is_change_password = true;
    this.current_password = "";
    this.new_password = "";
    this.confirm_password = "";

  }

  /**
   * 
   */
  _changePasswordCustomer() {
    this.is_loading = true;
    let _p = this._set();
    this.customerService.save(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      if (temp.success == true) {
        this.alertService.success(temp.msg);
      }
      else {
        this.alertService.error(temp.msg);
      }
      this._resetPwdForm();
    });

  }

  /**
   * 
   */
  _changePassword() {
    let _p = this._set();
    this.is_loading = true;
    this.userService.update(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.is_loading = false;
      if (temp.success == true) {
        this.alertService.success(temp.msg);
      }
      else {
        this.alertService.error(temp.msg);
      }
      this._resetPwdForm();
    });
  }



  /**
   * 
   * @param item 
   */
  onClick_Changeitem(item) {
    this._reset();
    if (item == 'profile') {
      this.is_on_profile = true;
    }
    if (item == 'plan') {
      this.is_on_plan = true;
    }
    if (item == 'pwd') {
      this.is_on_security = true;
    }
  }


  /**
   * 
   */
  onClick_Plan() {
    this.is_cannot_downgrade = false;
    this._reset();
    this.is_on_plan = true;

  }


  /**
   * 
   * @param plan 
   */
  onClick_SelectPlan(plan) {
    this.is_loading = true;
    let _p = {
      "plan": plan.id,
      "payment_type": this.payment_type,
      "_xr": this.trial_ends
    };
    this.businessService.choosePlan(_p).subscribe(data => {
      let temp: any;
      temp = data;
      //this._resetPayment();
      this._getBusiness();
      if (temp.success) {
        this.alertService.success(temp.msg);
        this.alertService.info("Refreshing permissions ...");
        setTimeout(() => {
          this.router.navigate(['/config']);
          //window.location.reload();
        },
          3000);

      }
      else {
        if (temp.msg == 'REDIRECT_DOWNGRADE_INFO_PAGE') {
          this.is_cannot_downgrade = true;
        }
        else {
          this.alertService.error(temp.msg);
        }
      }

      this.is_loading = false;

    });
  }

  /**
 * 
 */
  onClick_TryCancelSubscription() {
    this._reset();
    this.title = "Cancel Your Subscription";
    this.is_on_cancel_subscription = true;
    //this.modal.getModal('cancel_sub').open();
  }

  /**
   * 
   */
  onClick_ModalClose() {
    this.modal.getModal('cancel_sub').close();
  }

  /**
   * 
   */
  onClick_CancelSubsctiptionDeletion() {
    this._reset();
    this.is_on_plan = true;
    this.title = "Plan";

  }

  /**
   * 
   */
  onClick_CancelSubscription() {
    this.is_loading = true;
    this.businessService.cancelPlan().subscribe(data => {
      let temp: any;
      temp = data;
      //this._resetPayment();
      this._getBusiness();
      if (temp.success) {
        this.alertService.success(temp.msg);
        this.alertService.info("Refreshing permissions ...");
        setTimeout(() => {
          this.router.navigate(['/config']);
          //window.location.reload();
        },
          3000);
      }
      else {
        this.alertService.error(temp.msg);
      }
      this.is_loading = false;
    });

  }



  /**
   * 
   */
  _getProfile() {
    this.is_loading = true;

    if (this.is_customer == false) {
      this.businessService.getWhosLoggedIn().subscribe(data => {
        let temp: any = data;
        this.logo = temp.data.logo;
        this.name = temp.data.name;
        this.website = temp.data.website;
        this.street = temp.data.street;
        this.city = temp.data.city;
        this.state = temp.data.state;
        this.telephone = temp.data.telephone;
        this.zipcode = temp.data.zipcode;
        this.country = temp.data.country;
        this.description = temp.data.description;
        this.utc_offset = temp.data.utc_offset;
        this.timezone = temp.data.timezone;

        if (this.logo != "") {
          this.is_logo_exists = true;
        }
        else {
          this.is_logo_exists = false;
        }
        this.is_loading = false;

      });
    }
    this.is_loading = true;

    this.userService.getByAuthToken().subscribe(data => {
      this.is_loading = false;
      let temp: any = data;
      this.is_image_exists = false;
      if (this.string.hasSomething(temp.data.image)) {
        this.is_image_exists = true;
        this.image = temp.data.image;
      }
      this.username = temp.data.username;
      this.first_name = temp.data.first_name;
      this.last_name = temp.data.last_name;
      this.mobile = temp.data.mobile;
    });

  }

  /**
   * 
   */
  onClick_ProfileSave() {
    if (this.is_customer == false) {
      this._checkTz();
      if (this.utc_offset == "" || this.timezone == "") {
        this.alertService.error("You must select your timezone.");
      }
      else {
        this._saveBusiness();
      }
    }
    else {
      this._saveCustomer(1, 0);
    }
  }

  /**
   * 
   */
  _checkTz() {
    this.zones.forEach((k, i) => {
      if (k.timezone == this.timezone) {
        this.utc_offset = k.offset;
      }
    });
  }
  /**
   * 
   * @param event 
   */
  selectImage(event: any) {
    let _t = this;
    this.file.onFileChanged(event, function (url, filedata) {
      _t.logo = url;
      _t.image = url;
    });
  }

  /**
   * 
   */
  onClick_DeleteImage() {
    this.logo = "";
    this.image = "";
    if (this.is_customer == false) {
      this._saveBusiness();
    }
    else {
      this._saveCustomer(0, 1);
    }
  }

  /**
   * 
   */
  onClick_TryDelete() {
    //this.modal.getModal('acc_id').open();
    this._reset();
    this.is_trying_deleting = true;
  }

  /**
   * 
   */
  onClick_CancelDeletion() {
    this.is_trying_deleting = false;
    this.is_on_profile = true;
  }

  /**
   * 
   */
  onClick_Delete() {

    this.businessService.delete().subscribe(
      (res) => {
        this.authService.signout();
        this.router.navigate(['/good-bye']);
      },
      error => {
        this.router.navigate(['/good-bye']);
      });


  }
  /**
   * 
   */
  _saveCustomer(personal_profile = 0, image = 0) {
    let body = {
      "first_name": this.first_name,
      "last_name": this.last_name,
      "mobile": this.mobile,
      "personal_profile": personal_profile,
      "delete_image": image,
      "image": this.image,
    }
    this.is_loading = true;
    this.customerService.save(body).subscribe(data => {
      this.is_loading = false;
      let temp: any;
      temp = data;
      if (temp.success) {
        this.alertService.success(temp.msg);
      }
      else {
        this.alertService.error(temp.msg);
      }
    },
      error => {
        this.is_loading = false;
        this.alertService.error("An error occurred. Please try again or reload page once.");
      });

  }

  /**
   * 
   */
  _saveBusiness() {
    let body = {
      "username": this.username,
      "first_name": this.first_name,
      "last_name": this.last_name,
      "mobile": this.mobile,
      "name": this.name,
      "logo": this.logo,
      "telephone": this.telephone,
      "website": this.website,
      "street": this.street,
      "city": this.city,
      "state": this.state,
      "zipcode": this.zipcode,
      "country": this.country,
      "utc_offset": this.utc_offset,
      "timezone": this.timezone,
      "description": this.description
    }


    if (this._validate(body)) {
      this.is_loading = true;
      this.businessService.save(body).subscribe(
        (res) => {
          this.is_loading = false;
          let response = JSON.parse(JSON.stringify(res));
          if (response.success) {
            this.alertService.success(response.msg);
          }
          else {
            this.alertService.error(response.msg);
          }
        },
        error => {
          this.is_loading = false;
          this.alertService.error("An error occurred. Please try again or reload page once.");
        });
    }
  }

  _validate(body) {
    if (!this.string.isValidNorwayMobile(body.mobile)) {
      this.alertService.error("Mobile but be a valid mobile number");
      return false;
    }
    return true;

  }
}
