<div>
    <div class="card-header bg-light">
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <p-calendar (onSelect)="onClick_JumpToDate($event)"  [locale]="no" 
                    [(ngModel)]="datetime" [showIcon]="true" dateFormat="dd.m.yy" [readonlyInput]="true" ></p-calendar>
            </div>
        </div>
    </div>
    
    <div class="" *ngFor="let cal of slots">
        <hr class="mb-0 pb-0 pt-0 mt-0">
        <div class="card-header title-decorative text-primary">
            <div class="row ">
                <span class="col-lg-2 col-sm-4">
                    <img *ngIf="cal.image != '' && cal.image != null && cal.image != 'null' && cal.image != 'undefined'" (click)="open(model_id,cal)" 
                    class="thumbnail  rounded img-fluid" [src]="cal.image">
                </span>
                <span class="col-lg-8 col-sm-4">
                    {{cal.name}}<br>
                    <small class="text text-muted">{{cal.description}}</small>
                </span>
                <span class="col-lg-2 col-sm-4">
                    <small class="text text-muted">{{current_date.format('LL')}}</small>
                </span>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-3 col-3" id="slot_id_{{slot.id}}" *ngFor="let slot of cal.slots">
                    <h5>
                        <a href="javascript:void(0);" style="margin-bottom: 12px" class="btn btn-md btn-light  text text-primary"
                            (click)="onClick_Slot(slot.cal_id,slot.slot_minute,slot.slot_string,cal.name)">{{slot.slot_string}}</a>
                    </h5>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="is_show_error == true" class="card-body ">
    <div class="alert alert-danger">{{this.err}}</div>
</div>

<ng-template #model_id let-modal>
    <div class="modal-header">
        <div class="modal-title" id="modal-basic-title">{{modal_title}}</div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <img src="{{modal_image}}" class=" shadow rounded img-fluid">
            </div>
        </div>

        <br>
        <div>{{modal_description}}</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')" translate >btn-close</button>
    </div>
</ng-template>