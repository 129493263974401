<div class="container">
    <div class="row">
        <!--end of col-->
        <div class="col-12 col-md-3">
            <div class="card card-sm">
                <ul class="list-group">
                    <li class="list-group-item bg-light"
                        *ngIf="auth.getUser().user_type != 'customer' &&
                        is_enable_paypal == '1'">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_Paypal()" translate>btn-paypal
                            </button>
                    </li>
                    <li class="list-group-item bg-light"
                        *ngIf="auth.getUser().user_type != 'customer' &&
                        is_enable_stripe == '1'">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_Stripe()" translate>btn-stripe
                            </button>
                    </li>
                    <li class="list-group-item bg-light"
                        *ngIf="auth.getUser().user_type != 'customer'">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_Setting()" translate>btn-settings</button>
                    </li>
                    <li class="list-group-item bg-light" *ngIf="all_cards?.length == 0">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_AddCard()" translate>btn-add-card</button>
                    </li>
                    <li class="list-group-item" id="itm{{itm.id}}" *ngFor="let
                        itm of all_cards"
                        (click)="onClick_Card($event,itm.gateway_name,itm.card_number,itm.card_name,itm.card_type,itm.id)">
                        <a href="javascript:void(0);" id="itm{{itm.id}}">****{{itm.card_number}}<i
                                style="padding-top: 4px;"
                                class="float-right icon-chevron-right"
                                id="itm{{itm.id}}"></i></a></li>
                </ul>
            </div>
        </div>
        <!--end of col-->


        <div class="col-12 col-md-9" *ngIf="is_on_setting == true &&
            auth.getUser().user_type != 'customer'">
            <div class="card card-sm">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <span *ngIf="is_loading == false">{{title}}</span>
                    <span *ngIf="is_loading == true" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif">
                        txt-loading
                    </span>
                </div>
                <div class="card-body">
                    <br>
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-transfer-all-incoming-payment-to-below-account</label>
                            <select class="form-control form-control-lg"
                                [(ngModel)]="business.main_vendor_for_payment">
                                <option value="" translate> lbl-none </option>
                                <option *ngFor="let v of vendors"
                                    [value]="v.name">{{v.name}}</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="business.enable_paypal">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="business.enable_paypal ===
                                    boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (business.enable_paypal=
                                    boolean_status[0].value) :
                                    (business.enable_paypal=
                                    boolean_status[1].value);"
                                    id="enable_paypal">
                                <label class="custom-control-label"
                                    for="enable_paypal" translate>lbl-enable-paypal-online-transactions</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row form-group">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="business.enable_stripe">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="business.enable_stripe ===
                                    boolean_status[0].value"
                                    (change)="$event.target.checked?
                                    (business.enable_stripe=
                                    boolean_status[0].value) :
                                    (business.enable_stripe=
                                    boolean_status[1].value);"
                                    id="enable_stripe">
                                <label class="custom-control-label"
                                    for="enable_stripe" translate>lbl-enable-stripe-online-transactions</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-light">
                    <div class="col">
                        <button *ngIf="is_loading == true" class="btn btn-lg
                            text text-muted btn-light" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif"
                                translate> txt-loading
                        </button>

                        <button *ngIf="is_loading == false" class="btn btn-lg
                            btn-primary" (click)="onClick_SaveSetting()"
                            translate>btn-save</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12 col-md-9" *ngIf="is_on_paypal == true &&
            auth.getUser().user_type != 'customer'">
            <app-config-paypal></app-config-paypal>
        </div>
        <div class="col-12 col-md-9" *ngIf="is_on_stripe == true &&
            auth.getUser().user_type != 'customer'">
            <app-config-stripe></app-config-stripe>
        </div>

        <div class="col-12 col-md-9" *ngIf="is_card_detail == true">
            <div class="card card-sm">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <span *ngIf="is_loading == false">{{title}}</span>
                    <span *ngIf="is_loading == true" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif"
                            translate>
                        txt-loading
                    </span>

                </div>
                <div class="card-body">
                    <div class="row form-group">
                        <div class="col">
                            <br>
                            <div class="alert alert-light">
                                <h5 class="text text-primary">{{card_details}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-light">
                    <div class="col">
                        <button *ngIf="is_loading == true" class="btn btn-lg
                            text text-muted btn-light" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif"
                                translate> txt-loading
                        </button>

                        <button *ngIf="is_loading == false" class="btn btn-lg
                            float-right btn-outline-danger"
                            (click)="onClick_DeleteCard()"
                            translate>btn-delete</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-9" *ngIf="is_add_new_card == true">
            <div class="card card-sm">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <div>
                        <h6>{{title}}</h6>
                    </div>
                </div>
                <div class="card-header bg-light">
                    <div class="text text-danger" translate>
                        txt-we-do-not-save-info-on-server
                    </div>
                </div>
                <div class="card-body">
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-card-name</label><br>
                            <input  autocomplete="off" class="form-control form-control-lg"
                                type="text" [(ngModel)]="name" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-card-number</label> <br>
                            <input  autocomplete="off" class="form-control form-control-lg"
                                type="number" [(ngModel)]="number" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-expiry-month</label><br>
                            <input  autocomplete="off" class="form-control form-control-lg"
                                type="password" mask="00" [(ngModel)]="exp_month" />
                        </div>
                        <div class="col">
                            <label translate>lbl-expiry-year</label><br>
                            <input autocomplete="off"  class="form-control form-control-lg"
                                type="password" mask="00" [(ngModel)]="exp_year" />
                        </div>
                        <div class="col">
                            <label translate>lbl-cvc</label><br>
                            <input  autocomplete="off" class="form-control form-control-lg"
                                type="password" mask="000" [(ngModel)]="cvc" />
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-light">
                    <div class="col">
                        <button *ngIf="is_loading == true" class="btn btn-lg
                            text text-muted btn-light" translate>
                            <img src="assets/img/ajax-loader-dark-gray.gif">
                            txt-loading
                        </button>

                        <button *ngIf="is_loading == false" class="btn btn-lg
                            btn-primary" (click)="onClick_SaveCard()" translate>btn-save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end of row-->
</div>
