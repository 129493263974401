import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthHelper } from '../../_helper/auth';
import { Router } from '@angular/router';
import { DateHelper } from 'src/app/_helper/date';
import { BookingService } from 'src/app/_service/su/booking.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  bookings: any;
  is_loading = false;
  settings = {
    noDataMessage : this.translate.instant("txt-no-data-found"),
    attr: {
      class: 'table table-bordered'
    },
    actions:false,
    columns: {
      name: {
        title: "Business"
      },
      service_name: {
        title: "Service"
      },
      username: {
        title: "Customer"
      },
      price: {
        title: "Price"
      },
      start_timestamp_in_minute: {
        title: "Start",
        type: 'string',
        valuePrepareFunction: (value) => { return this.date.formatForNorway(value*60) },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            return moment.unix(cell*60).utc().format(this.date.norway_datetime_format).match(search);
          }
        }
      },
      end_timestamp_in_minute: {
        title: "Ends",
        valuePrepareFunction: (value) => { return this.date.formatForNorway(value*60) },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            return moment.unix(cell*60).utc().format(this.date.norway_datetime_format).match(search);
          }
        }

      },
      b_status: {
        title: "Status"
      },
      dated: {
        title: "Created",
        valuePrepareFunction: (value) => { return this.date.formatForNorway(value) },
        filterFunction: (cell?: any, search?: string) => {
          if (search.length > 0) {
            return moment.unix(cell*60).utc().format(this.date.norway_datetime_format).match(search);
          }
        }
      }
    }
  };

  constructor(    
    private translate: TranslateService,
    private router: Router,
    public date:DateHelper,
    private bookingService: BookingService,
    private auth: AuthHelper
    ) {

  }

  ngOnInit() {
    this.is_loading = true;
    this.bookingService.getAll().subscribe(data=>{
      let temp:any;
      temp = data;
      this.is_loading = false;
      this.bookings = temp.payload;
    });

  }


  userRowSelect(e){
    this.router.navigateByUrl("su/booking.details?id="+e.data.b_id);     
  }
  
  

}
