<section class="space-xs">
    <div class="container">
        <h3 class="text">Privacy Policy</h3>
        <hr>
        <p class=""><strong><span class="h4">Hvem vi er</span></strong></p>
        <p class="p2"><span class="s1">V&aring;r nettstedadresse er
                https://www.bookit24.no, og policien gjelder bruk av tjenesten p&aring; bookit24.no og ibook24.no<br /> Henvendeler rundt personvern for
                Bookit24 AS kan rettes til:</span></p>
        <p class="p4"><span class="s2">E-post: <a
                    href="mailto:personvern@bookit24.com"><span class="s3">personvern@bookit24.com</span></a></span></p>
        <p class="p5"><span class="s1">Adresse: Lyngb&oslash;lien 12, 5164
                Laksev&aring;g</span></p>
        <p class="p7"><strong><span class="h4 mt-5">Hvilke personopplysninger lagrer
                    vi?</span></strong></p>
        <p class="p5"><span class="s1">Vi lagrer f&oslash;lgende persondata i
                v&aring;re system:</span></p>
        <table class="table table-responsive table-bordered border rounded
            table-fluid shadow-sm" cellspacing="0" cellpadding="0">
            <tbody>
                <tr class="bg-light bg-secondary">
                    <td class="td1" valign="top">
                        <p class="p5"><span class="s1"><strong>Type</strong></span></p>
                    </td>
                    <td class="td1" valign="top">
                        <p class="p5"><span class="s1"><strong>Beskrivelse</strong></span></p>
                    </td>
                    <td class="td1" valign="top">
                        <p class="p5"><span class="s1"><strong>Sletting av data</strong></span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td2" valign="top">
                        <p class="p5"><span class="s1"><strong>Brukerdata</strong></span></p>
                    </td>
                    <td class="td2" valign="top">
                        <p class="p5"><span class="s1">Vi lagrer
                                kundeopplysninger om deg n&aring;r du
                                registreres som kunde. Dette er opplysninger som
                                navn, epost, adresse og telefonnummer.</span></p>
                    </td>
                    <td class="td2" valign="top">
                        <p class="p5"><span class="s1">Du vil kunne slette din
                                kundeprofil ved &aring; logge inn p&aring;
                                systemet. </span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td3" valign="top">
                        <p class="p5"><span class="s1"><strong>Henvendelser</strong></span></p>
                    </td>
                    <td class="td3" valign="top">
                        <p class="p5"><span class="s1">Dersom du kontakter oss,
                                s&aring; lagrer vi henvendelsen sammen med ditt
                                navn og e-post adresse.</span></p>
                    </td>
                    <td class="td3" valign="top">
                        <p class="p5"><span class="s1">Det er viktig for oss
                                &aring; lagre historikk for henvendelser til
                                kundeservice. Disse dataene slettes derfor ikke
                                automatisk.</span></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="p7"><strong><span class="h4 mt-5">Informasjonskapsler</span></strong></p>
        <p class="p5"><span class="s1">Vi benytter informasjonskapsler (cookies)
                for at systemet skal huske din p&aring;logging. Hvis du logger
                deg ut av kontoen din, vil denne informasjonskapslene forsvinne.<em><br
                        /> </em>Dette gj&oslash;res for at du skal slippe
                &aring; logge deg inn flere ganger.</span></p>
        <p class="p5"><span class="s1">Vi benytter ogs&aring; cookies fra Google
                Analytics for &aring; kunne analysere og forbedre<span
                    class="Apple-converted-space">&nbsp; </span>bruksm&oslash;nstre
                p&aring; siden.</span></p>
        <p class="p7"><strong><span class="h4 mt-5">Hvorfor samler vi inn
                    opplysninger?</span></strong></p>
        <p class="p5"><span class="s1">Vi samler inn og lagrer opplysninger med
                f&oslash;lgende form&aring;l:</span></p>
        <table class="table table-responsive table-bordered border rounded
            table-fluid shadow-sm" cellspacing="0" cellpadding="0">
            <tbody>
                <tr class="bg-light bg-secondary">
                    <td class="td4" valign="top">
                        <p class="p5"><span class="s1"><strong>Bruk</strong></span></p>
                    </td>
                    <td class="td5" valign="top">
                        <p class="p5"><span class="s1"><strong>Beskrivelse</strong></span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td6" valign="top">
                        <p class="p5"><span class="s1"><strong>Funksjonalitet</strong></span></p>
                    </td>
                    <td class="td7" valign="top">
                        <p class="p5"><span class="s1">Vi samler informasjon om
                                brukeren du er logget inn med slik at systemet
                                husker deg dersom du f.eks. lukker og
                                &aring;pner nettleseren igjen.</span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td8" valign="top">
                        <p class="p5"><span class="s1"><strong>Forbedringer</strong></span></p>
                    </td>
                    <td class="td9" valign="top">
                        <p class="p5"><span class="s1">Ved analyse kan vi se
                                hvilke funksjoner som brukes mye, og hvilke
                                funksjoner som er tidkrevende og derfor
                                b&oslash;r forenkles. Vi har mulighet til
                                &aring; analysere dette p&aring; et
                                gruppeniv&aring;, vi kan ikke se hva hver enkelt
                                bruker har gjort. </span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td6" valign="top">
                        <p class="p5"><span class="s1"><strong>Kontakt</strong></span></p>
                    </td>
                    <td class="td7" valign="top">
                        <p class="p5"><span class="s1">Vi samler informasjon om
                                det slik at du kan kontaktes. F.eks. kan
                                systemet sende deg automatisk p&aring;minnelse i
                                forkant av en booking.</span></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="p7"><strong><span class="h4 mt-5">Hvem vi deler dine opplysninger
                    med</span></strong></p>
        <p class="p5"><span class="s1">Vi har noen samarbeidspartnere vi bruker
                for &aring; gj&oslash;re Bookit24 til en bedre l&oslash;sning.
            </span></p>
        <p class="p5"><span class="s1">I de tilfeller der Bookit24 deler dine
                opplysninger med tredjepart er det opprettet en egen
                databehandleravtale, eller regulert gjennom Privacy Shield. Vi
                selger eller gir ikke opplysninger til &oslash;vrige
                tredjeparter som ikke ikke underst&oslash;tter tjenesten vi
                tilbyr.</span></p>
        <p class="p5"><span class="s1">Per i dag kan opplysninger deles med:</span></p>
        <table class="table table-responsive table-bordered border rounded
            table-fluid shadow-sm" cellspacing="0" cellpadding="0">
            <tbody>
                <tr style="height: 35px;" class="bg-light bg-secondary">
                    <td class="td10" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1"><strong>Hvem</strong></span></p>
                    </td>
                    <td class="td11" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1"><strong>Funksjon</strong></span></p>
                    </td>
                </tr>
                <tr style="height: 35px;">
                    <td class="td10" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1"><strong>Stripe</strong></span></p>
                    </td>
                    <td class="td11" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1">Utf&oslash;rer betaling
                                med kort i l&oslash;sningen</span></p>
                    </td>
                </tr>
                <tr style="height: 35px;">
                    <td class="td10" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1"><strong>Paypal</strong></span></p>
                    </td>
                    <td class="td11" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1">Utf&oslash;rer betaling
                                med kort i l&oslash;sningen</span></p>
                    </td>
                </tr>
                <tr style="height: 35px;">
                    <td class="td12" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1"><strong>Exivo</strong></span></p>
                    </td>
                    <td class="td13" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1">Ved integrasjon mot
                                l&aring;ssystem vil dine kontaktopplysninger bli
                                overf&oslash;rt til Exivo for &aring; kunne
                                aktivere/l&aring;se opp l&aring;sen.
                                Opplysningene slettes etter oppholdet.</span></p>
                    </td>
                </tr>
                <tr style="height: 35px;">
                    <td class="td14" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1"><strong>Twilio</strong></span></p>
                    </td>
                    <td class="td15" style="height: 35px;" valign="top">
                        <p class="p5"><span class="s1">SMS Sendes via Twilio.
                                Kun telefonnummer overf&oslash;res til Twilio i
                                forbindelse med utsendelse av SMS, ingen
                                personopplysninger. </span></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="p1"><strong><span class="h4 mt-5">Rettighetene dine n&aring;r det
                    gjelder opplysninger vi har om deg</span></strong></p>
        <table class="table table-responsive table-bordered border rounded
            table-fluid shadow-sm" cellspacing="0" cellpadding="0">
            <tbody>
                <tr class="bg-light bg-secondary">
                    <td class="td16" valign="top">
                        <p class="p5"><span class="s1"><strong>Rettighet</strong></span></p>
                    </td>
                    <td class="td17" valign="top">
                        <p class="p5"><span class="s1"><strong>Beskrivelse</strong></span></p>
                    </td>
                    <td class="td17" valign="top">
                        <p class="p5"><span class="s1"><strong>L&oslash;sning</strong></span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td18" valign="top">
                        <p class="p5"><span class="s1"><strong>Hva vi lagrer</strong></span></p>
                    </td>
                    <td class="td19" valign="top">
                        <p class="p5"><span class="s1">Du har rett til &aring;
                                hvite hvilke typer opplysninger vi lagrer om deg</span></p>
                    </td>
                    <td class="td19" valign="top">
                        <p class="p5"><span class="s1">Beskrevet p&aring; denne
                                siden</span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td20" valign="top">
                        <p class="p5"><span class="s1"><strong>Hvorfor vi lagrer
                                    informasjonen</strong></span></p>
                    </td>
                    <td class="td21" valign="top">
                        <p class="p5"><span class="s1">Du har rett til &aring;
                                vite hvorfor vi lagrer persondata</span></p>
                    </td>
                    <td class="td21" valign="top">
                        <p class="p5"><span class="s1">Beskrevet p&aring; denne
                                siden</span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td18" valign="top">
                        <p class="p5"><span class="s1"><strong>Innsyn</strong></span></p>
                    </td>
                    <td class="td19" valign="top">
                        <p class="p5"><span class="s1">Du har rett til &aring;
                                be om innsyn om hvilke opplysninger vi har
                                lagret om deg</span></p>
                    </td>
                    <td class="td19" valign="top">
                        <p class="p5"><span class="s1">Kontakt oss for oversikt</span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td22" valign="top">
                        <p class="p5"><span class="s1"><strong>Sletting eller
                                    endring</strong></span></p>
                    </td>
                    <td class="td23" valign="top">
                        <p class="p5"><span class="s1">Du har rett til &aring;
                                be om &aring; f&aring; slettet opplysninger vi
                                har lagret om deg, eller rette eventuelle feil.</span></p>
                    </td>
                    <td class="td23" valign="top">
                        <p class="p5"><span class="s1">Kontakt oss</span></p>
                    </td>
                </tr>
                <tr>
                    <td class="td24" valign="top">
                        <p class="p5"><span class="s1"><strong>Klage</strong></span></p>
                    </td>
                    <td class="td25" valign="top">
                        <p class="p5"><span class="s1">Du har rett til &aring;
                                klage dersom du mener dine rettigheter ikke er
                                ivaretatt av oss</span></p>
                    </td>
                    <td class="td25" valign="top">
                        <p class="p5"><span class="s1">Vi oppfordrer alltid til
                                &aring; kontakte oss slik at vi kan rette
                                feilen. Du har imidlertid rett til &aring;
                                ogs&aring; klage til Datatilsynet.</span></p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>
