import { Component, OnInit } from '@angular/core';
import { Input, Output,EventEmitter } from '@angular/core';
import {  CategoryService } from './../../../_service/category.service';
import {  AlertService } from './../../../_service/alert.service';
import {  AuthHelper } from '../../../_helper/auth';

@Component({
  selector: 'app-booking-create-category-list',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  @Output() select = new EventEmitter<object>();
  @Input() is_loading: boolean;
  is_available = true;
  categories:any;
  title = "Category";
  err = "";

  /**
   * 
   * @param categoryService 
   * @param auth 
   * @param alertService 
   */
  constructor(
    private categoryService: CategoryService,
    private auth: AuthHelper,
    private alertService: AlertService
    
  ) { 
    
  }

  /**
   * 
   */
  ngOnInit() {
  }

  /**
   * 
   * @param business 
   */
  getAllCategories(business) {
    this.is_loading = true;
    this.categoryService.getAll(business).subscribe(
        (res) => {
            this.is_loading = false;
            let response = JSON.parse(JSON.stringify(res));
            if (response.success) {
                this.categories = response.data;
                if (this.categories == null) {
                    this.is_available = false;
                    if (this.auth.getUser().user_type == "customer") {
                        this.err = "No Services available at the moment. Please try again later.";
                    }
                    if (this.auth.getUser().user_type == "provider") {
                        this.err = "Looks like you have not added any categories yet.";
                    }
                }
            } else {
                this.err = response.msg;
            }
        },
        error => {
            this.is_loading = false;
           // console.log(error);
            this.alertService.error(error);
        });
  }

  /**
   * 
   * @param event 
   * @param name 
   */
  onSelect(event,name) {
    let id = event.target.id.substr(3);
    this.select.emit({"id":id,"name":name});
  }
}
