<section class="space-xs">
	<div class="container">
		<h3 class="text">Personvern.</h3>
		<hr>

		Ved leie av leilighet: Vennligst legg merke til at systemet logger navn på
		bruker, samt tidpunktene for når døren låses opp. Dette blir lagret for
		servicehistorikk i systemet i 90 dager og er beskyttet av brukernavn og
		passord og kun tilgjengelig for administratorer av systemet.
	</div></section>
