<div class="container">
    <div class="row">
      <div class="col-10 col-md-10 col-lg-10">
        <div class="alert alert-light">
            <div *ngIf="is_loading == true">
                <span class="title-decorative" translate ><img src="assets/img/ajax-loader-light-gray.gif">
                     txt-loading </span>
            </div>            
            <div *ngIf="is_loading == false" translate >
            txt-portfolio-settings-information 
            <span  class="text text-primary">https://{{business.subdomain}}.{{business.top_level_domain}}</span>.
            </div>
        </div>
        <div class="form-group col-12 bg-light space-lg" style="height:320px">
            <img alt="Banner Image" [src]="business.banner" class="bg-image rounded">
            <div class="card-body">
                <h2 class="bg-parent" [style.color]="business.tagline1_color" 
                [style.font-size]="business.tagline1_size">{{business.tagline1}}</h2>
                <h5 class="bg-parent" [style.color]="business.tagline2_color" 
                [style.font-size]="business.tagline2_size">{{business.tagline2}}</h5>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-12">
                <label class="custom-file mb-0" for="file2">
                    <input type="file" (change)="selectImage($event)"
                        class="btn btn-light btn-sm">&nbsp;
                    <button class="btn btn-sm btn-outline-danger" 
                    (click)="onClick_DeleteImage()"  translate >btn-delete</button>
                </label>    
            </div>
          </div>
          <div class="row form-group">    
              <div class="col-8">
                  <input autocomplete="off"  class="form-control form-control-md" maxlength="40" [(ngModel)]="business.tagline1" 
                  placeholder="{{ 'lbl-tag-line-max-length-40-char' |  translate  }}">
              </div>
              <div class="col-2">
                  <input  autocomplete="off"  [cpPosition]="'bottom'" 
                  [(colorPicker)]="business.tagline1_color" [style.background]="business.tagline1_color" 
                 class="form-control form-control-md" type="text" />
              </div>
              <div class="col-2">
                  <select class="form-control form-control-md" [(ngModel)]="business.tagline1_size" translate>
                      <option [value]="40" translate >lbl-size-1</option>  
                      <option [value]="50" translate >lbl-size-2</option>  
                      <option [value]="55" translate >lbl-size-3</option>  
                    </select>
              </div>
          </div>
          <div class="row form-group">    
              <div class="col-8">
                  <input autocomplete="off"  class="form-control form-control-md" maxlength="160" [(ngModel)]="business.tagline2" 
                  placeholder="{{ 'lbl-tag-line-max-length-160-char' |  translate  }}">
              </div>
              <div class="col-2">
                  <input  autocomplete="off"  [cpPosition]="'bottom'" 
                  [(colorPicker)]="business.tagline2_color" [style.background]="business.tagline2_color" 
                 class="form-control form-control-md" type="text" />
              </div>
              <div class="col-2">
                  <select class="form-control form-control-md" [(ngModel)]="business.tagline2_size" >
                      <option [value]="15" translate >lbl-size-1</option>
                      <option [value]="20" translate >lbl-size-2</option>
                      <option [value]="25" translate >lbl-size-3</option>
                  </select>
              </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-2">
            <div class="overflow-auto text text-center" style="max-height:480px">
                <ul class="list-unstyled list-spacing-l">
                    <li *ngFor="let b of banners_list">
                        <img alt="Banner" (click)="onClick_PredefinedBanner(b)" 
                        src="assets/img/banner/{{b}}.jpg" class="pb-2 pl-0 pr-2 img-fluid responsive rounded">
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
        <div class="row flex-md-row card card-sm ">
            <div class="col-12 col-md-8 col-lg-8 col-sm-6 card-body bg-secondary">
                <div class=" mb-1">
                    <h5 class="mb-1 title-decorative" translate >heading-business-address</h5>
                </div>
                <div class="row ">
                    <div class="col-12">
                        <div class="form-row form-group">
                            <div class="col">
                                <input autocomplete="off"  class="form-control" type="text" (input)="onInput_FindLatLng()" [(ngModel)]="business.street" placeholder="{{'Street' |  translate }}" />
                            </div>
                        </div>
                        <div class="form-row form-group">
                            <div class="col">
                                <input autocomplete="off"  class="form-control" type="text" (input)="onInput_FindLatLng()" [(ngModel)]="business.city" placeholder="{{ 'City' | translate }}" />
                            </div>
                            <div class="col">
                                <input autocomplete="off"  class="form-control" type="text" (input)="onInput_FindLatLng()" [(ngModel)]="business.state" placeholder="{{ 'State' | translate }} " />
                            </div>
                        </div>
                        <div class="form-row form-group">
                            <div class="col">
                                <input autocomplete="off"  class="form-control" type="text"  (input)="onInput_FindLatLng()" [(ngModel)]="business.zipcode" placeholder="{{ 'Zip' | translate }}" />
                            </div>
                            <div class="col">
                                <input  autocomplete="off" class="form-control" type="text" (input)="onInput_FindLatLng()" [(ngModel)]="business.country" placeholder="{{ 'Country' | translate }}" />
                            </div>
                        </div>            
                    </div>
                </div>
                <div class=" mb-1">
                    <h5 class="mb-1 title-decorative" translate >heading-working-hours</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-row form-group">
                            <div class="col-4">
                                <label translate >lbl-monday</label>
                            </div>    
                            <div class="col-8">
                                <input class="form-control" [dropSpecialCharacters]="false" (input)="onInput_FixTime()"
                                mask='00:00-00:00' placeholder="e.g. 08:00-18:00" type="text" [(ngModel)]="business.mon_working_hours" />
                            </div>    
                        </div>
                        <div class="form-row form-group">
                            <div class="col-4">
                                <label translate >lbl-tuesday</label>
                            </div>    
                            <div class="col-8">
                                <input class="form-control" [dropSpecialCharacters]="false" (input)="onInput_FixTime()"
                                mask='00:00-00:00' placeholder="e.g. 08:00-18:00" type="text" [(ngModel)]="business.tue_working_hours" />
                            </div>    
                        </div>
                        <div class="form-row form-group">
                            <div class="col-4">
                                <label translate >lbl-wednesday</label>
                            </div>    
                            <div class="col-8">
                                <input class="form-control" [dropSpecialCharacters]="false" (input)="onInput_FixTime()"
                                mask='00:00-00:00' placeholder="e.g. 08:00-18:00" type="text" [(ngModel)]="business.wed_working_hours" />
                            </div>    
                        </div>
                        <div class="form-row form-group">
                            <div class="col-4">
                                <label translate >lbl-thursday</label>
                            </div>    
                            <div class="col-8">
                                <input class="form-control" [dropSpecialCharacters]="false" (input)="onInput_FixTime()"
                                mask='00:00-00:00' placeholder="e.g. 08:00-18:00" type="text" [(ngModel)]="business.thu_working_hours" />
                            </div>    
                        </div>
                        <div class="form-row form-group">
                            <div class="col-4">
                                <label translate >lbl-friday</label>
                            </div>    
                            <div class="col-8">
                                <input class="form-control" [dropSpecialCharacters]="false" (input)="onInput_FixTime()"
                                mask='00:00-00:00' placeholder="e.g. 08:00-18:00" type="text" [(ngModel)]="business.fri_working_hours" />
                            </div>    
                        </div>
                        <div class="form-row form-group">
                            <div class="col-4">
                                <label translate >lbl-saturday</label>
                            </div>    
                            <div class="col-8">
                                <input class="form-control" [dropSpecialCharacters]="false" (input)="onInput_FixTime()"                                
                                mask='00:00-00:00' placeholder="e.g. 08:00-18:00" type="text" [(ngModel)]="business.sat_working_hours" />
                            </div>    
                        </div>
                        <div class="form-row form-group">
                            <div class="col-4">
                                <label translate >lbl-sunday</label>
                            </div>    
                            <div class="col-8">
                                <input class="form-control" [dropSpecialCharacters]="false" (input)="onInput_FixTime()"
                                mask='00:00-00:00' placeholder="e.g. 08:00-18:00" type="text" [(ngModel)]="business.sun_working_hours" />
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
            <!--end of col-->
            <div class="col-12 col-md-4 col-lg-4 col-sm-6 ">
                <agm-map [mapTypeControl]="true" (mapReady)="mapReady($event)" [latitude]="business.lat" 
                    [longitude]="business.lng" [zoom]="14">
                    <agm-marker (markerClick)="openWindow()"  
                    [latitude]="business.lat" [longitude]="business.lng">
                        <agm-info-window [latitude]="business.lat" 
                        [longitude]="business.lng">{{business.name}}</agm-info-window>
                    </agm-marker>
                </agm-map>
                <div class="card-body ">
                    <h5>{{business.name}}</h5>
                    <p>
                        {{business.street}}
                        <br>{{business.zipcode}} {{business.city}} {{business.state}} 
                        <br>{{business.country}}
                    </p>
                    <div class="bg-light card-borderless text-muted card card-body card-sm" >
                        <div class="title-decorative " translate >lbl-opening-hour</div>
                        <div><span translate="">lbl-monday</span> {{mon_working_hours_alt}} </div>
                        <div><span translate="">lbl-tuesday</span> {{tue_working_hours_alt}} </div>
                        <div><span translate="">lbl-wednesday</span> {{wed_working_hours_alt}} </div>
                        <div><span translate="">lbl-thursday</span> {{thu_working_hours_alt}} </div>
                        <div><span translate="">lbl-friday</span> {{fri_working_hours_alt}} </div>
                        <div><span translate="">lbl-saturday</span> {{sat_working_hours_alt}} </div>
                        <div><span translate="">lbl-sunday</span> {{sun_working_hours_alt}} </div>
                    </div>
                </div>
           </div>
        </div>    
        <div class="row flex-md-row card card-sm ">
            <div class="col-12 card-body bg-secondary">
                <div class=" mb-1">
                    <h5 class="mb-1 title-decorative" translate >heading-social</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-row form-group">
                            <div class="col">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text title-decorative" style="display:flex"  >Twitter</span>
                                    </div>
                                    <input type="text" class="form-control" 
                                    placeholder="{{ 'lbl-twitter-handle' | translate }}" [(ngModel)]="business.twitter_handle">
                                </div>
                            </div>
                        </div>
                        <div class="form-row form-group">
                            <div class="col">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text title-decorative" style="display:flex">Facebook</span>
                                    </div>
                                    <input type="text" class="form-control" 
                                    placeholder="{{ 'lbl-facebook-url' | translate }}" [(ngModel)]="business.facebook_page_url">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        <div class="row card bg-secondary card-sm">
            <div class="card-body">
                <div class=" mb-1">
                    <h5 class="mb-1 title-decorative" translate >lbl-detailed-description-for-your-about-us-content</h5>
                </div>

                <div class="row form-group">    
                    <div class="col">
                        <textarea [(ngModel)]="business.about_us_page" class="col form-control" 
                        rows="6" ></textarea>
                    </div>
                </div>
                <div class="row form-group">   
                    <div class="col">
                        <div class="title-decorative" translate >lbl-preview</div> 
                        <p [innerHTML]="business.about_us_page"> </p>            
                    </div>
                </div>
            </div>
        </div>
        <button *ngIf="is_loading == true" class="btn btn-lg text text-muted btn-light mb-4 mt-2" translate> 
            <img src="assets/img/ajax-loader-dark-gray.gif" translate > txt-loading    
        </button>

        <button *ngIf="is_loading == false" class=" btn btn-lg btn-primary mb-4 mt-2" (click)="onClick_Save()"  translate >btn-save</button>     
      </div>
    </div>                  
</div>
