<div class="card-header bg-secondary pl-2 pr-2">
	<a (click)="onClick_Back()" class="float-left btn btn-sm
		btn-outline-secondary" href="javascript:void(0);"
		translate=""><i class="icon-chevron-left"></i> btn-back</a>
	<div class="text text-center text-muted title-decorative mt-1" translate>
		heading-login-or-register</div>
</div>
<div class="card-body pl-2 pr-2" *ngIf="config.is_loading">
	<div class="row">
		<div class="col-12">
			Please wait ...
		</div>
	</div>
</div>
<div class="card-body pl-2 pr-2" *ngIf="!config.is_loading">
	<div class="row">
		<div class="col-lg-6 col-sm-12">
			<div class="card">
				<div class="card-header bg-light text-muted title-decorative" translate>
					heading-signin
				</div>
				<div class="card-body">
					<div class="row no-gutters justify-content-center">
						<div class="text-left col-lg-12">
							<div class="form-group">
								<input autocomplete="off"  [(ngModel)]="customer.username" autocomplete="off" class="form-control
									form-control-lg" placeholder="{{'lbl-email' | translate}}"
									/>
							</div>
							<div class="form-group">
								<input autocomplete="off"  [(ngModel)]="customer.password" autocomplete="off" class="form-control
									form-control-lg" type="password" placeholder="{{
									'lbl-password' | translate}}" />
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer bg-light">
					<button (click)="onClick_Signin()" class="btn btn-primary"
						translate>btn-signin</button>
				</div>
			</div>
		</div>
		<div class="col-lg-6 col-sm-12" *ngIf="business?.enable_internal_booking=='0'">
			<div class="card">
				<div class="card-header text-muted bg-light title-decorative" translate>
					heading-register-to-bookit24 
				</div>
				<div class="card-body">
					<div class="row no-gutters justify-content-center">
						<div class="text-left col-lg-12">
							<div class="form-group">
								<input autocomplete="off"  [(ngModel)]="username" autocomplete="off"  class="form-control
									form-control-lg" placeholder="{{ 'lbl-email' | translate }}"
									/>
							</div>
							<div class="form-group">
								<input type="password" autocomplete="off" [(ngModel)]="new_password" autocomplete="off" class="form-control
									form-control-lg" placeholder="{{ 'lbl-password' | translate
									}}" />
							</div>
							<div class="form-group">
								<input autocomplete="off"  [(ngModel)]="mobile" autocomplete="off" class="form-control
									form-control-lg" type="" placeholder="{{ 'lbl-mobile' |
									translate }}" />
							</div>
							<div class="form-group">
								<input  autocomplete="off" [(ngModel)]="first_name" autocomplete="off" class="form-control
									form-control-lg" type="" placeholder="{{ 'lbl-first-name' |
									translate }}" />
							</div>
							<div class="form-group">
								<input autocomplete="off"  [(ngModel)]="last_name" autocomplete="off" class="form-control
									form-control-lg" type="" placeholder="{{ 'lbl-last-name' |
									translate }}" />
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer bg-success">
					<button (click)="onClick_Signup()" class="btn  active
						btn-success" translate>btn-submit</button>
				</div>
			</div>
		</div>
	</div>
</div>
