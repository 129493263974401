import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlHelper } from './url';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        var url = new UrlHelper;

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser != undefined && currentUser.data != undefined && currentUser.data.auth_token) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Token " + currentUser.data.auth_token + ":" + url.getDomainName()
                }
            });
        }
        else {
            request = request.clone({
                setHeaders: {
                    Authorization: "Token  null:" + url.getDomainName()
                }
            });
            
        }

        return next.handle(request);
    }
}