import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClientModule,HttpParams,HttpHeaders } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
import { HttpFormEncodingCodec } from './../_helper/http.form.codec';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

    /**
     * 
     */
    headers: HttpHeaders;
    
    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) { 
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }

    /**
     * 
     */
    _getBody(){
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    }

    /**
     * 
     */
    save(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token",user.data.auth_token);        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/provider/calendar/do/save-step-1",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    autoGenerateCalendar(_p){
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token",user.data.auth_token);        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/provider/calendar/do/auto-generate",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
        
    }

    /**
     * 
     */
    setSchedule(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token",user.data.auth_token);        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/provider/calendar/do/save-step-4",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    delete(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("calendar_id",_p.id).append("auth_token",user.data.auth_token);        
        let body = this._append(temp,_p);

        return this.http.post(environment.server+"/provider/calendar/do/delete",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    
      
    /**
     * 
     * @param temp 
     * @param _p 
     */
    _append(temp,_p){

        Object.keys(_p).forEach(k=>{
            let t = temp.append(k,_p[k]);   
            // below line is important because temp only return
            // it does not update temp 
            temp = t;
        });
        return temp;

    }
  
    /**
     * 
     */
    toggleService(assign,service_id,cal_id) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("service_id",service_id)
        .append("calendar_id",cal_id).append("auth_token",user.data.auth_token);
        let api = "deassign-service";
        if(assign){
            api = "assign-service";
        }
        return this.http.post(environment.server+"/provider/calendar/do/"+api,body, { headers: this.headers })
              .pipe(map(data => {
                  return data;
              }));
      }
  

    /**
     * 
     */
    getAll(bypass_id = null,_p = null) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("bypass_id",bypass_id).append("auth_token",user.data.auth_token);
        let body:any;
        if(_p != null){
            body = this._append(temp,_p);
        }
        else{
            body = temp;
        }
        return this.http.post(environment.server+"/provider/calendar/poll/all",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getAllResources(bypass_id = null,_p = null) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("bypass_id",bypass_id).append("auth_token",user.data.auth_token);
        let body:any;
        if(_p != null){
            body = this._append(temp,_p);
        }
        else{
            body = temp;
        }
        return this.http.post(environment.server+"/provider/calendar/poll/all-resources",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getSchedule(_p) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let temp = this._getBody().append("auth_token",user.data.auth_token);        
        let body = this._append(temp,_p);
        return this.http.post(environment.server+"/provider/calendar/availability/get",body, { headers: this.headers })
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * 
     */
    getById(id) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("id",id).append("auth_token",user.data.auth_token);
        return this.http.post(environment.server+"/provider/calendar/poll/by-id",body, { headers: this.headers })
              .pipe(map(data => {
                  return data;
              }));
      }
  
    /**
     * 
     */
    getOpen() {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        let body = this._getBody().append("auth_token",user.data.auth_token);
        return this.http.post(environment.server+"/provider/calendar/poll/open",body, { headers: this.headers })
              .pipe(map(data => {
                  return data;
              }));
      }
  
  
    
}
