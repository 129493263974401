import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_service/alert.service';
import { UserService } from '../../_service/user.service';
import { ConfigService } from '../../_service/config.service';
import { AuthHelper } from '../../_helper/auth';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-config-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {

  /**
   * 
   */

  item = { "name": "", "value": "", "id": 0,"description":"" };
  title = "";
  templates = [];
  is_loading = false;
  is_customer = false;
  is_item_selected = false;

  /**
   * 
   * @param userService 
   */
  constructor(
    private configService: ConfigService,
    public alertService: AlertService,
    public translate: TranslateService,
    public userService: UserService,
    public auth: AuthHelper
  ) {
    if (auth.getUser().user_type == 'customer') {
      this.is_customer = true;
    }
    this._resetUserForm();
  }

  /**
   * 
   */
  ngOnInit() {
    this._getAll();
  }

  /**
   * 
   */
  _getAll(){
    this.configService.getAll().subscribe(data => {
      let temp: any;
      temp = data;
      this.templates = temp.data;
    });

  }

  /**
   * 
   */
  _resetUserForm() {
    this.title = this.translate.instant("heading-templates");

  }

  /**
   * 
   * @param e 
   * @param value 
   * @param name 
   * @param id 
   * @param description 
   * @param alias 
   */
  onClick_Tpl(e, value, name, id,description,alias) {
    this.is_item_selected = true;
    this.item.name = name;
    this.item.value = value;
    this.item.description = description;
    this.item.id = id;
    this.title = alias;
  }

  /**
   * 
   * @param event 
   * @param name 
   * @param id 
   */
  onClick_Save() {
    let _p = {
      "id": this.item.id,
      "value": this.item.value,
      "single_template": true
    }
    this.userService.update(_p).subscribe(data => {
      let temp:any;
      temp = data;
      if(temp.success){
        this.alertService.success(this.translate.instant("alert-updated"));
      }
      else{
        this.alertService.success(this.translate.instant("alert-error"));
      }
    });
  }


}
