<div class="card-footer bg-secodary">
    <div class="row">
        <div class="col-lg-2">
            <img *ngIf="service?.image != '' && service?.image != null && service?.image != 'null' && service?.image != 'undefined' " class="thumbnail shadow rounded img-fluid" [src]="service?.image">
        </div>
        <div class="col-lg-5">
            <span class="title-decorative mb-0 text-muted"  translate >lbl-check-in <span class="text text-primary">{{service?.checkin}}</span></span>
            <span class="title-decorative mb-0 text-muted" translate >lbl-check-out <span class="text text-primary">{{service?.checkout}}</span></span>
        </div>
        <div class="col-lg-5">
            <span class="title-decorative mb-0 text-muted" translate >lbl-name <span class="text text-primary">{{service?.name}}</span></span>
            <span class="title-decorative mb-0 text-muted" translate >lbl-max-people <span class="text text-primary">{{service?.max_number_of_people}}</span></span>
        </div>
        <div class="col-12 text text-muted">
            {{service?.description}}
        </div>
    </div>
</div>

<div class="" *ngFor="let cal of slots">
    <hr class="mb-0 pb-0 pt-0 mt-0">
    <div class="card-header  mb-0 title-decorative text-primary">
        <div class="row ">
            <span class="col-lg-2 col-sm-4" *ngIf="is_customer == false && is_guest == false">
                <img *ngIf="cal?.image != ''" (click)="open(model_id,cal)" class="thumbnail  rounded img-fluid" [src]="cal?.image">
            </span>
            <span class="col-lg-5 col-sm-4">
                {{cal.name}}<br>
                <small class="text text-muted">{{cal.description}}</small>
            </span>
            <span class="col-lg-5 col-sm-4">
                <small class="text text-muted" translate >txt-click-start-and-end-date</small>
            </span>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-12 text text-center">
                <p-calendar (onSelect)="onClick_Rental24CalendarDate(cal.id,cal.name)" [(ngModel)]="datetime[cal.id]"
                    selectionMode="range" [disabledDates]="cal.invalid_dates" [numberOfMonths]="3" [minDate]="today"
                    [inline]="true"  [locale]="no" [readonlyInput]="true">
                </p-calendar>
            </div>
        </div>
    </div>
</div>

<ng-template #model_id let-modal>
    <div class="modal-header">
        <div class="modal-title" id="modal-basic-title">{{modal_title}}</div>
    </div>
    <div class="modal-body">
        <div class="row">
        <div class="col-12">
            <img src="{{modal_image}}" class=" shadow rounded img-fluid">
        </div>
        </div>

        <br>
        <div>{{modal_description}}</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')" translate >btn-close</button>
    </div>
</ng-template>

