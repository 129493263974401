import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.css']
})
export class CommonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getNextDateString(_c){
    let next_date = _c.clone();
    next_date.add(1, 'd').startOf("day");
    return next_date.format("l");
  }

  getPrevDateString(_c){
    let prev_date = _c.clone();
    prev_date.subtract(1, 'd').startOf("day");
    return prev_date.format("l");
  }

  fixState(_c,_s){
    _c.forEach((v, i) => {
      v.class = "btn-light";
      _s.forEach((v2, i2) => {
          if (v2.id == v.id) {
              v.class = "btn-success";
          }
      });
    });
    return _c;

  }

  


}
