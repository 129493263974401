import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AlertService } from './../../../../_service/alert.service';
import { AuthHelper } from '../../../../_helper/auth';
import { UrlHelper } from '../../../../_helper/url';
import { ServiceService } from '../../../../_service/service.service';
import { CalendarModule } from 'primeng/calendar';
import * as moment from 'moment-timezone';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-booking-datetime-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {

  minDate = new Date();

  @Input() config: any;
  @Output() emit_config = new EventEmitter<any>();
  en: any;
  no: any;
  modal_data: any;
  selected_date = 0;
  title = "Datetime";
  cals = [];
  constructor(
    public alertService: AlertService,
    public authHelper: AuthHelper,
    private modalService: NgbModal,
    public serviceService: ServiceService,
    public urlHelper: UrlHelper
  ) { }

  ngOnInit() {
    // below moment need to be like this, it must assume local exact time
    this.selected_date = moment().utcOffset(0, true).unix();
   // console.log(this.selected_date);
    this.en = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear'
    };
    this.no = {
      firstDayOfWeek: 1,
      dayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
      dayNamesShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
      dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
      monthNames: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
      today: 'I dag',
      clear: 'Klar'
    };
    this._getSlots();
  }


  _getSlots() {
    this.config.is_loading = true;
    let _p = { "date": this.selected_date, config: JSON.stringify(this.config), "subdomain": this.urlHelper.getSubdomain() };

    this.serviceService.getAvailableSlots2(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.config.is_loading = false;

      if (temp.success) {
        this.cals = temp.data;
      }
      else {
        this.alertService.error(temp.msg);
      }
    });
  }

  onClick_Slot(s, cal) {
    this.config.summary.starts = moment.unix(s.ts).utc().format("LLL");
    this.config.summary.start_ts = s.ts;
    this.config.calendar_id = cal.id;
    this._remapServiceDuration();
    this.config.step = "customer";
   // console.log(this.config);
    this.emit_config.emit(this.config);

  }

  _remapServiceDuration() {
    // must empty it
    let s = this.config.summary.start_ts;
    this.config.service.forEach((k, i) => {
      let e = Number(s) + (Number(k.duration) * 60);
      k.start_ts = s;
      k.end_ts = e;
      s = e;
    });
   // console.log(this.config);
  }

  onClick_Back() {
    this.config.step = "service";
    this.emit_config.emit(this.config);
  }

  onClick_DatetimeChange(e) {
    this.selected_date = moment(e).utcOffset(0, true).unix();
    this._getSlots();
  }

  open(modal, item) {
    this.modal_data = item;
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

}
