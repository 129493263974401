<div class="card-header bg-secondary pl-2 pr-2">
	<a (click)="onClick_Back()" class="float-left btn btn-sm
		btn-outline-secondary" href="javascript:void(0);"
		translate=""><i class="icon-chevron-left"></i> btn-back</a>
	<div class="text text-center text-muted title-decorative mt-1" translate>
		heading-pay-n-confirm</div>
</div>

<div class="card-body bg-light">
	<div class="row" *ngIf="is_cancelled == true">
		<div class="col-12 pt-2 pb-4">
			<h3 class="text h4" translate>heading-payment-error</h3>
			<hr>
			<div translate>txt-payment-error-information</div>
		</div>
	</div>
	<div class="row" *ngIf="is_error == true">
		<div class="col-12">
			<h3 class="text text-danger" translate>heading-payment-error</h3>
			<div translate>txt-payment-error-information</div>
		</div>
	</div>
	<div class="row mb-2 mt-0" *ngIf="!is_error && !has_paypal_payment_tried &&
		!has_stripe_payment_tried">
		<div class="col-sm-12 col-md-12 col-lg-12">
			<label class="title-decorative mb-0" translate="">lbl-notes</label>
			<textarea class="form-control" [(ngModel)]="notes" rows="2"
				placeholder=""></textarea>
		</div>
	</div>
</div>

<!-- 
	=uncomment this to show saved card options 
<div *ngIf="payment_type=='Stripe' && !has_stripe_payment_tried" class="card-header bg-secondary pl-2 pr-2 border-top"
	style="padding-top:18px">
	<div class="row pl-2 pr-2">
		<div class="col-md-6 col-lg-6">
			<div class="custom-control custom-radio">
				<input id="new" type="radio" class="custom-control-input"
					[value]="1"
					[(ngModel)]="card_options">
				<label class="custom-control-label" for="new" translate>lbl-pay-with-new-card</label>
			</div>
		</div>
		<div class="col-md-6 col-lg-6">
			<div class="custom-control custom-radio">
				<input id="existing" type="radio"
					class="custom-control-input"
					[value]="2" [(ngModel)]="card_options">
				<label class="custom-control-label" for="existing"
					translate>lbl-pay-with-a-saved-card</label>
			</div>
		</div>
	</div>
</div>
-->
<div class="card-body pt-4 pb-4 border-top pr-3 pl-3" *ngIf="!is_error">

	<!-- <div *ngIf="false && payment_type=='Stripe' && !has_stripe_payment_tried" class="">
		<form novalidate [formGroup]="stripeTest">
			<div id="card-element" class="field border bg-light rounded shadow-sm pl-2 pr-2"></div>
		</form>
		<div class="text-center">
			<img src="/assets/img/powered_by_stripe@2x.png" width="140" >
		</div>
	</div>
	-->
	<div class="row mb-0 mt-0" *ngIf="payment_type=='Stripe' &&
		!has_stripe_payment_tried">
		<div class="col-sm-12 col-md-12 col-lg-12">
			<div class="">
				<!-- <div class="text-muted" translate="">txt-all-payments-are-handled-by-stripe</div>-->
				<div class="card ml-4 mr-4 border-0"*ngIf="stripe_3d_step == '1'">
					<div class="card-body p-0" *ngIf="card_options == '1'">
						<div class="row form-group">
							<div class="col col-sm-12">
								<label translate>lbl-card-number</label> <br>
								<input  autocomplete="off" class="form-control form-control-lg" type="text"
									[(ngModel)]="stripe.number" autocomplete="cc-number" />
							</div>
						</div>
						<div class="row form-group">
							<div class="col col-6 col-md-4 col-sm-6">
								<label translate>lbl-expiry-month</label><br>
								<input  autocomplete="off" class="form-control form-control-lg" mask="00" type="text"
									[(ngModel)]="stripe.exp_month" autocomplete="cc-exp-month" />
							</div>
							<div class="col col-6 col-md-4 col-sm-6">
								<label translate>lbl-expiry-year</label><br>
								<input autocomplete="off"  class="form-control form-control-lg" mask="00" type="text"
									[(ngModel)]="stripe.exp_year" autocomplete="cc-exp-year" />
							</div>
							<div class="col col-6 col-md-4 scol-sm-6">
								<label translate>lbl-cvc</label><br>
								<input autocomplete="off"  class="form-control form-control-lg" mask="000" type="password"
									[(ngModel)]="stripe.cvc" autocomplete="cc-csc" />
							</div>
						</div>
						<!-- 
						<div class="row form-group">
							<div class="col">
								<div class="custom-control custom-checkbox custom-checkbox-switch">
									<input type="hidden" [(ngModel)]="save_new_card">
									<input type="checkbox" class="custom-control-input"
										[checked]="save_new_card ===
										boolean_status[0].value"
										(change)="$event.target.checked? (save_new_card=
										boolean_status[0].value) :
										(save_new_card= boolean_status[1].value)"
										id="save_new_card">
									<label class="custom-control-label text" for="save_new_card"
										translate>lbl-save-this-card</label>
								</div>
							</div>
						</div>
						-->
					</div>
					<div class="card-body pt-3 pb-2" *ngIf="card_options == '2'">
						<div class="row col text-danger" *ngIf="existing_cards.stripe.length <1"
							translate="">
							lbl-card-not-found
						</div>
						<div class="row form-group" *ngIf="existing_cards.stripe.length> 0">
							<div class="col">
								<label translate>lbl-select-a-card-for-payment</label> <br>
								<select [(ngModel)]="selected_existing_card" class="form-control">
									<option [value]="cc.gateway_token" *ngFor="let cc of
										existing_cards.stripe">{{cc.card_number}}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="" *ngIf="stripe_3d_step == '2'" translate="">
					<img src="assets/img/ajax-loader-light-gray.gif" translate="">
					txt-waiting-for-3D-secure-verification-from-stripe
				</div>
				<div class="" *ngIf="stripe_3d_step == '3'" translate="">
					<img src="assets/img/ajax-loader-light-gray.gif" translate="">
					txt-trying-to-finish-payment-and-booking-please-wait
				</div>
				<div class="mt-2">
					<img src="/assets/img/powered_by_stripe@2x.png" width="140">
				</div>

			</div>
		</div>
	</div>
</div>

<ngx-smart-modal [closable]=false [escapable]=false [dismissable]=false
	[customClass]="'h-100'" identifier="stripe_3d">
	<div class="row form-group p-0 h-100">
		<iframe [src]="stripe_3d_url" style="zoom:1" frameborder="0" height="100%"
			width="100%"></iframe>
	</div>
	<div class="row">
		<!-- <button class="btn shadow  btn-dark btn-lg btn-block" (click)="onClick_FinishedStripe3D()" translate="">btn-when-card-is-verified-please-click-here</button> -->
		<button class="btn shadow btn-light btn-lg text-danger"(click)="onClick_CloseStripe3D()"
			translate="">btn-or-cancel-your-booking</button>
	</div>
</ngx-smart-modal>

<div class="card-footer bg-light pl-2 pr-2" *ngIf="!is_error">
	<button class="btn btn-lg btn-outline-secondary" (click)="onClick_StartAgain()"
		translate>
		<i class="icon icon-chevron-left"></i> btn-start-again
	</button>
	<span class="float-right" *ngIf="!has_paypal_payment_tried &&
		payment_type=='Paypal'">
		<ngx-paypal [config]="payPalConfig"></ngx-paypal>
	</span>
	<span class="float-right" *ngIf="payment_type=='Stripe' &&
		!has_stripe_payment_tried">

		<div class="text text-center title-decorative mt-2" *ngIf="config.is_loading
			== true" translate>
			<img src="assets/img/ajax-loader-light-gray.gif" translate> txt-loading
		</div>

		<button *ngIf="stripe_3d_step != '3' && config.is_loading == false && (
			card_options == '1' || (card_options == '2' && existing_cards.stripe.length>
			0) )" class="btn btn-dark btn-lg"
			(click)="onClick_PayWithStripe()" translate=""><i class="fa fa-check"></i>
			btn-confirm-payment</button>
	</span>
</div>
