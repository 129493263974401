import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthHelper } from '../../_helper/auth';
import { LocaleHelper } from '../../_helper/locale';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../_service/auth.service';
import { SupportService } from 'src/app/_service/support.service';
import { UrlHelper } from 'src/app/_helper/url';
import { BusinessService } from '../../_service/business.service';


@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {

  current = "nb";
  @Output() emitLayout = new EventEmitter<object>();
  business:any;

  show_cookie_banner = false;
  is_customer = false;

  constructor(
    public auth: AuthHelper,
    private authService: AuthService,
    private locale: LocaleHelper,
    private meta: Meta,
    private url: UrlHelper,
    private router: Router,
    private businessService: BusinessService,
    private support: SupportService,
    private translate: TranslateService
  ) {
    //this.meta.addTag({ name: 'description', content: 'How to use Angular 4 meta service' });
    //this.meta.addTag({ name: 'author', content: 'talkingdotnet' });
    //this.meta.addTag({ name: 'keywords', content: 'Angular, Meta Service' });
    //this.meta.addTag({ name: 'charset', content: 'utf-8' });

    let subdomain = this.url.getSubdomain();
    this.businessService.getBySubdomain(subdomain).subscribe(data => {
      let temp: any;
      temp = data;
      this.business = temp.data;
      console.log(this.business);

    });


    if(auth.isLoggedIn()){
      let _p = {"id":auth.getUser().id};
      this.support.checkCookieBanner(_p).subscribe(data=>{
        let temp:any;
        temp = data;
        if(temp.data){
          this.show_cookie_banner = true;
        }

      });
    }
  }

  /**
   * 
   * @param lang 
   */
  onClick_ChangeLanguage() {
    if (this.current == 'en') {
      this.current = 'nb';
    }
    else {
      this.current = 'en';
    }
    this.translate.use(this.current);
    this.locale.setLocale(this.current);
    this.emitLayout.emit({ "lang": this.current });
  }

  /**
   * 
   * @param flag 
   */
  onClick_Cookie(flag) {
    let _p = { "auth_token": this.auth.getUser().auth_token, "cookie_flag": flag };
    this.support.cookie(_p).subscribe(data => {
      this.show_cookie_banner = false;
    });
  }

  /**
   * 
   */
  ngOnInit() {
    if (this.auth.getUser().user_type == 'su') {
      this.router.navigateByUrl("/su/dashboard");
    }
    if(this.auth.getUser().user_type == 'customer'){
      this.is_customer = true;
    }
    this.current = this.locale.getLocale();
    this.translate.use(this.locale.getLocale());
    //console.log(this.auth.getUser());
    /**         
    setInterval(() => { 
      if(!this.auth.isLoggedIn()){
        this.router.navigate(['/signout']);
      }
    }, 5000);

    setInterval(() => {
      this.authService.isLoggedInOnServer().subscribe(data => {
        let temp: any;
        temp = data;
        if (temp.success == false) {
          this.router.navigate(['/signout']);          
        }
      });
    },10000);
    */

  }
}