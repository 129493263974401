import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertService } from '../../../_service/alert.service';
import { AuthService } from '../../../_service/auth.service';
import * as moment from 'moment-timezone';
import { StringHelper } from '../../../_helper/string';
import { LocaleHelper } from '../../../_helper/locale';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { UrlHelper } from 'src/app/_helper/url';
import { ActivatedRoute } from '@angular/router';
import { SupportService } from 'src/app/_service/support.service';

@Component({
  selector: 'app-provider-signup',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css']
})
export class ProviderComponent implements OnInit {

  @Output() backToPlan = new EventEmitter();
  @Input() plan: any;

  countries = [
    {
      "name": "Norway",
      "code": "Norwegian",
      "locale": "no-NO"
    },
    {
      "name": "US",
      "code": "English",
      "locale": "en-US"
    },

  ]

  /**
   * 
   */
  name = "";
  mobile = "";
  country = "Norway";
  username = "";
  password = "";
  confirm_password = "";
  subdomain = "";
  is_signup_pending = true;
  zones = [];
  timezone = "Europe/Oslo";
  utc_offset = "";
  env: any;
  is_loading = false;
  billing_language = "no-NO";

  street = "";
  city = "";
  state = "";
  zipcode = "";
  contact_name = "";
  contact_email = "";
  contact_mobile = "";
  tax_id = "";
  payment_type = "1";
  tnc = false;

  plan_id=0;
  plan_type='m';


  /**
   * 
   */
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    public url: UrlHelper,
    private route: ActivatedRoute,
    private locale: LocaleHelper,
    private supportService:SupportService,
    private string: StringHelper,
    private alertService: AlertService
  ) {

    this.route.queryParams.subscribe(params => {
      this.plan_id = params['id'];
      this.plan_type = params['type'];
    });
    
    this.supportService.plans({}).subscribe(data=>{
      console.log(data);
    });
    

    this.env = environment;
    this._makeTimezoneArray();
    this._resetForm();

  }

  /**
   * 
   * @param lang 
   */
  onClick_ChangeLanguage(lang) {
    this.locale.setLocale(lang);
    this.translate.use(lang);
  }


  ngOnInit() {
    this.translate.use(this.locale.getLocale());
  }

  onClick_BackToPlans() {
    this.backToPlan.emit();
  }

  /**
   * 
   */
  _resetForm() {

  }

  /**
 * 
 */
  _makeTimezoneArray() {
    let names = moment.tz.names();
    let i: any;
    let _m = "";
    names.forEach((k, ii) => {
      _m = moment.tz(k).format("Z");
      i = { "timezone": k, "offset": _m };
      this.zones.push(i);
    });

  }

  onClick_Signup() {

    let flag = true;
    this._checkTz();
    if (!this.tnc) {
      this.alertService.error("Please accept Terms and Conditions");
      flag = false;
    }
    if (this.utc_offset == "" || this.timezone == "") {
      this.alertService.error(this.translate.instant("alert-please-select-timezone"));
      flag = false;
    }
    if (!this.string.isValidEmail(this.username.trim())) {
      this.alertService.error(this.translate.instant("alert-valid-email-is-required"));
      flag = false;
    }

    if (!this.string.isValidNorwayMobile(this.mobile)) {
      this.alertService.error(this.translate.instant("alert-valid-mobile-is-required"));
      flag = false;
    }

    if (this.username.trim() == "" || this.username.trim().length < 6 || (this.username.split(' ').length - 1) > 0) {
      this.alertService.error(this.translate.instant("alert-valid-email-is-required"));
      flag = false;
    }
    if (this.password.trim() == "" || this.password.trim().length < 8 || (this.password.split(' ').length - 1) > 0) {
      this.alertService.error(this.translate.instant("alert-password-is-required"));
      flag = false;
    }
    if (this.password.trim() != this.confirm_password.trim()) {
      this.alertService.error(this.translate.instant("alert-confirm-password-does-not-match"));
      flag = false;
    }
    if (this.subdomain.trim() == "" || this.subdomain.trim().length < 2) {
      this.alertService.error(this.translate.instant("alert-valid-subdomain-is-required"));
      flag = false;
    }
    if (this.name.trim() == "") {
      this.alertService.error(this.translate.instant("alert-business-name-is-required"));
      flag = false;
    }
    if (flag == true) {
      this._save();
    }

  }

  /**
   * 
   */
  _checkTz() {
    this.zones.forEach((k, i) => {
      if (k.timezone == this.timezone) {
        this.utc_offset = k.offset;
      }
    });
  }

  /**
   * 
   */
  _save() {
    let pt = "m";
    if (this.payment_type == '2') {
      pt = "y";
    }
    let _p = {
      "name": this.name,
      "tnc":this.tnc,
      "mobile": this.mobile,
      "street": this.street,
      "city": this.city,
      "state": this.state,
      "zipcode": this.zipcode,
      "contact_name": this.contact_name,
      "contact_email": this.contact_email,
      "contact_mobile": this.contact_mobile,
      "tax_id": this.tax_id,
      "country": "Norway",
      "billing_language":this.billing_language,
      "subdomain": this.subdomain,
      "domain":this.url.getDomainName(),
      "country_mobile_code": "40",
      "username": this.username,
      "timezone": this.timezone,
      "utc_offset": this.utc_offset,
      "plan": this.plan.id,
      "payment_type": pt,
      "password": this.password
    };
    this.is_loading = true;
    this.authService.providerSignup(_p).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.msg == "1") {
        this.is_signup_pending = false;
      }
      else if (temp.msg == "3") {
        this.alertService.error(this.translate.instant("alert-email-already-exists"));
      }
      else if (temp.msg == "2") {
        this.alertService.error(this.translate.instant("alert-subdomain-already-exists"));
      }
      else {
        this.alertService.error(temp.msg);
      }

      this.is_loading = false;

    });

  }

}