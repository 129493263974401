import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_service/alert.service';
import { BusinessService } from '../../_service/business.service';
import { AuthService } from '../../_service/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LocaleHelper } from '../../_helper/locale';
import { AuthHelper } from '../../_helper/auth';
import { environment } from '../../../environments/environment';

import { UrlHelper } from '../../_helper/url';


interface Response {
  success: boolean;
  data: any;
  msg: string;
}

/**
 * /================================
 * 
 *      ||||||||||      
 *   \==============/
 *       0      0
 *          ||
 *        /----\
 *           
 * 
 *  WARNING, DO NOT FORGET TO MAKE CHANGES IN BOOKING/CREATE/LOGIN COMPONENT
 * 
 * //===============================
 */
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  submitted = false;
  env: any;
  is_loading = false;
  returnUrl: string;
  public email = '';
  public password = '';
  subdomain = '';
  business = { 'enable_internal_booking': '1' };

  /**
   * 
   * @param alertService 
   * @param router 
   * @param translate 
   * @param url 
   * @param locale 
   * @param businessService 
   * @param route 
   * @param authService 
   */
  constructor(
    private alertService: AlertService,
    private router: Router,
    private translate: TranslateService,
    public url: UrlHelper,
    private auth: AuthHelper,
    private locale: LocaleHelper,
    private businessService: BusinessService,
    private route: ActivatedRoute,
    private authService: AuthService) {

    this.env = environment;
  }

  /**
   * 
   * @param lang 
   */
  onClick_ChangeLanguage(lang) {
    this.locale.setLocale(lang);
    this.translate.use(lang);
  }


  /**
   * 
   */
  ngOnInit() {

    this.translate.use(this.locale.getLocale());
    this.subdomain = this.url.getSubdomain();
    this.authService.signout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this._findBusiness();
  }

  /**
   * 
   */
  _findBusiness() {
    this.businessService.getBySubdomain(this.subdomain).subscribe(data => {
      let temp: any;
      temp = data;
      if (temp.success == true) {
        if (temp.data.subdomain != this.subdomain) {
          this.alertService.error(this.translate.instant("alert-invalid-business-account"));
        } else {
          this.business = temp.data;
        }
      }
      else {
        this.router.navigate(["/page-not-found"]);
      }
    });
  }

  /**
   * 
   */
  onSubmit_Signin() {
    this.submitted = true;
    this.is_loading = true;

    if (this.subdomain == "") {
      this.alertService.error(this.translate.instant("alert-invalid-business-account"));
    } else {
      this.authService.signin(this.email, this.password, this.subdomain).pipe(first()).subscribe(
        (res: Response) => {
          this.is_loading = false;

          if (res.success) {
            if (this.returnUrl == 'undefined' || this.returnUrl == undefined || this.returnUrl == "") {
              var is_force_reset_password = this.auth.getUser().is_force_reset_password;
              var x = this.auth.getUser().reset_password_token;
              if (is_force_reset_password == 1) {
                // c = 911 means we are forcing to change the password and 
                // need to show helpful information why we are asking to change it.
                this.router.navigate(['/reset-password'], { queryParams: { "c": "911", "x": x } });
              }
              else {
                if (this.auth.getUser().user_type == 'customer') {
                  this.router.navigate(['/booking.new']);
                }
                if (this.auth.getUser().user_type == 'su') {
                  this.router.navigate(['/su/dashboard']);
                }
                if (this.auth.getUser().user_type == 'provider') {
                  this.router.navigate(['/']);
                }
                if (this.auth.getUser().user_type == 'agent') {
                  this.router.navigate(['/']);
                }
              }
            } else {
              this.router.navigate([this.returnUrl]);
            }
          } else {
            this.alertService.error(this.translate.instant("alert-invalid-username-password"));
          }
        },
        error => {
          this.is_loading = false;
          this.alertService.error(this.translate.instant("alert-error"));
        });
    }
  }

  onClick_ForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  onClick_Signup() {
    this.router.navigateByUrl("/signup");
  }

}
