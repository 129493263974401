import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpFormEncodingCodec } from './../../_helper/http.form.codec';
import { ParamsHelper } from '../../_helper/params';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BusinessService = /** @class */ (function () {
    /**
     *
     * @param http
     */
    function BusinessService(http) {
        this.http = http;
        this.params = new ParamsHelper();
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    /**
     *
     */
    BusinessService.prototype._getBody = function () {
        return new HttpParams({ encoder: new HttpFormEncodingCodec() });
    };
    /**
     *
     */
    BusinessService.prototype.getAll = function () {
        return this.http.get(environment.apiv2_server + "/su/business", { headers: this.params.makeHeadersWithAuth() })
            .pipe(map(function (data) {
            return data;
        }));
    };
    BusinessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessService_Factory() { return new BusinessService(i0.ɵɵinject(i1.HttpClient)); }, token: BusinessService, providedIn: "root" });
    return BusinessService;
}());
export { BusinessService };
