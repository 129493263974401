import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule,  } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {  HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

import { AppRoutes } from './app.routing';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { ForgotPasswordComponent } from './_support/forgot-password/forgot-password.component';

import { SignoutComponent } from './auth/signout/signout.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { PlanComponent } from './auth/plan/plan.component';
import { StatsComponent } from './dashboard/stats/stats.component';


import { NewComponent as BookingNewComponent } from './booking/new/new.component';
import { ServiceComponent as BookingNewServiceComponent } from './booking/new/service/service.component';
import { AppointmentComponent as BookingNewDatetimeAppointmentComponent } from './booking/new/datetime/appointment/appointment.component';
import { RentalComponent as BookingNewDatetimeRentalComponent } from './booking/new/datetime/rental/rental.component';
import { AccommodationComponent as BookingNewDatetimeAccommodationComponent } from './booking/new/datetime/accommodation/accommodation.component';
import { CustomerComponent as BookingNewCustomerComponent } from './booking/new/customer/customer.component';
import { LoginComponent as BookingNewCustomerLoginComponent } from './booking/new/customer/login/login.component';
import { ConfirmComponent as BookingNewConfirmComponent } from './booking/new/confirm/confirm.component';
import { PayComponent as BookingNewPayComponent } from './booking/new/customer/pay/pay.component';
import { FinishComponent as BookingNewFinishComponent } from './booking/new/finish/finish.component';
import { ErrorComponent as BookingNewErrorComponent } from './booking/new/error/error.component';

import { CreateComponent as BookingCreateComponent } from './booking/create/create.component';


import { AppointmentComponent as BookingCreateAppointmentComponent } from './booking/create/appointment/appointment.component';
import { RentalComponent as BookingCreateRentalComponent } from './booking/create/rental/rental.component';
import { CommonComponent as BookingCreateCommonComponent } from './booking/create/common/common.component';
import { AccommodationComponent as BookingCreateAccommodationComponent } from './booking/create/accommodation/accommodation.component';
import { CategoryComponent as BookingCreateCategoryComponent } from './booking/create/category/category.component';
import { ServiceComponent as BookingCreateServiceComponent } from './booking/create/service/service.component';
import { CustomerComponent as BookingCreateCustomerComponent } from './booking/create/customer/customer.component';
import { LoginComponent as BookingCreateLoginComponent } from './booking/create/login/login.component';
import { PayComponent as BookingCreatePayComponent } from './booking/create/pay/pay.component';

import { LandingComponent as BookingLandingComponent } from './booking/landing/landing.component'; 
import { DetailComponent as BookingDetailComponent } from './booking/detail/detail.component'; 
import { ListComponent as CustomerListComponent } from './customer/list/list.component';
import { CreateComponent as CustomerCreateComponent } from './customer/create/create.component';
import { DetailComponent as CustomerDetailComponent } from './customer/detail/detail.component'; 
import { HistoryComponent as PaymentHistoryComponent } from './payment/history/history.component';
import { MessageComponent as ConversationMessageComponent } from './conversation/message/message.component';
import { SmsComponent as ConversationSmsComponent } from './conversation/sms/sms.component';
import { CalendarComponent as ConfigCalendarComponent } from './config/calendar/calendar.component';
import { ServiceComponent as ConfigServiceComponent } from './config/service/service.component';
import { CategoryComponent as ConfigCategoryComponent } from './config/category/category.component';
import { PortfolioComponent as ConfigPortfolioComponent } from './config/portfolio/portfolio.component';
import { UserComponent as ConfigUserComponent } from './config/user/user.component';
import { MainComponent as ConfigMainComponent } from './config/main/main.component';
import { ProfileComponent as ConfigProfileComponent } from './config/profile/profile.component';
import { PaymentComponent as ConfigPaymentComponent } from './config/payment/payment.component';
import { GlobalComponent as ConfigGlobalComponent } from './config/global/global.component';
import { PaypalComponent as ConfigPaypalComponent } from './config/payment/paypal/paypal.component';
import { TemplatesComponent as ConfigTemplatesComponent } from './config/templates/templates.component';
import { StripeComponent as ConfigStripeComponent } from './config/payment/stripe/stripe.component';
import { ScheduleComponent as ConfigScheduleCalendarComponent } from './config/calendar/schedule/schedule.component'; 

import { CustomerComponent as CustomerSignupComponent } from './auth/signup/customer/customer.component';
import { ProviderComponent as ProviderSignupComponent } from './auth/signup/provider/provider.component';

import { SigninLayoutComponent } from './_layout/signin-layout/signin-layout.component';
import { SignupLayoutComponent } from './_layout/signup-layout/signup-layout.component';
import { AlertComponent } from './_directive/alert/alert.component';
import { AuthService } from './_service/auth.service';
import { AuthGuard } from './_guard/auth.guard';
import { AlertService } from './_service/alert.service';
import { UserService } from './_service/user.service';

import {NgxMaskModule} from 'ngx-mask';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ModalComponent} from './_com/modal/modal.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ColorPickerModule } from 'ngx-color-picker';
import { CalendarModule as PrimeNgCalendar }  from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule as EditorModule } from 'primeng/editor';
import { NotifierModule ,NotifierOptions } from 'angular-notifier';

import { NgxPayPalModule } from 'ngx-paypal';

import { FullCalendarModule } from '@fullcalendar/angular';

import { JwtInterceptor } from './_helper/jwt.interceptor';
import { ErrorInterceptor } from './_helper/error.interceptor';
import { UrlHelper } from './_helper/url';
import { StringHelper } from './_helper/string';
import { DateHelper } from './_helper/date';
import { AuthHelper } from './_helper/auth';
import { LocaleHelper } from './_helper/locale';
import { FileHelper } from './_helper/file';
import { PlanHelper } from './_helper/plan';
import { CalendarHelper } from './_helper/calendar';
import { HttpFormEncodingCodec } from './_helper/http.form.codec';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { PageNotFoundComponent } from './_support/page-not-found/page-not-found.component';
import { AppComponent } from './app.component';
import { ResetPasswordComponent } from './_support/reset-password/reset-password.component';

import { environment } from '../environments/environment';
import { AboutComponent } from './_support/about/about.component';
import { TncComponent } from './_support/tnc/tnc.component';
import { PrivacyComponent } from './_support/privacy/privacy.component';
import { FaqsComponent } from './_support/faqs/faqs.component';
import { ContactComponent } from './_support/contact/contact.component';
import { GoodByeComponent } from './_support/good-bye/good-bye.component';

import { NgxStripeModule } from 'ngx-stripe';
import { GdprComponent } from './_support/gdpr/gdpr.component';
import { SuComponent } from './su/su.component';
import { SuLayoutComponent } from './_layout/su-layout/su-layout.component';
import { BusinessComponent } from './su/business/business.component';
import { DashboardComponent } from './su/dashboard/dashboard.component';
import { WelcomeComponent } from './_support/welcome/welcome.component';
import { BookingComponent } from './su/booking/booking.component';
import { UserComponent } from './su/user/user.component';
import { PaymentComponent as SuPaymentComponent } from './su/payment/payment.component';
import { LogComponent } from './su/log/log.component';
import { DetailsComponent as SuUserDetailsComponent } from './su/user/details/details.component';
import { DetailsComponent as SuBookingDetailsComponent} from './su/booking/details/details.component';
import { LoadingComponent } from './_support/loading/loading.component';

export function HttpLoaderFactory(http: HttpClient) {
  let h = new TranslateHttpLoader(http,environment.lang_location,'.json');
  return h;
}


const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      distance: 4
    },
    vertical: {
      position: 'top',
      distance: 64,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 2500,
    onClick: "hide",
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 8
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppLayoutComponent,
    ForgotPasswordComponent,
    SigninComponent,
    SignoutComponent,
    PlanComponent,
    SignupComponent,
    StatsComponent,
 
    BookingNewComponent,
    BookingNewServiceComponent,
    BookingNewDatetimeAppointmentComponent,
    BookingNewDatetimeRentalComponent,
    BookingNewDatetimeAccommodationComponent,
    BookingNewCustomerComponent,
    BookingNewCustomerLoginComponent,
    BookingNewConfirmComponent,
    BookingNewFinishComponent,
    BookingNewPayComponent,
    BookingNewErrorComponent,
 
    BookingCreateComponent,
    BookingCreateAppointmentComponent,
    BookingCreateRentalComponent,
    BookingCreateAccommodationComponent,
    BookingCreateCategoryComponent,
    BookingCreateServiceComponent,
    BookingCreateLoginComponent,
    BookingCreateCustomerComponent,
    BookingCreatePayComponent,
    BookingCreateCommonComponent,
    BookingLandingComponent,
    BookingDetailComponent,
    CustomerCreateComponent,
    CustomerDetailComponent,
    CustomerListComponent,
    PaymentHistoryComponent,
    ConversationMessageComponent,
    ConversationSmsComponent,
    ConfigCalendarComponent,
    ConfigCategoryComponent,
    ConfigServiceComponent,
    ConfigUserComponent,
    ConfigProfileComponent,
    ConfigMainComponent,
    ConfigPaymentComponent,
    ConfigGlobalComponent,
    ConfigTemplatesComponent,
    ConfigPaypalComponent,
    ConfigPortfolioComponent,
    ConfigStripeComponent,
    ConfigScheduleCalendarComponent,

    CustomerSignupComponent,
    ProviderSignupComponent,
    ModalComponent,
    
    SigninLayoutComponent,
    SignupLayoutComponent,
    AlertComponent,
    PageNotFoundComponent,
    AppComponent,
    ResetPasswordComponent,
    AboutComponent,
    TncComponent,
    PrivacyComponent,
    GdprComponent,
    FaqsComponent,
    ContactComponent,
    GoodByeComponent,
    GdprComponent,
    SuComponent,
    SuLayoutComponent,
    BusinessComponent,
    DashboardComponent,
    WelcomeComponent,
    BookingComponent,
    UserComponent,
    SuPaymentComponent,
    LogComponent,
    SuUserDetailsComponent,
    SuBookingDetailsComponent,
    LoadingComponent

  ],
  imports: [
    RouterModule.forRoot(AppRoutes,{useHash:true,onSameUrlNavigation: 'reload'}),    
    ColorPickerModule,
    BrowserAnimationsModule,
    Ng2SmartTableModule,
    FullCalendarModule,
    HttpClientModule,
    PrimeNgCalendar, 
    DropdownModule,
    EditorModule,
    NgxPayPalModule,
    NgxStripeModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAWQe_4r_pqtJ6KRoJ7LPznCZT_6dN_vVo'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(),    
    NgxSmartModalModule.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    NgbModule.forRoot()
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthService,
    CalendarHelper,
    PlanHelper,
    AuthHelper,   
    DateHelper,
    UrlHelper,
    StringHelper,
    LocaleHelper,
    FileHelper,
    UserService,
    HttpFormEncodingCodec,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: APP_BASE_HREF, useValue: '/'}
    //{provide: APP_BASE_HREF,useValue: '/' + (window.location.pathname.split('/')[1] || '')

  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
